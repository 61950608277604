import { FC, MouseEvent, useCallback } from 'react';
import { storeApi, useStoreActions } from '@jux/canjux/core';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { LayersPanelItem } from '@jux/ui/components/editor/components/panels/layers/state/getLayersItemsRecursive';
import { useSetLayersPanelState } from '@jux/ui/components/editor/components/panels/layers/state/useSetLayersPanelState';
import { useUpdateNodeDisplayName } from '@jux/ui/components/editor/hooks';
import { useTrackEvents } from '@jux/ui/hooks';
import { useLiveOrEdit } from '../../../liveOrEdit/useLiveOrEdit';
import {
  useDropZoneIndication,
  useLayersPanelExpansion,
  useLayersPanelStateValue,
  useLayersPanelVisibleItems,
  useReorderLayersPanel,
} from '../../state';
import { ObjectNavigatorItem } from './ObjectNavigatorItem';

export const ObjectNavigatorItems: FC<{
  items: Map<string, LayersPanelItem>;
}> = ({ items }) => {
  const visibleItems = useLayersPanelVisibleItems({ items });

  const { reorderHoveredItem } = useLayersPanelStateValue();
  const {
    commonActions: { setHoveredNode, setSelectedNodes },
  } = useStoreActions();

  const { toggleItemExpansion } = useLayersPanelExpansion({ items });
  const setLayersPanelState = useSetLayersPanelState();
  const updateNodeDisplayName = useUpdateNodeDisplayName();
  const {
    setReorderDraggedItem,
    setReorderHoveredItem,
    handleDropReorderItem,
  } = useReorderLayersPanel();
  const {
    handleDragAbove,
    handleDragBelow,
    handleDragInside,
    handleAbortDragInside,
  } = useDropZoneIndication({ items });

  const { isEdit, isLive } = useLiveOrEdit();

  const {
    trackLayersRenameEvent,
    trackTextNodeDoubleClickEvent,
    trackUpdateComponentDisplayName,
  } = useTrackEvents();

  const handleItemClick = useCallback(
    ({ id, isSelected }: typeof visibleItems[number]) =>
      (event: MouseEvent) => {
        event.stopPropagation();

        if (isLive) {
          return;
        }

        const isMultiSelectionActive = event.shiftKey;

        const isDeselect = isSelected && isMultiSelectionActive;

        if (isDeselect) {
          const { selectedNodesStack } = storeApi.getState();
          setSelectedNodes({
            nodeIds: selectedNodesStack.filter((nodeId) => nodeId !== id),
          });
        } else {
          setSelectedNodes({
            nodeIds: [id],
            append: isMultiSelectionActive,
          });
        }
      },
    [isLive, setSelectedNodes]
  );

  return (
    <MuiGrid container flexDirection="column">
      {visibleItems.map((item) => (
        <ObjectNavigatorItem
          {...item}
          key={item.path}
          onDragStart={() => {
            setReorderDraggedItem(item);
            setSelectedNodes({ nodeIds: [item.id] });
          }}
          onDrop={() => {
            handleDropReorderItem(item);
          }}
          onDragOver={() => {
            setReorderHoveredItem(item);
          }}
          onDragEnd={() => {
            if (
              reorderHoveredItem &&
              reorderHoveredItem.droppable &&
              reorderHoveredItem !== item
            ) {
              handleDropReorderItem(reorderHoveredItem);
            }
            setReorderDraggedItem(undefined);
            setReorderHoveredItem(undefined);
          }}
          onDragAbove={handleDragAbove}
          onDragBelow={handleDragBelow}
          onDragInside={handleDragInside}
          onAbortDragInside={handleAbortDragInside}
          onMouseEnter={() => {
            // When hovering on node from the layers panel, we need to set it directly
            setHoveredNode({
              nodeId: item.id,
              isHovered: true,
              affectChildren: item.isRoot,
            });
          }}
          onMouseLeave={() => {
            setHoveredNode({
              nodeId: item.id,
              isHovered: false,
              affectChildren: item.isRoot,
            });
          }}
          onClick={handleItemClick(item)}
          onExpanderClick={() => toggleItemExpansion(item.path)}
          onLabelBlur={(event) => {
            setLayersPanelState((state) => ({
              ...state,
              editingPath: undefined,
            }));
            if (event.target.innerText !== item.label) {
              updateNodeDisplayName({
                nodeId: item.id,
                value: event.target.innerText,
              });
              trackUpdateComponentDisplayName('layers');
            }
          }}
          onLabelDoubleClick={(event) => {
            event.stopPropagation();
            setLayersPanelState((state) => ({
              ...state,
              editingPath: item.path,
            }));

            trackLayersRenameEvent(
              event.currentTarget.innerText,
              item.indentLevel
            );

            trackTextNodeDoubleClickEvent({
              isEditMode: isEdit,
              clickedOn: 'layers',
            });
          }}
        />
      ))}
    </MuiGrid>
  );
};
