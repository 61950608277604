import {
  JuxStore,
  JuxStoreActionFn,
  replacePathsInStyles,
  TokenSetsActionsParams,
  updateComponentsWithNewStyles,
} from '@jux/canjux/core';
import {
  getCurrentTimestamp,
  namingPatternsSchema,
} from '@juxio/design-tokens';
import cloneDeep from 'lodash/cloneDeep';
import { z } from 'zod';
import { getDesignTokensParserContext } from '../../../store-utils/getDesignTokensParserContext';
import {
  isValidGroupPath,
  prepareGroupPathForStringifyActions,
} from '@jux/ui/utils/tokensPatterns';
import { createPath } from '@jux/ui/utils/tokensPath';
import { Draft as WritableDraft } from 'mutative';

const replaceGroupPathInComponentsStyles = ({
  components,
  newPath,
  oldPath,
}: {
  components: WritableDraft<JuxStore['components']>;
  newPath: string;
  oldPath: string;
}) => {
  const stylesToChangeByComponentId = Object.entries(components)
    .map(([componentId, { styles }]) => {
      if (!styles) return undefined;

      const formattedOldGroupPath =
        prepareGroupPathForStringifyActions(oldPath);
      const formattedNewGroupPath =
        prepareGroupPathForStringifyActions(newPath);

      if (
        isValidGroupPath(formattedOldGroupPath) &&
        isValidGroupPath(formattedNewGroupPath)
      ) {
        const updatedStyles = replacePathsInStyles({
          paths: [
            {
              oldPath: formattedOldGroupPath,
              newPath: formattedNewGroupPath,
            },
          ],
          styles,
        });

        if (updatedStyles) {
          return { [componentId]: updatedStyles };
        }
      }

      return undefined;
    })
    .filter(Boolean);

  updateComponentsWithNewStyles({
    components,
    stylesToChangeByComponentId,
  });
};

const designTokensParser = getDesignTokensParserContext();

export const renameGroup: JuxStoreActionFn<
  TokenSetsActionsParams['renameGroup'],
  JuxStore
> = ({ state, ...params }) => {
  const {
    path: groupPath,
    newName: groupName,
    oldName,
  } = z
    .object({
      newName: namingPatternsSchema,
      oldName: namingPatternsSchema,
      path: z.string(),
    })
    .parse(params);

  Object.values(state.tokenSets).forEach((tokenSet) => {
    tokenSet.value = designTokensParser
      .parse(cloneDeep(tokenSet.value))
      .setGroup({
        allowOverwrite: false, // we want to throw error if the new group name already exists in the containing group
        groupPath,
        groupName,
        oldName,
      })
      .getRawValueCopy();

    tokenSet.updatedAt = getCurrentTimestamp();
  });

  replaceGroupPathInComponentsStyles({
    components: state.components,
    newPath: createPath([groupPath, groupName]),
    oldPath: createPath([groupPath, oldName]),
  });

  return state;
};
