/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';

export const DivIcon = React.forwardRef<
  SVGSVGElement,
  React.HTMLAttributes<SVGSVGElement>
>(function DivIcon({ className, ...otherProps }, ref) {
  return (
    <svg
      width={'16'}
      height={'16'}
      viewBox={'0 0 16 16'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      className={className}
      {...otherProps}
    >
      <path
        d={
          'M4.5 4.5V11.5H11.5V4.5H4.5ZM4 3C3.44772 3 3 3.44772 3 4V12C3 12.5523 3.44772 13 4 13H12C12.5523 13 13 12.5523 13 12V4C13 3.44772 12.5523 3 12 3H4Z'
        }
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        fill={'currentColor'}
      />
    </svg>
  );
});
