import { useCallback, useMemo } from 'react';
import { useStoreActions } from '@jux/canjux/core';
import { useTrackEvents } from '@jux/ui/hooks';
import { ComponentTagName } from '@jux/data-entities';
import { generatePropName, generatePropValueName } from '../utils';
import { useParsedProperties } from './useParsedProperties';

export const useCreateNewProp = ({
  componentId,
  tagName,
}: {
  componentId: string | undefined;
  tagName: ComponentTagName | undefined;
}) => {
  const {
    propsActions: { addComponentProp },
  } = useStoreActions();
  const { trackPropCreatedEvent } = useTrackEvents();

  const { parsedProperties } = useParsedProperties();

  const allPropNames = useMemo(
    () => parsedProperties?.map((p) => p.title),
    [parsedProperties]
  );

  const createNewProperty = useCallback(() => {
    if (!componentId || !tagName) return;

    const newPropName = generatePropName(allPropNames ?? []);

    const newValueName = generatePropValueName([]);

    addComponentProp({
      componentId,
      propName: newPropName,
      propValue: newValueName,
    });

    trackPropCreatedEvent({
      componentUUID: componentId,
      tagName,
      propName: newPropName,
    });
  }, [
    componentId,
    tagName,
    allPropNames,
    addComponentProp,
    trackPropCreatedEvent,
  ]);

  return {
    createNewProperty,
  };
};
