import { CanjuxState, JuxStore } from '@jux/canjux/core';
import type { Draft as WritableDraft } from 'mutative';
import { reorderStorageNode } from '../../../store.changes.utils';

import { addInstanceNodes } from './addInstanceNodes';
import { deleteInstanceNodes } from './deleteInstanceNodes';
import { findInstancesOfInstances } from './findInstancesOfInstances';
import { NodeType } from '@jux/data-entities';

const isUptreeContainsDynamicSlot = (
  nodeId: string,
  components: JuxStore['components']
): boolean => {
  let currentComponent = components[nodeId];

  while (currentComponent) {
    if (currentComponent.type === NodeType.DYNAMIC_SLOT) {
      return true;
    }

    if (!currentComponent.parentId) {
      break;
    }

    currentComponent = components[currentComponent.parentId];
  }

  return false;
};

/**
 * Updates the instances of a moved node in the store.
 * @param sourceNodeId - The ID of the source node.
 * @param targetNodeId - The ID of the target node.
 * @param targetIndex - The index of insertion on the target node children.
 * @param state - The draft of the store.
 */
export const updateInstancesOnMovedNode = ({
  sourceNodeId,
  state,
  targetIndex,
  targetNodeId,
}: {
  sourceNodeId: string;
  state: WritableDraft<CanjuxState>;
  targetIndex: number;
  targetNodeId: string;
}) => {
  const { components, canvases } = state;

  // If one of the parent nodes is a dynamic slot, we don't need to update the instances
  const isMovedIntoDynamicSlot = isUptreeContainsDynamicSlot(
    targetNodeId,
    components
  );

  deleteInstanceNodes({
    canvases,
    components,
    sourceNodeId,
  });

  if (!isMovedIntoDynamicSlot) {
    addInstanceNodes({
      sourceNodeId,
      state,
      targetIndex,
      targetNodeId,
    });
  }
};

/**
 * Reorders the children of parent instances based on the instances of a moved node.
 * @param sourceNodeId - The ID of the source node.
 * @param targetIndex - The index at which the instance nodes should be inserted.
 * @param components - The draft of the store's components.
 */
export const reorderParentChildrenOnInstances = ({
  sourceNodeId,
  targetIndex,
  components,
}: {
  sourceNodeId: string;
  targetIndex: number;
  components: WritableDraft<JuxStore['components']>;
}) => {
  const parentId = components[sourceNodeId].parentId;
  if (!parentId) {
    return;
  }

  const parentInstances = findInstancesOfInstances({
    sourceNodeId: parentId,
    components,
  });

  const componentInstancesIds = findInstancesOfInstances({
    sourceNodeId: sourceNodeId,
    components,
  }).map((instance) => instance.id);

  for (const parentInstance of parentInstances) {
    const sourceInstanceToMove = componentInstancesIds.find((instanceId) =>
      parentInstance.children.includes(instanceId)
    );
    if (sourceInstanceToMove) {
      reorderStorageNode(
        parentInstance.children,
        sourceInstanceToMove,
        targetIndex
      );
    }
  }
};
