import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { MaxHeightField } from './MaxHeightField';
import { MinHeightField } from './MinHeightField';
import { HeightField } from './HeightField';

export const Height: FC = () => {
  const { shouldRender } = useSubModule(DDPSubModulesKeys.height);

  if (!shouldRender) return null;
  return (
    <SubModule title="Height">
      <HeightField />
      <MaxHeightField />
      <MinHeightField />
    </SubModule>
  );
};
