import { useStore } from '@jux/canjux/core';
import type { CanjuxProps } from '../../types';
import { useDirectStoreUpdater } from './useDirectStoreUpdater';
import { useEffectStoreUpdater } from './useEffectStoreUpdater';

export const useStoreUpdater = ({
  minZoom,
  maxZoom,
  snapGrid,
  snapToGrid,
  translateExtent,
  fitView,
  nodeOrigin,
  autoPanOnNodeDrag,
  disableNodesInteraction,
}: CanjuxProps) => {
  const { setMinZoom, setMaxZoom, setTranslateExtent } = useStore((s) => ({
    setMinZoom: s.setMinZoom,
    setMaxZoom: s.setMaxZoom,
    setTranslateExtent: s.setTranslateExtent,
  }));

  useDirectStoreUpdater('snapGrid', {
    x: 1,
    y: 1,
  });
  useDirectStoreUpdater('disableNodesInteraction', disableNodesInteraction);
  useDirectStoreUpdater('layersRerenderTimestamp', Date.now());
  useDirectStoreUpdater('snapToGrid', snapToGrid);
  useDirectStoreUpdater('snapGrid', snapGrid);
  useDirectStoreUpdater('fitViewOnInit', fitView);
  useDirectStoreUpdater('nodeOrigin', nodeOrigin);
  useDirectStoreUpdater('autoPanOnNodeDrag', autoPanOnNodeDrag);

  useEffectStoreUpdater(minZoom, setMinZoom);
  useEffectStoreUpdater(maxZoom, setMaxZoom);
  useEffectStoreUpdater(translateExtent, setTranslateExtent);

  return null;
};
