import { useState } from 'react';
import { DesignTokenData } from '@juxio/design-tokens';

export const useSelectedTokenState = () => {
  const [selectedToken, setSelectedToken] = useState<DesignTokenData>();

  return {
    selectedToken,
    setSelectedToken,
  };
};
