import { StateSlice } from '@jux/canjux/core';
import { systemAssets } from '@jux/elements';
import { CORE_TOKEN_SET_NAME } from '@jux/types';
import {
  addComponentPropValue,
  addComponentToLibrary,
  convertNodeToVariantsGroup,
  convertVariantsGroupToSourceComponent,
  createAsset,
  createCanvas,
  createComponentFromElement,
  createComponentInstanceNodeAction,
  createNode,
  createNodeWithChildren,
  createTextNode,
  deleteAsset,
  deleteCanvas,
  deleteComponent,
  deleteComponentProp,
  deleteComponentPropValue,
  deleteComponentStyles,
  deleteNode,
  deleteSelectedNodes,
  duplicateSelectedNodes,
  initD3Zoom,
  moveNodes,
  pasteCopiedNodes,
  renameComponentProp,
  renameComponentPropValue,
  repairState,
  replaceAssetContent,
  resetHoveredNodes,
  resetSelectedNodes,
  restoreSourceComponent,
  setCanvasName,
  setComponentPropValueChoice,
  setCurrentCanvasName,
  setEditModeInNodeToolbarLabel,
  setEditModeInTextNode,
  setHoveredNode,
  setImportedOldComponents,
  setNodeDraggingEnd,
  setNodeDraggingStart,
  setNodeDragStartingPoint,
  setPlaceholderMode,
  setSelectedNodes,
  setTransform,
  setUserSelection,
  triggerLayersUpdate,
  updateComponentDisplayName,
  updateComponentStyles,
  updateComponentVariantsEditingContext,
  updateNodeInteractiveState,
  updateNodePosition,
  updateNodePositionAndIntersections,
  updateNodeProperties,
  updateNodesDimensions,
  updateNodesIndicatorsPosition,
  wrapWithDiv,
} from '../actions';
import { addComponentProp } from '../actions/props/addComponentProp';
import { addFirstTokenSetAfterCore } from '../actions/token-sets/addFirstTokenSetAfterCore';
import { addGroup } from '../actions/token-sets/addGroup';
import { addTokenSet } from '../actions/token-sets/addTokenSet';
import { deleteTokenOrGroup } from '../actions/token-sets/deleteTokenOrGroup';
import { deleteTokenSet } from '../actions/token-sets/deleteTokenSet';
import { renameGroup } from '../actions/token-sets/renameGroup';
import { renameToken } from '../actions/token-sets/renameToken';
import { renameTokenSet } from '../actions/token-sets/renameTokenSet';
import { setEditorTokenSet } from '../actions/token-sets/setEditorTokenSet';
import { setToken } from '../actions/token-sets/setToken';
import { JuxStore } from '../store.interface';
import { createCanvasData } from './createCanvasData';
import { createStoreActions } from './createStoreActions';
import { getInitialCoreTokenSetData } from './token-sets/getInitialCoreTokenSetData';

export const DEFAULT_CANVAS_NAME = 'default';

const initialDefaultCanvas = createCanvasData(DEFAULT_CANVAS_NAME);

export const createNewStoreSlice: StateSlice<JuxStore> = (setState) => ({
  // Assets data
  assets: {
    ...systemAssets,
  },

  // Canvases data - default canvas is created with the name "default"
  canvases: {
    [DEFAULT_CANVAS_NAME]: initialDefaultCanvas,
  },

  canvasNodesDimensions: {},

  canvasNodesIndicatorsPositions: {},

  currentCanvasName: DEFAULT_CANVAS_NAME,

  // Components/elements/instances data
  components: {},

  // Tokens data
  tokenSets: {
    [CORE_TOKEN_SET_NAME]: getInitialCoreTokenSetData(),
  },

  editorTokenSetId: undefined,

  // Text editing data
  textEditingNodeStack: [],
  nodeIdLabelInEditMode: null,

  // Node selection/hovering data
  hoveredNodesStack: [],
  selectedNodesStack: [],
  selectedNodesStackTimestamp: 0,
  textNodeUserSelectionActive: false,
  autoSelectedNodeIdOnCreation: null,

  // Node dragging data
  dragNodeStarted: false,
  draggedNodeStartPosition: null,

  layersRerenderTimestamp: 0,
  nodesTriggeringLayersUpdate: new Set(),

  // DDP settings
  placeholderMode: {},

  // Actions
  // Keep actions separate from the rest of the data
  // Create store action slices by feature
  // Separate actions by feature
  commonActions: createStoreActions({
    actionsMap: {
      createCanvas,
      createComponentInstanceNodeAction,
      createNode,
      createNodeWithChildren,
      createTextNode,
      deleteCanvas,
      deleteNode,
      deleteSelectedNodes,
      initD3Zoom,
      moveNodes,
      pasteCopiedNodes,
      repairState,
      resetHoveredNodes,
      resetSelectedNodes,
      setCanvasName,
      setCurrentCanvasName,
      setEditModeInNodeToolbarLabel,
      setEditModeInTextNode,
      setHoveredNode,
      setImportedOldComponents,
      setNodeDraggingEnd,
      setNodeDraggingStart,
      setNodeDragStartingPoint,
      setSelectedNodes,
      setTransform,
      setUserSelection,
      updateNodePosition,
      updateNodePositionAndIntersections,
      updateNodeProperties,
      updateNodesDimensions,
      updateNodesIndicatorsPosition,
      wrapWithDiv,
      triggerLayersUpdate,
    },
    setState,
  }),

  ddpActions: createStoreActions({
    actionsMap: {
      deleteComponentStyles,
      updateComponentStyles,
      updateComponentVariantsEditingContext,
      setPlaceholderMode,
    },
    setState,
  }),

  assetsActions: createStoreActions({
    actionsMap: {
      createAsset,
      deleteAsset,
      replaceAssetContent,
    },
    setState,
  }),

  propsActions: createStoreActions({
    actionsMap: {
      addComponentPropValue,
      addComponentProp,
      deleteComponentProp,
      deleteComponentPropValue,
      renameComponentProp,
      renameComponentPropValue,
      setComponentPropValueChoice,
    },
    setState,
  }),

  componentActions: createStoreActions({
    actionsMap: {
      addComponentToLibrary,
      convertNodeToVariantsGroup,
      convertVariantsGroupToSourceComponent,
      createComponentFromElement,
      deleteComponent,
      duplicateSelectedNodes,
      restoreSourceComponent,
      updateComponentDisplayName,
      updateNodeInteractiveState,
    },
    setState,
  }),

  tokenSetsActions: createStoreActions({
    actionsMap: {
      addFirstTokenSetAfterCore,
      addGroup,
      addTokenSet,
      deleteTokenOrGroup,
      deleteTokenSet,
      renameGroup,
      renameToken,
      renameTokenSet,
      setEditorTokenSet,
      setToken,
    },
    setState,
  }),
});
