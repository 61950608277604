export enum ComponentDependencyType {
  Asset = 'asset',
  Component = 'component',
  ExternalDependency = 'external-dependency',
}

export type ExternalDependencyType = {
  /**
   * The package name of the dependency. For example, `@juxio/react-styled`.
   */
  moduleSpecifier: string;

  namedImports: {
    /**
     * The name of the import. For example, `Button`.
     */
    name: string;

    /**
     * The alias to use for the import. For example, `JuxButton`.
     */
    alias?: string;

    /**
     * Whether the import is a type-only import. For example, `import type { Button } from '@juxio/react-styled'`.
     */
    isTypeOnly?: boolean;
  }[];

  /**
   * Whether the import is a namespace import. For example, `import * as React from 'react'`.
   */
  isNamespaceImport?: boolean;

  /**
   * The alias to use for the namespace import. For example, `React`.
   */
  namespaceImport?: string;

  type: ComponentDependencyType.ExternalDependency;
};

export type InternalDependencyType = {
  name: string;
  id: string; // The node id this component depends on (should be an instance node)
  alias?: string; // The alias to use for the import
  type: ComponentDependencyType.Asset | ComponentDependencyType.Component;
};

export type ComponentDependency =
  | InternalDependencyType
  | ExternalDependencyType;

export type ComponentFileStructure = {
  name: string;
  dependencies: ComponentDependency[];
  file: {
    name: string;
    content: string;
  };
};
