import { EnumPropValue } from '@jux/types';
import type { Draft as WritableDraft } from 'mutative';
import { JuxStore } from '@jux/canjux/core';
import { NodeType } from '@jux/data-entities';

export const addNewPropToAllIntstances = ({
  componentId,
  propName,
  propValue,
  components,
}: {
  componentId: string;
  propName: string;
  propValue: EnumPropValue;
  components: WritableDraft<JuxStore['components']>;
}) => {
  const instances = Object.values(components).filter(
    (component) => component?.sourceComponentId === componentId
  );

  for (const instance of instances) {
    instance.config.props[propName] = propValue;

    // For variants inside a matrix fix display name too
    if (instance.type === NodeType.VARIANT_INSTANCE) {
      instance.displayName = `${instance.displayName},${propValue}`;
    }
  }
};
