import { useCallback, useMemo } from 'react';
import { UnitType, useUnitState } from '../useUnitState';
import { DimensionFieldOptions } from './useDimensionField.interface';
import {
  changeToProperUnit,
  dimensionFieldSchema,
  isValidDimensionTextValue,
  parseIntoField,
} from './useDimensionField.utils';

export const useDimensionFieldValue = ({
  rawValue,
  options,
  fieldName,
}: {
  rawValue: string;
  options: DimensionFieldOptions;
  fieldName: string;
}) => {
  const { unit, setUnit } = useUnitState();

  const parseValue = useCallback(
    (newValue: string) =>
      parseIntoField({
        value: newValue,
        supportNoneValue: options.supportNoneValue,
        key: fieldName,
      }),
    [fieldName, options.supportNoneValue]
  );

  const parseUnit = useCallback(
    (newValue: string, newUnit: UnitType): UnitType =>
      changeToProperUnit(newValue, newUnit, options.supportNoneValue),
    [options.supportNoneValue]
  );

  const parsedValue = useMemo(() => {
    const { value: parsedVal, unit: parsedUnit } = parseValue(rawValue);

    // TODO: remove this line - memo/selector should not have side-effects
    setUnit(parsedUnit);

    return parsedVal;
  }, [parseValue, rawValue, setUnit]);

  const activateUnit = useMemo(
    () => !isValidDimensionTextValue(parsedValue, options.supportNoneValue),
    [options.supportNoneValue, parsedValue]
  );

  const fieldSchema = useMemo(() => dimensionFieldSchema(options), [options]);

  const validateField = useCallback(
    (val: string) => dimensionFieldSchema(options).safeParse(val),
    [options]
  );

  return {
    unit,
    setUnit,
    parsedValue,
    rawValue,
    activateUnit,
    fieldSchema,
    parseValue,
    parseUnit,
    validateField,
  };
};
