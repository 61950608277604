import { FC, useCallback } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { DynamicProperties } from '@jux/ui/components/editor/components/panels/DDP/modules/props/sub-modules/DynamicProperties';
import { useSelectedNodeId } from '@jux/ui/components/editor/hooks';
import {
  selectSelectedNodeAsset,
  selectedNodeComponent,
  selectedNodeComponentVariants,
  useStore,
  useStoreActions,
} from '@jux/canjux/core';
import {
  COMPONENT_TAG_NAME,
  ComponentTagName,
  NodeType,
} from '@jux/data-entities';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { Module } from '../../base';
import { ModulesKeys } from '../../DDP.interface';
import { useShouldRenderModuleByKey, useSubModule } from '../../hooks';
import {
  InteractiveState,
  useInteractiveState,
  TextContent,
  EditProperties,
  AssetContent,
} from './sub-modules';
import { PlaceholderProps } from './sub-modules/placeholderProps/placeholderProps';

const MODULE_KEY = ModulesKeys.props;

const tagNamesWithPlaceholderEditingMode = [
  COMPONENT_TAG_NAME.JuxInput,
  COMPONENT_TAG_NAME.JuxTextarea,
] as ComponentTagName[];

const tagNamesWithInputProps = [
  COMPONENT_TAG_NAME.JuxTextField,
  COMPONENT_TAG_NAME.JuxInput,
  COMPONENT_TAG_NAME.JuxTextarea,
  COMPONENT_TAG_NAME.JuxTextareaField,
] as ComponentTagName[];

export const Props: FC<{
  hasInteractiveState?: boolean;
  hasEditProperties?: boolean;
  sourceTagName?: ComponentTagName;
}> = ({
  hasInteractiveState = true,
  hasEditProperties = true,
  sourceTagName,
}) => {
  const { shouldRender } = useShouldRenderModuleByKey(MODULE_KEY);
  const { shouldRender: shouldRenderByEditPropsKey } = useSubModule(
    DDPSubModulesKeys.editProperties
  );
  const { shouldRender: shouldRenderByTextContentKey } = useSubModule(
    DDPSubModulesKeys.textContent
  );

  const variantsConfig = useStore(selectedNodeComponentVariants);
  const component = useStore(selectedNodeComponent);
  const selectedNodeId = useSelectedNodeId();
  const { selectedAssetData, selectedAssetId } = useStore(
    selectSelectedNodeAsset
  );

  const {
    assetsActions: { replaceAssetContent },
  } = useStoreActions();

  const {
    nodeInteractiveState,
    handleNodeInteractiveStateChange,
    options: interactiveStateOptions,
  } = useInteractiveState({ nodeId: selectedNodeId });

  const handleReplaceAsset = useCallback(
    (assetId: string) => {
      if (!selectedNodeId) return;

      replaceAssetContent({
        newAssetId: assetId,
        componentId: selectedNodeId,
      });
    },
    [replaceAssetContent, selectedNodeId]
  );

  const hasPlaceholderEditingMode = component?.tagName
    ? tagNamesWithPlaceholderEditingMode.includes(component?.tagName)
    : false;
  const isInstanceWithInputProps =
    component?.type === NodeType.INSTANCE &&
    sourceTagName &&
    tagNamesWithInputProps.includes(sourceTagName);
  const isSvg = component?.tagName === COMPONENT_TAG_NAME.JuxSvg;
  const isDiv = component?.tagName === COMPONENT_TAG_NAME.JuxDiv;
  const isText = component?.tagName === COMPONENT_TAG_NAME.JuxText;
  const isDynamicSlot = component?.type === NodeType.DYNAMIC_SLOT;

  const shouldRenderInputProps = component?.tagName
    ? tagNamesWithInputProps.includes(component?.tagName)
    : isInstanceWithInputProps;
  const shouldRenderAssetContent =
    component?.tagName === COMPONENT_TAG_NAME.JuxSvg;
  const shouldRenderInteractiveState =
    hasInteractiveState && nodeInteractiveState;
  const shouldRenderEditProperties =
    component?.type === NodeType.VARIANTS_GROUP ||
    (shouldRenderByEditPropsKey && hasEditProperties && !isSvg && !isText);

  const shouldRenderPropsModule = !isDiv && !isDynamicSlot;

  // div and dynamic slot shouldn't have props unless they're components (local or library)
  if (
    !selectedNodeId ||
    (!shouldRender &&
      !shouldRenderPropsModule &&
      !shouldRenderEditProperties &&
      !shouldRenderByTextContentKey &&
      !shouldRenderAssetContent &&
      !shouldRenderInputProps &&
      !shouldRenderInteractiveState)
  )
    return null;

  return (
    <Module moduleKey={MODULE_KEY} title="Properties and states">
      <MuiGrid container flexDirection="column" gap="4px">
        {shouldRenderAssetContent && (
          <AssetContent
            selectedAssetData={selectedAssetData}
            selectedAssetId={selectedAssetId}
            handleReplaceAsset={handleReplaceAsset}
          />
        )}

        {shouldRenderInteractiveState && (
          <InteractiveState
            handleNodeInteractiveStateChange={handleNodeInteractiveStateChange}
            nodeInteractiveState={nodeInteractiveState}
            options={interactiveStateOptions}
          />
        )}
        {shouldRenderByTextContentKey && <TextContent />}

        <DynamicProperties
          variantsConfig={variantsConfig || []}
          nodeId={selectedNodeId}
        />

        {shouldRenderInputProps && (
          <PlaceholderProps
            nodeId={selectedNodeId}
            hasPlaceholderEditingMode={hasPlaceholderEditingMode}
          />
        )}

        {shouldRenderEditProperties && <EditProperties />}
      </MuiGrid>
    </Module>
  );
};
