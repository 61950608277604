import { useCallback } from 'react';

import {
  canvasDimensions,
  useStoreActions,
  useViewportHelper,
  storeApi,
  selectCurrentCanvas,
} from '@jux/canjux/core';
import { NodeType } from '@jux/data-entities';

export const useGoToSourceComponent = () => {
  const {
    commonActions: { setSelectedNodes },
  } = useStoreActions();
  const { setCenter } = useViewportHelper();

  const goToSourceComponent = useCallback(
    (sourceComponentNodeId: string) => {
      let goToComponentId = sourceComponentNodeId;
      const currentCanvas = selectCurrentCanvas(storeApi.getState());
      const components = storeApi.getState().components;

      if (!currentCanvas.nodes[sourceComponentNodeId]) {
        // Check if there is a variant group with the same source component id
        for (const nodeId of Object.keys(currentCanvas.nodes)) {
          const component = components[nodeId];
          if (
            component.type === NodeType.VARIANTS_GROUP &&
            component?.sourceComponentId === sourceComponentNodeId
          ) {
            goToComponentId = nodeId;
          }
        }
      }

      const nodePosition = currentCanvas.nodes[goToComponentId].position ?? {
        x: 0,
        y: 0,
      };

      const canvasDimensionsData = canvasDimensions(storeApi.getState());

      const { canvasNodesDimensions } = storeApi.getState();
      const { height, width } = canvasNodesDimensions[goToComponentId] ?? {
        height: 0,
        width: 0,
      };

      setSelectedNodes({ nodeIds: [goToComponentId] });
      setCenter(nodePosition.x + width / 2, nodePosition.y + height / 2, {
        zoom: canvasDimensionsData.zoom,
      });
    },
    [setCenter, setSelectedNodes]
  );

  return {
    goToSourceComponent,
  };
};
