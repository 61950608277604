import { Dimensions, useNodeInteractions, useStore } from '@jux/canjux/core';
import { NodeType, tagNameToNodeDisplayName } from '@jux/data-entities';
import { Icons, IconsVariantsType } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import {
  NODE_TOOLBAR_COLOR,
  NODE_TOOLBAR_COLOR_LIBRARY_COMPONENT,
  NODE_TOOLBAR_SELECTED_COLOR,
  NODE_TOOLBAR_SELECTED_COLOR_LIBRARY_COMPONENT,
} from '@jux/ui/theme/palette';
import { withPx } from '@jux/ui/utils/css';
import { FC, RefObject, useMemo } from 'react';
import { selectNodeIdGeneralInfo } from '../../../store/wip/selectors/selectNodeIdGeneralInfo';
import { NodeToolbarLabel } from './NodeToolbarLabel';
import { useNodeToolbarLabelEditing } from './useNodeToolbarLabelEditing';
import { useSettingsState } from '@jux/ui/state';

const PIXEL_DISTANCE_FROM_NODE = 16;
const MINIMUM_DISPLAY_WIDTH_PX = 16;
const LABEL_ON_MINIMUM_DISPLAY_SIZE = '...';
const ICON_LABEL_GAP_PX = 2;
const ICON_SIZE_PX = 16;

export type NodeNameToolbarProps = {
  nodeId: string;
  nodeDimensions: Dimensions;
  isSelected: boolean;
  isHovered: boolean;
  zoomLevel: number;
  isInLibrary: boolean;
};

export const NodeNameToolbar: FC<NodeNameToolbarProps> = ({
  nodeId,
  nodeDimensions,
  isSelected,
  isHovered,
  zoomLevel,
  isInLibrary,
}) => {
  const nodeInfo = useStore(selectNodeIdGeneralInfo(nodeId));
  const {
    settings: {
      tools: { layersPanelIds },
    },
  } = useSettingsState();

  const {
    handleNodeToolbarLabelDoubleClick,
    handleNodeToolbarLabelBlur,
    isNodeToolbarLabelEditing,
  } = useNodeToolbarLabelEditing(nodeId);

  const { elementRef: toolbarRef, registerEvents } = useNodeInteractions({
    nodeId,
  });

  const reachedMinimumDisplaySize = useMemo(() => {
    return nodeDimensions.width * zoomLevel < MINIMUM_DISPLAY_WIDTH_PX;
  }, [nodeDimensions.width, zoomLevel]);

  const label = useMemo(() => {
    if (reachedMinimumDisplaySize) {
      return LABEL_ON_MINIMUM_DISPLAY_SIZE;
    }

    return (
      nodeInfo?.displayName ||
      (nodeInfo.tagName ? tagNameToNodeDisplayName(nodeInfo.tagName) : '')
    );
  }, [nodeInfo, reachedMinimumDisplaySize]);

  const color = useMemo(() => {
    if (isSelected || isHovered) {
      return isInLibrary
        ? NODE_TOOLBAR_SELECTED_COLOR_LIBRARY_COMPONENT
        : NODE_TOOLBAR_SELECTED_COLOR;
    }
    return isInLibrary
      ? NODE_TOOLBAR_COLOR_LIBRARY_COMPONENT
      : NODE_TOOLBAR_COLOR;
  }, [isInLibrary, isHovered, isSelected]);

  const icon: IconsVariantsType | null = useMemo(() => {
    switch (nodeInfo.type) {
      case NodeType.ELEMENT:
      case NodeType.DYNAMIC_SLOT:
      case NodeType.LOGICAL_SLOT:
      case NodeType.VARIANT_INSTANCE:
        return null;
      case NodeType.INSTANCE:
        return 'COMPONENT_INSTANCE_OBJECT';
      case NodeType.VARIANTS_GROUP:
      case NodeType.LOCAL_COMPONENT:
      case NodeType.LIBRARY_COMPONENT:
        return 'COMPONENT_OBJECT';
    }
  }, [nodeInfo.type]);

  const labelMaxWidth = useMemo(
    () =>
      icon
        ? nodeDimensions.width * zoomLevel - ICON_LABEL_GAP_PX - ICON_SIZE_PX
        : nodeDimensions.width * zoomLevel,
    [icon, nodeDimensions.width, zoomLevel]
  );

  return (
    <div
      style={{
        zIndex: 4,
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        transform: `translateY(-${withPx(
          PIXEL_DISTANCE_FROM_NODE / zoomLevel
        )}) scale(${1 / zoomLevel})`,
        transformOrigin: '0 0',
        top: `calc(0% - ${4 / zoomLevel}px)`,
      }}
    >
      <MuiGrid
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          gap: '8px',
          position: 'absolute',
          color: color,
          cursor: 'default',
          height: '18px',
          width: `${withPx(nodeDimensions.width * zoomLevel)}`,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          pointerEvents: 'all',
          userSelect: 'none',
        }}
        {...registerEvents()}
        ref={toolbarRef as RefObject<HTMLDivElement>}
      >
        <MuiGrid
          gap={withPx(ICON_LABEL_GAP_PX)}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {icon && !reachedMinimumDisplaySize && (
            <Icons
              variant={icon}
              size={ICON_SIZE_PX}
              sx={{
                color,
                alignItems: 'center',
              }}
            />
          )}
          <NodeToolbarLabel
            label={layersPanelIds ? nodeId : label}
            color={color}
            isEditing={layersPanelIds ? false : isNodeToolbarLabelEditing}
            maxWidth={labelMaxWidth}
            onDoubleClick={handleNodeToolbarLabelDoubleClick}
            onBlur={handleNodeToolbarLabelBlur}
          />
        </MuiGrid>
      </MuiGrid>
    </div>
  );
};
