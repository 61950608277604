import { FC } from 'react';

import { MuiGrid } from '@jux/ui/components/common/mui';
import { ActionButton } from '../editor';

export const CANVAS_ACTIONS_CLASSNAME = 'canvas-actions';

export interface CanvasPopperMenuActions {
  onRename: () => void;
  onDelete: () => void;
  onOpenNewTab: () => void;
}

export const CanvasActionPopperMenu: FC<CanvasPopperMenuActions> = ({
  onDelete,
  onOpenNewTab,
  onRename,
}) => (
  <MuiGrid
    sx={{
      width: '100%',
      position: 'relative',
      padding: '3px',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: (theme) => theme.drimz.palette.background.default,
      borderRadius: (theme) => theme.drimz.size.borderRadius.small,
      border: 1,
      borderColor: (theme) => theme.drimz.palette.divider.primary,
      boxShadow: (theme) => theme.drimz.shadow.tokens.poppers.boxShadow,
    }}
  >
    <ActionButton className={CANVAS_ACTIONS_CLASSNAME} onClick={onRename}>
      Rename
    </ActionButton>
    <ActionButton className={CANVAS_ACTIONS_CLASSNAME} onClick={onOpenNewTab}>
      Open in new tab
    </ActionButton>
    <ActionButton className={CANVAS_ACTIONS_CLASSNAME} onClick={onDelete}>
      Delete
    </ActionButton>
  </MuiGrid>
);
