import { FC, useState } from 'react';
import capitalize from 'lodash/capitalize';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { IconButton, TextField, Typography } from '@jux/ui/components';
import { SegmentEventSourcePage } from '@jux/data-access/analytics/track-events-properties';
import { UseFieldFormRegisterReturn } from './useFieldForm';
import { useSaveInviteMembersForm } from './useSaveInviteMembersForm';

export type InviteMembersContentProps = {
  sourcePage: SegmentEventSourcePage;
  registerEmailField: () => UseFieldFormRegisterReturn;
  getEmailValue: () => string;
  setEmailValue: (value: string) => void;
  closeSubmitModal: () => void;
};

export const InviteMembersContent: FC<InviteMembersContentProps> = ({
  sourcePage,
  getEmailValue,
  registerEmailField,
  setEmailValue,
  closeSubmitModal,
}) => {
  const [errors, setErrors] = useState<string>();

  const { onSubmit } = useSaveInviteMembersForm({
    sourcePage,
    setEmailValue,
    getEmailValue,
    setErrors,
    closeSubmitModal,
  });

  return (
    <>
      <MuiGrid container item gap="6px">
        <MuiGrid item>
          <Typography
            variant="label-s-medium"
            color={(theme) => theme.drimz.palette.text.secondary}
          >
            Add your colleague email here, the rest is on us
          </Typography>
        </MuiGrid>
        <MuiGrid item container>
          <TextField
            type="email"
            height="32px"
            label=""
            fullWidth
            autoFocus
            placeholder="name@example.com"
            onEnter={onSubmit}
            {...registerEmailField()}
            error={Boolean(errors)}
            helperText={
              errors && (
                <Typography
                  variant="label-s-regular"
                  color={(theme) => theme.drimz.palette.error.main}
                >
                  {capitalize(errors)}
                </Typography>
              )
            }
          />
        </MuiGrid>
      </MuiGrid>
      <MuiGrid item container marginTop="24px">
        <MuiGrid item>
          <IconButton onClick={onSubmit} variant="primary" size="big">
            Send invite
          </IconButton>
        </MuiGrid>
      </MuiGrid>
    </>
  );
};
