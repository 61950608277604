import * as uuid from 'uuid';
import {
  NodeType,
  ComponentPropType,
  COMPONENT_TAG_NAME,
} from '@jux/data-entities';
import { Colors } from '../../common/colors';
import { ElementDataInit } from '../../types/ElementDataInit';
import { PLACEHOLDER_PSEUDO_ELEMENT } from '@jux/constants';
import { ComponentConfigWithStates as StylesConfig } from '@jux/types';

export const INPUT_ELEMENT_DEFAULT_DISPLAY_NAME = 'input';

export const INPUT_ELEMENT_DEFAULT_ROOT_STYLES: StylesConfig = {
  root: {
    [PLACEHOLDER_PSEUDO_ELEMENT]: {
      color: Colors.Neutrals_400,
    },
    border: `1px solid ${Colors.Neutrals_400}`,
    borderRadius: '4px',
    color: Colors.Neutrals_600,
    display: 'inline-block',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    outline: 'none',
    padding: '9px 12px',
    width: '320px',
  },
  variants: [
    {
      propsValues: {
        disabled: true,
      },
      styles: {
        backgroundColor: 'white',
        border: `1px solid ${Colors.Neutrals_200}`,
        color: Colors.Neutrals_400,
      },
    },
  ],
  states: {
    hover: {
      root: {},
      variants: [
        {
          propsValues: {
            disabled: false,
          },
          styles: {
            border: `1px solid ${Colors.Neutrals_600}`,
          },
        },
      ],
    },
    'focus-visible': {
      root: {},
      variants: [
        {
          propsValues: {
            disabled: false,
          },
          styles: {
            border: `1px solid ${Colors.Brand_500}`,
          },
        },
      ],
    },
  },
};

export const getJuxInputElementData: ElementDataInit<{
  styles?: StylesConfig;
}> = ({ parentId, styles = INPUT_ELEMENT_DEFAULT_ROOT_STYLES } = {}) => {
  const id = uuid.v4();
  return {
    root: {
      tagName: COMPONENT_TAG_NAME.JuxInput,
      type: NodeType.LOCAL_COMPONENT,
      id: id,
      parentId,
      scopes: [],
      children: [],
      styles,
      config: {
        props: {
          disabled: false,
          placeholder: 'Placeholder',
          value: 'Value',
        },
        variants: [
          {
            variant: 'disabled',
            propType: ComponentPropType.Enum,
            options: [
              { value: true, label: 'True' },
              { value: false, label: 'False' },
            ],
            defaultValue: false,
            isFixed: true,
          },
        ],
      },
      displayName: INPUT_ELEMENT_DEFAULT_DISPLAY_NAME,
    },
  };
};
