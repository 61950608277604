import { useCallback } from 'react';
import { useStore, PROP_EDITING_MODE_ALL } from '@jux/canjux/core';
import {
  useSetupDDPModuleState,
  useUpdateComponentVariantsEditingContext,
} from '@jux/ui/components/editor/hooks';
import { ComponentProp } from '@jux/data-entities';

export const useSetVariantPropsValues = () => {
  const { setComponentPropValueChoice } = useStore(
    (state) => state.propsActions
  );

  const updateComponentVariantsEditingContext =
    useUpdateComponentVariantsEditingContext();

  const { setupDDPValues } = useSetupDDPModuleState();

  const setPropValue = useCallback(
    ({
      nodeId,
      propName,
      propValue,
    }: {
      nodeId: string;
      propName: string;
      propValue: ComponentProp['defaultValue'];
    }) => {
      setComponentPropValueChoice({
        componentId: nodeId,
        propName,
        propValue,
      });

      setupDDPValues();
    },
    [setComponentPropValueChoice, setupDDPValues]
  );

  const setPropEditingModeAny = useCallback(
    ({ nodeId, propName }: { nodeId: string; propName: string }) => {
      updateComponentVariantsEditingContext({
        id: nodeId,
        propName,
        propValue: PROP_EDITING_MODE_ALL,
      });

      setupDDPValues();
    },
    [setupDDPValues, updateComponentVariantsEditingContext]
  );

  return { setPropValue, setPropEditingModeAny };
};
