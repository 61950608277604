import { CORE } from '@jux/types';
import { StyledSelectFieldOptionsProps } from '../../base';

const THIS_SET = 'This set';

// takes paths and core paths and returns valid options for the select field
// either
// options: [path1, path2, ...]
// or
// groupedOptions: {
//   [CORE]: [path1, path2, ...],
//   [THIS_SET]: [path1, path2, ...],
// }
export const getSelectFieldPathOptions = ({
  existingPaths,
  existingCorePaths,
  forceCoreOnly,
  parseOptions,
}: {
  existingPaths: Array<string>;
  existingCorePaths: Array<string>;
  forceCoreOnly?: boolean;
  parseOptions?: (paths: Array<string>) => Array<string>; // custom parser for paths (e.g. adding or removing a prefix)
}): {
  optionsProps: StyledSelectFieldOptionsProps;
  hasPaths: boolean;
} => {
  const hasCorePaths = Boolean(existingCorePaths?.length);
  const hasSetPaths = !forceCoreOnly && Boolean(existingPaths?.length);

  const parsedCorePaths =
    parseOptions?.(existingCorePaths) ?? existingCorePaths;
  const parsedPaths = parseOptions?.(existingPaths) ?? existingPaths;

  const groupedPathsOptions = {
    ...(hasCorePaths && {
      [CORE]: parsedCorePaths.map((p) => ({
        label: p,
        value: p,
      })),
    }),
    ...(hasSetPaths && {
      [THIS_SET]: (hasCorePaths ? existingPaths : parsedPaths).map((p) => ({
        label: p,
        value: p,
      })),
    }),
  };

  const hasBothPaths = hasCorePaths && hasSetPaths;

  if (hasBothPaths && forceCoreOnly) {
    return {
      optionsProps: {
        options: parsedCorePaths,
      },
      hasPaths: true,
    };
  }

  if (hasBothPaths && !forceCoreOnly) {
    return {
      optionsProps: {
        groupedOptions: groupedPathsOptions,
      },
      hasPaths: true,
    };
  }

  if (hasSetPaths && !hasCorePaths) {
    return {
      optionsProps: {
        options: parsedPaths,
      },
      hasPaths: true,
    };
  }
  if (hasCorePaths && !hasSetPaths) {
    return {
      optionsProps: {
        options: parsedCorePaths,
      },
      hasPaths: true,
    };
  }

  return {
    optionsProps: {
      options: [],
    },
    hasPaths: false,
  };
};
