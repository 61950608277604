import {
  CREATE_NEW_CANVAS_NODE_DISTANCE,
  getDefaultNodePropertiesByTagName,
  getRootNodeOfNode,
  JuxStore,
  SnappedPosition,
} from '@jux/canjux/core';
import type { Draft as WritableDraft } from 'mutative';
import {
  addStorageNode,
  reorderStorageNode,
} from '../../../store.changes.utils';
import { deleteInstanceNodes } from './deleteInstanceNodes';
import { setRootComponentUpdateTime } from './setRootComponentUpdateTime';

/**
 * Move a node to a new parent.
 */
export const moveNodeToRoot = ({
  nodeId,
  targetPosition = { xSnapped: 0, ySnapped: 0 },
  targetIndex,
  state,
}: {
  nodeId: string;
  targetPosition?: SnappedPosition;
  targetIndex: number;
  state: WritableDraft<JuxStore>;
}) => {
  const nodeComponentData = state.components[nodeId];
  const currentCanvas = state.canvases[state.currentCanvasName];
  let nodeData = currentCanvas.nodes[nodeId];

  if (!nodeData) {
    // moved node is not necessarily on the canvas - for example when moving a node inside a variant instance we are moviing
    // the original node which is not on the canvas - in this case we need to set node data for it.
    currentCanvas.nodes[nodeId] = {
      position: { x: 0, y: 0 },
      properties: getDefaultNodePropertiesByTagName({
        tagName: nodeComponentData?.tagName,
        type: nodeComponentData.type,
      }),
    };

    nodeData = currentCanvas.nodes[nodeId];
  }

  const sourceNodeParentData = nodeComponentData.parentId
    ? state.components[nodeComponentData.parentId]
    : null;
  if (!sourceNodeParentData) {
    reorderStorageNode(currentCanvas.rootNodesOrder, nodeId, targetIndex);
    return;
  }

  // If it's not a root node yet, remove node from parent
  sourceNodeParentData.children = sourceNodeParentData.children.filter(
    (childId) => childId !== nodeId
  );

  // If done under a component - update it's last 'updatedAt' time
  setRootComponentUpdateTime({
    id: nodeId,
    components: state.components,
  });

  // delete instances of old parent
  deleteInstanceNodes({
    sourceNodeId: nodeId,
    canvases: state.canvases,
    components: state.components,
  });

  // Make source node a root node
  nodeComponentData.parentId = undefined;

  // Add node to root nodes
  addStorageNode(currentCanvas.rootNodesOrder, nodeId, targetIndex);

  if (targetPosition.xSnapped !== 0 || targetPosition.ySnapped !== 0) {
    // This is a user action, so we need to update the position accordingly
    nodeData.position = {
      x: targetPosition.xSnapped,
      y: targetPosition.ySnapped,
    };
  } else {
    // This is a system action, so we need to reset the position according to the previouse parent's root node
    const rootNodeComponent = getRootNodeOfNode({
      components: state.components,
      nodeId: sourceNodeParentData.id,
    });
    const rootNodeWidth =
      state.canvasNodesDimensions[rootNodeComponent.id]?.width || 0;
    const rootNodePosition = currentCanvas.nodes[rootNodeComponent.id]
      ?.position ?? { x: 0, y: 0 };

    nodeData.position = {
      x: rootNodePosition.x + rootNodeWidth + CREATE_NEW_CANVAS_NODE_DISTANCE,
      y: rootNodePosition.y,
    };
  }

  return;
};
