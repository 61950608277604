import { Property } from 'csstype';
import { MuiGrid, styled } from '@jux/ui/components/common/mui';

export const LayoutRoot = styled(MuiGrid, { label: 'LayoutRoot' })(() => ({
  minHeight: '100vh',
  height: '100%',
  width: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
}));

export const LayoutContent = styled(MuiGrid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  position: 'relative',
}));

export const LayoutNavbar = styled(MuiGrid)<{ position: Property.Position }>(
  ({ theme }) => ({
    width: '100%',
    flex: 0,
    backgroundColor: theme.drimz.palette.background.navigation,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: 'none',
    //padding: theme.spacing(0, 1.5),
    borderBottom: '1px solid',
    borderColor: theme.drimz.palette.divider.primary,
    top: 0,
  })
);
