import { useCallback, useMemo } from 'react';
import { tokenFormByType } from '@jux/ui/components/tokens/token-drawer/forms/tokenFormByType';
import { useTokenDrawer } from '@jux/ui/components/tokens/token-drawer/useTokenDrawer';
import { useTokenDrawerSideEffects } from '@jux/ui/components/tokens/token-drawer/useTokenDrawerSideEffects';
import { useTokens, useTrackEvents } from '@jux/ui/hooks';
import { useTokenSetState } from '@jux/ui/state';
import { useSelectedTokenState } from '../../../tokensSets/state';

export const useCreateTokenForm = () => {
  const { close, open } = useTokenDrawer();
  const { currentTokenType, currentTokenSetId, isCoreTokenSet } =
    useTokenSetState();
  const { groupPathOptionsByType, groupPathCoreOptionsByType } = useTokens();

  const { onSave } = useTokenDrawerSideEffects();
  const { deselectToken } = useSelectedTokenState();
  const { trackTokenCreatedEvent } = useTrackEvents();

  const CreateTokenForm = useMemo(
    () => tokenFormByType[currentTokenType],
    [currentTokenType]
  );

  const openCreateTokenForm = useCallback(
    ({ groupPath }: { groupPath: string }) => {
      deselectToken();
      open({
        content: (
          <CreateTokenForm
            initialValues={{
              tokenSetId: currentTokenSetId,
              groupPath,
            }}
            groupPathOptions={groupPathOptionsByType[currentTokenType]}
            groupPathCoreOptions={groupPathCoreOptionsByType[currentTokenType]}
            isCoreTokenSet={isCoreTokenSet}
          />
        ),
        handlers: {
          onSave: () => {
            onSave(`New ${currentTokenType} token created successfully`);
            trackTokenCreatedEvent(currentTokenType);
          },
          onCancel: () => {
            close();
          },
          onClose: () => {
            close();
          },
        },
      });
    },
    [
      CreateTokenForm,
      close,
      currentTokenSetId,
      currentTokenType,
      deselectToken,
      groupPathCoreOptionsByType,
      groupPathOptionsByType,
      isCoreTokenSet,
      onSave,
      open,
      trackTokenCreatedEvent,
    ]
  );

  return {
    openCreateTokenForm,
  };
};
