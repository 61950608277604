import { getJuxButtonElementData } from './elements/button';
import { getJuxCheckboxElementData } from './elements/checkbox/checkbox';
import { getJuxCheckboxFieldElementData } from './elements/checkboxField/checkboxField';
import { getJuxChipElementData } from './elements/chip';
import { getJuxDivElementData } from './elements/div';
import { getJuxDividerElementData } from './elements/divider';
import { getJuxIconButtonElementData } from './elements/icon-button';
import { getJuxInputElementData } from './elements/input/input';
import { getJuxRadioElementData } from './elements/radio';
import { getJuxSlotElementData } from './elements/slot';
import { getJuxSvgElementData } from './elements/svg';
import { getJuxTextareaElementData } from './elements/textarea/textarea';
import { getJuxTextareaFieldElementData } from './elements/textareaField/textareaField';
import { getJuxTextElementData } from './elements/text';
import { getJuxTextFieldElementData } from './elements/textField/textField';
import { getJuxToggleElementData } from './elements/toggle';
import { ComponentTagName } from '@jux/data-entities';

export const ELEMENTS_DATA = {
  JuxButton: getJuxButtonElementData,
  JuxCheckbox: getJuxCheckboxElementData,
  JuxCheckboxField: getJuxCheckboxFieldElementData,
  JuxChip: getJuxChipElementData,
  JuxDiv: getJuxDivElementData,
  JuxDivider: getJuxDividerElementData,
  JuxIconButton: getJuxIconButtonElementData,
  JuxInput: getJuxInputElementData,
  JuxRadio: getJuxRadioElementData,
  JuxSlot: getJuxSlotElementData,
  JuxSvg: getJuxSvgElementData,
  JuxText: getJuxTextElementData,
  JuxTextarea: getJuxTextareaElementData,
  JuxTextareaField: getJuxTextareaFieldElementData,
  JuxTextField: getJuxTextFieldElementData,
  JuxToggle: getJuxToggleElementData,
} as const;

type ElementInitialSize = {
  width: number;
  height: number;
};

// The initial sizes of elements (computed) used to render a new element in the center of the screen.
// This is only for elements that show on the elements panel
export const ELEMENTS_INITIAL_SIZE: Record<
  ComponentTagName,
  ElementInitialSize
> = {
  JuxButton: {
    width: 123,
    height: 36,
  },
  JuxCheckboxField: {
    width: 88,
    height: 35,
  },
  JuxChip: {
    width: 100,
    height: 24,
  },
  JuxDiv: {
    width: 160,
    height: 160,
  },
  JuxDivider: {
    width: 100,
    height: 21,
  },
  JuxIconButton: {
    width: 36,
    height: 36,
  },
  JuxInput: {
    width: 320,
    height: 36,
  },
  JuxRadio: {
    width: 88,
    height: 35,
  },
  JuxSlot: {
    width: 0,
    height: 0,
  },
  JuxSvg: {
    width: 16,
    height: 16,
  },
  JuxText: {
    width: 122,
    height: 26,
  },
  JuxTextarea: {
    width: 320,
    height: 72,
  },
  JuxTextareaField: {
    width: 320,
    height: 242,
  },
  JuxTextField: {
    width: 263,
    height: 69,
  },
  JuxToggle: {
    width: 114,
    height: 38,
  },
  JuxCheckbox: {
    width: 16,
    height: 16,
  },
  JuxLabelTrigger: {
    width: 0,
    height: 0,
  },
} as const;
