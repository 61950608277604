import { FC, useEffect } from 'react';
import { useAtomValue } from 'jotai/index';
import { atom, useSetAtom } from 'jotai';
import * as Sentry from '@sentry/nextjs';
import { currentOrganizationAtom, useAnalytics } from '@jux/ui/hooks';
import { api } from '@jux/ui/trpc/client/api';
import { AuthorizedRequest } from '@jux/data-access';

export const currentUserAtom = atom<{
  user: AuthorizedRequest['user'] | undefined;
}>({
  user: undefined,
});

// this component is used to initialize Segment
export const Segment: FC = () => {
  const currentOrganization = useAtomValue(currentOrganizationAtom);
  const { data: user } = api.user.me.useQuery();
  const { groupEvent, identifyUser } = useAnalytics();
  const setCurrentUser = useSetAtom(currentUserAtom);

  // check if user is logged in and if so, save their identify
  useEffect(() => {
    identifyUser();

    if (user) {
      setCurrentUser({
        user,
      });
      Sentry.setUser({ id: user.id, email: user.email });
    }

    if (currentOrganization.id) groupEvent(currentOrganization);
  }, [currentOrganization, groupEvent, identifyUser, setCurrentUser, user]);

  return null;
};
