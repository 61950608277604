import {
  isEditableTextNodeExistInPosition,
  isNodeSelected,
  selectNodeParent,
  selectNodeProperties,
  selectResolvedComponentById,
  storeApi,
  useStoreActions,
} from '@jux/canjux/core';
import { COMPONENT_TAG_NAME } from '@jux/data-entities';
import { useLiveOrEdit } from '@jux/ui/components/editor/components/panels/liveOrEdit/useLiveOrEdit';
import { useTrackEvents } from '@jux/ui/hooks';
import { MouseEvent, useCallback } from 'react';
import { useSelectCurrentNode } from '../behaviors/selection/useSelectCurrentNode';

export const useNodeClicks = ({ nodeId }: { nodeId: string }) => {
  const {
    commonActions: { setSelectedNodes, setEditModeInTextNode },
  } = useStoreActions();

  const { selectCurrentNode } = useSelectCurrentNode({
    nodeId,
  });

  const { isEdit } = useLiveOrEdit();

  const { trackTextNodeDoubleClickEvent } = useTrackEvents();

  const getNodeProperties = useCallback(
    (id: string) => selectNodeProperties(id)(storeApi.getState()),
    []
  );
  const getNodeParent = useCallback(
    (id: string) => selectNodeParent(id)(storeApi.getState()),
    []
  );
  const getIsNodeSelected = useCallback(
    (id: string) => isNodeSelected(id)(storeApi.getState()),
    []
  );
  const getSourceComponent = useCallback(
    (id: string) => selectResolvedComponentById({ id })(storeApi.getState()),
    []
  );

  const handleDoubleClick = useCallback(
    (event: MouseEvent) => {
      const isMultiSelectionActive = event.shiftKey;

      /*
       * Do not process this event if an editable text node is in the current position.
       * In order to be able to select sub text inside the editable text node,
       * it is necessary to prevent the double click event from being processed, by the ancestor nodes of this editable text node.
       */
      if (
        isMultiSelectionActive ||
        isEditableTextNodeExistInPosition(event.clientX, event.clientY)
      ) {
        return;
      }

      trackTextNodeDoubleClickEvent({
        isEditMode: isEdit,
        clickedOn: 'canvas',
      });

      const { disableNodesInteraction } = storeApi.getState();

      if (disableNodesInteraction) {
        return;
      }

      const nodeProperties = getNodeProperties(nodeId);

      // We don't need to process this event if the node is not selectable
      if (!nodeProperties?.isSelectable) {
        return;
      }

      const isSelected = getIsNodeSelected(nodeId);
      const sourceComponent = getSourceComponent(nodeId);

      if (!isSelected) {
        // Find node to select
        let currentNodeId: string | undefined = nodeId;

        while (currentNodeId) {
          const { parentId } = getNodeParent(currentNodeId);
          const isCurrentSelected = getIsNodeSelected(currentNodeId);
          const isParentSelected = Boolean(
            parentId && getIsNodeSelected(parentId)
          );

          if (!isCurrentSelected && (!parentId || isParentSelected)) {
            selectCurrentNode(event);
            setSelectedNodes({ nodeIds: [currentNodeId] });
            break;
          } else {
            currentNodeId = parentId;
          }
        }
      }

      // if the source component is text element - even if the node is an instance to that
      const isTextElement =
        sourceComponent?.tagName === COMPONENT_TAG_NAME.JuxText;

      if (isSelected && isTextElement) {
        event.stopPropagation();

        setEditModeInTextNode({ nodeId, editable: true });
      }
    },
    [
      trackTextNodeDoubleClickEvent,
      isEdit,
      getNodeProperties,
      nodeId,
      getIsNodeSelected,
      getSourceComponent,
      getNodeParent,
      selectCurrentNode,
      setSelectedNodes,
      setEditModeInTextNode,
    ]
  );

  return {
    handleDoubleClick,
  };
};
