import { JuxComponentData, NodeType } from '@jux/data-entities';
import { CanjuxState } from '../store';
import { VariantConfig } from './getMatrixVariantsConfig';
import { resolvedProps } from '../wip/selectors';
import { DEFAULT_STATE } from '@jux/types';

export const getRootVariantConfig = ({
  component,
  components,
  assets,
}: {
  component: JuxComponentData;
  components: CanjuxState['components'];
  assets: CanjuxState['assets'];
}): VariantConfig => {
  let rootVariantNode = component;
  while (
    rootVariantNode.parentId &&
    components[rootVariantNode.parentId]?.type === NodeType.VARIANT_INSTANCE
  ) {
    rootVariantNode = components[rootVariantNode.parentId];
  }

  return {
    variantValues: resolvedProps({
      id: rootVariantNode.id,
      components,
      assets,
      onlyVariantsProps: true,
    }),
    interactiveState: rootVariantNode.config.interactiveState ?? DEFAULT_STATE,
  };
};
