import {
  JuxStore,
  JuxStoreActionFn,
  PropsActionsParams,
} from '../../store.interface';
import { ComponentPropType, NodeType } from '@jux/data-entities';
import { addNewPropToAllIntstances } from '../utils/addNewPropToAllIntstances';
import { setLayersData } from '@jux/canjux/core';

/**
 * Create a new property with a first value for the component
 */
export const addComponentProp: JuxStoreActionFn<
  PropsActionsParams['addComponentProp'],
  JuxStore
> = ({ componentId, propName, propValue, state }) => {
  const component = state.components[componentId];
  if (!component) return state;

  switch (component.type) {
    case NodeType.LOCAL_COMPONENT:
    case NodeType.LIBRARY_COMPONENT:
      if (!component.config.variants) return state;

      component.config.variants.push({
        propType: ComponentPropType.Enum,
        variant: propName,
        options: [
          {
            value: propValue,
            label: propValue.toString(),
          },
        ],
        defaultValue: propValue,
        displayName: propName,
      });

      // Adding the new prop, doing it this way because of immer
      component.config.props = {
        ...component.config.props,
        [propName]: propValue,
      };

      addNewPropToAllIntstances({
        componentId: component.id,
        propName,
        propValue,
        components: state.components,
      });

      component.updatedAt = new Date().getTime();

      break;
    default:
      throw new Error(`Cannot add prop for component type ${component.type}`);
  }

  // This is needed to update the layers data in case there was a matrix update
  setLayersData(state);

  return state;
};
