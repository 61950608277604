import { FC, useCallback } from 'react';
import { PanelsKeys } from '@jux/ui/components/editor/state';
import { IconButton } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { usePanelStateByKey } from '@jux/ui/components/editor/hooks';
import { SubModule } from '../../../base';

export const EditProperties: FC = () => {
  const { togglePanel: togglePropertiesPanel } = usePanelStateByKey(
    PanelsKeys.properties
  );

  const handleClick = useCallback(() => {
    togglePropertiesPanel();
  }, [togglePropertiesPanel]);

  return (
    <SubModule>
      <MuiGrid marginTop="8px">
        <IconButton
          onClick={handleClick}
          variant="secondary"
          fullWidth
          icon="EDIT"
        >
          Edit properties
        </IconButton>
      </MuiGrid>
    </SubModule>
  );
};
