import { createSelector } from 'reselect';
import { CanjuxState, findPathsInStyles } from '@jux/canjux/core';
import {
  isValidGroupPath,
  prepareGroupPathForStringifyActions,
} from '@jux/ui/utils/tokensPatterns';

export const getComponentsThatUseGroupPathSelector = createSelector(
  [(state: CanjuxState) => state.components],
  (components) => (groupPath: string) => {
    const componentsWithGroupPath = Object.entries(components).reduce(
      (newComponents, [componentId, component]) => {
        const { styles } = component;
        if (!styles) return newComponents;

        const formattedGroupPath =
          prepareGroupPathForStringifyActions(groupPath);

        if (isValidGroupPath(formattedGroupPath)) {
          if (findPathsInStyles({ paths: [formattedGroupPath], styles })) {
            newComponents[componentId] = component;
            return newComponents;
          }
        }
        return newComponents;
      },
      {} as CanjuxState['components']
    );

    return componentsWithGroupPath;
  }
);
