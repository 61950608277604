import { CanjuxState } from '../../store';
import { CanvasData } from '@jux/data-entities';

import { ComponentPlacement } from '../../../types';
import { isComponentAContainer } from './getDefaultNodePropertiesByTagName';

export const findFirstTargetUpTree = ({
  componentId,
  components,
  canvas,
  initiatedFromChildIndex,
}: {
  componentId: string;
  components: CanjuxState['components'];
  canvas: CanvasData;
  initiatedFromChildIndex?: number;
}): ComponentPlacement => {
  const currentComponent = components[componentId];

  const isContainer = isComponentAContainer({
    type: currentComponent.type,
    tagName: currentComponent.tagName,
  });

  if (isContainer) {
    const childIndex = initiatedFromChildIndex;
    return {
      targetNodeId: currentComponent.id,
      targetChildIndex:
        childIndex !== undefined
          ? childIndex + 1
          : currentComponent.children.length,
    };
  }

  if (!currentComponent.parentId) {
    return {
      targetNodeId: undefined,
      targetChildIndex: 0,
    };
  }

  const parentComponent = components[currentComponent.parentId];

  return findFirstTargetUpTree({
    componentId: parentComponent.id,
    components,
    initiatedFromChildIndex: parentComponent.children.indexOf(
      currentComponent.id
    ),
    canvas,
  });
};
