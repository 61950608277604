import { useCallback, useMemo } from 'react';
import { SupportedTokenTypes } from '@juxio/design-tokens';
import { getTokenDisplayName } from '@jux/ui/components/editor/utils';
import { AutocompleteOptionData } from '@jux/ui/components/editor/components/panels/DDP/base/fields/autocomplete';
import { useCreateTokenFormWithTypeAndInitialValue } from '@jux/ui/components/tokens/token-drawer/forms/createEditHooks';
import { useTokenDrawer } from '@jux/ui/components/tokens/token-drawer/useTokenDrawer';
import { useCurrentOrgEditorTokenSetId } from '@jux/ui/hooks';
import { BaseFieldTokenProps } from '../common';
import { UseTokenSelectionProps } from './TokenSelection.interface';

// consider moving this to the hooks level
export const useTokenSelection = ({
  anchorEl,
  tokensProps,
}: UseTokenSelectionProps) => {
  const {
    isTokenized,
    tokenType,
    tokens,
    selectedToken,
    onTokenSelection,
    onTokenDetach,
    tokensPopperProps,
    tokenDrawerProps,
  } = tokensProps || ({} as BaseFieldTokenProps);
  const { openTokens, closeTokens } = tokensPopperProps ?? {};
  const { initialValue, onSave, onValueChange, onCancelButton } =
    tokenDrawerProps ?? {};

  const tokensByDisplayName = useMemo(() => {
    if (!tokens) return [];

    return tokens?.map(
      ({ name, value }) =>
        ({
          label: getTokenDisplayName(name, tokenType),
          value,
        } as AutocompleteOptionData)
    );
  }, [tokenType, tokens]);

  const selectedTokenValueByDisplayName = useMemo(() => {
    if (!selectedToken || !tokenType) return '';

    const { name } = selectedToken;

    return getTokenDisplayName(name, tokenType) ?? '';
  }, [selectedToken, tokenType]);

  const fieldWidth = useMemo(
    () => anchorEl?.clientWidth,
    [anchorEl?.clientWidth]
  );

  const handleTokenButtonClick = useCallback(() => {
    if (isTokenized) {
      onTokenDetach?.(selectedToken);
    } else {
      openTokens?.();
    }
  }, [isTokenized, onTokenDetach, openTokens, selectedToken]);

  const handleTokenSelection = useCallback(
    (selectedTokenOption: AutocompleteOptionData) => {
      // find the token by the displayName
      const tokenData = tokens?.find(
        ({ name }) =>
          getTokenDisplayName(name, tokenType) === selectedTokenOption.label
      );

      if (tokenData) {
        // token is selected
        onTokenSelection?.(tokenData);
        closeTokens?.();
      }
    },
    [closeTokens, onTokenSelection, tokenType, tokens]
  );

  const { currentOrgTokenSetId: tokenSetId } = useCurrentOrgEditorTokenSetId();

  const { openCreateTokenDrawer } = useCreateTokenFormWithTypeAndInitialValue(
    tokenType as SupportedTokenTypes
  );

  const handleTokenCreationAction = useCallback(() => {
    if (!tokenSetId) return;

    // open a drawer and wait for the result value and pass it back on the callback
    openCreateTokenDrawer({
      initialTokenValue: initialValue,
      tokenSetId,
      onCreateNewTokenSaved: onSave,
      onTokenDrawerValueChange: onValueChange,
      onCancelButton,
      closeTokensPopper: closeTokens,
      isCreatedFromDDP: true,
    });
  }, [
    initialValue,
    onCancelButton,
    onSave,
    onValueChange,
    openCreateTokenDrawer,
    closeTokens,
    tokenSetId,
  ]);

  const { isOpen: isTokenDrawerOpen } = useTokenDrawer();

  const handleCloseTokens = useCallback(() => {
    if (isTokenDrawerOpen) return;
    closeTokens?.();
  }, [closeTokens, isTokenDrawerOpen]);

  return {
    handleTokenButtonClick,
    tokensByDisplayName,
    selectedTokenValueByDisplayName,
    fieldWidth,
    handleTokenSelection,
    handleCloseTokens,
    handleTokenCreationAction,
    openTokens,
  };
};
