import { UseComboboxStateChange, useCombobox } from 'downshift';
import { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { useEffectOnSelectedNodeChange } from '@jux/ui/components/editor/hooks';
import { downshiftScrollToItem } from '@jux/ui/utils/downshiftScrollToItem';
import { useEffectOnSelectedNodeStateChange } from '../../../hooks';
import {
  AutocompleteOptionData,
  AutocompleteProps,
} from './Autocomplete.interface';

export const useTokenSelectionAutocomplete = ({
  initialOptions,
  selectedOption,
  onSelect,
}: {
  initialOptions: Array<AutocompleteOptionData>;
  selectedOption?: string;
  onSelect: AutocompleteProps['onSelect'];
}) => {
  const optionsWrapperRef = useRef<HTMLDivElement>(null);
  const [options, setOptions] = useState(initialOptions);

  const selectedOptionIndex = useMemo(
    () => options.findIndex((option) => option.label === selectedOption),
    [options, selectedOption]
  );

  const initOptions = useCallback(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  useEffect(() => {
    initOptions();

    // scroll to the selected item when loading the component
    downshiftScrollToItem({
      optionsWrapperRef,
      itemIndex: selectedOptionIndex,
    });
  }, [initOptions, selectedOptionIndex]);

  const handleInputValueChange = useCallback(
    ({
      inputValue,
      isOpen,
    }: UseComboboxStateChange<AutocompleteOptionData>) => {
      if (!inputValue || !isOpen) {
        initOptions();
        return;
      }

      const filteredOptions = initialOptions.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );

      setOptions(filteredOptions);
    },
    [initialOptions, initOptions]
  );

  const handleSelectedItemChange = useCallback(
    ({
      selectedItem: newSelectedItem,
    }: UseComboboxStateChange<AutocompleteOptionData>) => {
      if (!newSelectedItem) return;
      // call onSelect with the title or the value if there is no title
      onSelect?.(newSelectedItem);
      initOptions();
    },
    [onSelect, initOptions]
  );

  const handleItemToString = useCallback(
    (item: AutocompleteOptionData | null) => item?.label || '',
    []
  );

  const {
    getMenuProps,
    getInputProps,
    getItemProps,
    setInputValue,
    highlightedIndex,
  } = useCombobox<AutocompleteOptionData>({
    items: options,
    onInputValueChange: handleInputValueChange,
    initialInputValue: selectedOption,
    onSelectedItemChange: handleSelectedItemChange,
    itemToString: handleItemToString,
  });

  const handleEffectChanges = useCallback(() => {
    if (!selectedOption) return;

    setInputValue(selectedOption);
  }, [selectedOption, setInputValue]);

  useEffectOnSelectedNodeChange(handleEffectChanges);
  useEffectOnSelectedNodeStateChange(handleEffectChanges);

  const getInputElement = useCallback(
    () => document.getElementById(getInputProps().id),
    [getInputProps]
  );

  const triggerInputFocus = useCallback(() => {
    const inputElement = getInputElement();
    inputElement?.focus();
  }, [getInputElement]);

  const triggerInputBlur = useCallback(() => {
    const inputElement = getInputElement();
    inputElement?.blur();
  }, [getInputElement]);

  const handleClear = useCallback(() => {
    setInputValue('');

    triggerInputFocus();
  }, [setInputValue, triggerInputFocus]);

  return {
    getInputProps,
    options,
    handleClear,
    getMenuProps,
    getItemProps,
    triggerInputBlur,
    triggerInputFocus,
    highlightedIndex,
    optionsWrapperRef,
  };
};
