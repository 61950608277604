import * as CSS from 'csstype';
import { useCallback } from 'react';
import { useStoreActions } from '@jux/canjux/core';
import { useNodeParentContextState } from '@jux/ui/components/editor/hooks';

export const useSaveSelectedNodeDDPChanges = () => {
  const {
    ddpActions: { updateComponentStyles },
  } = useStoreActions();

  const { getParentContextState } = useNodeParentContextState();

  const saveStyleChanges = useCallback(
    ({
      nodeIds,
      newStyle,
    }: {
      nodeIds: string[];
      newStyle: CSS.Properties | { [key: string]: CSS.Properties };
    }) => {
      updateComponentStyles({
        nodeIds,
        newStyles: newStyle,
        parentContextState: getParentContextState(),
      });
    },
    [getParentContextState, updateComponentStyles]
  );

  return { saveStyleChanges };
};
