import { useCallback, useMemo } from 'react';
import { merge } from 'lodash';
import { DesignTokenTypeEnum } from '@juxio/design-tokens';
import { useEffectOnSelectedNodeChange } from '@jux/ui/components/editor/hooks';
import { compareStrValues } from '@jux/ui/utils/compareStrValues';
import { useEffectOnSelectedNodeStateChange } from '../useEffectOnSelectedNodeStateChange';
import {
  useDimensionField,
  parseIntoField,
  isMixedMultiDirectionValue,
  ValidDimensionTextValue,
  DEFAULT_DIMENSION_OPTIONS,
  isValidDimensionTextValue,
} from '../useDimensionField';
import { useTokensByType } from '../useTokensByType';
import {
  createDirectionString,
  parseDirectionalShorthand,
} from './useMultiDirectionFields.utils';
import {
  DirectionFieldsValues,
  UseMultiDirectionFieldsProps,
  UseMultiDirectionFieldsReturnProps,
} from './useMultiDirectionFields.interface';

export const useMultiDirectionFields = ({
  value,
  initialValue,
  fieldName,
  saveChanges,
  dimensionFieldOptions,
}: UseMultiDirectionFieldsProps): UseMultiDirectionFieldsReturnProps => {
  const { supportNoneValue = DEFAULT_DIMENSION_OPTIONS.supportNoneValue } =
    dimensionFieldOptions ?? {};

  const { resolveTokenValue } = useTokensByType(
    fieldName,
    DesignTokenTypeEnum.dimension
  );

  const DirectionFields = useMemo(
    () =>
      ({
        top: `${fieldName}Top`,
        right: `${fieldName}Right`,
        bottom: `${fieldName}Bottom`,
        left: `${fieldName}Left`,
      } as const),
    [fieldName]
  );

  const {
    value: allDirectionsValue,
    top: topValue,
    right: rightValue,
    bottom: bottomValue,
    left: leftValue,
    isComputed,
  } = useMemo(
    () => parseDirectionalShorthand(fieldName, value),
    [fieldName, value]
  );

  const {
    value: allDirectionsInitialValue,
    top: topInitialValue,
    right: rightInitialValue,
    bottom: bottomInitialValue,
    left: leftInitialValue,
  } = useMemo(
    () => parseDirectionalShorthand(fieldName, initialValue),
    [fieldName, initialValue]
  );

  const resolvedAllDirectionsValue = useMemo(
    () => resolveTokenValue(allDirectionsValue) ?? allDirectionsValue,

    [allDirectionsValue, resolveTokenValue]
  );

  const saveCombineDirections = useCallback(
    (newValue: Partial<DirectionFieldsValues>) => {
      const newDirection: DirectionFieldsValues = {
        top: topValue ?? topInitialValue,
        right: rightValue ?? rightInitialValue,
        bottom: bottomValue ?? bottomInitialValue,
        left: leftValue ?? leftInitialValue,
        ...newValue,
      };

      const combinedDirections = createDirectionString(newDirection);
      saveChanges(combinedDirections);
    },
    [
      topValue,
      topInitialValue,
      rightValue,
      rightInitialValue,
      bottomValue,
      bottomInitialValue,
      leftValue,
      leftInitialValue,
      saveChanges,
    ]
  );

  const saveAllDirections = useCallback(
    (newValue: any) => {
      // Mixed is a valid value but should not affect the element (this is only indication)
      if (compareStrValues(newValue, ValidDimensionTextValue.mixed)) {
        return;
      }

      saveCombineDirections({
        top: newValue,
        right: newValue,
        bottom: newValue,
        left: newValue,
      });
    },
    [saveCombineDirections]
  );

  const saveTop = useCallback(
    (newValue?: string) => {
      saveCombineDirections({ top: newValue });
    },
    [saveCombineDirections]
  );

  const saveRight = useCallback(
    (newValue?: string) => {
      saveCombineDirections({ right: newValue });
    },
    [saveCombineDirections]
  );

  const saveBottom = useCallback(
    (newValue?: string) => {
      saveCombineDirections({ bottom: newValue });
    },
    [saveCombineDirections]
  );

  const saveLeft = useCallback(
    (newValue?: string) => {
      saveCombineDirections({ left: newValue });
    },
    [saveCombineDirections]
  );

  const allDirectionsField = useDimensionField({
    value: allDirectionsValue,
    initialValue: allDirectionsInitialValue,
    fieldName: fieldName,
    saveChanges: saveAllDirections,
    options: { ...dimensionFieldOptions, supportMixedValue: true },
  });

  const isMixedValue = useMemo(
    () => isMixedMultiDirectionValue(allDirectionsValue),
    [allDirectionsValue]
  );

  const isValidTextValue = useMemo(
    () => isValidDimensionTextValue(allDirectionsField.value, true),
    [allDirectionsField]
  );

  const topField = useDimensionField({
    value: topValue,
    initialValue: topInitialValue,
    fieldName: DirectionFields.top,
    saveChanges: saveTop,
    options: dimensionFieldOptions,
  });

  const rightField = useDimensionField({
    value: rightValue,
    initialValue: rightInitialValue,
    fieldName: DirectionFields.right,
    saveChanges: saveRight,
    options: dimensionFieldOptions,
  });

  const bottomField = useDimensionField({
    value: bottomValue,
    initialValue: bottomInitialValue,
    fieldName: DirectionFields.bottom,
    saveChanges: saveBottom,
    options: dimensionFieldOptions,
  });

  const leftField = useDimensionField({
    value: leftValue,
    initialValue: leftInitialValue,
    fieldName: DirectionFields.left,
    saveChanges: saveLeft,
    options: dimensionFieldOptions,
  });

  const syncFields = useCallback(
    (newValue: string, newInitialValue: string) => {
      const {
        top: newDirectionTop,
        right: newDirectionRight,
        bottom: newDirectionBottom,
        left: newDirectionLeft,
      } = parseDirectionalShorthand(fieldName, newValue ?? newInitialValue);

      // top
      const resolvedNewDirectionTop =
        resolveTokenValue(newDirectionTop) ?? newDirectionTop;
      const { value: newParsedDirectionTop, unit: newDirectionTopUnit } =
        parseIntoField({
          value: resolvedNewDirectionTop,
          supportNoneValue,
          key: DirectionFields.top,
        });
      topField.setValue(newParsedDirectionTop);
      topField.setUnit(newDirectionTopUnit);

      // right
      const resolvedNewDirectionRight =
        resolveTokenValue(newDirectionRight) ?? newDirectionRight;
      const { value: newParsedDirectionRight, unit: newDirectionRightUnit } =
        parseIntoField({
          value: resolvedNewDirectionRight,
          supportNoneValue,
          key: DirectionFields.right,
        });
      rightField.setValue(newParsedDirectionRight);
      rightField.setUnit(newDirectionRightUnit);

      // bottom
      const resolvedNewDirectionBottom =
        resolveTokenValue(newDirectionBottom) ?? newDirectionBottom;
      const { value: newParsedDirectionBottom, unit: newDirectionBottomUnit } =
        parseIntoField({
          value: resolvedNewDirectionBottom,
          supportNoneValue,
          key: DirectionFields.bottom,
        });
      bottomField.setValue(newParsedDirectionBottom);
      bottomField.setUnit(newDirectionBottomUnit);

      // left
      const resolvedNewDirectionLeft =
        resolveTokenValue(newDirectionLeft) ?? newDirectionLeft;
      const { value: newParsedDirectionLeft, unit: newDirectionLeftUnit } =
        parseIntoField({
          value: resolvedNewDirectionLeft,
          supportNoneValue,
          key: DirectionFields.left,
        });
      leftField.setValue(newParsedDirectionLeft);
      leftField.setUnit(newDirectionLeftUnit);
    },
    [
      fieldName,
      resolveTokenValue,
      supportNoneValue,
      DirectionFields,
      topField,
      rightField,
      bottomField,
      leftField,
    ]
  );

  const handleEffectChanges = useCallback(() => {
    syncFields(allDirectionsValue, allDirectionsInitialValue);

    const {
      value: newParsedAllDirectionValue,
      unit: newParsedAllDirectionUnit,
    } = parseIntoField({
      value: isMixedValue ? allDirectionsValue : resolvedAllDirectionsValue,
      supportNoneValue,
      key: fieldName,
    });

    allDirectionsField.setValue(newParsedAllDirectionValue);
    allDirectionsField.setUnit(newParsedAllDirectionUnit);
  }, [
    syncFields,
    allDirectionsValue,
    allDirectionsInitialValue,
    isMixedValue,
    resolvedAllDirectionsValue,
    supportNoneValue,
    fieldName,
    allDirectionsField,
  ]);

  useEffectOnSelectedNodeChange(handleEffectChanges);
  useEffectOnSelectedNodeStateChange(handleEffectChanges);

  const shouldActivateUnit = useMemo(() => {
    if (isMixedValue) {
      return false;
    }

    return !isValidTextValue;
  }, [isMixedValue, isValidTextValue]);

  return {
    rawAllDirectionsValue: allDirectionsValue,
    parsedRawAllDirectionsValue: resolvedAllDirectionsValue, // this is for the future feature to show the actual value of the token
    allDirectionsField: merge({}, allDirectionsField, {
      activateUnit: shouldActivateUnit,
      tokensDrawerProps: {
        initialValue: resolvedAllDirectionsValue,
      },
    }),
    isComputed,
    topField,
    rightField,
    bottomField,
    leftField,
  };
};
