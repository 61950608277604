import merge from 'lodash/merge';
import { CSSProperties, useCallback } from 'react';
import {
  getComponentStyles,
  getContextParentId,
  getNodeComponent,
  getResolvedContextStyles,
  getRootVariantConfig,
  PlaceholderMode,
  selectedNodeComponentVariantsPropsValues,
  storeApi,
  VariantConfig,
} from '@jux/canjux/core';
import { DEFAULT_STATE, StylesObject, StyleStatesObject } from '@jux/types';
import { useNodeParentContextState } from '@jux/ui/components/editor/hooks/useNodeParentContextState';
import { PLACEHOLDER_PSEUDO_ELEMENT } from '@jux/constants';
import { NodeType } from '@jux/data-entities';

const getSelectedNodeComponentVariantsPropsValues = () =>
  selectedNodeComponentVariantsPropsValues(storeApi.getState());
const getContextParentIdByNodeId = () =>
  getContextParentId(storeApi.getState());

const getMergedStyles = ({
  rootStyles,
  variantsStyles,
  contextStyles,
  isPlaceholderStyles,
}: {
  rootStyles: StylesObject | undefined;
  variantsStyles: (StylesObject | StyleStatesObject)[] | undefined;
  contextStyles: StylesObject[];
  isPlaceholderStyles?: boolean;
}) => {
  const finalStyles = merge(
    {},
    rootStyles,
    ...(variantsStyles ?? []),
    ...contextStyles
  );

  if (isPlaceholderStyles) {
    return finalStyles[PLACEHOLDER_PSEUDO_ELEMENT];
  }

  return finalStyles;
};

export const useNodeStyles = () => {
  const { getIsNodeParentContextOnById } = useNodeParentContextState();

  const getNodeStyles = useCallback(
    ({ id }: { id: string }): Partial<CSSProperties> => {
      const state = storeApi.getState();
      let component = getNodeComponent(id);
      const nodeState = component?.config.interactiveState ?? DEFAULT_STATE;

      let variantConfig: VariantConfig | undefined = undefined;
      if (component?.type === NodeType.VARIANT_INSTANCE) {
        if (!component.sourceComponentId)
          throw new Error('Variant instance must have a source component id');

        variantConfig = getRootVariantConfig({
          component: component,
          components: state.components,
          assets: state.assets,
        });

        component = getNodeComponent(component.sourceComponentId);
      }

      if (!component)
        throw new Error('Selected node does not have component data');

      const parentContextId = getContextParentIdByNodeId()(component.id);
      const variantsValues =
        getSelectedNodeComponentVariantsPropsValues() ?? {};
      const isPlaceholderStyles =
        storeApi.getState().placeholderMode[component.id] ===
        PlaceholderMode.placeholder;

      // node styles
      const { rootStyles, variantsStyles } =
        getComponentStyles()({
          id: component.id,
          interactiveState: variantConfig?.interactiveState ?? nodeState,
          variantsValues: variantConfig?.variantValues ?? variantsValues,
        }) || {};

      let contextStyles: StylesObject[] = [];
      if (parentContextId && getIsNodeParentContextOnById(id)) {
        contextStyles = getResolvedContextStyles()({
          id: component.id,
          parentContextId,
          contextProps: variantConfig?.variantValues,
          contextState: variantConfig?.interactiveState,
        });
      }

      // resolve styles
      return getMergedStyles({
        contextStyles,
        variantsStyles,
        isPlaceholderStyles,
        rootStyles,
      });
    },
    [getIsNodeParentContextOnById]
  );

  return {
    getNodeStyles,
  };
};
