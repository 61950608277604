import { SyntheticEvent, useCallback } from 'react';
import { z } from 'zod';
import { useDDPFieldForm } from '@jux/ui/components/editor/components/panels/DDP/hooks';
import {
  useEffectOnSelectedNodeChange,
  useSaveNodeTextContent,
} from '@jux/ui/components/editor/hooks';
import {
  DDPOtherFieldsKeys,
  useDDPFieldsState,
} from '@jux/ui/components/editor/state';
import { TEXT_CONTENT_PROP_NAME } from '@jux/types';
import { NodeType } from '@jux/data-entities';
import {
  selectedNodeComponent,
  selectedNodeSourceComponent,
  useStore,
} from '@jux/canjux/core';

const FIELD_KEY = DDPOtherFieldsKeys.textContent;

export const useTextContent = () => {
  const { saveTextContent } = useSaveNodeTextContent();
  const { setFieldsStateByKey } = useDDPFieldsState();

  const { directSource } = useStore(selectedNodeSourceComponent);
  const selectedComponent = useStore(selectedNodeComponent);

  const isVariantInstance =
    selectedComponent?.type === NodeType.VARIANT_INSTANCE;

  const initialValue =
    (isVariantInstance
      ? directSource?.config.props[TEXT_CONTENT_PROP_NAME]
      : selectedComponent?.config.props[TEXT_CONTENT_PROP_NAME]) ?? '';

  const { handleSubmit, registerField, setValue, getValue } = useDDPFieldForm({
    fieldName: FIELD_KEY,
    fieldSchema: z.string().optional(),
    initialValue,
  });

  const setFieldValue = useCallback(
    (newValue: string) => {
      setFieldsStateByKey(FIELD_KEY, {
        value: newValue,
        initialValue: newValue,
      });
      setValue(newValue);
    },
    [setValue, setFieldsStateByKey]
  );

  const handleNodeChangeEffect = useCallback(() => {
    if (!initialValue) {
      return;
    }

    setFieldValue(initialValue);
  }, [initialValue, setFieldValue]);

  useEffectOnSelectedNodeChange(handleNodeChangeEffect);

  const discard = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      const currentValue = getValue();

      if (currentValue !== initialValue) {
        setValue(initialValue);
      }

      event.currentTarget?.blur();
    },
    [getValue, initialValue, setValue]
  );

  const save = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      if (!selectedComponent) {
        return;
      }

      const newText = getValue();

      const { currentTarget } = event;

      const submit = handleSubmit(
        () => {
          let nodeToSaveId = selectedComponent?.id;
          if (isVariantInstance && directSource) {
            nodeToSaveId = directSource?.id;
          }

          saveTextContent({ nodeId: nodeToSaveId, newText });
          setFieldValue(newText);
          currentTarget.blur();
        },
        () => {
          discard(event);
        }
      );

      return submit(event);
    },
    [
      directSource,
      discard,
      getValue,
      handleSubmit,
      isVariantInstance,
      saveTextContent,
      selectedComponent,
      setFieldValue,
    ]
  );

  return {
    id: FIELD_KEY,
    save,
    discard,
    registerField,
  };
};
