// TODO: split this module into multiple files, up until now it contained only token-related validations
import { z } from 'zod';
import {
  aliasTokenValueSchema,
  borderTokenValueSchema,
  colorTokenValueSchema,
  dimensionTokenValueSchema,
  fontFamilyTokenAliasSchema,
  fontFamilyTokenValueSchema,
  fontWeightTokenValueSchema,
  namingPatternsSchema,
  strokeStyleTokenValueSchema,
  typographyTokenValueSchema,
} from '@juxio/design-tokens';

export const REQUIRED_FIELD_MESSAGE = 'Required';

export const bulkUpsertTokenInputSchema = z.object({
  alias: aliasTokenValueSchema.optional(),
  description: z.string().optional(),
  groupPath: z.string().min(1, REQUIRED_FIELD_MESSAGE),
  isCore: z.boolean().optional(),
  previousGroupPath: z.string().default('').optional(),
  previousTokenName: z.optional(namingPatternsSchema),
  tokenName: z
    .string()
    .min(1, REQUIRED_FIELD_MESSAGE)
    .and(namingPatternsSchema),
  tokenSetId: z.string().optional(),
  value: z.any(),
});

export const dimensionTokenInputSchema = bulkUpsertTokenInputSchema.extend({
  value: dimensionTokenValueSchema.min(1, REQUIRED_FIELD_MESSAGE),
});

export const fontFamilyTokenInputSchema = bulkUpsertTokenInputSchema.extend({
  value: fontFamilyTokenValueSchema.min(1, REQUIRED_FIELD_MESSAGE),
});

export const typographyTokenInputSchema = bulkUpsertTokenInputSchema.extend({
  value: typographyTokenValueSchema.extend({
    fontFamily: fontFamilyTokenAliasSchema
      .min(1, REQUIRED_FIELD_MESSAGE)
      .or(fontFamilyTokenValueSchema.min(1, REQUIRED_FIELD_MESSAGE)),
    fontSize: dimensionTokenValueSchema.min(1, REQUIRED_FIELD_MESSAGE),
    fontWeight: fontWeightTokenValueSchema.min(1, REQUIRED_FIELD_MESSAGE),
    letterSpacing: dimensionTokenValueSchema,
    lineHeight: dimensionTokenValueSchema,
  }),
});

export const borderTokenInputSchema = bulkUpsertTokenInputSchema.extend({
  value: borderTokenValueSchema.extend({
    color: colorTokenValueSchema.min(1, REQUIRED_FIELD_MESSAGE),
    width: dimensionTokenValueSchema.min(1, REQUIRED_FIELD_MESSAGE),
    style: strokeStyleTokenValueSchema.min(1, REQUIRED_FIELD_MESSAGE),
  }),
});

export const colorTokenInputSchema = bulkUpsertTokenInputSchema.extend({
  value: colorTokenValueSchema.min(1, REQUIRED_FIELD_MESSAGE),
});
