import { FC } from 'react';
import Link from 'next/link';
import { Typography } from '@jux/ui/components';
import { useTheme } from '@jux/ui/components/common/mui';

export const SecretKeyToast: FC<{ closeToast?: () => void }> = ({
  closeToast,
}) => {
  const theme = useTheme();

  return (
    <Typography variant="body-regular">
      Secret key not found,{' '}
      <Link
        href="https://discord.gg/tGnt95fs"
        target="blank"
        style={{
          textDecoration: 'underline',
          color: theme.drimz.palette.primary.main,
        }}
        onClick={closeToast}
      >
        contact us
      </Link>
    </Typography>
  );
};
