import { AnalyticsEvents } from './analytics-events';
import { ComponentTagName } from '@jux/data-entities';

export interface SegmentProperties {
  [key: string]: number | string | boolean | SegmentProperties;
}

// FRONTEND PAGE TRACK EVENTS
export type SegmentPageProperties = {
  pageName: string;
};

// FRONTEND TRACK EVENTS
export enum SegmentEventSourcePage {
  editor = 'editor',
  dashboard = 'dashboard',
}

type BasicSegmentProperties = {
  elementPath: string;
};

type SegmentClickProperties = BasicSegmentProperties & {
  clickSourcePage: SegmentEventSourcePage;
  componentUUID: string;
  componentName: string;
};

type SegmentTokenClickProperties = SegmentClickProperties & {
  deletedTokenId?: string;
  tokenCopyType?: 'label' | 'value';
};

type SegmentEditorDDPProperties = BasicSegmentProperties & {
  newCss?: object;
  nodeType?: string;
  nodeUUID?: string;
};

type SegmentEditorTreeProperties = BasicSegmentProperties & {
  componentUUID: string;
  treeLeafCount?: number;
  treeDepth?: number;
};

type SegmentEditorCanvasProperties = BasicSegmentProperties & {
  componentUUID: string;
  componentType?: string;
};

type SegmentEditorLayersProperties = {
  text: string;
  level: number;
};
type SegmentEditorSidebarProperties = {
  canvasId: string;
};

type SegmentTokenCreatedProperties = {
  tokenType: string;
};

type SegmentTokenUpdatedProperties = SegmentTokenCreatedProperties;

type SegmentTokenDetachedProperties = {
  fieldName: string;
};

type SegmentDDPFieldUpdatedProperties = {
  fieldName: string;
  fieldValueType: string;
  unit?: string;
};

type SegmentDDPModuleProperties = {
  moduleKey: string;
  isOpened: boolean;
};

type SegmentObjectAddedToCanvasProperties = {
  tagName?: ComponentTagName;
  componentUUID?: string;
};

type SegmentTextNodeDoubleClickProperties = {
  isEditMode: boolean;
  clickedOn: string;
};

type SegmentNewPropCreated = {
  componentUUID: string;
  tagName: ComponentTagName;
  createdPropName: string;
};
type SegmentNewPropValue = {
  componentUUID: string;
  tagName: ComponentTagName;
  propName: string;
  newPropValueName: string;
};
type SegmentPropRenamed = {
  componentUUID: string;
  tagName: ComponentTagName;
  oldPropName: string;
  newPropName: string;
};
type SegmentPropValueRenamed = {
  componentUUID: string;
  tagName: ComponentTagName;
  propName: string;
  oldPropValueName: string;
  newPropValueName: string;
};
type SegmentPropDeleted = {
  componentUUID: string;
  tagName: ComponentTagName;
  deletedPropName: string;
};
type SegmentPropValueDeleted = {
  componentUUID: string;
  tagName: ComponentTagName;
  propName: string;
  deletedPropValueName: string;
};
type SegmentAddComponentToLibrary = {
  componentUUID: string;
};
type SegmentCreateAsset = {
  assetName: string;
};
type SegmentKeyboardShortcut = {
  shortcut:
    | AnalyticsEvents.KBS_COPY
    | AnalyticsEvents.KBS_DELETE
    | AnalyticsEvents.KBS_DESELECT
    | AnalyticsEvents.KBS_DRILL_IN
    | AnalyticsEvents.KBS_DRILL_UP
    | AnalyticsEvents.KBS_PASTE
    | AnalyticsEvents.KBS_REDO
    | AnalyticsEvents.KBS_UNDO
    | AnalyticsEvents.KBS_WRAP_WITH_DIV;
  hotKey: string;
};
type SegmentDisplayName = {
  position: string;
};

type SegmentFeedbackSent = {
  feedback: string;
};

type SegmentPluginLogin = {
  outcome: 'success' | 'failure';
};

type SegmentPluginMoveVariables = {
  number_of_tokens: number;
  number_of_jux_sets: number;
};

type SegmentPluginIssueList = {
  number_of_bad_tokens: number;
};

type SegmentThemeChanged = {
  themeId: string;
  themeName: string;
};

export type SegmentTrackPropertiesEvents =
  | SegmentAddComponentToLibrary
  | SegmentClickProperties
  | SegmentCreateAsset
  | SegmentDDPFieldUpdatedProperties
  | SegmentDDPModuleProperties
  | SegmentDisplayName
  | SegmentEditorCanvasProperties
  | SegmentEditorDDPProperties
  | SegmentEditorLayersProperties
  | SegmentEditorSidebarProperties
  | SegmentEditorTreeProperties
  | SegmentFeedbackSent
  | SegmentKeyboardShortcut
  | SegmentNewPropCreated
  | SegmentNewPropValue
  | SegmentObjectAddedToCanvasProperties
  | SegmentPluginIssueList
  | SegmentPluginLogin
  | SegmentPluginMoveVariables
  | SegmentPropDeleted
  | SegmentPropRenamed
  | SegmentPropValueDeleted
  | SegmentPropValueRenamed
  | SegmentTextNodeDoubleClickProperties
  | SegmentTokenClickProperties
  | SegmentTokenCreatedProperties
  | SegmentTokenDetachedProperties
  | SegmentTokenUpdatedProperties
  | SegmentThemeChanged;
