import * as uuid from 'uuid';
import {
  ComponentConfigWithStates as StylesConfig,
  DEFAULT_STATE,
} from '@jux/types';
import { Colors } from '../common/colors';
import {
  ComponentPropType,
  COMPONENT_TAG_NAME,
  NodeType,
} from '@jux/data-entities';
import { getJuxSvgElementData } from './svg';
import { ElementDataInit } from '../types/ElementDataInit';
import { JUX_ASSET_LINK_ICON_ID } from '../common/assets';

export const ICON_BUTTON_ELEMENT_DEFAULT_DISPLAY_NAME = 'iconButton';

export const ICON_BUTTON_ELEMENT_DEFAULT_STYLES: StylesConfig = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    height: '36px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px',
    padding: '8px 10px',
    borderRadius: '4px',
    borderStyle: 'none',
    borderColor: '', // this is set so that DDP would not show browser default #767676 and show empty value instead
    gap: '8px',
    cursor: 'pointer',
    outline: 'none',
  },
  variants: [
    {
      propsValues: {
        size: 'small',
        disabled: false,
      },
      styles: {
        backgroundColor: Colors.Brand_500,
        height: '28px',
        padding: '8px 6px',
      },
    },
    {
      propsValues: {
        size: 'small',
        disabled: true,
      },
      styles: {
        backgroundColor: Colors.Brand_200,
        height: '28px',
        padding: '8px 6px',
      },
    },
    {
      propsValues: {
        size: 'medium',
        disabled: false,
      },
      styles: {
        backgroundColor: Colors.Brand_500,
        padding: '8px 10px',
      },
    },
    {
      propsValues: {
        size: 'medium',
        disabled: true,
      },
      styles: {
        backgroundColor: Colors.Brand_200,
        padding: '8px 10px',
      },
    },
    {
      propsValues: {
        size: 'large',
        disabled: false,
      },
      styles: {
        backgroundColor: Colors.Brand_500,
        height: '44px',
        padding: '8px 14px',
      },
    },
    {
      propsValues: {
        size: 'large',
        disabled: true,
      },
      styles: {
        backgroundColor: Colors.Brand_200,
        height: '44px',
        padding: '8px 14px',
      },
    },
  ],
  states: {
    hover: {
      root: {
        backgroundColor: Colors.Brand_600,
      },
      variants: [
        {
          propsValues: {
            size: 'small',
            disabled: false,
          },
          styles: {
            backgroundColor: Colors.Brand_600,
          },
        },
        {
          propsValues: {
            size: 'medium',
            disabled: false,
          },
          styles: {
            backgroundColor: Colors.Brand_600,
            padding: '8px 10px',
          },
        },
        {
          propsValues: {
            size: 'large',
            disabled: false,
          },
          styles: {
            backgroundColor: Colors.Brand_600,
            height: '44px',
            padding: '8px 14px',
          },
        },
      ],
    },
    active: {
      root: {
        outline: 'none',
      },
      variants: [
        {
          propsValues: {
            size: 'small',
            disabled: false,
          },
          styles: {
            backgroundColor: Colors.Brand_700,
          },
        },
        {
          propsValues: {
            size: 'medium',
            disabled: false,
          },
          styles: {
            backgroundColor: Colors.Brand_700,
            padding: '8px 10px',
          },
        },
        {
          propsValues: {
            size: 'large',
            disabled: false,
          },
          styles: {
            backgroundColor: Colors.Brand_700,
            height: '44px',
            padding: '8px 14px',
          },
        },
      ],
    },
  },
};

export const getJuxIconButtonElementData: ElementDataInit<undefined> = ({
  parentId,
} = {}) => {
  const rootIconButtonId = uuid.v4();

  const leadingIcon = getJuxSvgElementData({
    parentId: rootIconButtonId,
    displayName: 'leading_icon',
    sourceComponentId: JUX_ASSET_LINK_ICON_ID,
    rootStyles: {
      color: Colors.Neutrals_0,
    },
  }).root;

  return {
    root: {
      type: NodeType.LOCAL_COMPONENT,
      id: rootIconButtonId,
      parentId,
      scopes: [],
      tagName: COMPONENT_TAG_NAME.JuxButton,
      styles: ICON_BUTTON_ELEMENT_DEFAULT_STYLES,
      config: {
        props: {
          // starting values of props
          size: 'medium',
          disabled: false,
        },
        interactiveState: DEFAULT_STATE,
        variants: [
          {
            variant: 'disabled',
            propType: ComponentPropType.Enum,
            options: [
              { value: true, label: 'True' },
              { value: false, label: 'False' },
            ],
            defaultValue: false,
            isFixed: true,
          },
          {
            variant: 'size',
            propType: ComponentPropType.Enum,
            options: [
              { value: 'small', label: 'Small' },
              { value: 'medium', label: 'Medium' },
              { value: 'large', label: 'Large' },
            ],
            defaultValue: 'medium',
            isFixed: false,
          },
        ],
      },
      displayName: ICON_BUTTON_ELEMENT_DEFAULT_DISPLAY_NAME,
      children: [leadingIcon.id],
    },
    subComponents: [leadingIcon],
  };
};
