import { SxProps, Theme } from '@jux/ui/components/common/mui';
import {
  ICON_BUTTON_ICON_COLOR,
  ICON_BUTTON_ICON_FOCUS_BORDER,
  ICON_BUTTON_ICON_HOVER_BG,
  ICON_BUTTON_ICON_HOVER_COLOR,
  NAVIGATOR_BUTTON_VARIANT_PRIMARY_ACTIVE_BACKGROUND_COLOR,
  NAVIGATOR_BUTTON_VARIANT_PRIMARY_DISABLED_BACKGROUND_COLOR,
  NAVIGATOR_BUTTON_VARIANT_PRIMARY_DISABLED_BORDER_COLOR,
  NAVIGATOR_BUTTON_VARIANT_PRIMARY_FOCUSED_BACKGROUND_COLOR,
  NAVIGATOR_BUTTON_VARIANT_PRIMARY_FOCUSED_BORDER_COLOR,
  NAVIGATOR_BUTTON_VARIANT_PRIMARY_IDLE_BACKGROUND_COLOR,
  NAVIGATOR_BUTTON_VARIANT_PRIMARY_IDLE_BORDER_COLOR,
  NAVIGATOR_BUTTON_VARIANT_SECONDARY_DISABLED_BORDER_COLOR,
  NAVIGATOR_BUTTON_VARIANT_SECONDARY_DISABLED_COLOR,
  NAVIGATOR_BUTTON_VARIANT_SECONDARY_FOCUSED_BORDER_COLOR,
  NAVIGATOR_BUTTON_VARIANT_SECONDARY_HOVER_BORDER_COLOR,
  NAVIGATOR_BUTTON_VARIANT_SECONDARY_IDLE_BORDER_COLOR,
  NAVIGATOR_BUTTON_VARIANT_SECONDARY_IDLE_COLOR,
} from '@jux/ui/theme/palette';
import { LoadingButtonWithIconProps as ButtonProps } from '@jux/ui/components';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';

const buttonVariants = {
  primary: 'primary',
  secondary: 'secondary',
  icon: 'icon',
} as const;

export type VariantsKeys = keyof typeof buttonVariants;

const buttonSizes = {
  small: 'small',
  medium: 'medium',
  normal: 'normal',
  big: 'big',
} as const;

export const DEFAULT_BUTTON_SIZE = buttonSizes.normal;

export type SizesKeys = keyof typeof buttonSizes;

type Sizes = Record<
  SizesKeys,
  {
    props: ButtonProps;
    styles: SxProps<Theme>;
  }
>;

type Variants = Record<
  VariantsKeys,
  {
    props: ButtonProps;
    sizes: Partial<Sizes>;
    styles: Partial<{
      idle: SxProps<Theme>;
      hover: SxProps<Theme>;
      active: SxProps<Theme>;
      focus: SxProps<Theme>;
      disabled: SxProps<Theme>;
    }>;
  }
>;

const variants: Variants = {
  primary: {
    props: {},
    sizes: {
      normal: {
        props: {
          typographyVariant: 'label-s-regular',
        },
        styles: {
          height: (theme) => theme.drimz.size.button.normal.height,
          padding: '0 10px',
        },
      },
      big: {
        props: {
          typographyVariant: 'body-regular',
        },
        styles: {
          height: (theme) => theme.drimz.size.button.big.height,
          padding: '0 16px',
        },
      },
    },
    styles: {
      idle: {
        borderRadius: (theme) => theme.drimz.size.borderRadius.small,
        color: (theme: Theme) => theme.drimz.palette.text.button,
        border: '1px solid',
        borderColor: NAVIGATOR_BUTTON_VARIANT_PRIMARY_IDLE_BORDER_COLOR,
        backgroundColor: NAVIGATOR_BUTTON_VARIANT_PRIMARY_IDLE_BACKGROUND_COLOR,
      },
      hover: {
        borderColor: (theme: Theme) => theme.drimz.palette.primary.main,
        backgroundColor: (theme: Theme) => theme.drimz.palette.primary.main,
      },
      active: {
        borderColor: (theme: Theme) => theme.drimz.palette.primary.main,
        backgroundColor:
          NAVIGATOR_BUTTON_VARIANT_PRIMARY_ACTIVE_BACKGROUND_COLOR,
      },
      focus: {
        borderColor: NAVIGATOR_BUTTON_VARIANT_PRIMARY_FOCUSED_BORDER_COLOR,
        backgroundColor:
          NAVIGATOR_BUTTON_VARIANT_PRIMARY_FOCUSED_BACKGROUND_COLOR,
      },
      disabled: {
        color: (theme: Theme) => theme.drimz.palette.text.button,
        borderColor: NAVIGATOR_BUTTON_VARIANT_PRIMARY_DISABLED_BORDER_COLOR,
        backgroundColor:
          NAVIGATOR_BUTTON_VARIANT_PRIMARY_DISABLED_BACKGROUND_COLOR,
      },
    },
  },
  secondary: {
    props: {},
    sizes: {
      normal: {
        props: {
          typographyVariant: 'label-s-regular',
        },
        styles: {
          height: (theme) => theme.drimz.size.button.normal.height,
          padding: '0 10px',
        },
      },
      big: {
        props: {
          typographyVariant: 'body-regular',
        },
        styles: {
          height: (theme) => theme.drimz.size.button.big.height,
          padding: '0 16px',
        },
      },
    },
    styles: {
      idle: {
        borderRadius: (theme) => theme.drimz.size.borderRadius.small,
        color: NAVIGATOR_BUTTON_VARIANT_SECONDARY_IDLE_COLOR,
        border: '1px solid',
        borderColor: NAVIGATOR_BUTTON_VARIANT_SECONDARY_IDLE_BORDER_COLOR,
        backgroundColor: (theme: Theme) => theme.drimz.palette.transparent,
      },
      hover: {
        backgroundColor: (theme: Theme) => theme.drimz.palette.transparent,
        borderColor: NAVIGATOR_BUTTON_VARIANT_SECONDARY_HOVER_BORDER_COLOR,
      },
      active: {
        borderColor: (theme: Theme) => theme.drimz.palette.primary.main,
      },
      focus: {
        borderColor: NAVIGATOR_BUTTON_VARIANT_SECONDARY_FOCUSED_BORDER_COLOR,
      },
      disabled: {
        color: NAVIGATOR_BUTTON_VARIANT_SECONDARY_DISABLED_COLOR,
        borderColor: NAVIGATOR_BUTTON_VARIANT_SECONDARY_DISABLED_BORDER_COLOR,
      },
    },
  },
  icon: {
    props: {},
    sizes: {
      small: {
        props: {},
        styles: {
          borderRadius: (theme) => theme.drimz.size.borderRadius.small,
          padding: 0,
        },
      },
      medium: {
        props: {},
        styles: {
          borderRadius: (theme) => theme.drimz.size.borderRadius.main,
          height: (theme) => theme.drimz.size.button.medium.height,
          width: (theme) => theme.drimz.size.button.medium.width,
          padding: '4px',
        },
      },
      normal: {
        props: {},
        styles: {
          borderRadius: (theme) => theme.drimz.size.borderRadius.main,
          height: (theme) => theme.drimz.size.button.normal.height,
          width: (theme) => theme.drimz.size.button.normal.width,
          padding: '6px',
        },
      },
      big: {
        props: {},
        styles: {
          borderRadius: (theme) => theme.drimz.size.borderRadius.main,
          height: (theme) => theme.drimz.size.button.big.height,
          width: (theme) => theme.drimz.size.button.big.width,
          padding: '6px 5px',
        },
      },
    },
    styles: {
      idle: {
        color: ICON_BUTTON_ICON_COLOR,
        margin: 0,
        border: 'none',
        width: 'auto',
        height: 'fit-content',
        outline: '1px solid',
        outlineColor: (theme) => theme.drimz.palette.transparent,
      },
      hover: {
        color: ICON_BUTTON_ICON_HOVER_COLOR,
        backgroundColor: ICON_BUTTON_ICON_HOVER_BG,
      },
      focus: {
        outlineColor: ICON_BUTTON_ICON_FOCUS_BORDER,
      },
    },
  },
} as const;

export const getVariantStyles = (
  variant: VariantsKeys,
  size: SizesKeys = DEFAULT_BUTTON_SIZE,
  isDisabled: boolean | undefined = undefined,
  isWrapperStyles = false
): SxProps<Theme> => {
  const idleStyles = variants[variant].styles.idle ?? {};

  const sizeStyles = variants[variant].sizes[size]?.styles ?? {};

  const disabled = variants[variant].styles.disabled ?? {};

  const isWithWrapperStyle = isWrapperStyles ? ' > button' : '';

  const idleKey = `&${isWithWrapperStyle}` as const;
  const focusKey =
    `&:focus${isWithWrapperStyle}, &:focus-within${isWithWrapperStyle}` as const;
  const hoverKey = `&:hover${isWithWrapperStyle}` as const;
  const activeKey = `&:active${isWithWrapperStyle}` as const;

  const nonDisabled = {
    [focusKey]: variants[variant].styles.focus,
    [hoverKey]: variants[variant].styles.hover,
    [activeKey]: variants[variant].styles.active,
  } as SxProps<Theme> as any;

  const variantsStylesWithSize = mergeTyped(sizeStyles, idleStyles);

  return mergeTyped(
    {
      [idleKey]: variantsStylesWithSize,
    },
    isDisabled ? disabled : nonDisabled
  );
};

export const getVariantProps = (
  variant: VariantsKeys,
  size: SizesKeys = DEFAULT_BUTTON_SIZE
): ButtonProps => {
  const idleProps = variants[variant].props;

  const sizeProps = variants[variant].sizes[size]?.props ?? {};

  return mergeTyped(sizeProps, idleProps);
};
