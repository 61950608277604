import { CanjuxState } from '@jux/canjux/core';
import { createSelector } from 'reselect';
import { NodeType } from '@jux/data-entities';
import { DEFAULT_STATE } from '@jux/types';

export const selectNodeChildren = (id: string) =>
  createSelector(
    [
      (state: CanjuxState) => state.components,
      (state: CanjuxState) => state.disableNodesInteraction,
    ],
    (components, disableNodesInteraction) => {
      const component = components[id];
      if (!component) {
        return [];
      }

      const isMatrixInLiveMode =
        component.type === NodeType.VARIANTS_GROUP && disableNodesInteraction;

      if (isMatrixInLiveMode) {
        return component.children.filter(
          (childId) =>
            components[childId]?.config.interactiveState === DEFAULT_STATE
        );
      }

      return component.children;
    }
  );
