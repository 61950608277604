import { MuiSelect, SelectProps, styled } from '@jux/ui/components/common/mui';
import { typographyVariants } from '@jux/ui/components';
import { withImportant } from '@jux/ui/utils/css';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';

const iconStyles = {
  minWidth: 0,
  margin: 0,
  marginRight: '4px',
};

export const SelectField = styled(
  ({
    name,
    sx,
    placeholder,
    MenuProps,
    onChange,
    blurOnSelect,
    ...props
  }: SelectProps<string> & {
    blurOnSelect?: boolean;
  }) => (
    <MuiSelect
      variant="outlined"
      size="small"
      id={name}
      name={name}
      MenuProps={mergeTyped(
        {
          PaperProps: {
            sx: {
              borderRadius: (theme) => theme.drimz.size.borderRadius.small,
              border: 1,
              borderColor: (theme) => theme.drimz.palette.divider.primary,
              maxHeight: '260px',
            },
          },
          MenuListProps: {
            sx: {
              m: '4px',
              gap: '2px',
              display: 'flex',
              flexDirection: 'column',

              '& .MuiMenuItem-root': {
                ...typographyVariants['label-s-medium'],

                '& .MuiListItemIcon-root': iconStyles,
              },
            },
          },
        },
        MenuProps
      )}
      sx={{
        minWidth: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        height: (theme) => theme.drimz.size.editor.ddp.selectField.height,
        backgroundColor: (theme) => theme.drimz.palette.background.default,
        ...typographyVariants['label-s-medium'],

        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: (theme) => theme.drimz.size.borderRadius.small,
        },
        '& .MuiInputBase-input': {
          display: 'flex',
          alignItems: 'center',
          height: withImportant('100%'),
          boxSizing: 'border-box',
          backgroundColor: (theme) => theme.drimz.palette.background.default,
        },

        '& .MuiListItemIcon-root': iconStyles,

        '& .MuiSvgIcon-root': {
          backgroundColor: (theme) => theme.drimz.palette.background.default,
          right: 0,
          width: '30px',
        },

        '& .MuiSelect-select .notranslate::after': placeholder
          ? {
              content: `"${placeholder}"`,
            }
          : {},
        ...sx,
      }}
      onChange={async (e, c) => {
        onChange?.(e, c);

        if (blurOnSelect) {
          // blur the select field to prevent the focus from being stuck
          // in setTimeout in order to make sure it runs after the onChange
          setTimeout(() => {
            (document.activeElement as HTMLElement)?.blur();
          }, 0);
        }
      }}
      {...props}
    />
  )
)(() => ({}));
