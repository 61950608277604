/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '../core/CustomIcon';

export const DynamicSlotLayerIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M3 5C3 4.72386 3.22386 4.5 3.5 4.5H5.5V3H3.5C2.39543 3 1.5 3.89543 1.5 5V11C1.5 12.1046 2.39543 13 3.5 13H5.5V11.5H3.5C3.22386 11.5 3 11.2761 3 11V5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M4.5 7H6V9H4.5V7Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M11.5 7H10V9H11.5V7Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M7 7H9V9H7V7Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M13 5C13 4.72386 12.7761 4.5 12.5 4.5H10.5V3H12.5C13.6046 3 14.5 3.89543 14.5 5V11C14.5 12.1046 13.6046 13 12.5 13H10.5V11.5H12.5C12.7761 11.5 13 11.2761 13 11V5Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
