export enum AnalyticsEvents {
  // PAGES
  CANVAS_PAGE = 'Canvas',
  TOKENS_PAGE = 'Tokens Management',

  // ORGANIZATION
  ORGANIZATION_CREATED = 'Organization Created',
  ORGANIZATION_DELETED = 'Organization Deleted',
  INVITE_SENT = 'Invite Sent',
  USER_ADDED_TO_ORGANIZATION = 'User Added to Organization',
  USER_REMOVED_FROM_ORGANIZATION = 'User Removed from Organization',
  INVITATION_ACCEPTED = 'Invitation Accepted',

  // USERS
  USER_CREATED = 'User Created',
  LOGIN_SUCCESS = 'Login Success',
  LOGIN_FAILED = 'Login Failed',

  // JUX COMPONENTS
  COMPONENT_CREATED = 'Create Component',
  CLI_PULL_COMPONENTS = 'CLI pull components',

  // Props operations
  NEW_PROP_CREATED = 'New prop was added',
  PROP_RENAMED = 'Prop was renamed',
  NEW_PROP_VALUE_CREATED = 'New prop value was added',
  PROP_VALUE_RENAMED = 'Prop value was renamed',
  PROP_VALUE_DELETED = 'Prop value was deleted',
  PROP_DELETED = 'Prop deleted',

  // CANVAS EVENTS
  CREATE_CANVAS = 'Create Canvas',
  CANVAS_CHANGE = 'Canvas change',
  CANVAS_LOADED = 'Canvas loaded',

  // TOKEN SETS
  CREATE_TOKEN_SET = 'Create Token Set',
  UPDATE_MANY_TOKEN_SETS = 'Update Many Token Sets',
  UPDATE_TOKEN_SET = 'Update Token Set',

  // TOKENS_PAGE
  TOKENS_PAGE_LOADED = 'Tokens Page',

  CLONE_STARTER_KIT_COMPONENTS = 'Clone Starter Kit Components',

  // FRONTEND EVENTS
  PAGE_LOADED = 'Page Loaded',
  CLICK = 'Click',
  TREE_LOADED = 'Tree Loaded',

  DDP_VALUE_CHANGE = 'DDP Value change',
  DDP_TOKEN_SELECTED = 'DDP token chosen',
  DDP_FIELD_UNIT_SELECTED = 'DDP field unit selected',
  DDP_FIELD_TOKEN_DETACHED = 'DDP field token detached',
  DDP_MODULE_TOGGLE_OPEN = 'DDP module toggle open',

  OBJECT_ADDED_TO_CANVAS = 'Object added to canvas',

  LAYERS_RENAME = 'Layers - rename',
  TOKEN_CREATED = 'Token created',
  TOKEN_UPDATED = 'Token updated',

  TEXT_NODE_DOUBLE_CLICK = 'Text node double click',

  // DEPRECATED EVENTS
  COMPONENT_UPDATED = 'Update Component',
  GET_ORGANIZATION_TOKEN_SETS = 'Get Organization Token Sets',
  GET_TOKEN_SET = 'Get Token Set',
  CREATE_COMPONENT_VERSION = 'Create Component Version',
  DELETE_COMPONENT_VERSION = 'Delete Component Version',
  SAVE_NEW_VERSION_WITH_CHANGES = 'Save New Version with Changes',
  SAVE_UNPUBLISHED_CHANGES = 'Save Unpublished Changes',

  // CODE COMPONENTS
  GET_COMPONENTS_LIST = 'Get Components List',
  GET_COMPONENT_BY_UUID = 'Get Component by UUID',
  GET_COMPONENT_VERSION = 'Get Component Version',
  DELETE_COMPONENT_BY_UUID = 'Delete Component by UUID',

  // USER INTERACTION EVENTS
  ADD_COMPONENT_TO_LIBRARY = 'Add Component to Library',
  CREATE_ASSET = 'Create Asset',
  OPEN_SIDEBAR = 'Logo open sidebar',
  TOKEN_SIDEBAR_CLICK = 'Token sidebar click',
  EDITOR_SIDEBAR_CLICK = 'Editor sidebar click',
  NAVIGATION_ELEMENTS_CLICKED = 'Navigation elements clicked',
  NAVIGATION_COMPONENTS_CLICKED = 'Navigation components clicked',
  NAVIGATION_ASSETS_CLICKED = 'Navigation assets clicked',
  ADD_TO_LIBRARY_CLICKED = 'Add to library clicked',
  LIVE_MODE_CLICKED = 'Live mode clicked',
  EDIT_MODE_CLICKED = 'Edit mode clicked',
  CONTEXT_MENU_HOW_TO_CLICKED = 'Context menu how to clicked',
  CONTEXT_MENU_FEEDBACK_CLICKED = 'Context menu feedback clicked',
  CONTEXT_MENU_SHORTCUTS_CLICKED = 'Context menu keyboard shortcuts clicked',
  CONTEXT_MENU_LOGOUT_CLICKED = 'Context menu logout clicked',
  CONTEXT_MENU_CHANGE_THEME_CLICKED = 'Context menu change theme clicked',
  RESTORE_SOURCE_COMPONENT = 'Restore source component',
  GO_TO_SOURCE_COMPONENT = 'Go to source component',
  COMPONENT_RESIZED = 'Component resized',
  UPDATE_COMPONENT_DISPLAY_NAME = 'Update component display name',
  NODE_CLICKED = 'Node clicked',
  FEEDBACK_SENT = 'Feedback sent',
  FEEDBACK_CANCEL = 'Feedback cancel',
  THEME_CHANGED = 'Theme changed',
  OPEN_MATRIX = 'Open component matrix',
  CLOSE_MATRIX = 'Close component matrix',

  // Keyboard events
  KBS_SHORTCUT = 'Keyboard shortcut',
  KBS_WRAP_WITH_DIV = 'wrap with div',
  KBS_COPY = 'copy',
  KBS_PASTE = 'paste',
  KBS_DESELECT = 'deselect',
  KBS_DRILL_IN = 'select children (drill in)',
  KBS_DRILL_UP = 'select parent (drill up)',
  KBS_UNDO = 'undo',
  KBS_REDO = 'redo',
  KBS_DELETE = 'delete',

  // Plugin events
  PLUGIN_LOGIN = 'Plugin login',
  PLUGIN_LOGOUT = 'Plugin logout',
  PLUGIN_MOVE_VARIABLES = 'Plugin move variables',
  PLUGIN_SET_SELECTED = 'Plugin set selected',
  PLUGIN_SUCCESS_BACK = 'Plugin success - back',
  PLUGIN_SUCCESS_ISSUE_LIST = 'Plugin success - issue list',
}
