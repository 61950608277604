import { scrollToCenter } from './scrollToCenter';

export const downshiftScrollToItem = ({
  itemIndex,
  optionsWrapperRef,
}: {
  itemIndex: number;
  optionsWrapperRef: React.RefObject<HTMLDivElement>;
}) => {
  const optionsWrapperElement = optionsWrapperRef.current;
  if (!optionsWrapperElement || itemIndex < 0) return;

  const { id, children } = optionsWrapperElement;
  const selectedOptionId = id.replace('menu', 'item-') + itemIndex;

  const findSelectedItem = Array.from(children).find(
    (node) => node.id === selectedOptionId
  );

  if (!findSelectedItem) return;

  scrollToCenter(findSelectedItem, optionsWrapperElement);
};
