import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import {
  useSetFieldValue,
  useFieldValues,
  useMultiDirectionFields,
} from '../../../../hooks';

const FIELD_KEY = DDPPrimitiveFieldsKeys.margin;

export const useMarginFields = () => {
  const { value, initialValue } = useFieldValues(FIELD_KEY);
  const { saveValue: setMargin } = useSetFieldValue(FIELD_KEY);

  const {
    rawAllDirectionsValue: rawMarginValue,
    parsedRawAllDirectionsValue: parsedRawMarginValue,
    allDirectionsField: marginField,
    topField: marginTopField,
    rightField: marginRightField,
    bottomField: marginBottomField,
    leftField: marginLeftField,
    isComputed,
  } = useMultiDirectionFields({
    value,
    initialValue,
    fieldName: FIELD_KEY,
    saveChanges: setMargin,
    dimensionFieldOptions: {
      supportNegativeValue: true,
      supportAutoValue: true,
      supportNegativePercentValue: true,
    },
  });

  return {
    marginField,
    marginTopField,
    marginRightField,
    marginBottomField,
    marginLeftField,
    rawMarginValue,
    parsedRawMarginValue,
    isComputed,
  };
};
