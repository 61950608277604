import { DesignTokenTypeEnum, formatToAlias } from '@juxio/design-tokens';
import { FC, useMemo } from 'react';
import { z } from 'zod';
import { useStoreActions } from '@jux/canjux/core';
import {
  FieldContainer,
  StyledSelectField,
  StyledTextField,
  TITLE_CREATE_NEW_TOKEN,
  TITLE_EDIT_TOKEN,
  TokenDrawerFormLayout,
  TokenDrawerIconLabel,
  TokenNameField,
} from '@jux/ui/components/tokens/token-drawer/base';
import {
  ALIAS_PLACEHOLDER_TEXT,
  FONT_FAMILY_PATH_PREFIX,
  formatAliasPath,
  formatGroupPath,
  getSelectFieldPathOptions,
  parseFontFamilyTokenValue,
  useFontFamilyOptions,
  useFormValueAlias,
  useInitialValues,
  useRegisterFieldFns,
  useValidateTokenName,
} from '@jux/ui/components/tokens/token-drawer/forms/helpers';
import { formatFontFamilyTokenValue } from '@jux/ui/components/tokens/token-drawer/forms/helpers/formatFontFamilyTokenValue';
import { TokenFormProps } from '@jux/ui/components/tokens/token-drawer/forms/types';
import { useTokenDrawer } from '@jux/ui/components/tokens/token-drawer/useTokenDrawer';
import { getUpdatedTokenData } from '@jux/ui/components/tokens/token-drawer/utils/getUpdatedTokenData';
import { useZodForm } from '@jux/ui/hooks/useZodForm';
import { fontFamilyTokenInputSchema } from '@jux/ui/trpc/validations';
import { createPath } from '@jux/ui/utils/tokensPath';

const fontFamilyTokenFormSchema = fontFamilyTokenInputSchema.extend({
  value: z.string(),
});

type FontFamilyTokenFormProps = TokenFormProps<
  typeof fontFamilyTokenInputSchema
>;

export const FontFamilyTokenForm: FC<FontFamilyTokenFormProps> = ({
  initialValues,
  groupPathOptions,
  groupPathCoreOptions,
  isCoreTokenSet,
  isCreatedFromDDP,
  componentsThatUseToken,
}) => {
  const {
    tokenSetsActions: { setToken },
  } = useStoreActions();

  const form = useZodForm({
    schema: fontFamilyTokenFormSchema,
    defaultValues: {
      ...initialValues,
      value: parseFontFamilyTokenValue(initialValues.value),
    },
  });

  const { options: fontFamilies, fallbacks } = useFontFamilyOptions();

  const { handlers } = useTokenDrawer();

  const [
    registerTokenNameField,
    registerGroupPathField,
    registerAliasField,
    registerValueField,
    registerDescriptionField,
  ] = useRegisterFieldFns(form, [
    'tokenName',
    'groupPath',
    {
      alias: {
        onChange: (e) =>
          handlers.onChange?.({ newValue: formatToAlias(e.target.value) }),
      },
    },
    {
      value: {
        onChange: (e) => handlers.onChange?.({ newValue: e.target.value }),
      },
    },
    'description',
  ]);

  // using controlled fields to support aliases.
  // each field has a default/initial value, but if the user has changed the value,
  // we want to use the value they have changed it to, rather than the default value.
  const [value] = useInitialValues(['value'], {
    form,
    initialValues: {
      ...initialValues,
      value: parseFontFamilyTokenValue(initialValues.value),
    },
  });

  const { hasPaths: hasGroups, optionsProps: groupsOptionsProps } = useMemo(
    () =>
      getSelectFieldPathOptions({
        existingPaths: groupPathOptions,
        existingCorePaths:
          isCoreTokenSet || isCreatedFromDDP ? groupPathCoreOptions : [],
        forceCoreOnly: isCoreTokenSet && !isCreatedFromDDP,
      }),
    [groupPathOptions, isCoreTokenSet, isCreatedFromDDP, groupPathCoreOptions]
  );

  const {
    aliasOptionsProps,
    hasAliases,
    aliasValue,
    isAliasToken,
    validateCircularAlias,
  } = useFormValueAlias<typeof form>({
    form,
    isCoreTokenSet,
    isCreatedFromDDP,
    parseValue: parseFontFamilyTokenValue,
    prefix: FONT_FAMILY_PATH_PREFIX,
  });

  const validateTokenName = useValidateTokenName({
    form,
  });

  const handleSubmit = useMemo(
    () =>
      form.handleSubmit(
        (values) => {
          if (validateTokenName() && validateCircularAlias()) {
            const fontFamilyValue = formatFontFamilyTokenValue({
              value: values.value,
              fallbacks: fallbacks[values.value],
            });

            setToken({
              data: {
                ...values,
                alias: formatAliasPath(values.alias),
                isCore: isCoreTokenSet,
                value: fontFamilyValue,
              },
              type: DesignTokenTypeEnum.fontFamily,
            });

            handlers.onSave(
              getUpdatedTokenData({
                tokenPath: createPath([values.groupPath, values.tokenName]),
                tokenSetId: values.tokenSetId,
              })
            );
          }
        },
        () => validateTokenName() && validateCircularAlias()
      ),
    [
      form,
      validateTokenName,
      validateCircularAlias,
      fallbacks,
      setToken,
      isCoreTokenSet,
      handlers,
    ]
  );

  return (
    <TokenDrawerFormLayout
      title={
        initialValues.tokenName ? TITLE_EDIT_TOKEN : TITLE_CREATE_NEW_TOKEN
      }
      componentsThatUseToken={componentsThatUseToken}
      onSubmit={handleSubmit}
    >
      <TokenDrawerIconLabel iconVariant="FONT_FAMILY" labelText="Font Family" />
      <FieldContainer error={form.formState.errors.tokenName?.message}>
        <TokenNameField
          error={Boolean(form.formState.errors.tokenName)}
          inputRef={registerTokenNameField().ref}
          {...registerTokenNameField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.groupPath?.message}>
        <StyledSelectField
          placeholder="Folder"
          disabled={!hasGroups}
          defaultValue={initialValues.groupPath}
          renderValue={(item) =>
            formatGroupPath(item, isCoreTokenSet && !isCreatedFromDDP)
          }
          error={Boolean(form.formState.errors.groupPath)}
          inputRef={registerGroupPathField().ref}
          disableMenuPortal
          {...groupsOptionsProps}
          {...registerGroupPathField()}
        />
      </FieldContainer>
      <FieldContainer>
        <StyledSelectField
          disabled={!hasAliases}
          placeholder={ALIAS_PLACEHOLDER_TEXT}
          value={aliasValue}
          renderValue={(item) =>
            formatGroupPath(item, isCoreTokenSet && !isCreatedFromDDP)
          }
          error={Boolean(form.formState.errors.alias)}
          inputRef={registerAliasField().ref}
          disableMenuPortal
          {...aliasOptionsProps}
          {...registerAliasField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.value?.message}>
        <StyledSelectField
          placeholder="Font family"
          disabled={isAliasToken}
          value={value}
          error={Boolean(form.formState.errors.value)}
          options={fontFamilies}
          inputRef={registerValueField().ref}
          disableMenuPortal
          {...registerValueField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.description?.message}>
        <StyledTextField
          inputProps={{
            placeholder: 'Token description',
          }}
          error={Boolean(form.formState.errors.description)}
          inputRef={registerDescriptionField().ref}
          {...registerDescriptionField()}
        />
      </FieldContainer>
    </TokenDrawerFormLayout>
  );
};
