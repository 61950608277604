import { XYPosition } from '@jux/data-entities';
import type { Selection as D3Selection } from 'd3';
import { CANJUX_CONTAINER_PANE } from '../constants';
import { storeApi } from '../crdt';
import { SnapGrid } from '../store';
import {
  Dimensions,
  JuxRect,
  NodeOrigin,
  SnappedPosition,
  Transform,
} from '../types';

import { boxToRect, clamp, getBoundsOfBoxes, rectToBox } from './';

/**
 * Calculates the position of a point on the Canjux canvas,
 * optionally snapping it to a grid.
 *
 * @param {XYPosition} point - The point coordinates.
 * @param {Transform} transform - The canvas transform state.
 * @param {boolean} snapToGrid - Flag indicating whether to snap the point to the grid.
 * @param {SnapGrid} [snapGrid={ x: 0, y: 0 }] - The grid size for snapping.
 * @returns {XYPosition & SnappedPosition} - The calculated position, with optional snapping.
 */
export const getPositionOnCanjux = (
  { x, y }: XYPosition,
  { x: tx, y: ty, zoom: tScale }: Transform,
  snapToGrid: boolean,
  snapGrid: SnapGrid = { x: 0, y: 0 }
): XYPosition & SnappedPosition => {
  const position: XYPosition = {
    x: (x - tx) / tScale,
    y: (y - ty) / tScale,
  };

  return {
    xSnapped: snapToGrid
      ? snapGrid.x * Math.round(position.x / snapGrid.x)
      : position.x,
    ySnapped: snapToGrid
      ? snapGrid.y * Math.round(position.y / snapGrid.y)
      : position.y,
    x: position.x,
    y: position.y,
  };
};

export const rendererPointToPoint = (
  position: XYPosition,
  transform: Transform
) => {
  const { x, y } = position;
  const { x: tx, y: ty, zoom } = transform;
  const newX = tx + x * zoom;
  const newY = ty + y * zoom;
  return { x: newX, y: newY };
};

export const getNodePosition = ({
  nodeDimensions = { width: 0, height: 0 },
  nodeOrigin = { x: 0, y: 0 },
  nodePosition = { x: 0, y: 0 },
  nodePositionAbsolute,
}: {
  nodeDimensions: Dimensions;
  nodeOrigin: NodeOrigin;
  nodePosition?: XYPosition;
  nodePositionAbsolute: XYPosition;
}): XYPosition & { positionAbsolute: XYPosition } => {
  const offsetX = (nodeDimensions.width ?? 0) * nodeOrigin.x;
  const offsetY = (nodeDimensions.height ?? 0) * nodeOrigin.y;

  const position: XYPosition = {
    x: nodePosition.x - offsetX,
    y: nodePosition.y - offsetY,
  };

  // const positionAbsolute = position;
  const positionAbsolute = nodePositionAbsolute
    ? {
        x: nodePositionAbsolute.x - offsetX,
        y: nodePositionAbsolute.y - offsetY,
      }
    : position;

  return {
    ...position,
    positionAbsolute,
  };
};

export const getRectOfNodes = ({
  nodeIds,
  nodeOrigin = { x: 0, y: 0 },
}: {
  nodeIds: Array<string>;
  nodeOrigin: NodeOrigin;
}): JuxRect => {
  if (nodeIds.length === 0) {
    return { x: 0, y: 0, width: 0, height: 0 };
  }

  const box = nodeIds.reduce(
    (currBox, nodeId) => {
      const state = storeApi.getState();
      const nodeDimensions = state.canvasNodesDimensions[nodeId];
      const nodePosition =
        state.canvases[state.currentCanvasName].nodes[nodeId].position;
      const nodePositionAbsolute = state.canvasNodesIndicatorsPositions[nodeId]
        ?.positionAbsolute as XYPosition;
      const { x, y } = getNodePosition({
        nodeDimensions,
        nodeOrigin,
        nodePosition,
        nodePositionAbsolute,
      }).positionAbsolute;

      return getBoundsOfBoxes(
        currBox,
        rectToBox({
          x,
          y,
          width: nodeDimensions?.width || 0,
          height: nodeDimensions?.height || 0,
        })
      );
    },
    { x: Infinity, y: Infinity, x2: -Infinity, y2: -Infinity }
  );

  return boxToRect(box);
};

export const getTransformForBounds = (
  bounds: JuxRect,
  width: number,
  height: number,
  minZoom: number,
  maxZoom: number,
  padding = 0.1
): Transform => {
  const xZoom = width / (bounds.width * (1 + padding));
  const yZoom = height / (bounds.height * (1 + padding));
  const zoom = Math.min(xZoom, yZoom);
  const clampedZoom = clamp(zoom, minZoom, maxZoom);
  const boundsCenterX = bounds.x + bounds.width / 2;
  const boundsCenterY = bounds.y + bounds.height / 2;
  const x = width / 2 - boundsCenterX * clampedZoom;
  const y = height / 2 - boundsCenterY * clampedZoom;

  return { x, y, zoom: clampedZoom };
};

export const getD3Transition = (
  selection: D3Selection<Element, unknown, null, undefined>,
  duration = 0
) => {
  return selection.transition().duration(duration);
};

export function calculateSpaceBetween(
  parentNode: HTMLElement,
  childNode: HTMLElement
) {
  // Get the bounding rectangles of the parent and child nodes
  const parentRect = parentNode.getBoundingClientRect();
  const childRect = childNode.getBoundingClientRect();

  // Calculate the space between the child and parent nodes
  return {
    top: childRect.top - parentRect.top,
    left: childRect.left - parentRect.left,
    bottom: childRect.y + childRect.height - parentRect.y,
    right: childRect.x + childRect.width - parentRect.x,
  };
}

export function getElementAbsolutePosition(element: HTMLElement): XYPosition {
  const parent = document.getElementById(CANJUX_CONTAINER_PANE);

  if (!parent) {
    throw new Error(
      `Canjux container pane = '${CANJUX_CONTAINER_PANE}' not found`
    );
  }

  const dim = calculateSpaceBetween(parent, element);

  return {
    x: dim.left,
    y: dim.top,
  };
}
