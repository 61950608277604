import { forwardRef, PropsWithChildren, useMemo } from 'react';
import { css } from '@emotion/css';
import { ComponentProps, ComponentSourceData } from '@jux/data-entities';
import { StylesState, ThemeConfig, VariantsValues } from '@jux/types';
import { getJuxComponentElement } from '@jux/react';
import { useCalculatedStyles } from '@jux/canjux/core';
import { resolveStyles, useTheme } from '@jux/dui';
import logger from '@jux/ui-logger';

export type ElementProps = Pick<ComponentSourceData, 'tagName' | 'styles'> &
  PropsWithChildren<{
    elementProps?: ComponentProps;
    variantsProps?: VariantsValues;
    stylesState: StylesState;
  }>;

const Element = forwardRef<HTMLElement, ElementProps>(
  (
    { children, elementProps, variantsProps, styles, stylesState, tagName },
    ref
  ) => {
    const theme = useTheme();
    const ElementByTag = getJuxComponentElement(tagName);

    const calculatedStyles = useCalculatedStyles(styles, stylesState);

    // Separated the variant styles calculation from the position styles and other element props
    const stylesObject = useMemo(() => {
      let finalStyles = calculatedStyles.root;

      if (variantsProps) {
        // TODO: try to implement using variantsResolver, it returns an array and i'm not sure how to use i correctly
        for (const variantStyle of calculatedStyles?.variants || []) {
          if (variantStyle.propsValues) {
            const allPropsMatch = Object.entries(
              variantStyle.propsValues
            ).every(
              ([key, value]) =>
                key in variantsProps && variantsProps[key] === value
            );
            if (allPropsMatch) {
              finalStyles = { ...finalStyles, ...variantStyle.styles };
            }
          }
        }
      }

      return resolveStyles(finalStyles, theme as ThemeConfig);
    }, [calculatedStyles, variantsProps, theme]);

    if (!ElementByTag) {
      logger.error('Unsupported element tag:', { tagName });
      return null;
    }

    return (
      <ElementByTag
        {...(elementProps as any)}
        className={css(stylesObject)}
        ref={ref}
        data-jux-element={tagName}
      >
        {children}
      </ElementByTag>
    );
  }
);

export default Element;
