import {
  JuxStore,
  replacePathsInStyles,
  updateComponentsWithNewStyles,
} from '@jux/canjux/core';
import {
  isValidTokenPath,
  prepareReplaceTokenPaths,
} from '@jux/ui/utils/tokensPatterns';
import { Draft as WritableDraft } from 'mutative';

export const replaceTokenPathInComponentsStyles = ({
  components,
  newPath,
  oldPath,
}: {
  components: WritableDraft<JuxStore['components']>;
  newPath: string;
  oldPath: string;
}) => {
  const stylesToChangeByComponentId = Object.entries(components)
    .map(([componentId, { styles }]) => {
      if (!styles) return undefined;

      const tokenPathsArray = prepareReplaceTokenPaths({ oldPath, newPath });

      const isValidPaths = tokenPathsArray.every(
        (path) =>
          isValidTokenPath(path.oldPath) && isValidTokenPath(path.newPath)
      );

      if (!isValidPaths) {
        return undefined;
      }

      const updatedStyles = replacePathsInStyles({
        paths: tokenPathsArray,
        styles,
      });

      if (updatedStyles) {
        return { [componentId]: updatedStyles };
      }

      return undefined;
    })
    .filter(Boolean);

  updateComponentsWithNewStyles({
    components,
    stylesToChangeByComponentId,
  });
};
