import { NodeData } from '@jux/data-entities';

export const defaultNodeData: NodeData = {
  properties: {
    isContainer: false,
    isDeletable: true,
    isDraggable: true,
    isDragged: false,
    isHidden: false,
    isImmutable: false,
    isSelectable: true,
  },
};

export const defaultInstanceNodeData: NodeData = {
  properties: {
    isContainer: false,
    isDeletable: false,
    isDraggable: false,
    isDragged: false,
    isHidden: false,
    isImmutable: false,
    isSelectable: true,
  },
};

export const dynamicSlotInstanceNodeData: NodeData = {
  properties: {
    isContainer: true,
    isDeletable: false,
    isDraggable: false,
    isDragged: false,
    isHidden: false,
    isImmutable: true,
    isSelectable: true,
  },
};
