import { DesignTokenTypeEnum, formatToAlias } from '@juxio/design-tokens';
import { FC, useMemo } from 'react';
import { useStoreActions } from '@jux/canjux/core';
import {
  FieldContainer,
  StyledSelectField,
  StyledTextField,
  TITLE_CREATE_NEW_TOKEN,
  TITLE_EDIT_TOKEN,
  TokenDrawerFormLayout,
  TokenDrawerIconLabel,
  TokenNameField,
} from '@jux/ui/components/tokens/token-drawer/base';
import {
  ALIAS_PLACEHOLDER_TEXT,
  DIMENSION_PATH_PREFIX,
  formatAliasPath,
  formatGroupPath,
  getSelectFieldPathOptions,
  useFormValueAlias,
  useInitialValues,
  useRegisterFieldFns,
  useValidateTokenName,
} from '@jux/ui/components/tokens/token-drawer/forms/helpers';
import { TokenFormProps } from '@jux/ui/components/tokens/token-drawer/forms/types';
import { useTokenDrawer } from '@jux/ui/components/tokens/token-drawer/useTokenDrawer';
import { getUpdatedTokenData } from '@jux/ui/components/tokens/token-drawer/utils/getUpdatedTokenData';
import { useZodForm } from '@jux/ui/hooks/useZodForm';
import { dimensionTokenInputSchema } from '@jux/ui/trpc/validations';
import { createPath } from '@jux/ui/utils/tokensPath';

const validateDimensionInitialValue = <T extends object>(initialValues: T) => {
  const initialValue = (initialValues as { value: any }).value;

  let value = initialValue?.split(' ')[0] ?? '';

  if (typeof value === 'string' && Number.isNaN(parseInt(value))) {
    value = '0px';
  }

  return {
    ...initialValues,
    value,
  };
};

export const DimensionTokenForm: FC<
  TokenFormProps<typeof dimensionTokenInputSchema>
> = ({
  initialValues,
  groupPathOptions,
  groupPathCoreOptions,
  isCoreTokenSet,
  isCreatedFromDDP,
  componentsThatUseToken,
}) => {
  const {
    tokenSetsActions: { setToken },
  } = useStoreActions();

  const { handlers } = useTokenDrawer();
  const validatedInitialValues = validateDimensionInitialValue(initialValues);

  const form = useZodForm({
    schema: dimensionTokenInputSchema,
    defaultValues: validatedInitialValues,
  });

  const [
    registerTokenNameField,
    registerGroupPathField,
    registerAliasField,
    registerValueField,
    registerDescriptionField,
  ] = useRegisterFieldFns(form, [
    'tokenName',
    'groupPath',
    {
      alias: {
        onChange: (e) =>
          handlers.onChange?.({
            newValue: formatToAlias(e.target.value),
          }),
      },
    },
    {
      value: {
        onChange: async () => {
          /**
           * Checking if the 'value' field is valid before triggering the onChange callback.
           */
          if (!handlers.onChange || !(await form.trigger('value'))) return;

          handlers.onChange({
            newValue: form.getValues().value,
          });
        },
      },
    },
    'description',
  ]);

  // using controlled fields to support aliases.
  // each field has a default/initial value, but if the user has changed the value,
  // we want to use the value they have changed it to, rather than the default value.
  const [value] = useInitialValues(['value'], {
    form,
    initialValues: validatedInitialValues,
  });

  const { hasPaths: hasGroups, optionsProps: groupsOptionsProps } = useMemo(
    () =>
      getSelectFieldPathOptions({
        existingPaths: groupPathOptions,
        existingCorePaths:
          isCoreTokenSet || isCreatedFromDDP ? groupPathCoreOptions : [],
        forceCoreOnly: isCoreTokenSet && !isCreatedFromDDP,
      }),
    [groupPathOptions, isCoreTokenSet, isCreatedFromDDP, groupPathCoreOptions]
  );

  const {
    aliasOptionsProps,
    hasAliases,
    aliasValue,
    isAliasToken,
    validateCircularAlias,
  } = useFormValueAlias<typeof form>({
    form,
    isCoreTokenSet,
    isCreatedFromDDP,
    prefix: DIMENSION_PATH_PREFIX,
  });

  const validateTokenName = useValidateTokenName({
    form,
  });

  const handleSubmit = useMemo(
    () =>
      form.handleSubmit(
        (values) => {
          if (validateTokenName() && validateCircularAlias()) {
            setToken({
              data: {
                ...values,
                alias: formatAliasPath(values.alias),
                isCore: isCoreTokenSet,
              },
              type: DesignTokenTypeEnum.dimension,
            });

            handlers.onSave(
              getUpdatedTokenData({
                tokenPath: createPath([values.groupPath, values.tokenName]),
                tokenSetId: values.tokenSetId,
              })
            );
          }
        },
        () => validateTokenName() && validateCircularAlias()
      ),
    [
      form,
      validateTokenName,
      validateCircularAlias,
      setToken,
      isCoreTokenSet,
      handlers,
    ]
  );

  return (
    <TokenDrawerFormLayout
      title={
        validatedInitialValues.tokenName
          ? TITLE_EDIT_TOKEN
          : TITLE_CREATE_NEW_TOKEN
      }
      componentsThatUseToken={componentsThatUseToken}
      onSubmit={handleSubmit}
    >
      <TokenDrawerIconLabel iconVariant="DIMENSION" labelText="Dimension" />
      <FieldContainer error={form.formState.errors.tokenName?.message}>
        <TokenNameField
          error={Boolean(form.formState.errors.tokenName)}
          inputRef={registerTokenNameField().ref}
          {...registerTokenNameField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.groupPath?.message}>
        <StyledSelectField
          placeholder="Folder"
          defaultValue={validatedInitialValues.groupPath}
          renderValue={(item) =>
            formatGroupPath(item, isCoreTokenSet && !isCreatedFromDDP)
          }
          error={Boolean(form.formState.errors.groupPath)}
          inputRef={registerGroupPathField().ref}
          disabled={!hasGroups}
          disableMenuPortal
          {...groupsOptionsProps}
          {...registerGroupPathField()}
        />
      </FieldContainer>
      <FieldContainer>
        <StyledSelectField
          disabled={!hasAliases}
          placeholder={ALIAS_PLACEHOLDER_TEXT}
          value={aliasValue}
          renderValue={(item) =>
            formatGroupPath(item, isCoreTokenSet && !isCreatedFromDDP)
          }
          error={Boolean(form.formState.errors.alias)}
          inputRef={registerAliasField().ref}
          disableMenuPortal
          {...aliasOptionsProps}
          {...registerAliasField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.value?.message}>
        <StyledTextField
          disabled={isAliasToken}
          value={value}
          inputProps={{
            placeholder: 'Dimension value',
          }}
          error={Boolean(form.formState.errors.value)}
          inputRef={registerValueField().ref}
          {...registerValueField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.description?.message}>
        <StyledTextField
          inputProps={{
            placeholder: 'Token description',
          }}
          error={Boolean(form.formState.errors.description)}
          inputRef={registerDescriptionField().ref}
          {...registerDescriptionField()}
        />
      </FieldContainer>
    </TokenDrawerFormLayout>
  );
};
