import { httpBatchLink, httpLink, loggerLink, splitLink } from '@trpc/client';
import { inferRouterOutputs } from '@trpc/server';
import { createTRPCNext } from '@trpc/next';
import { errorLink } from '@jux/ui/trpc/client/helpers/errorLink';
import { getBaseUrl } from '@jux/ui/trpc/client/helpers/getBaseUrl';
import { redirectToLogin } from '@jux/ui/trpc/client/helpers/redirectToLogin';
import SuperJSON from '@jux/ui/trpc/client/helpers/superjson';
import { AppRouter } from '@jux/ui/trpc/server/api/routers/root';

export type RouterOutputs = inferRouterOutputs<AppRouter>;

export const api = createTRPCNext<AppRouter>({
  config() {
    return {
      /**
       * Transformer used for data de-serialization from the server.
       *
       * @see https://trpc.io/docs/data-transformers
       */
      transformer: SuperJSON,

      /**
       * Links used to determine request flow from client to server.
       *
       * @see https://trpc.io/docs/links
       */
      links: [
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === 'development' ||
            (opts.direction === 'down' && opts.result instanceof Error),
        }),
        errorLink((err: any) => {
          if (err.data?.code === 'UNAUTHORIZED') {
            void redirectToLogin();
          }
        }),
        // Using splitLink to conditionally use batching
        splitLink({
          condition(op) {
            // No batching for mutations
            return op.type !== 'mutation';
          },
          // When condition is `false`, use normal request
          false: httpLink({
            url: `${getBaseUrl()}/api/trpc`,
          }),
          // When condition is `true`, use batching
          true: httpBatchLink({
            url: `${getBaseUrl()}/api/trpc`,
            // Limit the number of requests per batch
            maxURLLength: 2083,
          }),
        }),
      ],
    };
  },
  ssr: true,
});
