import { ComponentSourceData, NodeInteractiveState } from '@jux/data-entities';
import { InteractiveStateOptionsByTagName } from '@jux/ui/components/editor/components/panels/DDP/modules/props/sub-modules/interactiveState';
import { DEFAULT_STATE, VariantsValues } from '@jux/types';
import { getCartesianVariantsWithoutDisabled } from './cartesianProduct';

export type VariantConfig = {
  variantValues: VariantsValues;
  interactiveState: NodeInteractiveState;
};

export const getMatrixVariantsConfig = (
  sourceComponent: ComponentSourceData
): VariantConfig[] => {
  const elementStates =
    InteractiveStateOptionsByTagName[sourceComponent.tagName] || [];
  const variantsConfig = sourceComponent.config.variants;

  if (!variantsConfig || variantsConfig?.length === 0) {
    return elementStates.map((interactiveState) => ({
      variantValues: {},
      interactiveState,
    }));
  }

  // Get all variant combinations where disabled prop is only false (if exists)
  const cartesianProductValues =
    getCartesianVariantsWithoutDisabled(variantsConfig);

  const variantsConfigurations: VariantConfig[] = [];

  for (const interactiveState of elementStates) {
    for (const variantCombination of cartesianProductValues) {
      const variantValues: VariantsValues = {};
      for (let i = 0; i < variantCombination.length; i++) {
        variantValues[variantsConfig[i].variant] = variantCombination[i];
      }

      variantsConfigurations.push({
        variantValues,
        interactiveState,
      });
    }
  }

  // Add combination for disabled true with only Default state
  const indexOfDisabledProp = variantsConfig.findIndex(
    (variant) => variant.variant === 'disabled'
  );
  if (indexOfDisabledProp !== -1) {
    for (const variantCombination of cartesianProductValues) {
      const variantValues: VariantsValues = {};
      for (let i = 0; i < variantCombination.length; i++) {
        variantValues[variantsConfig[i].variant] = variantCombination[i];
      }
      variantValues.disabled = true;

      variantsConfigurations.push({
        variantValues,
        interactiveState: DEFAULT_STATE,
      });
    }
  }

  return variantsConfigurations;
};
