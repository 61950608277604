import { useEffect } from 'react';
import { selectedNodeInteractiveState, useStore } from '@jux/canjux/core';

export const useEffectOnSelectedNodeStateChange = (callback: () => void) => {
  const selectedNodeState = useStore(selectedNodeInteractiveState);

  useEffect(() => {
    callback();
  }, [callback, selectedNodeState]);
};
