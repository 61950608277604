import { TextValidatorFunction } from '@jux/ui/utils/textValidators';
import { hasAliasCharacters } from '@jux/ui/utils/tokensPatterns';

const validateAliasCharacters: TextValidatorFunction = (value) => ({
  isValid: !hasAliasCharacters(value),
  message: `Property value can't contain alias characters - '{' or '}'`,
  autoCloseDelay: 2200,
});

const validateMaxLength: (maxLength: number) => TextValidatorFunction =
  (maxLength) => (value) => ({
    isValid: value.length <= maxLength,
    message: `Property value should be less than ${maxLength} characters`,
  });

export const propertyValueValidators = ({
  maxLength,
}: {
  maxLength: number;
}): Array<TextValidatorFunction> => [
  validateAliasCharacters,
  validateMaxLength(maxLength),
  // NOTE: ChipsInput has a nameAlreadyExistsValidator built in - no need to add it here
];
