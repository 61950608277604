/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';

export const DropdownArrowSmall = React.forwardRef<
  SVGSVGElement,
  React.HTMLAttributes<SVGSVGElement>
>(function DropdownArrowSmall({ className, ...otherProps }, ref) {
  return (
    <svg
      width={'16'}
      height={'16'}
      viewBox={'0 0 16 16'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      className={className}
      {...otherProps}
    >
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M11.2104 6.14645C11.4056 6.34171 11.4056 6.65829 11.2104 6.85355L8.02838 10.0355L4.8464 6.85355C4.65114 6.65829 4.65114 6.34171 4.8464 6.14645C5.04166 5.95118 5.35824 5.95118 5.5535 6.14645L8.02838 8.62132L10.5033 6.14645C10.6985 5.95118 11.0151 5.95118 11.2104 6.14645Z'
        }
        fill={'currentColor'}
      />
    </svg>
  );
});
