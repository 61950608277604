import { useCallback } from 'react';
import { storeApi, PlaceholderMode } from '@jux/canjux/core';
import {
  useSaveSelectedNodeDDPChanges,
  useSelectedNodeId,
} from '@jux/ui/components/editor/hooks';
import {
  DDPFieldsStateKeys,
  useDDPFieldsState,
} from '@jux/ui/components/editor/state';
import { useTrackEvents } from '@jux/ui/hooks';
import { PLACEHOLDER_PSEUDO_ELEMENT } from '@jux/constants';
import { generateStylesForSelector } from './setFieldValue.utils';

export const useSetFieldValue = (key: DDPFieldsStateKeys) => {
  const selectedNodeId = useSelectedNodeId();

  const { setFieldsStateByKey } = useDDPFieldsState();
  const { saveStyleChanges } = useSaveSelectedNodeDDPChanges();

  const { trackFieldValueChangeEvent } = useTrackEvents();

  const saveValue = useCallback(
    (value: any) => {
      if (!selectedNodeId) return;

      setFieldsStateByKey(key, { value });

      // TODO: move this to a different hook
      const inputMode = storeApi.getState().placeholderMode[selectedNodeId];

      saveStyleChanges({
        nodeIds: [selectedNodeId],
        newStyle:
          // In case we're editing the placeholder styles we need to make sure it's on the right selector
          inputMode === PlaceholderMode.placeholder
            ? generateStylesForSelector({
                selector: PLACEHOLDER_PSEUDO_ELEMENT,
                key,
                value,
              })
            : {
                [key]: value,
              },
      });

      trackFieldValueChangeEvent(key, value);
      return;
    },
    [
      key,
      saveStyleChanges,
      selectedNodeId,
      setFieldsStateByKey,
      trackFieldValueChangeEvent,
    ]
  );

  const saveValueWithoutStyleChanges = useCallback(
    (value: any) => {
      if (!selectedNodeId) return;
      setFieldsStateByKey(key, { value });
    },
    [key, selectedNodeId, setFieldsStateByKey]
  );

  return {
    saveValue,
    saveValueWithoutStyleChanges,
    saveStyleChanges,
  };
};
