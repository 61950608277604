import {
  CanjuxState,
  Dimensions,
  getRootNodeOfNode,
  selectIsNodeInLibrary,
} from '@jux/canjux/core';
import {
  ComponentSourceData,
  JuxComponentData,
  NodeProperties,
  NodeType,
  XYPosition,
} from '@jux/data-entities';
import deepmerge from 'deepmerge';
import omit from 'lodash/omit';
import { createSelector } from 'reselect';
import { getLocalComponentDependencies } from '../helpers/getLocalComponentDependencies';
import { getResolvedSourceComponentData } from './utils/getResolvedSourceComponentData';
import { DEFAULT_STATE } from '@jux/types';

export type LayerNodeData = JuxComponentData & {
  dimensions: Dimensions & { positionAbsolute: XYPosition };
  position?: XYPosition;
  isContainsLocalComponentInstance: boolean;
  sourceComponentType: NodeType;
  isNodeInLibrary: boolean;
  properties: NodeProperties;
  rootNodeType: NodeType;
};

export const selectLayersPanelItems = createSelector(
  [
    (state: CanjuxState) => state,
    (state: CanjuxState) => state.canvases[state.currentCanvasName]?.nodes,
    (state: CanjuxState) => state.canvasNodesIndicatorsPositions,
    (state: CanjuxState) => state.canvasNodesDimensions,
    (state: CanjuxState) => state.disableNodesInteraction,
  ],
  (
    state,
    nodes,
    canvasNodesIndicatorsPositions,
    canvasNodesDimensions,
    disableNodesInteraction
  ) =>
    nodes &&
    Object.entries(nodes).reduce((acc, [id, data]) => {
      const components = state.components;
      const component = components[id];

      const sourceComponent = getResolvedSourceComponentData({
        component,
        components,
      });
      if (!sourceComponent) {
        return acc;
      }

      const isLiveModeAndVariantInstanceWithState =
        disableNodesInteraction &&
        component.type === NodeType.VARIANT_INSTANCE &&
        component.config.interactiveState !== DEFAULT_STATE;
      if (isLiveModeAndVariantInstanceWithState) {
        // Do not show non default state variants on live mode
        return acc;
      }

      const isNodeInLibrary = selectIsNodeInLibrary(id)(state);

      const isContainsLocalComponentInstance =
        getLocalComponentDependencies({
          componentId: id,
          components,
        }).length > 0;

      const rootNode = getRootNodeOfNode({ nodeId: id, components });
      const rootNodeType = rootNode ? rootNode?.type : NodeType.LOCAL_COMPONENT;

      const mergedComponent = deepmerge<JuxComponentData>(
        omit(sourceComponent, [
          'children',
          'id',
          'parentId',
        ]) as JuxComponentData,
        component
      );

      acc[id] = {
        ...data,
        ...(mergedComponent as ComponentSourceData),
        dimensions: {
          ...(canvasNodesDimensions[id] || { height: 0, width: 0 }),
          positionAbsolute: canvasNodesIndicatorsPositions[id]
            ?.positionAbsolute || {
            x: 0,
            y: 0,
          },
        },
        isContainsLocalComponentInstance,
        isNodeInLibrary,
        sourceComponentType: sourceComponent.type,
        rootNodeType,
      };

      return acc;
    }, {} as Record<string, LayerNodeData>)
);
