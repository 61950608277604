import { trimLowerCaseStr } from '@jux/ui/utils/trimLowerCaseStr';
import {
  FlexDirectionValue,
  SupportedFlexDirectionValues,
} from './FlexDirectionField.interface';

export const parseFlexDirection = (
  value: string
): SupportedFlexDirectionValues | undefined => {
  switch (trimLowerCaseStr(value)) {
    case 'column-reverse':
      return FlexDirectionValue.columnReverse;
    case 'row-reverse':
      return FlexDirectionValue.rowReverse;
    case 'column':
      return FlexDirectionValue.column;
    case 'row':
      return FlexDirectionValue.row;
    case undefined:
      return undefined;
  }

  return undefined;
};

export const isFlexDirectionReversed = (
  value: SupportedFlexDirectionValues | undefined
) =>
  value === FlexDirectionValue.columnReverse ||
  value === FlexDirectionValue.rowReverse;

const isFlexDirectionColumn = (
  value: SupportedFlexDirectionValues | undefined
) =>
  value === FlexDirectionValue.column ||
  value === FlexDirectionValue.columnReverse;

const isFlexDirectionRow = (value: SupportedFlexDirectionValues | undefined) =>
  value === FlexDirectionValue.row || value === FlexDirectionValue.rowReverse;

export const isSelectedFlexDirection = (
  checkValue: SupportedFlexDirectionValues | undefined,
  equalToValue: SupportedFlexDirectionValues | undefined
) =>
  (isFlexDirectionColumn(checkValue) && isFlexDirectionColumn(equalToValue)) ||
  (isFlexDirectionRow(checkValue) && isFlexDirectionRow(equalToValue));

export const toggleReverseFlexDirection = (
  value: SupportedFlexDirectionValues | undefined
) => {
  switch (trimLowerCaseStr(value)) {
    case FlexDirectionValue.columnReverse:
      return FlexDirectionValue.column;
    case FlexDirectionValue.rowReverse:
      return FlexDirectionValue.row;
    case FlexDirectionValue.column:
      return FlexDirectionValue.columnReverse;
    case FlexDirectionValue.row:
      return FlexDirectionValue.rowReverse;
    case undefined:
      return undefined;
  }

  return undefined;
};

export const toggleFlexDirection = (
  value: SupportedFlexDirectionValues | undefined,
  isReverseSelected: boolean
) => {
  if (isReverseSelected) {
    return toggleReverseFlexDirection(value);
  }

  return value;
};
