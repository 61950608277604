import { useParseFieldValue } from '../useParseFieldValue';
import {
  UseNumericFieldProps,
  UseNumericFieldReturnProps,
} from './useNumericField.interface';
import { useNumericFieldValue } from './useNumericFieldValue';
import { useNumericFieldInputs } from './useNumericFieldInputs';
import { DEFAULT_OPTIONS } from './useNumericField.utils';

export const useNumericField = ({
  fieldName,
  initialValue,
  value,
  saveChanges: setFieldValue,
  parentFieldTokenType,
  options,
  fieldSchema,
}: UseNumericFieldProps): UseNumericFieldReturnProps => {
  options = { ...DEFAULT_OPTIONS, ...options };

  const { rawValue, isComputed } = useParseFieldValue({
    fieldName,
    value,
    initialValue,
    tokenType: null,
    parentFieldTokenType,
  });

  const { parsedValue, parseValue, validateField } = useNumericFieldValue({
    rawValue,
    fieldSchema,
    options,
    fieldName,
  });

  const {
    fieldValue,
    registerField,
    handleEscape,
    handleBlur,
    handleEnter,
    handleKeyDown,
    setValue,
  } = useNumericFieldInputs({
    fieldName,
    value,
    parsedValue,
    initialValue,
    saveChanges: setFieldValue,
    fieldSchema,
    parseValue,
    validateField,
    options,
  });

  return {
    isComputed,
    setValue,

    value: fieldValue,
    rawValue,

    inputProps: {
      inputRegister: registerField,
      onEscape: handleEscape,
      onEnter: handleEnter,
      onBlur: handleBlur,
      onKeyDown: handleKeyDown,
    },
  };
};
