/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';

export const TextIcon = React.forwardRef<
  SVGSVGElement,
  React.HTMLAttributes<SVGSVGElement>
>(function TextIcon({ className, ...otherProps }, ref) {
  return (
    <svg
      width={'16'}
      height={'17'}
      viewBox={'0 0 16 17'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      className={className}
      {...otherProps}
    >
      <path
        d={
          'M13 4.25C13 3.97386 12.7761 3.75 12.5 3.75H3.5C3.22386 3.75 3 3.97386 3 4.25V5.25C3 5.52614 3.22386 5.75 3.5 5.75L7 5.75V13.25C7 13.5261 7.22386 13.75 7.5 13.75H8.5C8.77614 13.75 9 13.5261 9 13.25V5.75L12.5 5.75C12.7761 5.75 13 5.52614 13 5.25V4.25Z'
        }
        fill={'currentColor'}
      />
    </svg>
  );
});
