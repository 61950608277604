import { useCallback } from 'react';
import {
  selectedNodeComponent,
  selectedNodeComponentBooleanType,
  selectNodeLocalComponentDependencies,
  useStore,
  useStoreActions,
} from '@jux/canjux/core';
import { useAddToLibraryModal } from '@jux/ui/components/common/modal/useAddToLibraryModal/useAddToLibraryModal';
import { useTrackEvents } from '@jux/ui/hooks';
import { useSettingsState } from '@jux/ui/state';
import { toast } from '@jux/ui/toast';
import { InviteMemberToast } from '../components';

export const useAddToLibrary = () => {
  const selectedComponent = useStore(selectedNodeComponent);
  const { isLocalComponent } = useStore(selectedNodeComponentBooleanType);
  const localComponentDependencies = useStore(
    selectNodeLocalComponentDependencies
  );

  const {
    settings: {
      devFeatures: { addToLibraryToast },
    },
    disableAddToLibraryToast,
  } = useSettingsState();

  const { openAddToLibraryModal } = useAddToLibraryModal();
  const { trackAddComponentToLibraryEvent, trackAddToLibraryClick } =
    useTrackEvents();

  const {
    componentActions: { addComponentToLibrary },
  } = useStoreActions();

  const addComponentAndDependenciesToLibrary = useCallback(() => {
    if (!selectedComponent) {
      return;
    }

    addComponentToLibrary({ componentId: selectedComponent.id });
    trackAddComponentToLibraryEvent({ componentUUID: selectedComponent.id });

    if (addToLibraryToast) {
      toast.action(({ closeToast }) => (
        <InviteMemberToast closeToast={closeToast} />
      ));
      disableAddToLibraryToast();
    }
  }, [
    selectedComponent,
    addComponentToLibrary,
    trackAddComponentToLibraryEvent,
    addToLibraryToast,
    disableAddToLibraryToast,
  ]);

  const handleAddToLibrary = useCallback(async () => {
    if (!selectedComponent || !isLocalComponent) {
      return;
    }

    const deps = localComponentDependencies(selectedComponent.id) ?? [];
    const dependenciesCount = deps.length;
    const hasLocalComponentDependencies = dependenciesCount > 0;

    if (hasLocalComponentDependencies) {
      const dependenciesNames = deps.map((dep) => `[${dep}]`).join(', ');

      openAddToLibraryModal({
        componentName: selectedComponent?.displayName || selectedComponent.id,
        onConfirm: addComponentAndDependenciesToLibrary,
        dependenciesNames,
        dependenciesCount,
        componentsToAddCount: dependenciesCount + 1,
      });
    } else {
      addComponentAndDependenciesToLibrary();
    }
    trackAddToLibraryClick();
  }, [
    addComponentAndDependenciesToLibrary,
    isLocalComponent,
    localComponentDependencies,
    openAddToLibraryModal,
    selectedComponent,
    trackAddToLibraryClick,
  ]);

  return {
    handleAddToLibrary,
  };
};
