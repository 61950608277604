import { ComponentProps, FC, PropsWithChildren } from 'react';
import { MuiBox, MuiGrid } from '@jux/ui/components/common/mui';
import {
  TokenDrawerActions,
  TokenDrawerHeader,
  TokenUsedByComponents,
} from '@jux/ui/components/tokens/token-drawer/base';
import { useTokenDrawer } from '@jux/ui/components/tokens/token-drawer/useTokenDrawer';
import { TokenUsages } from '@jux/canjux/core';
import { FullHeightForm } from './FullHeightForm';

const Spacer = () => <MuiBox sx={{ flexGrow: 1 }} />;

export const TITLE_CREATE_NEW_TOKEN = 'Create new token';
export const TITLE_EDIT_TOKEN = 'Edit token';

export const TokenDrawerFormLayout: FC<
  PropsWithChildren<
    Required<Pick<ComponentProps<typeof FullHeightForm>, 'onSubmit'>> & {
      title?: string;
      componentsThatUseToken?: TokenUsages;
    }
  >
> = ({
  children,
  title = TITLE_CREATE_NEW_TOKEN,
  componentsThatUseToken,
  ...props
}) => {
  const { close, handlers } = useTokenDrawer();

  return (
    <FullHeightForm {...props}>
      <TokenDrawerHeader title={title} />
      <MuiGrid
        display="flex"
        flexDirection="column"
        flexGrow={1}
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          width: '100%',
        }}
      >
        {children}
        {componentsThatUseToken !== undefined && (
          <TokenUsedByComponents tokenUsages={componentsThatUseToken} />
        )}
      </MuiGrid>
      <Spacer />
      <TokenDrawerActions onCancel={handlers.onCancel || close} />
    </FullHeightForm>
  );
};
