import * as uuid from 'uuid';
import {
  ComponentConfigWithStates as StylesConfig,
  DEFAULT_STATE,
  StylesObject,
} from '@jux/types';
import { Colors } from '../common/colors';
import {
  ComponentPropType,
  COMPONENT_TAG_NAME,
  NodeType,
} from '@jux/data-entities';
import { getJuxSvgElementData } from './svg';
import { getJuxTextElementData } from './text';
import { ElementDataInit } from '../types/ElementDataInit';
import {
  JUX_ASSET_LINK_ICON_ID,
  JUX_ASSET_ARROW_TO_RIGHT_ICON_ID,
} from '../common/assets';

const JUX_BUTTON_DEFAULT_TEXT = 'Button';

const JUX_BUTTON_TEXT_DEFAULT_STYLES: StylesObject = {
  fontFamily: 'Inter',
  fontWeight: '500',
  fontStyle: 'normal',
  fontSize: '14px',
  lineHeight: '20px',
  display: 'inline-block',
  color: Colors.Neutrals_0,
};

export const BUTTON_ELEMENT_DEFAULT_DISPLAY_NAME = 'button';

export const BUTTON_ELEMENT_DEFAULT_STYLES: StylesConfig = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    height: '36px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px',
    padding: '8px 16px',
    borderRadius: '4px',
    borderStyle: 'none',
    borderColor: '', // this is set so that DDP would not show browser default #767676 and show empty value instead
    gap: '8px',
    cursor: 'pointer',
    outline: 'none',
  },
  variants: [
    {
      propsValues: {
        hierarchy: 'primary',
        disabled: false,
      },
      styles: {
        backgroundColor: Colors.Brand_500,
      },
    },
    {
      propsValues: {
        hierarchy: 'secondary',
        disabled: false,
      },
      styles: {
        backgroundColor: Colors.Neutrals_400,
      },
    },
    {
      propsValues: {
        hierarchy: 'primary',
        disabled: true,
      },
      styles: {
        backgroundColor: Colors.Brand_200,
        cursor: 'default',
      },
    },
    {
      propsValues: {
        hierarchy: 'secondary',
        disabled: true,
      },
      styles: {
        backgroundColor: Colors.Neutrals_300,
        cursor: 'default',
      },
    },
  ],
  states: {
    hover: {
      root: {},
      variants: [
        {
          propsValues: {
            hierarchy: 'primary',
            disabled: false,
          },
          styles: {
            backgroundColor: Colors.Brand_600,
          },
        },
        {
          propsValues: {
            hierarchy: 'secondary',
            disabled: false,
          },
          styles: {
            backgroundColor: Colors.Neutrals_500,
          },
        },
      ],
    },
    active: {
      root: {
        outline: 'none',
      },
      variants: [
        {
          propsValues: {
            hierarchy: 'primary',
            disabled: false,
          },
          styles: {
            backgroundColor: Colors.Brand_700,
          },
        },
        {
          propsValues: {
            hierarchy: 'secondary',
            disabled: false,
          },
          styles: {
            backgroundColor: Colors.Neutrals_600,
          },
        },
      ],
    },
  },
};

export const getJuxButtonElementData: ElementDataInit<undefined> = ({
  parentId,
} = {}) => {
  const rootButtonId = uuid.v4();

  const leadingIcon = getJuxSvgElementData({
    parentId: rootButtonId,
    displayName: 'leading_icon',
    sourceComponentId: JUX_ASSET_LINK_ICON_ID,
    rootStyles: {
      color: Colors.Neutrals_0,
    },
  }).root;
  const buttonLabel = getJuxTextElementData({
    rootStyles: JUX_BUTTON_TEXT_DEFAULT_STYLES,
    parentId: rootButtonId,
    text: JUX_BUTTON_DEFAULT_TEXT,
    displayName: 'button_label',
  }).root;
  const trailingIcon = getJuxSvgElementData({
    parentId: rootButtonId,
    displayName: 'trailing_icon',
    sourceComponentId: JUX_ASSET_ARROW_TO_RIGHT_ICON_ID,
    rootStyles: {
      color: Colors.Neutrals_0,
    },
  }).root;

  return {
    root: {
      type: NodeType.LOCAL_COMPONENT,
      id: rootButtonId,
      parentId,
      scopes: [],
      tagName: COMPONENT_TAG_NAME.JuxButton,
      styles: BUTTON_ELEMENT_DEFAULT_STYLES,
      config: {
        props: {
          // starting values of props
          hierarchy: 'primary',
          disabled: false,
        },
        interactiveState: DEFAULT_STATE,
        variants: [
          {
            variant: 'disabled',
            propType: ComponentPropType.Enum,
            options: [
              { value: true, label: 'True' },
              { value: false, label: 'False' },
            ],
            defaultValue: false,
            isFixed: true,
          },
          {
            variant: 'hierarchy',
            propType: ComponentPropType.Enum,
            options: [
              { value: 'primary', label: 'Primary' },
              { value: 'secondary', label: 'Secondary' },
            ],
            defaultValue: 'primary',
            isFixed: false,
          },
        ],
      },
      displayName: BUTTON_ELEMENT_DEFAULT_DISPLAY_NAME,
      children: [leadingIcon.id, buttonLabel.id, trailingIcon.id],
    },
    subComponents: [leadingIcon, buttonLabel, trailingIcon],
  };
};
