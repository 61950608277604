export const LIVEBLOCKS_READY_CLASS = 'liveblocks-ready';

export const TEST_ID_MAIN_LOADING = 'main-loading';

export const TEST_ID_MENU_VERTICAL_ICON = 'menu-vertical-icon';
export const TEST_ID_INVITE_USERS_ICON_BUTTON = 'invite-users-icon-button';
export const TEST_ID_DIV_ICON_BUTTON = 'div-icon-button';
export const TEST_ID_TEXT_ICON_BUTTON = 'text-icon-button';
export const TEST_ID_SLOT_ICON_BUTTON = 'slot-icon-button';
export const TEST_ID_COMPONENTS_POPPER = 'components-popper';
export const TEST_ID_ELEMENTS_POPPER = 'elements-popper';
export const TEST_ID_ASSETS_POPPER = 'assets-popper';
export const TEST_ID_COMPONENT_PREVIEW_ITEM = 'component-preview-item';
export const TEST_ID_ASSET_PREVIEW_ITEM = 'asset-preview-item';
export const TEST_ID_NAVIGATION_HEADER = 'navigation-header';
export const TEST_ID_SIDEBAR = 'sidebar-drawer';

export const DIV_ELEMENT_DATA_ATTR = '[data-jux-element="JuxDiv"]';
export const TEXT_ELEMENT_DATA_ATTR = '[data-jux-element="JuxText"]';
export const SVG_ELEMENT_DATA_ATTR = '[data-jux-element="JuxSvg"]';
