import { CanjuxState } from '../../store';
import { getRootNodeOfNode } from './getRootNodeOfNode';
import { NodeType } from '@jux/data-entities';
import { isInstanceExistInTree } from './isInstanceExistInTree';
import { getLocalComponentDependencies } from './getLocalComponentDependencies';

/**
 * Check if the target node root is a component and the added node has an instance of it in its tree.
 */
export const isTargetNodeRootComponentWithInstance = ({
  sourceComponentId,
  targetNodeId,
  components,
}: {
  sourceComponentId: string;
  targetNodeId: string;
  components: CanjuxState['components'];
}): boolean => {
  const targetRootNode = getRootNodeOfNode({
    components,
    nodeId: targetNodeId,
  });
  const isTargetRootNodeAComponent =
    targetRootNode.type === NodeType.LIBRARY_COMPONENT ||
    targetRootNode.type === NodeType.LOCAL_COMPONENT;

  return (
    isTargetRootNodeAComponent &&
    (sourceComponentId === targetRootNode.id ||
      isInstanceExistInTree({
        components,
        nodeId: sourceComponentId,
        sourceComponentNodeId: targetRootNode.id,
      }))
  );
};

/**
 * Check if the target node root is a library component and the added node has local component dependencies.
 */
export const isTargetNodeRootLibraryComponentWithLocalDependencies = ({
  sourceComponentId,
  targetNodeId,
  components,
}: {
  sourceComponentId: string;
  targetNodeId: string;
  components: CanjuxState['components'];
}): boolean => {
  const targetRootNode = getRootNodeOfNode({
    components,
    nodeId: targetNodeId,
  });

  if (targetRootNode.type === NodeType.LIBRARY_COMPONENT) {
    const sourceComponentType = components[sourceComponentId]?.type;
    const hasLocalComponentDependencies =
      sourceComponentType === NodeType.LOCAL_COMPONENT ||
      getLocalComponentDependencies({
        componentId: sourceComponentId,
        components: components,
      }).length > 0;
    return hasLocalComponentDependencies;
  }

  return false;
};
