import { MuiGrid, styled } from '@jux/ui/components/common/mui';

const MIN_HEIGHT = 400;
const MAX_HEIGHT = 750;
const VH_HEIGHT = '60vh';

export const DDPAssetsPopperWrapper = styled(MuiGrid)(({ theme }) => ({
  width: '228px',
  backgroundColor: theme.drimz.palette.background.default,
  boxShadow: theme.drimz.shadow.editor.panels.ddp.assetsPopper.boxShadow,
  display: 'flex',
  flexDirection: 'column',

  maxHeight: MAX_HEIGHT,
  minHeight: MIN_HEIGHT,
  height: VH_HEIGHT,
}));

export const ContentHeader = styled(MuiGrid)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.drimz.palette.background.default,
  zIndex: 1,
}));

export const DDPAssetsPopperItems = styled(MuiGrid)(() => ({
  padding: '16px 20px 16px',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  overflowX: 'hidden',
}));
