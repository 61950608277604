import { CORE_TOKEN_SET_NAME } from '@jux/types';
import {
  getCurrentTimestamp,
  getDefaultStructuredTokenSet,
} from '@juxio/design-tokens';
import { v4 as uuidv4 } from 'uuid';
import { getDesignTokensParserContext } from '../../../store-utils/getDesignTokensParserContext';

const designTokensParser = getDesignTokensParserContext();

export const getInitialCoreTokenSetData = () => ({
  createdAt: getCurrentTimestamp(),
  id: uuidv4(),
  name: CORE_TOKEN_SET_NAME,
  updatedAt: getCurrentTimestamp(),
  value: designTokensParser
    .parse(getDefaultStructuredTokenSet())
    .getRawValueCopy(),
});
