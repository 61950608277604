import colorString from 'color-string';
import { z } from 'zod';
import { colorTokenValueSchema } from '@juxio/design-tokens';
import { colorTokenFormSchema } from '@jux/ui/components/tokens/token-drawer/forms/colorTokenForm/ColorTokenForm.interface';
import { withHash } from '@jux/ui/utils/css';

const parseAlphaToOpacity = (alpha: number) => Math.floor(alpha * 100);

export const parseFormValueToColorPickerValue = (
  hex: string,
  opacity: number
): string | undefined => {
  const parsedColor = colorString.get.rgb(withHash(hex));
  if (!parsedColor) return;

  const alpha = opacity / 100;
  if (alpha > 1 || alpha < 0) return;

  const [r, g, b] = parsedColor;
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const parseColorPickerValueToFormValue = (rgba: string) => {
  const parsedRgba = colorString.get.rgb(rgba);

  const [r, g, b, alpha] = parsedRgba || [0, 0, 0, 1];
  const color = colorString.to.hex([r, g, b]).replace('#', '');
  const opacity = parseAlphaToOpacity(alpha);

  return {
    color,
    opacity,
  };
};

export const colorTokenValueParser = {
  stringToObject: z.optional(colorTokenValueSchema).transform((value) => {
    if (!value)
      return {
        color: '',
        opacity: 100,
        colorPickerRgba: undefined,
      };

    const [r, g, b, alpha] = colorString.get.rgb(value);
    const color = colorString.to.hex([r, g, b]).replace('#', '');
    const opacity = parseAlphaToOpacity(alpha);

    return {
      color,
      opacity,
      colorPickerRgba: parseFormValueToColorPickerValue(color, opacity),
    };
  }),
  objectToString: colorTokenFormSchema.shape.value.transform(
    ({ color, opacity }) => {
      // parse color and opacity to rgb color with opacity "rgb(0 0 0 / 50%)""
      const [r, g, b] = colorString.get.rgb(withHash(color));

      return `rgba(${r} ${g} ${b} / ${opacity}%)`;
    }
  ),
};
