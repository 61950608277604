import { JuxStoreActionFn, PropsActionsParams } from '../../store.interface';
import type { Draft as WritableDraft } from 'mutative';
import { ComponentConfigWithStates } from '@jux/types';
import {
  updateContextStylesOnDeletePropValue,
  updateStylesOnDeletePropValue,
} from '../utils/deletePropValueUtils';
import { replacePropValueInAllInstances } from '../utils/replacePropValueInAllInstances';
import { NodeType } from '@jux/data-entities';
import { updateMatrixNodesOnDeletePropValue } from '../../../matrix-utils/updateMatrixNodesOnDeleteProp';
import { CanjuxState } from '@jux/canjux/core';

/**
 * Delete a component property value option
 */
export const deleteComponentPropValue: JuxStoreActionFn<
  PropsActionsParams['deleteComponentPropValue'],
  CanjuxState
> = ({ componentId, propName, propValueLabel, newPropValue, state }) => {
  const component = state.components[componentId];
  if (!component) return state;

  switch (component.type) {
    case NodeType.LOCAL_COMPONENT:
    case NodeType.LIBRARY_COMPONENT:
      const variantConfig = component.config.variants?.find(
        (variant) => variant.variant === propName
      );
      // If propName not found or trying to delete the last value - return.
      if (!variantConfig || variantConfig.options.length === 1) {
        return state;
      }

      const optionToDelete = variantConfig.options.find(
        (option) => option.label === propValueLabel
      );
      if (!optionToDelete) {
        return state;
      }

      const oldPropValue = optionToDelete.value;

      variantConfig.options = variantConfig.options.filter(
        (option) => option.label !== propValueLabel
      );

      // if the default value deleted - set a new default by the order
      if (variantConfig.defaultValue === optionToDelete.value) {
        variantConfig.defaultValue = variantConfig.options[0].value;
      }

      const isCurrentValueWasDeleted =
        propName in component.config.props &&
        component.config.props[propName] === optionToDelete.value;
      // Replace the current selected variant value if needed
      if (isCurrentValueWasDeleted) {
        component.config.props[propName] = variantConfig.defaultValue;
      }

      // Edit existing styles and duplicate default styles for new value options
      const styles =
        component.styles as WritableDraft<ComponentConfigWithStates>;
      if (styles.variants) {
        styles.variants = updateStylesOnDeletePropValue({
          variantsStyles: styles.variants,
          propName: propName,
          propValueToDelete: oldPropValue,
        });
      }

      // Go over state styles and do the same
      for (const stateStyles of Object.values(styles.states)) {
        if (stateStyles.variants) {
          stateStyles.variants = updateStylesOnDeletePropValue({
            variantsStyles: stateStyles.variants,
            propName: propName,
            propValueToDelete: oldPropValue,
          });
        }
      }

      if (styles.contextStyles) {
        styles.contextStyles = updateContextStylesOnDeletePropValue({
          contextStyles: styles.contextStyles,
          propName: propName,
          propValueToDelete: oldPropValue,
        });
      }

      // Update matrix nodes before fixing instances
      updateMatrixNodesOnDeletePropValue({
        sourceComponentId: component.id,
        propName,
        deletedValue: oldPropValue,
        state,
      });

      const newPropValueToSet = newPropValue
        ? newPropValue
        : variantConfig.defaultValue;
      replacePropValueInAllInstances({
        componentId,
        propName,
        propValue: oldPropValue,
        newPropValue: newPropValueToSet,
        components: state.components,
      });

      component.updatedAt = new Date().getTime();

      break;
    default:
      throw new Error(
        `Cannot delete prop value for component type ${component.type}`
      );
  }

  return state;
};
