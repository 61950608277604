import { KeyboardEvent, MouseEvent, useCallback } from 'react';
import { useAtomValue } from 'jotai/index';
import { toast } from '@jux/ui/toast';
import { SegmentEventSourcePage } from '@jux/data-access/analytics/track-events-properties';
import { currentOrganizationAtom } from '@jux/ui/hooks';
import { api } from '@jux/ui/trpc/client/api';
import { useTrackInviteMembers } from './useTrackInviteMembers';

const parseStringToArray = (str: string): string[] =>
  str.split(',').map((email) => email.trim());

export const useSaveInviteMembersForm = ({
  sourcePage,
  setErrors,
  getEmailValue,
  setEmailValue,
  closeSubmitModal,
}: {
  sourcePage: SegmentEventSourcePage;
  setErrors: (error: string) => void;
  getEmailValue: () => string;
  setEmailValue: (value: string) => void;
  closeSubmitModal: () => void;
}) => {
  const currentOrganization = useAtomValue(currentOrganizationAtom);
  const InviteUserByEmails =
    api.organizations.inviteNewUserByEmails.useMutation();
  const { trackSendInviteMembers } = useTrackInviteMembers(sourcePage);

  const sendEmail = useCallback(
    (emails: string) => {
      InviteUserByEmails.mutate(
        {
          emails: parseStringToArray(emails),
          org_id: currentOrganization.id,
        },
        {
          onSuccess: () => {
            setEmailValue('');

            trackSendInviteMembers();
            toast.success('Invitation sent', {
              role: 'alert',
            });

            closeSubmitModal();
          },
          onError: (error: any) => {
            try {
              if (error.data?.code === 'BAD_REQUEST') {
                const errorMessage = JSON.parse(error.message)[0].message;
                setErrors(errorMessage);
              } else {
                setErrors(error.message);
              }
            } catch (e) {
              setErrors('Something went wrong');
            }
          },
        }
      );
    },
    [
      InviteUserByEmails,
      closeSubmitModal,
      currentOrganization.id,
      setEmailValue,
      setErrors,
      trackSendInviteMembers,
    ]
  );

  const handleSubmit = useCallback(
    (event: Event | KeyboardEvent | MouseEvent) => {
      event.preventDefault();

      setErrors('');

      sendEmail(getEmailValue());
    },
    [getEmailValue, sendEmail, setErrors]
  );

  return {
    onSubmit: handleSubmit,
  };
};
