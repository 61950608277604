import {
  CanjuxState,
  ComponentActionsParams,
  ComponentPlacement,
  findFirstTargetUpTree,
  getRootNodeValidPlacement,
  JuxStoreActionFn,
  setLayersData,
} from '@jux/canjux/core';
import { duplicateNode } from './utils/duplicateNode';
import { toast } from '@jux/ui/toast';
import { NodeType } from '@jux/data-entities';

const NOT_ALLOWED_TO_DUPLICATE_INTO_INSTANCES =
  'It isn’t possible to duplicate objects inside instances';

type DuplicateErrors = typeof NOT_ALLOWED_TO_DUPLICATE_INTO_INSTANCES;

export const duplicateSelectedNodes: JuxStoreActionFn<
  ComponentActionsParams['duplicateSelectedNodes'],
  CanjuxState
> = ({ state }) => {
  // Getting the right placement of a the new component on the canvas
  // TODO: need to provide the component׳s dimensions in order to calculate more accurate position
  const { width, height, transform } = state;
  const currentCanvas = state.canvases[state.currentCanvasName];

  const errors = new Map<DuplicateErrors, boolean>([
    [NOT_ALLOWED_TO_DUPLICATE_INTO_INSTANCES, false],
  ]);

  for (const componentId of state.selectedNodesStack) {
    let component = state.components[componentId];
    if (!component) return state;

    if (component.type === NodeType.VARIANT_INSTANCE) {
      if (!component.sourceComponentId)
        throw new Error('Variant instance without source component');

      component = state.components[component.sourceComponentId];

      if (!component.parentId) {
        toast.error(
          'You cannot duplicate root variant in matrix, use edit properties panel to add new variants',
          { autoClose: 2000 }
        );
        continue;
      }
    }

    let targetPlacement: ComponentPlacement;
    const targetComponent = component.parentId
      ? state.components[component.parentId]
      : undefined;

    if (!targetComponent) {
      targetPlacement = getRootNodeValidPlacement({
        originalTargetNodeId: component.id,
        components: state.components,
        canvasNodesDimensions: state.canvasNodesDimensions,
        canvas: currentCanvas,
        transform: transform,
        canvasDimensions: { width, height },
      });
    } else {
      // Search for valid location from parent and up
      targetPlacement = findFirstTargetUpTree({
        componentId: targetComponent.id,
        initiatedFromChildIndex: targetComponent.children.indexOf(component.id),
        components: state.components,
        canvas: currentCanvas,
      });
      if (!targetPlacement.targetNodeId) {
        // If the duplicated node is not a root node but doesn't have a valid parent, skip it
        errors.set(NOT_ALLOWED_TO_DUPLICATE_INTO_INSTANCES, true);
        continue;
      }
    }

    duplicateNode({
      position: targetPlacement.position,
      sourceComponentId: component.id,
      state,
      targetCanvasName: currentCanvas.name,
      targetIndex: targetPlacement.targetChildIndex,
      targetNodeId: targetPlacement.targetNodeId,
    });
  }

  setLayersData(state);

  for (const [error, hasError] of errors) {
    if (hasError) {
      toast.error(error);
    }
  }

  return state;
};
