/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';

export const Logoicon = React.forwardRef<
  SVGSVGElement,
  React.HTMLAttributes<SVGSVGElement>
>(function Logoicon({ className, ...otherProps }, ref) {
  return (
    <svg
      width={'28'}
      height={'28'}
      viewBox={'0 0 28 28'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      className={className}
      {...otherProps}
    >
      <g clipPath={'url(#clip0_1702_77511)'}>
        <rect width={'28'} height={'28'} rx={'2'} fill={'#1A1A1A'} />
        <path
          d={'M14.1265 14L10 20H14.7522L18.8 14L14.7522 8H10L14.1265 14Z'}
          fill={'white'}
        />
      </g>
      <defs>
        <clipPath id={'clip0_1702_77511'}>
          <rect width={'28'} height={'28'} rx={'2'} fill={'white'} />
        </clipPath>
      </defs>
    </svg>
  );
});
