import { DATA_JUX_NODE_ID_ATTRIBUTE } from '../../constants';
import { storeApi } from '../../crdt';
import { NodeChange } from '../../store';
import { getElementAbsolutePosition, getPositionOnCanjux } from '../graph';

/**
 * Calculates the absolute position of nodes in the DOM, where each Node represents a node in canjux.
 *
 * @param nodesElements - An array of nodes to calculate and update the position for.
 * @returns An array of node changes with updated absolute positions.
 * @throws Error if the parent container pane is not found.
 */
export const getNodesAbsolutePositionUpdates = (nodesElements: Node[]) => {
  const changes: Array<NodeChange<'position_absolute'>> = [];
  const { transform } = storeApi.getState();

  function traverseNodes(node: Node) {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const n = node as HTMLElement;
      const juxNodeId = n.getAttribute(DATA_JUX_NODE_ID_ATTRIBUTE);
      if (!juxNodeId) {
        return;
      }

      const dim = getPositionOnCanjux(
        getElementAbsolutePosition(n),
        transform,
        false
      );

      changes.push({
        id: n.id,
        type: 'position_absolute',
        data: {
          x: dim.x,
          y: dim.y,
        },
      });

      node.childNodes.forEach(
        (child) =>
          (child as HTMLElement).getAttribute?.(DATA_JUX_NODE_ID_ATTRIBUTE) &&
          traverseNodes(child)
      );
    }
  }

  nodesElements.forEach(
    (node) =>
      (node as HTMLElement).getAttribute(DATA_JUX_NODE_ID_ATTRIBUTE) &&
      traverseNodes(node)
  );

  return changes;
};
