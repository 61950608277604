import {
  ComponentActionsParams,
  JuxStore,
  JuxStoreActionFn,
} from '@jux/canjux/core';
import { NodeType } from '@jux/data-entities';

/**
 * Update a node's interactive state
 */
export const updateNodeInteractiveState: JuxStoreActionFn<
  ComponentActionsParams['updateNodeInteractiveState'],
  JuxStore
> = ({ nodeId, interactiveState, state }) => {
  const component = state.components[nodeId];
  if (
    component.type !== NodeType.LIBRARY_COMPONENT &&
    component.type !== NodeType.LOCAL_COMPONENT
  )
    return state;

  component.config.interactiveState = interactiveState;
  return state;
};
