import {
  COMPONENT_TAG_NAME,
  NodeProperties,
  NodeType,
} from '@jux/data-entities';
import {
  NAVIGATOR_DYNAMIC_SLOT_COLOR,
  NODE_TOOLBAR_SELECTED_COLOR,
  NODE_TOOLBAR_SELECTED_COLOR_LIBRARY_COMPONENT,
} from '@jux/ui/theme/palette';
import { withPx } from '@jux/ui/utils/css';
import equal from 'fast-deep-equal';
import { FC, memo, RefObject, useMemo } from 'react';
import { storeApi } from '../../../crdt';
import { useNodeInteractions, useStore } from '../../../hooks';
import {
  nodeComponentByNodeId,
  selectCanvasNodesDimensions,
  selectCanvasNodesIndicatorsPositions,
  selectHoveredNodeById,
  selectIsLiveMode,
  selectIsTextNodeInEditMode,
  selectSelectedNodeIds,
} from '../../../store';
import { ControlHandlePosition, ResizePosition } from '../../NodeResizer';
import HandleControl from '../../NodeResizer/HandleControl';
import LineControl from '../../NodeResizer/LineControl';
import { NodeNameToolbar } from './NodeNameToolbar';
import { NodeSizeToolbar } from './NodeSizeToolbar';
import { DATA_JUX_ID_ATTRIBUTE } from '@jux/canjux/core';

const handleControls: ControlHandlePosition = [
  {
    verticalPosition: ResizePosition.top,
    horizontalPosition: ResizePosition.left,
  },
  {
    verticalPosition: ResizePosition.top,
    horizontalPosition: ResizePosition.right,
  },
  {
    verticalPosition: ResizePosition.bottom,
    horizontalPosition: ResizePosition.left,
  },
  {
    verticalPosition: ResizePosition.bottom,
    horizontalPosition: ResizePosition.right,
  },
];

export const lineControls = [
  ResizePosition.top,
  ResizePosition.right,
  ResizePosition.bottom,
  ResizePosition.left,
] as const;

const HANDLE_BACKGROUND_COLOR = '#fff';
export const LINE_THICKNESS = '1px';
export const LINE_OVERFLOW = '5px';
const HANDLE_SIZE = '1px';

type NodeResizerProps = {
  nodeId: string;
  nodeProperties: NodeProperties;
  disableNodesInteraction: boolean;
  isInLibrary: boolean;
};

export const NodeToolbar: FC<NodeResizerProps> = memo(
  ({ nodeId, nodeProperties, disableNodesInteraction, isInLibrary }) => {
    const getIsTextNodeInEditMode = useStore(selectIsTextNodeInEditMode);
    const textNodeInEditMode = Boolean(getIsTextNodeInEditMode(nodeId));
    const nodeHoverState = useStore(selectHoveredNodeById(nodeId));
    const nodeComponentData = useStore(nodeComponentByNodeId(nodeId));
    const selectedNodes = useStore(selectSelectedNodeIds, equal);
    const nodeDimensions = useStore(selectCanvasNodesDimensions(nodeId), equal);
    const nodePositionAbsolute = useStore(
      selectCanvasNodesIndicatorsPositions(nodeId)
    );
    const transform = useStore((s) => s.transform);
    const isLive = useStore(selectIsLiveMode());
    const isMatrixWrapper = nodeComponentData?.type === NodeType.VARIANTS_GROUP;

    const sourceComponent = nodeComponentData?.sourceComponentId
      ? storeApi.getState().components[nodeComponentData.sourceComponentId]
      : undefined;
    const isDynamicSlotOrDynamicSlotInstance =
      nodeComponentData?.type === NodeType.DYNAMIC_SLOT ||
      sourceComponent?.type === NodeType.DYNAMIC_SLOT;

    const showNodeNameToolbar = useMemo(() => {
      const isElementDivOrText =
        nodeComponentData?.type === NodeType.ELEMENT &&
        (nodeComponentData?.tagName === COMPONENT_TAG_NAME.JuxText ||
          nodeComponentData?.tagName === COMPONENT_TAG_NAME.JuxDiv);
      return (
        !nodeComponentData?.parentId &&
        !disableNodesInteraction &&
        !isElementDivOrText
      );
    }, [nodeComponentData, disableNodesInteraction]);

    const color = useMemo(() => {
      if (isDynamicSlotOrDynamicSlotInstance) {
        return NAVIGATOR_DYNAMIC_SLOT_COLOR;
      }

      return isInLibrary
        ? NODE_TOOLBAR_SELECTED_COLOR_LIBRARY_COMPONENT
        : NODE_TOOLBAR_SELECTED_COLOR;
    }, [isDynamicSlotOrDynamicSlotInstance, isInLibrary]);

    const zIndex = useMemo(() => {
      let index = 1;
      let parentId = nodeComponentData?.parentId;

      while (parentId) {
        index++;
        parentId = storeApi.getState().components[parentId]?.parentId;
      }

      return index;
    }, [nodeComponentData?.parentId]);

    const { elementRef, registerEvents } = useNodeInteractions({
      nodeId,
    });

    const isHovered = Boolean(nodeHoverState);

    const isSelected = selectedNodes.includes(nodeId);

    const { isDragged, isImmutable } = nodeProperties;

    const shouldShowNodeResizer =
      !isDragged && !disableNodesInteraction && (isSelected || isHovered);

    const shouldShowNodeSizeToolbar =
      !isDragged && !disableNodesInteraction && isSelected && !isMatrixWrapper;

    const isResizable =
      !isImmutable &&
      nodeComponentData?.type !== NodeType.INSTANCE &&
      nodeComponentData?.type !== NodeType.LOGICAL_SLOT &&
      !isMatrixWrapper &&
      selectedNodes.length === 1;

    const shouldShowNodeToolbar = !isLive && !isDragged;

    return (
      <div
        style={{
          zIndex,
          position: 'absolute',
          transform: `translate(${withPx(nodePositionAbsolute.x)}, ${withPx(
            nodePositionAbsolute.y
          )})`,
          backgroundColor: 'transparent',
          width: nodeDimensions.width,
          height: nodeDimensions.height,
          visibility: shouldShowNodeToolbar ? 'visible' : 'hidden',
          pointerEvents:
            textNodeInEditMode || !shouldShowNodeToolbar ? 'none' : 'all',
        }}
        ref={elementRef as RefObject<HTMLDivElement>}
        {...registerEvents()}
        {...{ [DATA_JUX_ID_ATTRIBUTE]: nodeId }}
      >
        {/* Node Display Name */}
        {showNodeNameToolbar && (
          <NodeNameToolbar
            nodeId={nodeId}
            isSelected={isSelected}
            isHovered={isHovered}
            nodeDimensions={nodeDimensions}
            zoomLevel={transform.zoom}
            isInLibrary={isInLibrary}
          />
        )}

        {/* Node Size Toolbar */}
        {shouldShowNodeSizeToolbar && (
          <NodeSizeToolbar
            nodeDimensions={nodeDimensions}
            zoomLevel={transform.zoom}
          />
        )}

        {shouldShowNodeResizer && (
          <>
            {/* Line Controls */}
            {(nodeHoverState || isSelected) &&
              lineControls.map((lc, index) => (
                <LineControl
                  hoverLineType={nodeHoverState?.type}
                  isDynamicSlot={isDynamicSlotOrDynamicSlotInstance}
                  isHovered={Boolean(nodeHoverState) && !isSelected}
                  isResizable={isResizable}
                  isSelected={isSelected}
                  key={`${lc}-${index}`}
                  lineColor={color}
                  lineOverflow={LINE_OVERFLOW}
                  lineThickness={LINE_THICKNESS}
                  nodeDimensions={nodeDimensions}
                  nodeId={nodeId}
                  nodePosition={nodePositionAbsolute}
                  position={lc}
                  revertZoomScale={1 / transform.zoom}
                />
              ))}

            {/* Handle Controls */}
            {isSelected &&
              isResizable &&
              handleControls.map((c) => (
                <HandleControl
                  backgroundColor={HANDLE_BACKGROUND_COLOR}
                  handleSize={HANDLE_SIZE}
                  horizontalPosition={c.horizontalPosition}
                  isSelected={isSelected}
                  key={`${c.verticalPosition}-${c.horizontalPosition}`}
                  lineColor={color}
                  nodeDimensions={nodeDimensions}
                  nodeId={nodeId}
                  nodePosition={nodePositionAbsolute}
                  revertZoomScale={1 / transform.zoom}
                  verticalPosition={c.verticalPosition}
                />
              ))}
          </>
        )}
      </div>
    );
  }
);
