import { ComponentConfigWithStates } from '@jux/types';

export const findPathsInStyles = ({
  paths,
  styles,
}: {
  paths: Array<string>;
  styles: ComponentConfigWithStates;
}): boolean => {
  const stringifiedStyles = JSON.stringify(styles);

  return paths.some((path) => stringifiedStyles.includes(path));
};
