import { UseComboboxStateChange, useCombobox } from 'downshift';
import {
  useCallback,
  useState,
  useEffect,
  useMemo,
  KeyboardEvent,
  FocusEvent,
  useRef,
} from 'react';
import { useEffectOnSelectedNodeChange } from '@jux/ui/components/editor/hooks';
import { downshiftScrollToItem } from '@jux/ui/utils/downshiftScrollToItem';
import { useEffectOnSelectedNodeStateChange } from '../../../hooks';

import {
  AutocompleteOptionData,
  AutocompleteProps,
} from './Autocomplete.interface';

export const useSelectFieldAutocomplete = ({
  initialOptions,
  selectedOption,
  onSelect,
  onBlur,
  onEnter,
  onEscape,
  isMenuOpen,
  closeMenu,
  openMenu,
}: {
  initialOptions: Array<AutocompleteOptionData>;
  selectedOption?: string;
  onSelect: AutocompleteProps['onSelect'];
  onBlur: AutocompleteProps['onBlur'];
  onEnter: AutocompleteProps['onEnter'];
  onEscape: AutocompleteProps['onEscape'];
  isMenuOpen: AutocompleteProps['isMenuOpen'];
  closeMenu: AutocompleteProps['closeMenu'];
  openMenu: AutocompleteProps['openMenu'];
}) => {
  const [inputValue, setInputValue] = useState(selectedOption || '');
  const [options, setOptions] = useState(initialOptions);
  const optionsWrapperRef = useRef<HTMLDivElement>(null);

  const initialSelectedItemIndex = useMemo(
    () => options.findIndex((option) => option.label === selectedOption),
    [options, selectedOption]
  );
  const [highlightedIndex, setHighlightedIndex] = useState<number | undefined>(
    initialSelectedItemIndex
  );

  const initialSelectedItem = useMemo(
    () => options.find((option) => option.label === selectedOption),
    [options, selectedOption]
  );

  const initOptions = useCallback(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  const findFirstOptionIndex = useCallback(
    (newInputValue: string | undefined) => {
      const filteredOptions = initialOptions.filter((option) =>
        option.label.toLowerCase().includes(newInputValue?.toLowerCase() || '')
      );
      const findFirstOption = filteredOptions.find((option) =>
        option.label.toLowerCase().includes(newInputValue?.toLowerCase() || '')
      );
      const firstOptionIndex = findFirstOption
        ? filteredOptions.indexOf(findFirstOption)
        : undefined;

      return {
        filteredOptions,
        firstOptionIndex,
      };
    },
    [initialOptions]
  );

  const handleSelectedItemChange = useCallback(
    ({ selectedItem }: UseComboboxStateChange<AutocompleteOptionData>) => {
      if (!selectedItem) return;
      // call onSelect with the title or the value if there is no title
      setInputValue(selectedItem.label);
      onSelect?.(selectedItem);
      initOptions();
      closeMenu?.();

      (document.activeElement as HTMLElement).blur();
    },
    [setInputValue, onSelect, initOptions, closeMenu]
  );

  const handleInputValueChange = useCallback(
    ({
      inputValue: newInputValue,
      isOpen,
    }: UseComboboxStateChange<AutocompleteOptionData>) => {
      // Handle input value change when the menu is closed
      if (!isOpen) {
        setInputValue(newInputValue || inputValue || '');
        initOptions();
        return;
      }

      // Handle input value change when value is empty
      if (!newInputValue) {
        setInputValue('');
        initOptions();
        return;
      }

      // Handle input value change when menu is open and value is not empty
      const { firstOptionIndex, filteredOptions } =
        findFirstOptionIndex(newInputValue);

      const hasOptionIndex = firstOptionIndex !== undefined;
      const hasSelectedOptionInCurrentFilteredOptions = filteredOptions.find(
        (option) => option.value === selectedOption
      );

      if (hasOptionIndex && !hasSelectedOptionInCurrentFilteredOptions) {
        setHighlightedIndex(firstOptionIndex);
      } else {
        setHighlightedIndex(undefined);
      }

      setInputValue(newInputValue);
      setOptions(filteredOptions);
    },
    [findFirstOptionIndex, initOptions, inputValue, selectedOption]
  );

  const handleIsOpenChange = useCallback(
    ({ isOpen, type }: UseComboboxStateChange<AutocompleteOptionData>) => {
      // scroll to the selected item when the menu is opened
      if (isOpen) {
        // we need to wait for the menu to be rendered before scrolling to the selected item
        setTimeout(() => {
          downshiftScrollToItem({
            optionsWrapperRef,
            itemIndex: initialSelectedItemIndex,
          });
        }, 0);
      }

      // Handle Escape key - close menu and clear input value
      if (type === useCombobox.stateChangeTypes.InputKeyDownEscape) {
        setInputValue('');
        initOptions();

        // Either clear the input value or close the menu
        if (isOpen || inputValue) {
          openMenu?.();
          setInputValue('');
        } else {
          closeMenu?.();
          setInputValue(selectedOption || '');
        }

        return;
      }
      openMenu?.();
    },
    [
      closeMenu,
      initOptions,
      initialSelectedItemIndex,
      inputValue,
      openMenu,
      selectedOption,
    ]
  );

  const handleItemToString = useCallback(
    (item: AutocompleteOptionData | null) => item?.label || '',
    []
  );

  const { getMenuProps, getInputProps, getItemProps, isOpen } =
    useCombobox<AutocompleteOptionData>({
      inputValue,
      highlightedIndex,
      onHighlightedIndexChange(changes) {
        setHighlightedIndex(changes.highlightedIndex);
      },
      initialSelectedItem,
      isOpen: isMenuOpen,
      items: options,
      onInputValueChange: handleInputValueChange,
      onSelectedItemChange: handleSelectedItemChange,
      itemToString: handleItemToString,
      onIsOpenChange: handleIsOpenChange,
    });

  const handleEffectChanges = useCallback(() => {
    if (!selectedOption) return;

    setInputValue(selectedOption);
  }, [selectedOption, setInputValue]);

  useEffectOnSelectedNodeChange(handleEffectChanges);
  useEffectOnSelectedNodeStateChange(handleEffectChanges);

  const handleEnter = useCallback(
    async (e: KeyboardEvent<HTMLInputElement>) => {
      if (!e) return;

      const { value } = e.currentTarget;

      if (!value || highlightedIndex === undefined) return;

      const highlightedOption = Object.values(options)[highlightedIndex];
      if (!highlightedOption) return;

      onSelect?.(highlightedOption);
      closeMenu?.();
      e.currentTarget.blur();
      onEnter?.(e);
    },
    [highlightedIndex, options, onSelect, closeMenu, onEnter]
  );

  const handleEscape = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      setInputValue('');
      initOptions();
      e.currentTarget.blur();
      onEscape?.(e);
    },
    [initOptions, onEscape]
  );

  const handleInputBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      setInputValue(selectedOption || '');
      initOptions();
      closeMenu?.();
      onBlur?.(e);
    },
    [closeMenu, initOptions, onBlur, selectedOption]
  );

  return {
    getInputProps,
    getItemProps,
    getMenuProps,
    handleInputBlur,
    highlightedIndex,
    isOpen,
    options,
    handleEnter,
    handleEscape,
    optionsWrapperRef,
  };
};
