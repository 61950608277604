import { DesignTokensParser } from '@juxio/design-tokens';
import { useAtomValue } from 'jotai/index';
import { useStore } from '@jux/canjux/core';
import { currentTokenSetIdAtom } from '@jux/ui/state';

export const useCurrentTokenSetData = () => {
  const currentTokenSetId = useAtomValue(currentTokenSetIdAtom);

  return useStore((state) => {
    const tokens =
      Object.values(state.tokenSets).find(({ id }) => id === currentTokenSetId)
        ?.value || {};
    const existingTokenPaths = Object.keys(
      new DesignTokensParser(tokens).getValuesMap()
    );

    return {
      existingTokenPaths,
      tokens,
    };
  });
};
