/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';

export const MenuVerticalIcon = React.forwardRef<
  SVGSVGElement,
  React.HTMLAttributes<SVGSVGElement>
>(function MenuVerticalIcon({ className, ...otherProps }, ref) {
  return (
    <svg
      width={'16'}
      height={'16'}
      viewBox={'0 0 16 16'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      className={className}
      {...otherProps}
    >
      <path
        d="M2.75 10C3.7165 10 4.5 9.2165 4.5 8.25C4.5 7.2835 3.7165 6.5 2.75 6.5C1.7835 6.5 1 7.2835 1 8.25C1 9.2165 1.7835 10 2.75 10Z"
        fill="currentColor"
      />
      <path
        d="M8.25 10C9.2165 10 10 9.2165 10 8.25C10 7.2835 9.2165 6.5 8.25 6.5C7.2835 6.5 6.5 7.2835 6.5 8.25C6.5 9.2165 7.2835 10 8.25 10Z"
        fill="currentColor"
      />
      <path
        d="M13.75 10C14.7165 10 15.5 9.2165 15.5 8.25C15.5 7.2835 14.7165 6.5 13.75 6.5C12.7835 6.5 12 7.2835 12 8.25C12 9.2165 12.7835 10 13.75 10Z"
        fill="currentColor"
      />
    </svg>
  );
});
