export const calcDistanceThreshold = (
  zoomLevel: number,
  customMaxThreshold?: number
) => {
  const minZoom = 0.2; // 20%
  const maxZoom = 4.0; // 400%
  const minThreshold = 1; // pixels
  const maxThreshold = customMaxThreshold || 10; // pixels

  const threshold =
    maxThreshold -
    ((zoomLevel - minZoom) / (maxZoom - minZoom)) *
      (maxThreshold - minThreshold);

  return Math.ceil(threshold);
};
