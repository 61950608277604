import {
  DesignToken,
  DesignTokenTypeEnum,
  DesignTokenValue,
  SupportedTokenTypes,
} from '@juxio/design-tokens';
import {
  CardAliasType,
  ColorTokenValue,
  FontFamilyTokenValue,
  TypographyTokenValue,
} from './cardContentByType/base/CardContentByType.interface';

export type TokenCardContentProps = CardAliasType & {
  type: SupportedTokenTypes;
  value: DesignTokenValue;
  description: DesignToken['$description'];
};

export const isTokenCardContentColor = (
  type: SupportedTokenTypes,
  value: DesignTokenValue
): value is ColorTokenValue => type === DesignTokenTypeEnum.color;

export const isTokenCardContentDimension = (
  type: SupportedTokenTypes,
  value: DesignTokenValue
): value is ColorTokenValue => type === DesignTokenTypeEnum.dimension;

export const isTokenCardContentFontFamily = (
  type: SupportedTokenTypes,
  value: DesignTokenValue
): value is FontFamilyTokenValue => type === DesignTokenTypeEnum.fontFamily;

export const isTokenCardContentTypography = (
  type: SupportedTokenTypes,
  value: DesignTokenValue
): value is TypographyTokenValue => type === DesignTokenTypeEnum.typography;
