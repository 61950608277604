import { ElementDataInit } from '../../types/ElementDataInit';
import { getJuxDivElementData } from '../div';
import { getHelperTextElement } from './helperText';
import { getTextLabelElement } from './textLabel';

export const getHelperTextWithLabelElement: ElementDataInit<undefined> = ({
  parentId,
} = {}) => {
  const labelElement = getTextLabelElement({}).root;
  const helperTextElement = getHelperTextElement({}).root;

  const textWrapperDivElement = getJuxDivElementData({
    parentId,
    displayName: 'text_wrapper',
    styles: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '4px 0',
        width: 'auto',
        height: 'auto',
      },
      states: {},
    },
    children: [labelElement.id, helperTextElement.id],
  }).root;
  labelElement.parentId = textWrapperDivElement.id;
  helperTextElement.parentId = textWrapperDivElement.id;

  return {
    root: textWrapperDivElement,
    subComponents: [labelElement, helperTextElement],
  };
};
