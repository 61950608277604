import {
  ComponentSourceData,
  ComponentTagNames,
  NodeType,
  VariantsConfig,
} from '@jux/data-entities';
import { getCartesianVariantsWithoutDisabled } from './cartesianProduct';
import { InteractiveStateOptionsByTagName } from '@jux/ui/components/editor/components/panels/DDP/modules/props/sub-modules/interactiveState';
import { v4 as uuidv4 } from 'uuid';
import { ComponentConfigWithStates as StylesConfig } from '@jux/types';

const AUTO = 'auto';
const SPACE = ' ';

const repeatText = ({
  text,
  separator = SPACE,
  count = 1,
}: {
  text: string;
  separator?: string;
  count?: number;
}) => {
  if (count < 0) return '';
  if (count === 1) return text;

  return (text + separator).repeat(count).trim();
};

export const getMatrixGridColumnsAndRows = (
  sourceComponent: ComponentSourceData
) => {
  const elementStatesCount =
    InteractiveStateOptionsByTagName[sourceComponent.tagName]?.length ?? 0;

  const variants: VariantsConfig = sourceComponent.config?.variants ?? [];
  const hasDisabled = variants.find(
    (variant) => variant.variant === 'disabled'
  );
  const gridTemplateRows = repeatText({
    text: AUTO,
    count: elementStatesCount + (hasDisabled ? 1 : 0),
  });

  const numberOfVariants = getCartesianVariantsWithoutDisabled(variants).length;

  const gridTemplateColumns = repeatText({
    text: AUTO,
    count: numberOfVariants,
  });

  return { gridTemplateColumns, gridTemplateRows };
};

const getVariantsGroupWrapperStyles = ({
  gridTemplateRows,
  gridTemplateColumns,
}: {
  gridTemplateRows: string;
  gridTemplateColumns: string;
}): StylesConfig => {
  return {
    root: {
      background: '#F7F7F7',
      border: '1px solid #F7F7F7',
      borderRadius: '8px',
      height: 'auto',
      width: 'auto',
      padding: '50px',
      display: 'grid',
      gridTemplateRows,
      gridTemplateColumns,
      gap: '50px 50px',
    },
    states: {},
  };
};

// This is the actual node that will wrap the Variants in the matrix
export const getVariantsGroupWrapperData = (
  sourceComponent: ComponentSourceData
): ComponentSourceData => {
  const { gridTemplateColumns, gridTemplateRows } =
    getMatrixGridColumnsAndRows(sourceComponent);

  const groupDivWrapper: ComponentSourceData = {
    type: NodeType.VARIANTS_GROUP,
    id: uuidv4(),
    sourceComponentId: sourceComponent.id,
    tagName: ComponentTagNames.JuxDiv,
    scopes: [],
    config: { props: {} },
    displayName: sourceComponent.displayName ?? '',
    styles: getVariantsGroupWrapperStyles({
      gridTemplateRows,
      gridTemplateColumns,
    }),
    children: [],
  };

  return groupDivWrapper;
};
