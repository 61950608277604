import { useCallback, useMemo } from 'react';
import {
  isMac,
  selectedNodeComponent,
  useStore,
  useStoreActions,
} from '@jux/canjux/core';
import { useTrackEvents } from '@jux/ui/hooks';
import { NodeType } from '@jux/data-entities';

export const useVariantsGroup = () => {
  const selectedComponent = useStore(selectedNodeComponent);

  const { trackOpenMatrix, trackCloseMatrix } = useTrackEvents();

  const {
    componentActions: {
      convertNodeToVariantsGroup,
      convertVariantsGroupToSourceComponent,
    },
  } = useStoreActions();

  const selectedNodeVariantGroupActionName = useMemo(() => {
    const hotKey = isMac() ? '(⌘M)' : '';
    if (
      selectedComponent?.type === NodeType.LOCAL_COMPONENT ||
      selectedComponent?.type === NodeType.LIBRARY_COMPONENT
    ) {
      return `Open Matrix ${hotKey}`;
    }

    if (selectedComponent?.type === NodeType.VARIANTS_GROUP) {
      return `Close Matrix ${hotKey}`;
    }

    return '';
  }, [selectedComponent?.type]);

  const selectedNodeVariantGroupAction = useCallback(() => {
    if (
      selectedComponent?.type === NodeType.LOCAL_COMPONENT ||
      selectedComponent?.type === NodeType.LIBRARY_COMPONENT
    ) {
      convertNodeToVariantsGroup({
        nodeId: selectedComponent.id,
      });
      trackOpenMatrix();
    } else if (selectedComponent?.type === NodeType.VARIANTS_GROUP) {
      convertVariantsGroupToSourceComponent({
        nodeId: selectedComponent.id,
      });
      trackCloseMatrix();
    }
  }, [
    convertNodeToVariantsGroup,
    convertVariantsGroupToSourceComponent,
    selectedComponent?.id,
    selectedComponent?.type,
    trackCloseMatrix,
    trackOpenMatrix,
  ]);

  return {
    selectedNodeVariantGroupActionName,
    selectedNodeVariantGroupAction,
  };
};
