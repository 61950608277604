import { JuxStore } from '@jux/canjux/core';
import type { Draft as WritableDraft } from 'mutative';
import { NodeType } from '@jux/data-entities';
import { ComponentConfigWithStates } from '@jux/types';

export const updateComponentsWithNewStyles = ({
  components,
  stylesToChangeByComponentId,
}: {
  components: WritableDraft<JuxStore['components']>;
  stylesToChangeByComponentId: Array<
    | {
        [componentId: string]: ComponentConfigWithStates;
      }
    | undefined
  >;
}) => {
  stylesToChangeByComponentId.forEach((data) => {
    if (!data) return;

    const [componentId, newStyles] = Object.entries(data)[0];

    const { type } = components[componentId];
    if (type === NodeType.INSTANCE || type === NodeType.VARIANT_INSTANCE) {
      return;
    }

    components[componentId].styles = newStyles;
  });
};
