import { atom, useAtom } from 'jotai';
import { atomWithReset, RESET, useResetAtom } from 'jotai/utils';
import React, { useCallback } from 'react';
import { DesignTokenData } from '@juxio/design-tokens';
import { TransitionProps } from '@jux/ui/components/common/mui';

type MaybeContent = React.ReactNode | null;

export type TokenDrawerTransition = TransitionProps['timeout'] | null;
export enum DrawerActions {
  open = 'open',
  close = 'close',
}
export enum DrawerStates {
  opened = 'opened',
  closed = 'closed',
}
export type DrawerState = keyof typeof DrawerStates;

export type OnTokenDrawerChange = ({
  newValue,
  subFieldName,
}: {
  newValue: string;
  subFieldName?: string;
}) => void | Promise<void>;

export type DrawerEventHandlers = Record<
  'onCancel' | 'onClose',
  () => void | Promise<void>
> & {
  // TODO: token details as argument should not be optional
  onSave: (token?: DesignTokenData) => void | Promise<void>;
  onChange?: OnTokenDrawerChange;
};

export type DrawerEvent = keyof DrawerEventHandlers;

export const tokenDrawerStateAtom = atomWithReset<DrawerState>('closed');

export const tokenDrawerContentAtom = atomWithReset<MaybeContent>(null);

export const tokenDrawerTransitionAtom =
  atomWithReset<TokenDrawerTransition>(null);

export const tokenDrawerHandlersAtom = atomWithReset<DrawerEventHandlers>({
  onCancel: () => {},
  onClose: () => {},
  onSave: () => {},
});

type UpdateTokenDrawerArgs = {
  action: keyof typeof DrawerActions;
  content?: MaybeContent;
  handlers?: DrawerEventHandlers;
  transitionDuration?: TokenDrawerTransition;
};

export const tokenDrawerAtom = atom(
  (get) => ({
    state: get(tokenDrawerStateAtom),
    content: get(tokenDrawerContentAtom),
    handlers: get(tokenDrawerHandlersAtom),
    transitionDuration: get(tokenDrawerTransitionAtom),
  }),
  (_get, set, update: UpdateTokenDrawerArgs | typeof RESET) => {
    // handle reset
    if (update === RESET) {
      set(tokenDrawerStateAtom, RESET);
      set(tokenDrawerHandlersAtom, RESET);
      set(tokenDrawerTransitionAtom, RESET);
      /*
       * due to the built-in transitions, and to avoid flickers/bugs,
       * we could wait a bit before resetting the content,
       * or use some observable + intermediate "closing/opening" states,
       * but instead - we simply update it only when opening the drawer.
       * // set(drawerContentAtom, RESET);
       */
      return;
    }

    const { action, content, handlers, transitionDuration } = update;

    // handle open action
    if (action === DrawerActions.open) {
      // set/update content when opening
      if (content) {
        set(tokenDrawerContentAtom, content);
      }
      // set/update handlers when opening
      if (handlers) {
        set(tokenDrawerHandlersAtom, handlers);
      }
      // open drawer
      set(tokenDrawerStateAtom, DrawerStates.opened);
    }
    // handle close action
    else {
      set(tokenDrawerStateAtom, DrawerStates.closed);
    }

    if (transitionDuration) {
      set(tokenDrawerTransitionAtom, transitionDuration);
    }
  }
);

export const useTokenDrawer = () => {
  const [{ state, content, handlers, transitionDuration }, setState] =
    useAtom(tokenDrawerAtom);

  const reset = useResetAtom(tokenDrawerAtom);

  const close = useCallback(() => {
    handlers.onClose?.();
    setState({
      action: DrawerActions.close,
    });
  }, [handlers, setState]);

  const transitionClose = useCallback(
    (newTransitionDuration: TokenDrawerTransition) => {
      handlers.onClose?.();
      setState({
        action: DrawerActions.close,
        transitionDuration: newTransitionDuration,
      });

      const timeout =
        typeof newTransitionDuration === 'number'
          ? newTransitionDuration
          : newTransitionDuration?.exit ?? 0;

      setTimeout(() => {
        reset();
      }, timeout);
    },
    [handlers, reset, setState]
  );

  const open = useCallback(
    ({
      content: newContent,
      handlers: newHandlers,
    }: Omit<UpdateTokenDrawerArgs, 'action'>) => {
      setState({
        action: DrawerActions.open,
        content: newContent,
        handlers: newHandlers,
      });
    },
    [setState]
  );

  return {
    content,
    state,
    handlers,
    open,
    close,
    transitionClose,
    transitionDuration,
    reset,
    isOpen: state === DrawerStates.opened,
    isClosed: state === DrawerStates.closed,
  };
};
