import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { currentOrganizationAtom } from '@jux/ui/hooks';
import { api } from '@jux/ui/trpc/client/api';

// TODO: Rename this to `useLiveblocksRoomId` or similar
export const useLiveblocksRoom = () => {
  const currentOrganization = useAtomValue(currentOrganizationAtom);

  const { data, isLoading } = api.canvas.getOrganizationDefaultRoom.useQuery(
    {
      orgId: currentOrganization.id,
    },
    {
      enabled: Boolean(currentOrganization.id),
    }
  );

  const room = useMemo(
    () => ({
      roomId: data?.data.liveblocksRoomId,
    }),
    [data?.data.liveblocksRoomId]
  );

  return {
    room,
    isLoading,
  };
};
