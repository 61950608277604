import { FC, forwardRef, useState } from 'react';
import * as I from '../../imported/components/feedbackModal';

const CLOSE_ICON = `<svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M12.198 3.80199C11.9098 3.51372 11.4424 3.51372 11.1541 3.80199L8.00003 6.95607L4.84594 3.80199C4.55767 3.51372 4.09029 3.51372 3.80202 3.80199C3.51375 4.09026 3.51375 4.55764 3.80202 4.84591L6.95611 8L3.80202 11.1541C3.51375 11.4424 3.51375 11.9097 3.80202 12.198C4.09029 12.4863 4.55767 12.4863 4.84594 12.198L8.00003 9.04392L11.1541 12.198C11.4424 12.4863 11.9098 12.4863 12.198 12.198C12.4863 11.9097 12.4863 11.4424 12.198 11.1541L9.04395 8L12.198 4.84591C12.4863 4.55764 12.4863 4.09026 12.198 3.80199Z" fill="currentColor"/>
</svg>
`;

type FeedbackModalProps = {
  onConfirm: (data: string) => void;
  onCancel?: () => void;
};

export const FeedbackModal: FC<FeedbackModalProps> = forwardRef<
  HTMLDivElement,
  FeedbackModalProps
>(({ onConfirm, onCancel }, ref) => {
  const [isCancelIconFocused, setIsCancelIconFocused] = useState(false);
  const [feedback, setFeedback] = useState('');
  const onSubmitFeedback = () => {
    onConfirm(feedback);
  };

  return (
    <I.FeedbackModal
      FeedbackModal_header_Props={{
        IconButton_Props: {
          onClick: onCancel,
          disabled: false,
          hierarchy: 'primary',
          style: {
            // focus state
            outline: 'none',
            ...(isCancelIconFocused && {
              borderColor: '{color.core.grays_800}',
            }),
          },
          onFocus: () => setIsCancelIconFocused(true),
          onBlur: () => setIsCancelIconFocused(false),
          IconButton_Icon_Props: {
            content: CLOSE_ICON,
            color: '{color.core.grays_800}',
          },
        },
      }}
      FeedbackModal_body_Props={{
        FeedbackModal_body_text_Props: {
          text: 'Your voice is our progress.',
        },
        Input_Props: {
          disabled: false,
          placeholder: 'How about...',
          style: {
            width: '100%',
          },
          onChange: (e) => setFeedback(e.target.value),
          value: feedback,
        },
      }}
      FeedbackModal_footer_Props={{
        FeedbackModal_footer_div_1_Props: {
          JuxButton_Props: {
            JuxButton_text_Props: {
              text: 'Next time',
            },
            onClick: onCancel,
          },
        },
        FeedbackModal_footer_div_2_Props: {
          Import_button_Props: {
            disabled: false,
            hierarchy: 'primary',
            Size: 'big',
            Import_button_button_label_Props: {
              text: `Share Feedback`,
            },
            onClick: onSubmitFeedback,
          },
        },
      }}
      ref={ref}
    />
  );
});
