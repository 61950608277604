import { CanjuxState } from '@jux/canjux/core';
import { createSelector } from 'reselect';
import { getResolvedSourceComponentData } from './utils/getResolvedSourceComponentData';

/*
   Get the resolved ComponentSourceData from a component by id.
   If this is an instance, return the resolved data of the instance combined with the SourceComponents
 */
export const selectResolvedComponentById = ({
  id,
  shouldStopOnVariantMatrix,
}: {
  id: string;
  shouldStopOnVariantMatrix?: boolean;
}) =>
  createSelector([(state: CanjuxState) => state.components], (components) =>
    getResolvedSourceComponentData({
      component: components[id],
      components,
      shouldStopOnVariantMatrix,
    })
  );
