import {
  ComponentTagName,
  ComponentTagNames,
  NodeProperties,
  NodeType,
} from '@jux/data-entities';

export const isComponentAContainer = ({
  tagName,
  type,
}: {
  tagName?: ComponentTagName;
  type?: NodeType;
}): boolean => {
  return (
    (type === NodeType.ELEMENT ||
      type === NodeType.LOCAL_COMPONENT ||
      type === NodeType.LIBRARY_COMPONENT ||
      type === NodeType.DYNAMIC_SLOT ||
      type === NodeType.LOGICAL_SLOT) &&
    tagName !== ComponentTagNames.JuxText &&
    tagName !== ComponentTagNames.JuxInput &&
    tagName !== ComponentTagNames.JuxSvg
  );
};

export const isDeleteable = ({
  nodeType,
  parentNodeType,
  parentNodeDirectSourceType,
}: {
  nodeType: NodeType;
  parentNodeType?: NodeType;
  parentNodeDirectSourceType?: NodeType;
}) => {
  const isStaticChildOfInstance =
    parentNodeType === NodeType.INSTANCE &&
    parentNodeDirectSourceType !== NodeType.DYNAMIC_SLOT;

  const isVariantInstanceRoot = parentNodeType === NodeType.VARIANTS_GROUP;

  return (
    nodeType !== NodeType.LOGICAL_SLOT &&
    !isStaticChildOfInstance &&
    !isVariantInstanceRoot
  );
};

export const isDraggable = ({
  parentNodeType,
  parentNodeDirectSourceType,
}: {
  parentNodeType?: NodeType;
  parentNodeDirectSourceType?: NodeType;
}) => {
  const isStaticChildOfInstance =
    parentNodeType === NodeType.INSTANCE &&
    parentNodeDirectSourceType !== NodeType.DYNAMIC_SLOT;

  const isVariantInstanceRoot = parentNodeType === NodeType.VARIANTS_GROUP;

  return !isStaticChildOfInstance && !isVariantInstanceRoot;
};

export const getDefaultNodePropertiesByTagName = ({
  tagName,
  type,
}: {
  tagName?: ComponentTagName;
  type?: NodeType;
}): NodeProperties => {
  return {
    isContainer: isComponentAContainer({ tagName, type }),
    isDeletable: type !== NodeType.LOGICAL_SLOT,
    isDraggable: true,
    isDragged: false,
    isHidden: false,
    isImmutable: false,
    isSelectable: true,
  };
};
