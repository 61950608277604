import {
  JuxStore,
  JuxStoreActionFn,
  PropsActionsParams,
} from '../../store.interface';
import type { Draft as WritableDraft } from 'mutative';
import { ComponentConfigWithStates } from '@jux/types';
import {
  duplicateContextStylesForNewPropValue,
  duplicateStylesForNewPropValue,
} from '../utils/createComponentPropValueUtils';
import { NodeType } from '@jux/data-entities';
import { updateMatrixNodesOnAddPropValue } from '../../../matrix-utils/updateMatrixNodesOnAddPropValue';

/**
 * Add a new prop value option to a component property
 */
export const addComponentPropValue: JuxStoreActionFn<
  PropsActionsParams['addComponentPropValue'],
  JuxStore
> = ({ componentId, propName, propValue, state }) => {
  const component = state.components[componentId];
  if (!component) return state;

  switch (component.type) {
    case NodeType.LOCAL_COMPONENT:
    case NodeType.LIBRARY_COMPONENT:
      const variantConfig = component.config.variants?.find(
        (variant) => variant.variant === propName
      );
      if (!variantConfig) {
        return state;
      }

      variantConfig.options.push({
        value: propValue,
        label: propValue.toString(),
      });

      // Edit existing styles and duplicate default styles for new value options
      const styles =
        component.styles as WritableDraft<ComponentConfigWithStates>;
      if (styles.variants) {
        duplicateStylesForNewPropValue(
          styles.variants,
          variantConfig,
          propName,
          propValue
        );
      }

      // Go over state styles and do the same
      for (const stateStyles of Object.values(styles.states)) {
        if (!stateStyles.variants) {
          continue;
        }
        duplicateStylesForNewPropValue(
          stateStyles.variants,
          variantConfig,
          propName,
          propValue
        );
      }

      if (styles.contextStyles) {
        duplicateContextStylesForNewPropValue(
          styles.contextStyles,
          variantConfig,
          propName,
          propValue
        );
      }

      updateMatrixNodesOnAddPropValue({
        sourceComponentId: component.id,
        state,
      });

      component.updatedAt = new Date().getTime();

      break;
    default:
      throw new Error(
        `Cannot add prop value for component type ${component.type}`
      );
  }

  return state;
};
