/* eslint-disable @typescript-eslint/no-empty-interface */
import * as React from 'react';
import { styled, type Merge } from '@juxio/react-styled';
import { useCallback, useRef } from 'react';
import {
  useAssetsPopper,
  useComponentsPopper,
  useElementsPopper,
} from '@jux/ui/components/editor/components/navbar/hooks';
import { useTrackEvents } from '@jux/ui/hooks';
import { TopNavigationItem } from '../../../../../../ui-core/src/lib/components/jux/TopNavigationItem';

export interface NavLeftActionsVariants {}

export type NavLeftActionsProps = Merge<
  React.HTMLAttributes<HTMLDivElement>,
  NavLeftActionsVariants
>;

const StyledNavLeftActions = styled<'div', NavLeftActionsProps>('div', {
  root: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    padding: '0px 0px 0px 0px',
    alignItems: 'center',
    borderStyle: 'none',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
  },
  variants: [],
});

export const NavLeftActions = React.forwardRef<
  HTMLDivElement,
  NavLeftActionsProps
>(function NavLeftActions({ ...otherProps }, ref) {
  const elementsRef = useRef<HTMLDivElement | null>(null);
  const { openElementsPopper, isElementPopperOpen } = useElementsPopper();

  const componentsRef = useRef<HTMLDivElement | null>(null);
  const { openComponentsPopper, isComponentsPopperOpen } =
    useComponentsPopper();

  const assetsRef = useRef<HTMLDivElement | null>(null);
  const { openAssetsPopper, isAssetsPopperOpen } = useAssetsPopper();

  const { trackNavigationClick } = useTrackEvents();

  const handleElementsClick = useCallback(() => {
    openElementsPopper(elementsRef);
    trackNavigationClick({ name: 'elements' });
  }, [openElementsPopper, trackNavigationClick]);

  const handleComponentsClick = useCallback(() => {
    openComponentsPopper(componentsRef);
    trackNavigationClick({ name: 'components' });
  }, [openComponentsPopper, trackNavigationClick]);

  const handleAssetsClick = useCallback(() => {
    openAssetsPopper(assetsRef);
    trackNavigationClick({ name: 'assets' });
  }, [openAssetsPopper, trackNavigationClick]);

  return (
    <StyledNavLeftActions ref={ref} {...otherProps}>
      <TopNavigationItem
        text={'Elements'}
        ref={elementsRef}
        onClick={handleElementsClick}
        selected={isElementPopperOpen}
      />
      <TopNavigationItem
        text={'Components'}
        ref={componentsRef}
        onClick={handleComponentsClick}
        selected={isComponentsPopperOpen}
      />
      <TopNavigationItem
        text={'Assets'}
        ref={assetsRef}
        onClick={handleAssetsClick}
        selected={isAssetsPopperOpen}
      />
    </StyledNavLeftActions>
  );
});
