import React from 'react';
import { default as emotionStyled } from '@emotion/styled';
import { isAlias } from '@juxio/design-tokens';
import { ComponentConfig, DeepPartial, ThemeConfig } from '@jux/types';
import { transformAndResolveTokens } from '../utils/transformAndResolveTokens';
import { CreateStyled, StyledOptions } from './styled.interface';
import { useTheme } from '../hooks';

export const resolveStyles = (
  styles: Record<string, any>,
  theme: DeepPartial<ThemeConfig>
) => {
  const resolvedStyles: Record<string, any> = {};

  Object.entries(styles).forEach(([key, value]) => {
    if (isAlias(value)) {
      resolvedStyles[key] = transformAndResolveTokens(
        value,
        theme as ThemeConfig
      );
    } else if (typeof value === 'function') {
      resolvedStyles[key] = value(theme);
    } else if (typeof value === 'object') {
      resolvedStyles[key] = resolveStyles(value, theme);
    } else {
      resolvedStyles[key] = value;
    }
  });

  return resolvedStyles;
};

// This is the first function that determines the styles that will be applied to the component
const overrideRootStyles = (
  theme: DeepPartial<ThemeConfig>,
  componentConfig?: ComponentConfig
) => {
  if (!componentConfig?.root) return;

  return resolveStyles(componentConfig.root, theme);
};

const variantsResolver = (
  props: Record<string, any>,
  styles: ComponentConfig,
  theme: Partial<ThemeConfig>
) => {
  const variantsStyles: Array<Record<string, any>> = [];

  if (styles.variants) {
    for (const variant of styles.variants) {
      if (
        // Check if the variant has no props
        !variant.propsValues ||
        !Object.keys(variant.propsValues).length ||
        // Or check for existence of all props
        Object.entries(variant.propsValues).every(
          ([key, value]) => key in props && props[key] === value
        )
      ) {
        // If all conditions of the variant are met
        variantsStyles.push(resolveStyles(variant.styles, theme));
      }
    }
  }

  return variantsStyles;
};

export function createStyled<Theme extends ThemeConfig>(): CreateStyled<Theme> {
  return (component: any, options: StyledOptions): React.FC => {
    if (!component) {
      throw new Error(
        'You are trying to create a Jux component with undefined component'
      );
    }

    const drimzComponent = emotionStyled(component, {
      shouldForwardProp: options.shouldForwardProp,
    })([
      (props) => overrideRootStyles(props.theme, options.styles),
      (props) =>
        variantsResolver(props, options.styles || { root: {} }, props.theme),
    ]);

    if (process.env['NODE_ENV'] !== 'production') {
      drimzComponent.displayName = `Drimz(${options.name || component})`;
    }

    return React.forwardRef((props, ref) => {
      const theme = useTheme();
      return React.createElement(drimzComponent, {
        ...props,
        theme,
        ref,
      });
    });
  };
}
