import {
  CanjuxState,
  CommonActionsParams,
  ComponentPlacement,
  createComponentInstanceCanvasNode,
  findFirstTargetUpTree,
  getDefaultNodeData,
  getOverlappingArea,
  getRootNodeValidPlacement,
  isTargetNodeRootComponentWithInstance,
  isTargetNodeRootLibraryComponentWithLocalDependencies,
  JuxRect,
  JuxStoreActionFn,
  setLayersData,
} from '@jux/canjux/core';
import {
  CanvasData,
  ComponentConfigData,
  ComponentTagNames,
  JuxComponentData,
  NodeType,
} from '@jux/data-entities';
import intersection from 'lodash/intersection';
import { create, Draft as WritableDraft } from 'mutative';
import { setSelectedNodes } from './setSelectedNodes';
import { duplicateNode } from './utils/duplicateNode';
import { CopiedNodeData } from '@jux/ui/components/editor/hooks/useClipboardStore';
import { v4 as uuidv4 } from 'uuid';
import { toast } from '@jux/ui/toast';
import { moveNodes } from './moveNodes';

const NOT_ALLOWED_TO_PASTE_INTO_INSTANCES =
  'It isn’t possible to paste objects into instances';
const NOT_ALLOWED_LOCAL_CHILDREN_IN_LIBRARY_COMPONENTS =
  'Library components can’t have local children';
const NOT_ALLOWED_TO_PASTE_INTO_SAME_SOURCE_COMPONENT =
  'Instances can’t be pasted into their own source components';

type CopyPasteErrors =
  | typeof NOT_ALLOWED_TO_PASTE_INTO_INSTANCES
  | typeof NOT_ALLOWED_LOCAL_CHILDREN_IN_LIBRARY_COMPONENTS
  | typeof NOT_ALLOWED_TO_PASTE_INTO_SAME_SOURCE_COMPONENT;

const pasteNodeFromCopiedData = ({
  sourceComponent,
  sourceComponentChildren,
  targetNodeId,
  targetIndex,
  position,
  state,
}: {
  sourceComponent: JuxComponentData;
  sourceComponentChildren?: JuxComponentData[];
  propsOverrides?: ComponentConfigData['props'];
  targetNodeId?: string;
  targetIndex?: number;
  position?: { x: number; y: number };
  state: WritableDraft<CanjuxState>;
}) => {
  const currentCanvas = state.canvases[state.currentCanvasName];

  switch (sourceComponent.type) {
    case NodeType.INSTANCE:
      if (!sourceComponent.sourceComponentId) return undefined;

      return createComponentInstanceCanvasNode({
        canvasName: state.currentCanvasName,
        componentId: sourceComponent.sourceComponentId,
        propsOverrides: sourceComponent.config.props,
        parentId: targetNodeId,
        targetIndex,
        state,
      });
    case NodeType.ELEMENT:
    case NodeType.DYNAMIC_SLOT:
      const newNodeId = uuidv4();
      // Create new component data from the source component
      state.components[newNodeId] = create(sourceComponent, (draft) => {
        draft.id = newNodeId;
        draft.parentId = undefined;
        draft.children = []; // reset children and copy them later
        // Reset context styles (will be cloned later)
        if (draft.styles) {
          draft.styles.contextStyles = [];
        }
      });

      // Create new node data on the target canvas
      currentCanvas.nodes[newNodeId] = getDefaultNodeData({
        isContainer:
          sourceComponent.tagName === ComponentTagNames.JuxDiv ||
          sourceComponent.tagName === ComponentTagNames.JuxSlot, // TODO: check if it's a container
        parentId: targetNodeId,
        position,
      });

      // Copy children of the source node recursively
      sourceComponent.children.forEach((childComponentId) => {
        const childComponent = sourceComponentChildren?.find(
          (c) => c.id === childComponentId
        );
        if (childComponent) {
          pasteNodeFromCopiedData({
            sourceComponent: childComponent,
            sourceComponentChildren,
            targetNodeId: newNodeId,
            state,
          });
        }
      });

      if (targetNodeId) {
        moveNodes({
          sourceNodeIds: [newNodeId],
          targetNodeId,
          targetIndex,
          state,
        });
      } else {
        currentCanvas.rootNodesOrder.unshift(newNodeId);
      }

      return newNodeId;
    default:
      throw new Error('Unsupported node type');
  }
};

const pasteNode = ({
  copiedSourceComponent,
  sourceComponentChildren,
  propsOverrides,
  targetPlacement,
  state,
}: {
  copiedSourceComponent: JuxComponentData;
  sourceComponentChildren?: JuxComponentData[];
  propsOverrides?: Record<string, any>;
  targetPlacement: ComponentPlacement;
  state: WritableDraft<CanjuxState>;
}) => {
  const currentCanvas = state.canvases[state.currentCanvasName];

  const { targetNodeId, targetChildIndex, position } = targetPlacement;

  switch (copiedSourceComponent.type) {
    case NodeType.LOCAL_COMPONENT:
    case NodeType.LIBRARY_COMPONENT:
      if (copiedSourceComponent.id in state.components) {
        // In case of a source component, we want to create an instance of it
        return createComponentInstanceCanvasNode({
          canvasName: currentCanvas.name,
          componentId: copiedSourceComponent.id,
          propsOverrides: propsOverrides || {},
          parentId: targetNodeId,
          targetIndex: targetChildIndex,
          position,
          state,
        });
      }
      break;
    case NodeType.ELEMENT:
    case NodeType.DYNAMIC_SLOT:
      return pasteNodeFromCopiedData({
        sourceComponent: copiedSourceComponent,
        sourceComponentChildren: sourceComponentChildren,
        targetNodeId,
        targetIndex: targetChildIndex,
        position,
        state,
      });

    default:
      throw new Error('Unsupported node type');
  }

  return undefined;
};

const isCopiedRectInViewport = ({
  copiedItemsRect,
  state,
}: {
  copiedItemsRect: JuxRect;
  state: WritableDraft<CanjuxState>;
}): boolean => {
  const { width, height, transform } = state;

  const canvasRect = {
    x: -transform.x / transform.zoom,
    y: -transform.y / transform.zoom,
    width: width / transform.zoom,
    height: height / transform.zoom,
  };

  return getOverlappingArea(copiedItemsRect, canvasRect) > 0;
};

/**
 * Paste copied nodes as root nodes
 * @param copiedItems
 * @param state
 */
const pasteCopiedNodesAsRootNodes = ({
  copiedItems,
  copiedItemsRect,
  currentCanvas,
  state,
}: {
  copiedItems: CopiedNodeData[];
  copiedItemsRect: JuxRect;
  currentCanvas: WritableDraft<CanvasData>;
  state: WritableDraft<CanjuxState>;
}) => {
  const pastedNodeIds: string[] = [];

  const isCopiedNodesRectInViewport = isCopiedRectInViewport({
    copiedItemsRect,
    state,
  });

  for (const copiedItem of copiedItems) {
    const isCopiedNodeOnCanvas = copiedItem.id in currentCanvas.nodes;
    const wasDeleted =
      state.currentCanvasName === copiedItem.canvasName &&
      !isCopiedNodeOnCanvas;

    let targetPlacement: ComponentPlacement;
    if (
      // if node was deleted, we have it's previous location as a root node the rect of
      // the copied nodes is in the current viewport, paste to it's previous location
      wasDeleted &&
      copiedItem.position &&
      isCopiedNodesRectInViewport
    ) {
      // if the copied node was deleted, paste it in it's original location
      targetPlacement = {
        position: copiedItem.position,
        targetNodeId: undefined,
        targetChildIndex: 0,
      };
    } else {
      // setting the originalTargetNodeId will paste it 80 pixels to the right of the original node
      targetPlacement = getRootNodeValidPlacement({
        // if original copied nodes rect is not in viewport, select root node placement without regard to the original placement (will paste in the center of the screen)
        originalTargetNodeId: isCopiedNodesRectInViewport
          ? copiedItem.id
          : undefined,
        components: state.components,
        canvas: currentCanvas,
        canvasDimensions: { width: state.width, height: state.height },
        transform: state.transform,
        canvasNodesDimensions: state.canvasNodesDimensions,
      });
    }

    const newNode = pasteNode({
      copiedSourceComponent: copiedItem.sourceComponent,
      sourceComponentChildren: copiedItem.sourceComponentChildren,
      propsOverrides: copiedItem.propsOverrides,
      targetPlacement,
      state,
    });
    if (newNode) {
      pastedNodeIds.push(newNode);
    }
  }

  return pastedNodeIds;
};

const copyPasteRootNode = ({
  nodeToDuplicate,
  currentCanvas,
  state,
}: {
  nodeToDuplicate: CopiedNodeData;
  currentCanvas: WritableDraft<CanvasData>;
  state: WritableDraft<CanjuxState>;
}) => {
  const { width, height, transform } = state;
  const rootNodePlacement = getRootNodeValidPlacement({
    originalTargetNodeId: nodeToDuplicate.id,
    components: state.components,
    canvasNodesDimensions: state.canvasNodesDimensions,
    canvas: currentCanvas,
    transform: transform,
    canvasDimensions: { width, height },
  });

  return pasteNode({
    copiedSourceComponent: nodeToDuplicate.sourceComponent,
    sourceComponentChildren: nodeToDuplicate.sourceComponentChildren,
    propsOverrides: nodeToDuplicate.propsOverrides,
    targetPlacement: rootNodePlacement,
    state,
  });
};

/**
 * Copy-Paste selected nodes (when no object selection was changed) - acts as duplicate selected nodes (copy and paste)
 * Unlike actual duplicate action, this will create an instance when pasting a source component
 * @param copiedItems
 * @param state
 */
const copyPasteSelectedNodes = ({
  copiedItems,
  currentCanvas,
  errors,
  state,
}: {
  copiedItems: CopiedNodeData[];
  currentCanvas: WritableDraft<CanvasData>;
  errors: Map<CopyPasteErrors, boolean>;
  state: WritableDraft<CanjuxState>;
}) => {
  const pastedNodeIds: string[] = [];
  // We do not treat the selected nodes as target nodes, we duplicate each node
  // into it's current parent (if it's a container) or canvas (if it's a root node)

  const targets: Record<string, { nodeIds: string[]; startingIndex: number }> =
    {};

  for (const copiedNodeData of copiedItems) {
    let nodeToDuplicate = state.components[copiedNodeData.id];
    if (!nodeToDuplicate) {
      continue;
    }

    let targetComponent = nodeToDuplicate.parentId
      ? state.components[nodeToDuplicate.parentId]
      : undefined;
    if (!targetComponent) {
      const newNode = copyPasteRootNode({
        nodeToDuplicate: copiedNodeData,
        currentCanvas,
        state,
      });
      if (newNode) {
        pastedNodeIds.push(newNode);
      }

      continue;
    }

    const isVariantInstance =
      nodeToDuplicate.type === NodeType.VARIANT_INSTANCE;

    if (isVariantInstance) {
      const directSourceComponent = nodeToDuplicate.sourceComponentId
        ? state.components[nodeToDuplicate.sourceComponentId]
        : undefined;
      if (!directSourceComponent)
        throw new Error('Variant instance has no source component id');

      if (!directSourceComponent.parentId) {
        // If the source component is a root node, we cannot duplicate it in the Variants group
        toast.error(
          'You cannot paste a root variant in matrix, use edit properties panel to add new variants',
          {
            autoClose: 2000,
          }
        );
        continue;
      }

      // If the source component is a variant instance, we want to duplicate it in the parent of the source component
      nodeToDuplicate = directSourceComponent;
      targetComponent = state.components[directSourceComponent?.parentId];
    }

    // Search for valid location from parent and up
    const validParentPlacement = findFirstTargetUpTree({
      componentId: targetComponent.id,
      initiatedFromChildIndex: targetComponent.children.indexOf(
        nodeToDuplicate.id
      ),
      components: state.components,
      canvas: currentCanvas,
    });
    if (
      !validParentPlacement ||
      !validParentPlacement.targetNodeId ||
      !validParentPlacement.targetChildIndex
    ) {
      errors.set(NOT_ALLOWED_TO_PASTE_INTO_INSTANCES, true);
      continue;
    }

    if (!targets[validParentPlacement.targetNodeId]) {
      targets[validParentPlacement.targetNodeId] = {
        nodeIds: [],
        startingIndex: 0,
      };
    }

    targets[validParentPlacement.targetNodeId].nodeIds.push(nodeToDuplicate.id);

    // if the new target index is bigger than the current starting index, update it because
    // we want to start adding nodes only after the last node
    if (
      validParentPlacement.targetChildIndex >
      targets[validParentPlacement.targetNodeId].startingIndex
    ) {
      targets[validParentPlacement.targetNodeId].startingIndex =
        validParentPlacement.targetChildIndex;
    }
  }

  // Go over the targets and paste the nodes
  for (const [targetId, { nodeIds, startingIndex }] of Object.entries(
    targets
  )) {
    let index = 0;
    for (const nodeId of nodeIds) {
      pastedNodeIds.push(
        duplicateNode({
          sourceComponentId: nodeId,
          targetCanvasName: currentCanvas.name,
          targetIndex: startingIndex + index,
          targetNodeId: targetId,
          state,
        })
      );
      index++;
    }
  }

  return pastedNodeIds;
};

const pasteCopiedNodesToTargets = ({
  selectedNodesStack,
  copiedItems,
  currentCanvas,
  errors,
  state,
}: {
  selectedNodesStack: string[];
  copiedItems: CopiedNodeData[];
  currentCanvas: WritableDraft<CanvasData>;
  errors: Map<CopyPasteErrors, boolean>;
  state: WritableDraft<CanjuxState>;
}) => {
  const pastedNodeIds: string[] = [];
  const targets: Record<string, number> = {};

  // Go over selected nodes and determine valid targets
  for (const targetNodeId of selectedNodesStack) {
    const validParentPlacement = findFirstTargetUpTree({
      componentId: targetNodeId,
      components: state.components,
      canvas: currentCanvas,
    });

    if (
      !validParentPlacement.targetNodeId ||
      validParentPlacement.targetChildIndex === undefined // it's a number so do not test with "! operator" to support 0
    ) {
      // in case we have selected nodes, do not allow pasting as a root node
      errors.set(NOT_ALLOWED_TO_PASTE_INTO_INSTANCES, true);
      continue;
    }

    // Do not paste to the same target twice, if target was already found, select the maximum child index
    if (
      !targets[validParentPlacement.targetNodeId] ||
      targets[validParentPlacement.targetNodeId] <
        validParentPlacement.targetChildIndex
    ) {
      targets[validParentPlacement.targetNodeId] =
        validParentPlacement.targetChildIndex;
    }
  }

  for (const [targetNodeId, targetIndex] of Object.entries(targets)) {
    let pasteItemIndex = 0;
    for (const copiedItem of copiedItems) {
      if (copiedItem.sourceComponent.id === targetNodeId) {
        // Do not copy an object into itself (no need to print error, just skip it)
        continue;
      }

      if (
        isTargetNodeRootComponentWithInstance({
          sourceComponentId: copiedItem.sourceComponent.id,
          targetNodeId,
          components: state.components,
        })
      ) {
        errors.set(NOT_ALLOWED_TO_PASTE_INTO_SAME_SOURCE_COMPONENT, true);
        continue;
      }
      if (
        isTargetNodeRootLibraryComponentWithLocalDependencies({
          sourceComponentId: copiedItem.sourceComponent.id,
          targetNodeId,
          components: state.components,
        })
      ) {
        errors.set(NOT_ALLOWED_LOCAL_CHILDREN_IN_LIBRARY_COMPONENTS, true);
        continue;
      }

      const newNodeId = pasteNode({
        copiedSourceComponent: copiedItem.sourceComponent,
        sourceComponentChildren: copiedItem.sourceComponentChildren,
        propsOverrides: copiedItem.propsOverrides,
        targetPlacement: {
          targetNodeId,
          targetChildIndex: targetIndex + pasteItemIndex,
        },
        state,
      });
      if (newNodeId) {
        pastedNodeIds.push(newNodeId);
        pasteItemIndex++;
      }
    }
  }

  return pastedNodeIds;
};

export const pasteCopiedNodes: JuxStoreActionFn<
  CommonActionsParams['pasteCopiedNodes'],
  CanjuxState
> = ({ copiedItems, copiedItemsRect, state }) => {
  const errors = new Map<CopyPasteErrors, boolean>([
    [NOT_ALLOWED_TO_PASTE_INTO_INSTANCES, false],
    [NOT_ALLOWED_LOCAL_CHILDREN_IN_LIBRARY_COMPONENTS, false],
    [NOT_ALLOWED_TO_PASTE_INTO_SAME_SOURCE_COMPONENT, false],
  ]);

  // Keep track of the pasted node ids, so we can select them afterwords
  let pastedNodeIds: string[] = [];
  const currentCanvas = state.canvases[state.currentCanvasName];
  const selectedNodesStack = state.selectedNodesStack;

  if (selectedNodesStack.length === 0) {
    pastedNodeIds = pasteCopiedNodesAsRootNodes({
      copiedItems,
      copiedItemsRect,
      currentCanvas,
      state,
    });
  } else {
    // Selected nodes that are included in the copied data
    const intersectingNodes = intersection(
      selectedNodesStack,
      copiedItems.map(({ id }) => id)
    );

    // if the copied nodes are the same nodes that are selected when pasting
    const hasFullIntersection = intersectingNodes.length === copiedItems.length;
    if (hasFullIntersection) {
      pastedNodeIds = copyPasteSelectedNodes({
        copiedItems,
        currentCanvas,
        errors,
        state,
      });
    } else {
      pastedNodeIds = pasteCopiedNodesToTargets({
        selectedNodesStack,
        copiedItems,
        currentCanvas,
        errors,
        state,
      });
    }
  }

  setSelectedNodes({
    nodeIds: pastedNodeIds.filter(Boolean) as string[],
    state,
  });

  setLayersData(state);

  for (const [error, hasError] of Object.entries(errors)) {
    if (hasError) {
      toast.error(error);
    }
  }

  return state;
};
