/* eslint-disable @typescript-eslint/naming-convention */
import { FC } from 'react';
import * as React from 'react';
import { css } from '@juxio/react-styled';
import {
  hasOneSelectedNode,
  selectedNodeComponentBooleanType,
  useCurrentCanvasName,
  useStore,
} from '@jux/canjux/core';
import { Typography } from '@jux/ui/components';
import { JuxButton } from '@jux/ui-core';
import {
  useCreateComponent,
  useAddToLibrary,
  useVariantsGroup,
} from '../hooks';

const slot_df198fd7 = css({
  width: 'auto',
  height: 'auto',
  display: 'flex',
  padding: '0px 0px 0px 0px',
  alignItems: 'center',
  borderStyle: 'none',
  flexDirection: 'row',
  justifyContent: 'center',
  backgroundColor: '#FFFFFF',
  gap: 'normal {dimension.core.size_sm}',
  _name: 'slot_df198fd7',
});

export const MiddleSlot: FC = () => {
  const hasOnlyOneSelectedNode = useStore(hasOneSelectedNode);
  const canvasName = useCurrentCanvasName();
  const { isElement, isLibraryComponent, isLocalComponent, isDiv } = useStore(
    selectedNodeComponentBooleanType
  );
  const { handleCreateComponent } = useCreateComponent();
  const { handleAddToLibrary } = useAddToLibrary();
  const { selectedNodeVariantGroupActionName, selectedNodeVariantGroupAction } =
    useVariantsGroup();

  if (!hasOnlyOneSelectedNode) {
    return <Typography variant="label-s-regular">{canvasName}</Typography>;
  }

  if (isLocalComponent) {
    return (
      <div className={slot_df198fd7}>
        <JuxButton
          text={selectedNodeVariantGroupActionName}
          onClick={selectedNodeVariantGroupAction}
          hierarchy={'secondary'}
          disabled={false}
          size={'medium'}
        />
        <JuxButton
          text={'Add To Library'}
          onClick={handleAddToLibrary}
          hierarchy={'primary'}
          disabled={false}
          size={'medium'}
        />
      </div>
    );
  }

  if (isLibraryComponent) {
    return (
      <JuxButton
        text={selectedNodeVariantGroupActionName}
        onClick={selectedNodeVariantGroupAction}
        hierarchy={'secondary'}
        disabled={false}
        size={'medium'}
      />
    );
  }

  if (isElement && isDiv) {
    return (
      <JuxButton
        onClick={handleCreateComponent}
        hierarchy="primary"
        disabled={false}
        size="medium"
        text="Create Component"
      />
    );
  }

  return <Typography variant="label-s-regular">{canvasName}</Typography>;
};
