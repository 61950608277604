import DrimzAPI from '@jux/ui/lib/api';
import { createClient } from '@liveblocks/client';
import { createStore } from '../store';

export const liveblocksClient = createClient({
  authEndpoint: async (roomId = '') => {
    const result = await DrimzAPI.post('/api/auth/liveblocks', {
      roomId: roomId,
    });

    // Return token
    return result.data;
  },
  throttle: 16, // 60 FPS
  lostConnectionTimeout: 3000, // 3 seconds
});

export type Presence = {
  cursor: { x: number; y: number } | null;
  selectedNodeIds: string[];
};

export type UserInfo = {
  name: string;
};

export type UserMeta = {
  id: string;
  info: UserInfo;
};

export const storeApi = createStore(liveblocksClient);
