import { JuxStoreActionFn, PropsActionsParams } from '../../store.interface';
import type { Draft as WritableDraft } from 'mutative';
import { ComponentConfigWithStates } from '@jux/types';
import {
  deletePropInAllInstances,
  updateContextStylesDeleteProp,
  updateStylesDeleteProp,
} from '../utils/deletePropUtils';
import { NodeType } from '@jux/data-entities';
import { updateMatrixNodesOnDeleteProp } from '../../../matrix-utils/updateMatrixNodesOnDeleteProp';
import { CanjuxState } from '@jux/canjux/core';

/**
 * Delete a property of a component and fix all instances and matrixes in all canvases
 */
export const deleteComponentProp: JuxStoreActionFn<
  PropsActionsParams['deleteComponentProp'],
  CanjuxState
> = ({ componentId, propName, state }) => {
  const component = state.components[componentId];
  if (!component) return state;

  switch (component.type) {
    case NodeType.LOCAL_COMPONENT:
    case NodeType.LIBRARY_COMPONENT:
      const variantConfig = component.config.variants?.find(
        (variant) => variant.variant === propName
      );
      if (!variantConfig) {
        return state;
      }

      // remove variant from variant config
      component.config.variants = component.config.variants?.filter(
        (variant) => variant.variant !== propName
      );

      if (propName in component.config.props) {
        delete component.config.props[propName];
      }

      const styles =
        component.styles as WritableDraft<ComponentConfigWithStates>;
      if (styles.variants) {
        styles.variants = updateStylesDeleteProp({
          variantsStyles: styles.variants,
          propName: propName,
          defaultValue: variantConfig.defaultValue,
        });
      }

      // Go over state styles and do the same
      for (const stateStyles of Object.values(styles.states)) {
        if (!stateStyles.variants) {
          continue;
        }
        stateStyles.variants = updateStylesDeleteProp({
          variantsStyles: stateStyles.variants,
          propName: propName,
          defaultValue: variantConfig.defaultValue,
        });
      }

      if (styles.contextStyles) {
        styles.contextStyles = updateContextStylesDeleteProp({
          contextStyles: styles.contextStyles,
          propName: propName,
          defaultValue: variantConfig.defaultValue,
        });
      }

      // Update matrix nodes before fixing instances
      updateMatrixNodesOnDeleteProp({
        sourceComponentId: component.id,
        propName,
        defaultValue: variantConfig.defaultValue,
        state,
      });

      deletePropInAllInstances({
        componentId,
        propName,
        components: state.components,
      });

      component.updatedAt = new Date().getTime();

      break;
    default:
      throw new Error(
        `Cannot delete prop for component type ${component.type}`
      );
  }

  return state;
};
