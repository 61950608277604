export const getCanduSctipt = (
  userId: string,
  isProd: boolean
) => `(function(d, params){
    var script = d.createElement('script');
    script.setAttribute('src', 'https://cdn.candu.ai/sdk/latest/candu.umd.js?token=' + params.clientToken);
    script.onload = function () {
        Candu.init(params);
    }
    d.head.appendChild(script);
  })(document, {
    userId: '${userId}',
    clientToken: '${isProd ? 'qnyHyHZAWm' : 'b6ZKwcpyx4'}',
   });`;
