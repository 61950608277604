import { useCallback } from 'react';
import {
  selectResolvedComponentProps,
  storeApi,
  useStore,
} from '@jux/canjux/core';
import { TEXT_CONTENT_PROP_NAME } from '@jux/types';

export const useSaveNodeTextContent = (propName = TEXT_CONTENT_PROP_NAME) => {
  const { setComponentPropValueChoice } = useStore(
    (state) => state.propsActions
  );

  const saveTextContent = useCallback(
    ({ nodeId, newText }: { nodeId: string; newText: string }) => {
      const resolvedProps = selectResolvedComponentProps({
        id: nodeId,
        onlyVariantsProps: false,
      })(storeApi.getState());

      // Check if the prop is supported
      if (!(propName in resolvedProps)) {
        throw new Error(
          `Text content ${propName} is not supported for this element type - ${
            storeApi.getState().components[nodeId]?.tagName
          }`
        );
      }

      const currentText = resolvedProps[propName];
      if (currentText === newText) {
        return;
      }

      setComponentPropValueChoice({
        componentId: nodeId,
        propName,
        propValue: newText,
      });
    },
    [propName, setComponentPropValueChoice]
  );

  return { saveTextContent };
};
