import { forwardRef } from 'react';
import { Icons, Typography } from '@jux/ui/components/common';
import { MuiGrid } from '@jux/ui/components/common/mui';
import * as UI from '@jux/ui-core';

type FilesImportButtonProps = Pick<UI.JuxButtonProps, 'onClick'>;

export const FilesImportButton = forwardRef<
  HTMLButtonElement,
  FilesImportButtonProps
>((props, ref) => (
  <UI.JuxButton
    ref={ref}
    {...props}
    hierarchy="primary"
    disabled={false}
    size="medium"
  >
    <MuiGrid container alignItems="center" gap="8px">
      <Typography
        variant="filesImportButtonTypography"
        color={(theme) => theme.drimz.palette.text.button}
      >
        Upload
      </Typography>
      <Icons variant="UPLOAD" />
    </MuiGrid>
  </UI.JuxButton>
));
