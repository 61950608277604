import { LiveblocksData } from '../components-service/liveblocks/liveblocks.interface';
import { NodeType } from '../components-service/nodetypes.enum';
import { isDynamicSlotOrSlotInstance } from './dynamic-slot-helpers';

/**
 * Traverses up the component tree to find any parent that is a dynamic slot
 */
export function isInsideDynamicSlotOrInstance({
  componentId,
  components,
}: {
  componentId: string;
  components: LiveblocksData['components'];
}): boolean {
  let currentComponent = components[componentId];

  while (currentComponent) {
    if (
      isDynamicSlotOrSlotInstance({
        componentToCheck: currentComponent,
        components,
      })
    ) {
      return true;
    }

    if (!currentComponent.parentId) {
      break;
    }

    currentComponent = components[currentComponent.parentId];
  }

  return false;
}

/**
 * Traverses up the component tree to find any parent that is a dynamic slot
 */
export function isDynamicSlotChild({
  componentId,
  components,
}: {
  componentId: string;
  components: LiveblocksData['components'];
}): boolean {
  let currentComponent = components[componentId];

  while (currentComponent.parentId) {
    currentComponent = components[currentComponent.parentId];
    if (currentComponent?.type === NodeType.DYNAMIC_SLOT) {
      return true;
    }
  }

  return false;
}
