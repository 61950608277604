import { AnalyticsEvents } from '@jux/data-access/analytics';
import { useTrackEvents } from '@jux/ui/hooks';
import { useCallback } from 'react';
import { storeApi } from '../crdt';
import { useStoreActions } from './';
import {
  keyboardActionKeys,
  useDuplicateSelectedNodes,
  useRegisterHotKey,
} from './useHotkeys';
import { useDrillInOut } from './useHotkeys/useDrillInOut';
import { useVariantsGroup } from '@jux/ui/components/editor/components/navbar/hooks';

export const useGlobalKeyHandler = (): void => {
  const {
    commonActions: {
      deleteSelectedNodes,
      setUserSelection,
      wrapWithDiv,
      resetSelectedNodes,
    },
  } = useStoreActions();
  const { handleDuplicateSelectedNodes } = useDuplicateSelectedNodes();

  const { trackKeyboardShortcut } = useTrackEvents();

  const handleDelete = useCallback(() => {
    deleteSelectedNodes();
    setUserSelection({
      nodesSelectionActive: false,
    });
  }, [deleteSelectedNodes, setUserSelection]);

  const redo = useCallback(() => {
    const { liveblocks } = storeApi.getState();
    liveblocks.room?.history.redo();
    trackKeyboardShortcut({
      shortcut: AnalyticsEvents.KBS_REDO,
      hotKey: keyboardActionKeys.REDO,
    });
  }, [trackKeyboardShortcut]);

  const undo = useCallback(() => {
    const { liveblocks } = storeApi.getState();
    liveblocks.room?.history.undo();
    trackKeyboardShortcut({
      shortcut: AnalyticsEvents.KBS_UNDO,
      hotKey: keyboardActionKeys.UNDO,
    });
  }, [trackKeyboardShortcut]);

  const deselect = useCallback(() => {
    resetSelectedNodes();
    trackKeyboardShortcut({
      shortcut: AnalyticsEvents.KBS_DESELECT,
      hotKey: keyboardActionKeys.DESELECT,
    });
  }, [resetSelectedNodes, trackKeyboardShortcut]);

  const handleWrapWithDiv = useCallback(() => {
    wrapWithDiv({ nodeIds: storeApi.getState().selectedNodesStack });
    trackKeyboardShortcut({
      shortcut: AnalyticsEvents.KBS_WRAP_WITH_DIV,
      hotKey: keyboardActionKeys.WRAP_WITH_DIV,
    });
  }, [trackKeyboardShortcut, wrapWithDiv]);

  const { handleDrillUp, handleEnterPress } = useDrillInOut();

  const { selectedNodeVariantGroupAction: handleOpenCloseMatrix } =
    useVariantsGroup();

  useRegisterHotKey({
    action: keyboardActionKeys.BACKSPACE_DELETE,
    callback: () => {
      handleDelete();
      // Need to differentiate between backspace and delete
      trackKeyboardShortcut({
        shortcut: AnalyticsEvents.KBS_DELETE,
        hotKey: keyboardActionKeys.BACKSPACE_DELETE,
      });
    },
  });
  useRegisterHotKey({
    action: keyboardActionKeys.DELETE,
    callback: () => {
      handleDelete();
      // Need to differentiate between backspace and delete
      trackKeyboardShortcut({
        shortcut: AnalyticsEvents.KBS_DELETE,
        hotKey: keyboardActionKeys.DELETE,
      });
    },
  });
  useRegisterHotKey({
    action: keyboardActionKeys.WRAP_WITH_DIV,
    callback: handleWrapWithDiv,
  });
  useRegisterHotKey({
    action: keyboardActionKeys.DESELECT,
    callback: deselect,
  });
  useRegisterHotKey({
    action: keyboardActionKeys.REDO,
    callback: redo,
  });
  useRegisterHotKey({
    action: keyboardActionKeys.UNDO,
    callback: undo,
  });

  useRegisterHotKey({
    action: keyboardActionKeys.DRILL_IN,
    callback: handleEnterPress,
  });
  useRegisterHotKey({
    action: keyboardActionKeys.DRILL_OUT,
    callback: handleDrillUp,
  });

  useRegisterHotKey({
    action: keyboardActionKeys.OPEN_CLOSE_MATRIX,
    callback: handleOpenCloseMatrix,
    shouldPreventDefault: true,
  });

  useRegisterHotKey({
    action: keyboardActionKeys.DUPLICATE_COMPONENT,
    callback: handleDuplicateSelectedNodes,
    shouldPreventDefault: true,
  });
};
