import { FC, PropsWithChildren, ReactNode } from 'react';
import { PopperDirection } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import * as S from './NavigationPopper.style';

export const NavigationPopper: FC<
  PropsWithChildren<{
    header?: ReactNode;
    popperDirection?: PopperDirection;
    testId?: string;
  }>
> = ({ children, header, popperDirection = 'ltr', testId }) => (
  <S.Wrapper data-testid={testId} popperDirection={popperDirection}>
    <S.WrapperContent>
      <S.ContentHeader>{header}</S.ContentHeader>

      <MuiGrid>{children}</MuiGrid>
    </S.WrapperContent>
  </S.Wrapper>
);
