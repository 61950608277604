import { useCallback } from 'react';
import { useAnalytics } from '@jux/ui/hooks';
import {
  AnalyticsEvents,
  SegmentEventSourcePage,
} from '@jux/data-access/analytics';

export const useTrackInviteMembers = (sourcePage: SegmentEventSourcePage) => {
  const { trackEvent } = useAnalytics();

  const isSourcePageDashboard = sourcePage === SegmentEventSourcePage.dashboard;

  const trackOpenInviteMembers = useCallback(() => {
    trackEvent({
      eventName: AnalyticsEvents.CLICK,
      properties: {
        elementPath:
          sourcePage === SegmentEventSourcePage.dashboard
            ? 'dashboard/sidebar/invite'
            : 'editor/navbar/invite',
        clickSourcePage: sourcePage,
      },
    });
  }, [sourcePage, trackEvent]);

  const trackSendInviteMembers = useCallback(() => {
    trackEvent({
      eventName: AnalyticsEvents.CLICK,
      properties: {
        elementPath:
          sourcePage === SegmentEventSourcePage.dashboard
            ? 'dashboard/sidebar/invite/sent'
            : 'editor/navbar/invite/sent',
        clickSourcePage: sourcePage,
      },
    });
  }, [sourcePage, trackEvent]);

  return {
    trackOpenInviteMembers,
    trackSendInviteMembers,
    isSourcePageDashboard,
  };
};
