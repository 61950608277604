// TODO: split this logic into smaller functions
import type { Draft as WritableDraft } from 'mutative';
import { ComponentConfigWithStates, DEFAULT_STATE } from '@jux/types';
import { NodeInteractiveState } from '@jux/data-entities';
import {
  PROP_EDITING_MODE_ALL,
  VariantsEditingContext,
} from '@jux/canjux/core';
import * as CSS from 'csstype';
import deepmerge from 'deepmerge';

export const updateStylesSheet = ({
  currentStyles,
  nodeInteractiveState,
  variantsEditingContext,
  newCss,
}: {
  currentStyles: WritableDraft<ComponentConfigWithStates>;
  nodeInteractiveState: NodeInteractiveState;
  variantsEditingContext: VariantsEditingContext;
  newCss: CSS.Properties;
}) => {
  const isDefaultState =
    !nodeInteractiveState || nodeInteractiveState === DEFAULT_STATE;

  // Keep only props that are not set to any
  const finalPropsContext = Object.fromEntries(
    Object.entries(variantsEditingContext).filter(
      ([, propValue]) => propValue !== PROP_EDITING_MODE_ALL
    )
  );

  if (Object.keys(finalPropsContext).length === 0) {
    if (isDefaultState) {
      currentStyles.root = deepmerge(currentStyles.root, newCss);
    } else {
      currentStyles.states[nodeInteractiveState] = {
        ...currentStyles.states[nodeInteractiveState],
        root: {
          ...currentStyles.states[nodeInteractiveState]?.root,
          ...newCss,
        },
      };
    }
    return;
  }

  const variants = isDefaultState
    ? currentStyles.variants || []
    : currentStyles.states[nodeInteractiveState]?.variants || [];

  const variant = variants.find(
    ({ propsValues: variantStyleProps }) =>
      variantStyleProps.length === finalPropsContext.length &&
      Object.entries(variantStyleProps).every(
        ([propName, propValue]) =>
          propName in finalPropsContext &&
          finalPropsContext[propName] === propValue
      )
  );

  if (variant) {
    variant.styles = deepmerge(variant.styles, newCss);
  } else {
    variants.push({ propsValues: finalPropsContext, styles: newCss });

    // Sort the variants by the number of props in propsValues
    variants.sort(
      (a, b) =>
        Object.keys(a.propsValues).length - Object.keys(b.propsValues).length
    );
  }
};
