import { createSelector } from 'reselect';
import { CanjuxState } from '@jux/canjux/core';
import { getResolvedSourceComponentData } from './utils/getResolvedSourceComponentData';
import { NodeType } from '@jux/data-entities';
import equal from 'fast-deep-equal';

export const selectIsNodeInLibrary = (id: string) =>
  createSelector(
    [
      (state: CanjuxState) =>
        getResolvedSourceComponentData({
          component: state.components[id],
          components: state.components,
        }),
      (state: CanjuxState) => state,
    ],
    (sourceComponent, state): boolean => {
      if (!sourceComponent) return false;

      if (sourceComponent.parentId) {
        return selectIsNodeInLibrary(sourceComponent.parentId)(state);
      } else {
        return sourceComponent.type === NodeType.LIBRARY_COMPONENT;
      }
    },
    {
      memoizeOptions: {
        equalityCheck: equal,
        resultEqualityCheck: equal,
      },
    }
  );
