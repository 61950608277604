import {
  CanjuxState,
  ComponentActionsParams,
  CREATE_NEW_CANVAS_NODE_DISTANCE,
  createComponentInstanceCanvasNode,
  getRootNodeOfNode,
  JuxStore,
  JuxStoreActionFn,
  selectOrgComponentsNames,
  setLayersData,
} from '@jux/canjux/core';
import { NodeType } from '@jux/data-entities';
import { generateName } from '@jux/ui/utils/generateName';
import type { Draft as WritableDraft } from 'mutative';
import { removeStorageNode } from '../../store.changes.utils';
import { findInstancesOfInstances } from './utils';

const replaceOldTreeReferences = ({
  sourceNodeId,
  targetNodeId,
  components,
}: {
  sourceNodeId: string;
  targetNodeId: string;
  components: WritableDraft<JuxStore['components']>;
}) => {
  const instancesOfRoot = findInstancesOfInstances({
    sourceNodeId,
    components,
  });
  for (const instance of instancesOfRoot) {
    // point all new instances to new targetNodeId
    if (instance.id !== targetNodeId) {
      instance.sourceComponentId = targetNodeId;
    }
  }

  for (const [index, childId] of components[sourceNodeId].children.entries()) {
    const newChildNode = components[targetNodeId].children[index];

    replaceOldTreeReferences({
      sourceNodeId: childId,
      targetNodeId: newChildNode,
      components,
    });
  }
};

const turnToComponent = ({
  nodeId,
  newNodeType,
  state,
}: {
  nodeId: string;
  newNodeType: NodeType;
  state: WritableDraft<JuxStore>;
}) => {
  const component = state.components[nodeId];

  component.type = newNodeType;
  component.displayName = generateName({
    baseName: component?.displayName ?? component.tagName ?? 'Component',
    namesArray: selectOrgComponentsNames(state),
    options: {
      formatters: ['formatCase', 'removeIllegalChars'],
    },
  });
};

/**
 * Takes a node of 'Element' type and turns it into a new component.
 * If it has a parent node it will put the new component next to the root node,
 * and replace the actual node with an instance
 */
export const createComponentFromElement: JuxStoreActionFn<
  ComponentActionsParams['createComponentFromElement'],
  CanjuxState
> = ({ nodeId, state }) => {
  const component = state.components[nodeId];
  if (component.type !== NodeType.ELEMENT) {
    return state;
  }

  setLayersData(state);

  if (!component.parentId) {
    turnToComponent({ nodeId, newNodeType: NodeType.LOCAL_COMPONENT, state });

    return state;
  }

  const rootNode = getRootNodeOfNode({
    nodeId,
    components: state.components,
  });

  const rootNodeDimensions = state.canvasNodesDimensions[rootNode.id];
  const rootNodePositionAbsolute =
    state.canvasNodesIndicatorsPositions[rootNode.id]?.positionAbsolute;

  let newComponentType = NodeType.LOCAL_COMPONENT;
  if (rootNode.type === NodeType.LIBRARY_COMPONENT) {
    newComponentType = NodeType.LIBRARY_COMPONENT;
  }

  const nodeParentId = component.parentId;
  const indexInParent =
    state.components[component.parentId].children.indexOf(nodeId);

  // Detach Tree from parent and make it a new component type - Local / Library
  removeStorageNode(state.components[component.parentId].children, nodeId);

  turnToComponent({ nodeId, newNodeType: newComponentType, state });
  delete component.parentId;

  const currentCanvas = state.canvases[state.currentCanvasName];
  // Make the newly created component nodes - that still exist on canvas, attach as a new root tree.
  currentCanvas.rootNodesOrder.unshift(nodeId);
  const componentNodePosition = currentCanvas.nodes[nodeId].position ?? {
    x: 0,
    y: 0,
  };
  const componentNodePositionAbsolute =
    state.canvasNodesIndicatorsPositions[rootNode.id]?.positionAbsolute;

  // Put the new component node 'CREATED_NEW_COMPONENT_DISTANCE' pixels right to the end of the original root node
  componentNodePosition.x =
    rootNodePositionAbsolute.x +
    rootNodeDimensions.width +
    CREATE_NEW_CANVAS_NODE_DISTANCE;
  componentNodePosition.y = componentNodePositionAbsolute.y;

  // Create a new instance node with all of it's children
  // creates new instance nodes in all canvases where parentId exists
  // This will also update the 'updatedAt' time for the root componentNode
  const newInstanceId = createComponentInstanceCanvasNode({
    canvasName: currentCanvas.name,
    componentId: nodeId,
    parentId: nodeParentId,
    position: { x: 0, y: 0 },
    state,
    targetIndex: indexInParent,
  });
  if (!newInstanceId) {
    // Throwing an error will revert the state to the previous state
    throw new Error('Failed to create a new instance node');
  }

  // Go over all instances of previous Tree and infer all instances to
  // the new layers of the new instance
  replaceOldTreeReferences({
    sourceNodeId: nodeId,
    targetNodeId: newInstanceId,
    components: state.components,
  });

  setLayersData(state);

  return state;
};
