import {
  CanjuxState,
  ComponentActionsParams,
  getDefaultNodePropertiesByTagName,
  JuxStoreActionFn,
  recursivelyFindComponentChildrenToAddToCanvas,
  setLayersData,
} from '@jux/canjux/core';
import { NodeData, NodeType } from '@jux/data-entities';
import { deleteNode } from './deleteNode';

/**
 * Convert Variants Group node into Local / Library component node
 */
export const convertVariantsGroupToSourceComponent: JuxStoreActionFn<
  ComponentActionsParams['convertVariantsGroupToSourceComponent'],
  CanjuxState
> = ({ nodeId, state }) => {
  const currentCanvas = state.canvases[state.currentCanvasName];
  const component = state.components[nodeId];
  const sourceNodePosition = currentCanvas.nodes[nodeId].position ?? {
    x: 0,
    y: 0,
  };
  if (
    component.type !== NodeType.VARIANTS_GROUP ||
    !component.sourceComponentId
  ) {
    return state;
  }

  const sourceComponent = state.components[component.sourceComponentId];
  if (!sourceComponent) {
    return state;
  }

  const sourceRootNodeIndex = currentCanvas.rootNodesOrder.indexOf(nodeId);

  // This will delete the node from the canvas but not it's source component data
  deleteNode({ nodeId, state });

  // create a new node for the source component
  const sourceNode: NodeData = {
    position: sourceNodePosition,
    properties: getDefaultNodePropertiesByTagName({
      tagName: sourceComponent.tagName,
      type: sourceComponent.type,
    }),
  };

  // add the source component node to the canvas
  currentCanvas.nodes[sourceComponent.id] = sourceNode;

  // add the source component to the root nodes order list
  currentCanvas.rootNodesOrder.splice(
    sourceRootNodeIndex, // original index before it was removed
    0,
    component.sourceComponentId
  );

  // recursively find the source component's children ids
  const childrenIds = recursivelyFindComponentChildrenToAddToCanvas({
    children: sourceComponent.children,
    components: state.components,
  });

  // create a new node for each child component and add it to the canvas
  childrenIds.forEach((childId) => {
    const childComponent = state.components[childId];

    currentCanvas.nodes[childId] = {
      properties: getDefaultNodePropertiesByTagName({
        tagName: childComponent.tagName,
        type: childComponent.type,
      }),
    };
  });

  state.selectedNodesStack = [component.sourceComponentId];

  setLayersData(state);

  return state;
};
