/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { styled, css, type Merge } from '@juxio/react-styled';
import { TextIcon } from './icons/TextIcon';

export interface IconButtonVariants {
  disabled: boolean;
  hierarchy: 'primary' | 'secondary';
}

export type IconButtonProps = Merge<
  React.HTMLAttributes<HTMLButtonElement>,
  IconButtonVariants
>;

const icon_f8629381 = css({
  color: 'rgba(26,26,26,1)',
});

const StyledIconButton = styled<'button', IconButtonProps>(
  'button',
  {
    root: {
      gap: '8px',
      color: '#FFFFFF',
      width: 'auto',
      cursor: 'pointer',
      height: '36px',
      display: 'flex',
      padding: '8px 16px',
      fontSize: '14px',
      fontStyle: 'normal',
      alignItems: 'center',
      fontFamily: 'Inter',
      fontWeight: '500',
      lineHeight: '20px',
      borderStyle: 'none',
      borderRadius: '4px',
      flexDirection: 'row',
      justifyContent: 'center',
      outline: 'none',
      '&:focus-visible': {
        borderColor: '#9e9e9e',
        borderRadius: '2px',
      },
    },
    variants: [
      {
        props: {
          disabled: false,
          hierarchy: 'primary',
        },
        style: {
          width: '28px',
          height: '28px',
          padding: '0px 0px 0px 0px',
          borderColor: '{color.fill_base.-5}',
          borderStyle: 'Solid',
          borderWidth: '1px',
          borderRadius: '2px',
          backgroundColor: '{color.fill_base.-5}',
          '&:hover': {
            borderColor: '{color.fill_base.-2}',
            backgroundColor: '{color.fill_base.-5}',
          },
          '&:active': {
            borderColor: '{color.fill_base.0}',
            backgroundColor: '{color.fill_base.-5}',
          },
        },
      },
      {
        props: {
          disabled: false,
          hierarchy: 'secondary',
        },
        style: {
          width: '28px',
          height: '28px',
          padding: '0px 0px 0px 0px',
          borderRadius: '2px',
          backgroundColor: '{core.color.greys.0}',
          '&:hover': {
            width: '28px',
            height: '28px',
            padding: '0px 0px 0px 0px',
            backgroundColor: '{core.color.greys.200}',
          },
          '&:active': {
            width: '28px',
            height: '28px',
            padding: '0px 0px 0px 0px',
            borderColor: '{core.color.greys.300}',
            borderStyle: 'Solid',
            borderWidth: '1px',
            backgroundColor: '{core.color.greys.200}',
          },
        },
      },
      {
        props: {
          disabled: true,
          hierarchy: 'primary',
        },
        style: {
          width: '28px',
          cursor: 'default',
          height: '28px',
          padding: '0px 0px 0px 0px',
          backgroundColor: '{core.color.greys.0}',
        },
      },
      {
        props: {
          disabled: true,
          hierarchy: 'secondary',
        },
        style: {
          width: '28px',
          cursor: 'default',
          height: '28px',
          padding: '0px 0px 0px 0px',
          backgroundColor: '{core.color.greys.0}',
        },
      },
    ],
  },
  {
    // Prevent non-valid HTML props from being passed to the DOM
    shouldForwardProp: (propName) => !['hierarchy'].includes(propName),
  }
);

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton(
    { disabled = false, hierarchy = 'primary', ...otherProps },
    ref
  ) {
    return (
      <StyledIconButton
        disabled={disabled}
        hierarchy={hierarchy}
        ref={ref}
        {...otherProps}
      >
        {otherProps.children || <TextIcon className={icon_f8629381} />}
      </StyledIconButton>
    );
  }
);
