import { FC } from 'react';
import { SelectField } from '@jux/ui/components';
import { MuiMenuItem } from '@jux/ui/components/common/mui';
import {
  PlaceholderMode,
  PlaceholderModeValues,
  useStore,
  useStoreActions,
} from '@jux/canjux/core';
import { SubModule } from '../../../../base';
import * as S from '../textContent/TextContent.style';
import { usePlaceholderAndTextContent } from './usePlaceholderAndTextContent';

export const PlaceholderProps: FC<{
  nodeId: string;
  hasPlaceholderEditingMode: boolean;
}> = ({ nodeId, hasPlaceholderEditingMode }) => {
  const storePlaceholderMode = useStore((s) => s.placeholderMode[nodeId]);

  const {
    saveTextValue,
    savePlaceholderText,
    discardTextValue,
    discardPlaceHolderText,
    registerTextFieldValue,
    registerPlaceHolderField,
  } = usePlaceholderAndTextContent(nodeId);

  const {
    ddpActions: { setPlaceholderMode },
  } = useStoreActions();

  return (
    <>
      {hasPlaceholderEditingMode && (
        <S.TextContentLabel>
          <SubModule title="Input Mode" renderInline>
            <SelectField
              type="text"
              name="Input mode"
              value={storePlaceholderMode ?? PlaceholderMode.text}
              onChange={(mode) => {
                setPlaceholderMode({
                  nodeId,
                  mode: mode.target.value as PlaceholderModeValues,
                });
              }}
              blurOnSelect
            >
              <MuiMenuItem value={PlaceholderMode.text}>Value</MuiMenuItem>
              <MuiMenuItem value={PlaceholderMode.placeholder}>
                Placeholder
              </MuiMenuItem>
            </SelectField>
          </SubModule>
        </S.TextContentLabel>
      )}
      <S.TextContentLabel>
        <SubModule title="Input text" renderInline>
          <S.TextContentInput
            onEnter={saveTextValue}
            onEscape={discardTextValue}
            {...registerTextFieldValue()}
            onBlurCapture={saveTextValue}
          />
        </SubModule>
      </S.TextContentLabel>
      <S.TextContentLabel>
        <SubModule title="Placeholder text" renderInline>
          <S.TextContentInput
            onEscape={discardPlaceHolderText}
            onEnter={savePlaceholderText}
            {...registerPlaceHolderField()}
            onBlurCapture={savePlaceholderText}
          />
        </SubModule>
      </S.TextContentLabel>
    </>
  );
};
