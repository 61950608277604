import { useCallback } from 'react';
import { useStoreActions } from '@jux/canjux/core';
import { useTrackEvents } from '@jux/ui/hooks';
import { ComponentTagName } from '@jux/data-entities';

export const useHandleRenamePropValue = ({
  componentId,
  tagName,
}: {
  componentId: string | undefined;
  tagName: ComponentTagName | undefined;
}) => {
  const {
    propsActions: { renameComponentPropValue },
  } = useStoreActions();

  const { trackPropValueRenamedEvent } = useTrackEvents();

  const renamePropValue = useCallback(
    (
      propName: string,
      newOptionValue: {
        value: string;
        newValue: string;
      }
    ): boolean => {
      if (!componentId || !tagName) return false;

      try {
        renameComponentPropValue({
          componentId,
          propName,
          propValueLabel: newOptionValue.value,
          newPropValue: newOptionValue.newValue,
        });

        trackPropValueRenamedEvent({
          componentUUID: componentId,
          tagName,
          propName,
          oldPropValueName: newOptionValue.value,
          newPropValueName: newOptionValue.newValue,
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    [componentId, renameComponentPropValue, tagName, trackPropValueRenamedEvent]
  );

  return {
    handleRenamePropValue: renamePropValue,
  };
};
