import { atom, useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { DesignTokenTypeEnum, SupportedTokenTypes } from '@juxio/design-tokens';
import {
  formatTokenSetData,
  getTokenSetsByCreatedAt,
  getTotalTokens,
  hasGroups,
  sortByCreatedAtAsc,
  useStore,
} from '@jux/canjux/core';
import { TokenSetData } from '@jux/data-entities';
import { CORE_TOKEN_SET_NAME, isCoreTokenSetName } from '@jux/types';

type TokenSetInfo = Pick<TokenSetData, 'createdAt' | 'id' | 'name'> & {
  index: number;
  isCore: boolean;
  hasGroups: boolean;
  totalTokens: number;
};

export const sortByCoreTokenSetFirst = <T extends { name: string }>(
  a: T,
  b: T
) => {
  if (isCoreTokenSetName(a.name)) {
    return -1;
  }

  if (isCoreTokenSetName(b.name)) {
    return 1;
  }

  return 0;
};

export const currentTokenSetKeyAtom = atom<string>(CORE_TOKEN_SET_NAME);
export const currentTokenSetIdAtom = atom<string>('');
export const currentTokenTypeAtom = atom<SupportedTokenTypes>(
  DesignTokenTypeEnum.color
);

// TODO: Move this to the tokenSets module
export const useTokenSetState = () => {
  const [currentTokenSetKey, setCurrentTokenSetKey] = useAtom(
    currentTokenSetKeyAtom
  );
  const [currentTokenType, setCurrentTokenType] = useAtom(currentTokenTypeAtom);

  const [currentTokenSetId, setCurrentTokenSetId] = useAtom(
    currentTokenSetIdAtom
  );

  // TODO: Move this to custom hook like `useUseTokenSetStateState` 😜
  const { currentOrgTokenSets, tokenSet } = useStore((s) => ({
    currentOrgTokenSets: s.tokenSets,
    tokenSet: currentTokenSetId
      ? Object.values(s.tokenSets).find(({ id }) => id === currentTokenSetId)
      : undefined,
  }));

  const tokenSetData = useMemo(() => {
    if (!tokenSet) return;

    return formatTokenSetData({ tokenSet });
  }, [tokenSet]);

  const tokenSets = useMemo((): Record<string, TokenSetInfo> => {
    let hasCoreTokenSet = false;
    let index = 0;
    let initialId = '';

    if (!currentOrgTokenSets) return {};

    const sets = Object.values(currentOrgTokenSets)
      .toSorted(sortByCreatedAtAsc)
      .toSorted(sortByCoreTokenSetFirst)
      .reduce((acc, { createdAt, id, name }) => {
        const isCore = isCoreTokenSetName(name);

        if (isCore) {
          hasCoreTokenSet = isCore;
        }

        if (!initialId) {
          initialId = id;
        }

        const groupsByType = isCore
          ? tokenSetData?.core.groupsByType
          : tokenSetData?.groupsByType;

        // TODO: this is confusing, change this to not use name where id should be used
        acc[isCore ? CORE_TOKEN_SET_NAME : id] = {
          createdAt,
          id,
          name,
          isCore,
          index,
          hasGroups: hasGroups({ groupsByType, currentTokenType }),
          totalTokens: getTotalTokens({ groupsByType }),
        };

        index++;
        return acc;
      }, {} as Record<string, TokenSetInfo>);

    // this could be the case when the store (and the function getInitialCoreTokenSetData)
    // is initialized but not synced with the room storage
    const currentTokenSetIdExistsInSets =
      currentTokenSetId &&
      Object.values(sets).find(({ id }) => id === currentTokenSetId);

    // in case we don't have a core token set, we need to add a fake core token set
    if (!hasCoreTokenSet) {
      const findFirstNonCoreTokenSet = getTokenSetsByCreatedAt(
        currentOrgTokenSets
      )?.find(({ name }) => name !== CORE_TOKEN_SET_NAME);

      if (findFirstNonCoreTokenSet) {
        if (!currentTokenSetId || !currentTokenSetIdExistsInSets) {
          setCurrentTokenSetId(findFirstNonCoreTokenSet.id);
        }

        const groupsByType = tokenSetData?.core.groupsByType;

        return {
          [CORE_TOKEN_SET_NAME]: {
            createdAt: new Date(0).toISOString(),
            id: findFirstNonCoreTokenSet.id,
            name: CORE_TOKEN_SET_NAME,
            isCore: true,
            hasGroups: hasGroups({ groupsByType, currentTokenType }),
            totalTokens: getTotalTokens({ groupsByType }),
            index: -1,
          },
          ...sets,
        };
      }
    }

    if (!currentTokenSetId || !currentTokenSetIdExistsInSets) {
      setCurrentTokenSetId(initialId);
    }

    return sets;
  }, [
    currentOrgTokenSets,
    currentTokenSetId,
    currentTokenType,
    setCurrentTokenSetId,
    tokenSetData?.core.groupsByType,
    tokenSetData?.groupsByType,
  ]);

  const setCurrentTokenSet = useCallback(
    ({ key, id }: { key: string; id: string }) => {
      setCurrentTokenSetId(id);
      setCurrentTokenSetKey(key);
    },
    [setCurrentTokenSetId, setCurrentTokenSetKey]
  );

  return useMemo(
    () => ({
      currentTokenSetId,

      currentTokenSetKey,
      setCurrentTokenSet,

      isCoreTokenSet: Boolean(tokenSets[currentTokenSetKey]?.isCore),
      currentTokenType,
      setCurrentTokenType,

      tokenSetData,
      tokenSets,
    }),
    [
      currentTokenSetId,
      currentTokenSetKey,
      setCurrentTokenSet,
      tokenSets,
      currentTokenType,
      setCurrentTokenType,
      tokenSetData,
    ]
  );
};
