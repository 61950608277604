import {
  ControlLinePosition,
  Dimensions,
  LineType,
  ResizePosition,
} from '@jux/canjux/core';
import { XYPosition } from '@jux/data-entities';
import { styled } from '@jux/ui/components/common/mui';
import { Property } from 'csstype';
import { CSSProperties, FC, memo, useRef } from 'react';
import useResizerDrag from './useResizerDrag';

export interface LineControlProps {
  hoverLineType?: LineType;
  isDynamicSlot: boolean;
  isHovered: boolean;
  isResizable: boolean;
  isSelected: boolean;
  lineColor: string;
  lineOverflow?: string;
  lineThickness: string;
  nodeDimensions: Dimensions;
  nodeId: string;
  nodePosition: XYPosition;
  position: ControlLinePosition;
  revertZoomScale: number;
}

// For some reason we get type error when using Property.BorderStyle, so we have to define each one individually
type BorderStyle =
  | Property.BorderRightStyle
  | Property.BorderLeftStyle
  | Property.BorderTopStyle
  | Property.BorderBottomStyle;

const getStylesByPositionDirection = (
  position: ControlLinePosition,
  isResizable: boolean
): CSSProperties => {
  if (position === ResizePosition.left || position === ResizePosition.right) {
    return {
      width: 0,
      height: '100%',
      cursor: isResizable ? 'ew-resize' : 'default',
    };
  }
  if (position === ResizePosition.top || position === ResizePosition.bottom) {
    return {
      height: 0,
      width: '100%',
      cursor: isResizable ? 'ns-resize' : 'default',
    };
  }
  return {};
};

/**
 * @param position used to determine which line to render
 * @param isHovered used to determine if line should be dashed or solid
 * @param revertZoomScale used to revert zoom so that line's thickness doesn't scale with zoom
 * @param lineThickness used to determine the thickness of the line
 * @param lineOverflow used to determine the size to add to the interaction area of the line
 * @param hoverLineType used to determine if line should be dashed or solid
 **/
const getLineStylesByPosition = ({
  position,
  isHovered,
  revertZoomScale,
  isDynamicSlot,
  lineThickness = '1px',
  lineOverflow = '1px',
  hoverLineType = LineType.PARENT,
}: {
  position: ControlLinePosition;
  isHovered: LineControlProps['isHovered'];
  revertZoomScale: number;
  isDynamicSlot: boolean;
  lineThickness: LineControlProps['lineThickness'];
  lineOverflow: LineControlProps['lineOverflow'];
  hoverLineType?: LineType;
}): CSSProperties => {
  let borderStyle: BorderStyle = isDynamicSlot ? 'dashed' : 'solid';

  if (isHovered && !isDynamicSlot) {
    switch (hoverLineType) {
      case LineType.PARENT:
        borderStyle = 'solid';
        break;
      case LineType.CHILD:
        borderStyle = 'dotted';
        break;
    }
  }

  switch (position) {
    case ResizePosition.left:
      return {
        borderRightStyle: borderStyle,
        borderRightWidth: lineThickness,
        paddingRight: lineOverflow,
        transform: `scaleX(${revertZoomScale}) translate(-100%, 0%)`,
        transformOrigin: '0% 0%',
        left: 0,
      };
    case ResizePosition.right:
      return {
        borderLeftStyle: borderStyle,
        borderLeftWidth: lineThickness,
        paddingLeft: lineOverflow,
        transform: `scaleX(${revertZoomScale})`,
        transformOrigin: '0% 0%',
        left: '100%',
      };
    case ResizePosition.top:
      return {
        borderBottomStyle: borderStyle,
        borderBottomWidth: lineThickness,
        paddingBottom: lineOverflow,
        transform: `scaleY(${revertZoomScale}) translate(0%, -100%)`,
        transformOrigin: '0% 0%',
        top: 0,
      };
    case ResizePosition.bottom:
      return {
        borderTopStyle: borderStyle,
        borderTopWidth: lineThickness,
        paddingTop: lineOverflow,
        transform: `scaleY(${revertZoomScale})`,
        transformOrigin: '0% 0%',
        top: '100%',
      };
  }
};

const StyledLineControl = styled('div', {
  shouldForwardProp: (prop: string) =>
    ![
      'position',
      'revertZoomScale',
      'isResizable',
      'isHovered',
      'lineThickness',
      'lineOverflow',
      'hoverLineType',
      'lineColor',
      'isDynamicSlot',
    ].includes(prop),
})<
  Omit<
    LineControlProps,
    | 'isSelected'
    | 'nodeDimensions'
    | 'nodeId'
    | 'nodePosition'
    | 'nodeProperties'
  >
>(
  ({
    position,
    revertZoomScale,
    isDynamicSlot,
    isResizable,
    isHovered,
    lineThickness,
    lineOverflow,
    hoverLineType,
    lineColor,
  }) => ({
    ...getStylesByPositionDirection(position, isResizable),
    ...getLineStylesByPosition({
      position,
      isDynamicSlot,
      isHovered,
      revertZoomScale,
      lineThickness,
      lineOverflow,
      hoverLineType,
    }),
    borderColor: lineColor,
    zIndex: 4,
    position: 'absolute',
    outline: 'none',
    pointerEvents: isHovered ? 'none' : 'all',
  })
);

const LineControl: FC<LineControlProps> = ({
  hoverLineType = LineType.PARENT,
  isDynamicSlot,
  isHovered,
  isResizable,
  isSelected,
  lineColor,
  lineThickness,
  lineOverflow,
  nodeDimensions,
  nodeId,
  nodePosition,
  position,
  revertZoomScale,
}) => {
  const resizeControlRef = useRef<HTMLDivElement>(null);

  useResizerDrag({
    resizeControlRef,
    controlPosition: position,
    nodeId,
    nodeDimensions,
    nodePosition,
    enabled: isSelected && isResizable,
  });

  return (
    <StyledLineControl
      hoverLineType={hoverLineType}
      isDynamicSlot={isDynamicSlot}
      isHovered={isHovered}
      isResizable={isResizable}
      lineColor={lineColor}
      lineOverflow={lineOverflow}
      lineThickness={lineThickness}
      position={position}
      ref={resizeControlRef}
      revertZoomScale={revertZoomScale}
    />
  );
};

export default memo(LineControl);
