// Must be an enum, rather than a union -
// otherwise, renaming and deleting members will be a pain...
export const ComponentTagNames = {
  JuxButton: 'JuxButton',
  JuxIconButton: 'JuxIconButton',
  JuxCheckboxField: 'JuxCheckboxField',
  JuxCheckbox: 'JuxCheckbox',
  JuxLabelTrigger: 'JuxLabelTrigger',
  JuxChip: 'JuxChip',
  JuxDiv: 'JuxDiv',
  JuxDivider: 'JuxDivider',
  JuxInput: 'JuxInput',
  JuxTextField: 'JuxTextField',
  JuxRadio: 'JuxRadio',
  JuxSlot: 'JuxSlot',
  JuxSvg: 'JuxSvg',
  JuxText: 'JuxText',
  JuxTextarea: 'JuxTextarea',
  JuxTextareaField: 'JuxTextareaField',
  JuxToggle: 'JuxToggle',
} as const;
export type ComponentTagName = keyof typeof ComponentTagNames;
