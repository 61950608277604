import { ThemeOptions } from '@jux/ui/components/common/mui';
import { baseThemeSize } from './base-theme-size';
import { transitions } from './base-theme-transitions';

export const components: ThemeOptions['components'] = {
  MuiCssBaseline: {
    styleOverrides: {},
  },

  // BUTTON
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
        borderRadius: baseThemeSize.borderRadius.small,
      },
      sizeSmall: {
        padding: '4px 6px',
      },
      sizeMedium: {
        padding: '6px 8px',
      },
      sizeLarge: {
        padding: '8px 10px',
      },
      textSizeSmall: {
        padding: '4px 6px',
      },
      textSizeMedium: {
        padding: '6px 8px',
      },
      textSizeLarge: {
        padding: '8px 10px',
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        width: '100%',
        height: '30px',
      },
      grouped: {
        margin: 2,
        border: 0,
        '&.Mui-disabled': {
          border: 0,
        },
        '&:not(:first-of-type)': {
          borderRadius: 8,
        },
        '&:first-of-type': {
          borderRadius: 8,
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        borderRadius: baseThemeSize.borderRadius.main,
        padding: '8px',
        '&:hover': {
          borderRadius: '50%',
        },
      },
      sizeSmall: {
        padding: '4px',
      },
    },
  },

  // INPUT
  MuiInputBase: {
    styleOverrides: {
      root: {
        margin: 0,
        height: '100%',
      },
      input: {
        padding: 0,
        '&::placeholder': {
          opacity: 1,
        },
        '&[type=number]': {
          MozAppearance: 'textfield',
        },
        '&[type=number]::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&[type=number]::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: baseThemeSize.borderRadius.main,
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px !important',
        },
      },
      input: {
        padding: '8px',

        '&:-webkit-autofill': {
          transition: 'background-color 5000s ease-in-out 0s',
          WebkitBoxShadow: 'none',
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        margin: '0',
      },
    },
  },

  // CARD
  MuiCardActions: {
    styleOverrides: {
      root: {
        padding: '16px',
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: '16px 16px',
        '&:last-child': {
          paddingBottom: 0,
        },
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: 'subtitle2',
      },
      subheaderTypographyProps: {
        variant: 'body2',
      },
    },
    styleOverrides: {
      root: {
        padding: '16px 16px',
      },
      action: {
        margin: 0,
        alignItems: 'center',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: baseThemeSize.borderRadius.main,
      },
    },
  },

  // MENU & LIST
  MuiMenu: {
    styleOverrides: {
      paper: {
        borderRadius: baseThemeSize.borderRadius.small,
      },
      list: {
        padding: 0,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        borderRadius: baseThemeSize.borderRadius.tiny,
        transition: transitions.all,
        padding: '6px 8px',
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        marginRight: '16px',

        '&.MuiListItemIcon-root': {
          minWidth: 'unset',
        },
      },
    },
  },

  // DIALOG
  MuiDialog: {
    styleOverrides: {
      root: {
        '& .MuiDialog-paper': {
          borderRadius: baseThemeSize.borderRadius.main,
        },
      },
    },
  },

  // PAPER
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'none',
        boxShadow: 'none',

        '&.MuiMenu-paper': {
          borderRadius: baseThemeSize.borderRadius.small,
        },

        '&.MuiList-paper': {
          borderRadius: baseThemeSize.borderRadius.small,
        },
      },
    },
  },

  // POPOVER
  MuiPopover: {
    defaultProps: {
      elevation: 16,
    },
    styleOverrides: {
      paper: {
        borderRadius: baseThemeSize.borderRadius.main,
        border: '1px solid',
      },
    },
  },

  // TABLE
  MuiTableHead: {
    styleOverrides: {
      root: {
        borderBottom: 'none',

        '& .MuiTableCell-root': {
          borderBottom: 'none',
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: 1,
          letterSpacing: 0.5,
          textTransform: 'uppercase',
        },
        '& .MuiTableCell-paddingCheckbox': {
          paddingTop: 4,
          paddingBottom: 4,
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottom: 'none',
        padding: '15px 16px',
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&:not(:last-child)': {
          borderBottom: '1px solid',
        },
      },
    },
  },

  //
  MuiCheckbox: {
    defaultProps: {
      color: 'primary',
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      fontSizeSmall: {
        fontSize: '1rem',
      },
    },
  },

  MuiLinearProgress: {
    styleOverrides: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: 'none',
    },
  },

  MuiRadio: {
    defaultProps: {
      color: 'primary',
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginLeft: 0,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.71,
        minWidth: 'auto',
        paddingLeft: 0,
        paddingRight: 0,
        textTransform: 'none',
        '& + &': {
          marginLeft: 24,
        },
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        margin: '0 !important',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: baseThemeSize.borderRadius.small,
        fontSize: 12,
        fontWeight: 500,
        height: 22,

        '& .MuiChip-label': {
          paddingLeft: 8,
          paddingRight: 8,
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        borderBottom: '1px solid',
        minHeight: '20px !important',
      },
      content: {
        margin: '12px 0 !important',
      },
    },
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  MuiTreeItem: {
    styleOverrides: {
      root: {
        paddingTop: '4px',
        paddingBottom: '4px',
      },
      content: {
        padding: '8px 16px',
        '&.Mui-selected': {
          borderRadius: baseThemeSize.borderRadius.main,
        },
        '&:hover': {
          borderRadius: baseThemeSize.borderRadius.main,
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: '16px !important',
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        fontSize: '0.75rem',
        fontWeight: 600,
        letterSpacing: 0,
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: '12px',
        padding: '2px 6px !important',
        borderRadius: baseThemeSize.borderRadius.small,
        border: '1px solid',
      },
    },
  },
};
