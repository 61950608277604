import { useCallback } from 'react';
import { DesignTokenData } from '@juxio/design-tokens';
import { AutocompleteOptionData } from '@jux/ui/components/editor/components/panels/DDP/base/fields/autocomplete';
import { useEffectOnSelectedNodeChange } from '@jux/ui/components/editor/hooks';
import { useEffectOnSelectedNodeStateChange } from '../useEffectOnSelectedNodeStateChange';
import { UseSelectFieldInputsProps } from './useSelectField.interface';

export const useSelectFieldInputs = ({
  parsedValue,
  initialValue,
  saveChanges: setFieldValue,
  initSelectionOptions,
  selectedOption,
  setSelectedOption,
  setOptions,
}: UseSelectFieldInputsProps) => {
  const updateStateByTokenValue = useCallback(
    (newToken: DesignTokenData) => {
      setSelectedOption({ label: newToken.name, value: newToken.value });
    },
    [setSelectedOption]
  );

  const handleEffectChanges = useCallback(() => {
    setOptions(initSelectionOptions);
    setSelectedOption({ label: parsedValue, value: parsedValue });
  }, [initSelectionOptions, parsedValue, setOptions, setSelectedOption]);

  useEffectOnSelectedNodeChange(handleEffectChanges);
  useEffectOnSelectedNodeStateChange(handleEffectChanges);

  const saveSelection = useCallback(
    (newSelectedOption: AutocompleteOptionData) => {
      setSelectedOption(newSelectedOption);
      setFieldValue(newSelectedOption.label);
    },
    [setFieldValue, setSelectedOption]
  );

  const discardChanges = useCallback(() => {
    setSelectedOption(selectedOption);
  }, [selectedOption, setSelectedOption]);

  const handleOnSelect = useCallback(
    (newSelectedOption: AutocompleteOptionData) => {
      saveSelection(newSelectedOption);
    },
    [saveSelection]
  );

  const handleBlur = useCallback(() => {
    discardChanges();
  }, [discardChanges]);

  const handleEscape = useCallback(() => {
    discardChanges();
  }, [discardChanges]);

  const handleRevertToInitial = useCallback(() => {
    saveSelection({ label: initialValue, value: initialValue });
  }, [initialValue, saveSelection]);

  return {
    handleOnSelect,
    handleBlur,
    handleEnter: () => {},
    handleEscape,
    discardChanges,
    updateStateByTokenValue,
    handleRevertToInitial,
  };
};
