import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { useSelectedNodeComponent } from '@jux/ui/components/editor/hooks';
import { NodeType } from '@jux/data-entities';
import { PropertiesActions } from './PropertiesActions';
import { PropertiesFields } from './PropertiesFields';

export const PropertiesPanelContent: FC = () => {
  const { component, sourceComponent } = useSelectedNodeComponent();

  let componentToUse = component;
  if (component?.type === NodeType.VARIANTS_GROUP) {
    componentToUse = sourceComponent;
  }

  return (
    <MuiGrid container item>
      <PropertiesFields
        componentId={componentToUse?.id}
        tagName={componentToUse?.tagName}
      />
      <PropertiesActions
        componentId={componentToUse?.id}
        tagName={componentToUse?.tagName}
      />
    </MuiGrid>
  );
};
