import { useCallback, useMemo } from 'react';
import { SelectChangeEvent } from '@jux/ui/components/common/mui';
import {
  nodeComponentInteractiveStateByNodeId,
  selectResolvedComponentById,
  useStore,
  useStoreActions,
} from '@jux/canjux/core';
import { isSelectedNodeValidInteractiveStateOptions } from '@jux/ui/components/editor/hooks/useNodeInteractiveState.interface';
import { useSetupDDPModuleState } from '@jux/ui/components/editor/hooks';
import {
  InteractiveStateOptionsByTagName,
  InteractiveStateProps,
} from './InteractiveState.interface';

export const useInteractiveState = ({
  nodeId = '',
}: {
  nodeId?: string;
}): InteractiveStateProps => {
  const { setupDDPValues } = useSetupDDPModuleState();
  const {
    componentActions: { updateNodeInteractiveState },
  } = useStoreActions();

  const nodeInteractiveState = useStore(
    nodeComponentInteractiveStateByNodeId(nodeId)
  );
  const resolvedComponent = useStore(
    selectResolvedComponentById({
      id: nodeId,
      shouldStopOnVariantMatrix: false,
    })
  );

  const interactiveStateOptions = useMemo(() => {
    if (!resolvedComponent?.tagName) return [];

    const interactiveStateOptionsByTagName =
      InteractiveStateOptionsByTagName[resolvedComponent.tagName];

    return interactiveStateOptionsByTagName || [];
  }, [resolvedComponent?.tagName]);

  const handleNodeInteractiveStateChange = useCallback(
    (e: SelectChangeEvent) => {
      const { value } = e.target;
      if (!value || !isSelectedNodeValidInteractiveStateOptions(value)) {
        return;
      }

      updateNodeInteractiveState({ nodeId, interactiveState: value });

      setupDDPValues();
    },
    [setupDDPValues, updateNodeInteractiveState, nodeId]
  );

  return {
    nodeInteractiveState,
    handleNodeInteractiveStateChange,
    options: interactiveStateOptions,
  };
};
