import { useCallback } from 'react';
import {
  useSaveSelectedNodeDDPChanges,
  useSelectedNodeId,
} from '@jux/ui/components/editor/hooks';
import {
  DDPFieldsStateKeys,
  useDDPFieldsState,
} from '@jux/ui/components/editor/state';
import { useTrackEvents } from '@jux/ui/hooks';

export const useSetMultiFieldsValues = () => {
  const selectedNodeId = useSelectedNodeId();

  const { setFieldsStateByKey } = useDDPFieldsState();
  const { saveStyleChanges } = useSaveSelectedNodeDDPChanges();

  const { trackFieldValueChangeEvent } = useTrackEvents();

  const setMultiFieldsValues = useCallback(
    (newValues: { [key in DDPFieldsStateKeys]?: any }) => {
      if (!selectedNodeId) return;

      Object.keys(newValues).forEach((key) => {
        setFieldsStateByKey(key as DDPFieldsStateKeys, {
          value: newValues[key as DDPFieldsStateKeys],
        });
        trackFieldValueChangeEvent(key, newValues[key as DDPFieldsStateKeys]);
      });

      saveStyleChanges({
        nodeIds: [selectedNodeId],
        newStyle: newValues,
      });
    },
    [
      saveStyleChanges,
      selectedNodeId,
      setFieldsStateByKey,
      trackFieldValueChangeEvent,
    ]
  );

  const setMultiFieldsValuesForNodeId = useCallback(
    (id: string, newValues: { [key in DDPFieldsStateKeys]?: any }) => {
      Object.keys(newValues).forEach((key) => {
        setFieldsStateByKey(key as DDPFieldsStateKeys, {
          value: newValues[key as DDPFieldsStateKeys],
        });
      });

      // TODO: use the editing context of the node id that was specified !
      saveStyleChanges({
        nodeIds: [id],
        newStyle: newValues,
      });
    },
    [saveStyleChanges, setFieldsStateByKey]
  );

  return {
    setMultiFieldsValues,
    setMultiFieldsValuesForNodeId,
  };
};
