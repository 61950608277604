import { ForwardedRef, JSX, RefAttributes, forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { ComponentConfigWithStates } from '@jux/types';
import * as S from './ComponentPreview.style';
import { ElementPreview } from './ElementPreview';

export const ComponentPreview = forwardRef(
  (
    {
      componentId,
      componentDragRef,
      componentRectRef,
      loadingRenderer,
      previewStyles,
      previewPortalContainer,
      showLoading,
      transform,
    }: {
      componentId: string;
      componentDragRef: RefAttributes<HTMLElement>['ref'];
      componentRectRef: RefAttributes<HTMLElement>['ref'];
      loadingRenderer: JSX.Element;
      previewStyles: ComponentConfigWithStates;
      previewPortalContainer: HTMLElement;
      showLoading: boolean;
      transform?: string | undefined;
    },
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <S.ComponentPreviewWrapper ref={ref} container item>
      {showLoading && (
        <S.ComponentPreviewLoadingWrapper>
          {loadingRenderer}
        </S.ComponentPreviewLoadingWrapper>
      )}
      {/* This is the actual component preview that is visible in the panel */}
      <S.ComponentPreviewTransformed transform={transform}>
        <ElementPreview
          ref={componentDragRef}
          componentId={componentId}
          styleOverrides={previewStyles}
          isRoot
        />
      </S.ComponentPreviewTransformed>
      {/* Rendering the element in a portal to determine its actual size */}
      {createPortal(
        <S.ComponentPreviewStretched>
          <ElementPreview
            ref={componentRectRef}
            componentId={componentId}
            isRoot
          />
        </S.ComponentPreviewStretched>,
        previewPortalContainer
      )}
    </S.ComponentPreviewWrapper>
  )
);
