import { DesignTokensParser, formatToAlias } from '@juxio/design-tokens';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { KeepStateOptions, SetValueConfig } from 'react-hook-form';
import { z } from 'zod';
import { CORE } from '@jux/types';
import {
  ALIAS_DESELECTION_TEXT,
  ALIAS_PLACEHOLDER_TEXT,
  getAliasResolutionPath,
  getCircularAliasFilter,
  getSelectFieldPathOptions,
} from '@jux/ui/components/tokens/token-drawer/forms/helpers';
import { useCurrentTokenSetData } from '@jux/ui/components/tokens/token-drawer/forms/helpers/useCurrentTokenSetData';
import { useZodForm } from '@jux/ui/hooks/useZodForm';
import { toast } from '@jux/ui/toast';
import { bulkUpsertTokenInputSchema } from '@jux/ui/trpc/validations';
import { createPath } from '@jux/ui/utils/tokensPath';

const KEEP_STATE_OPTIONS: KeepStateOptions = {
  keepDefaultValues: true,
  keepDirty: true,
  keepIsSubmitted: true,
  keepTouched: true,
  keepIsValid: true,
  keepSubmitCount: true,
  keepDirtyValues: true,
  keepErrors: true,
  keepValues: true,
};

const SET_FIELD_VALUE_OPTIONS: SetValueConfig = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true,
};

export type ParseValueFn = (
  value: any
) => z.infer<typeof bulkUpsertTokenInputSchema>['value'];

const filterByPrefix = ({
  paths,
  prefix,
}: {
  paths: Array<string>;
  prefix: string;
}) => paths.filter((p) => p.startsWith(prefix));

export const useFormValueAlias = <T extends ReturnType<typeof useZodForm>>({
  form,
  prefix,
  isCoreTokenSet,
  isCreatedFromDDP,
  parseValue = (v) => v,
}: {
  form: T;
  prefix: string;
  isCoreTokenSet: boolean;
  isCreatedFromDDP?: boolean;
  parseValue?: ParseValueFn;
}) => {
  const aliasValue = form.watch('alias');
  const groupPathValue = form.watch('groupPath');
  const tokenNameValue = form.watch('tokenName');
  const isGroupCoreSet = groupPathValue?.startsWith(CORE);
  const isAliasCoreSet = aliasValue?.startsWith(CORE);

  const isAliasToken = Boolean(
    aliasValue &&
      aliasValue !== ALIAS_DESELECTION_TEXT &&
      aliasValue !== ALIAS_PLACEHOLDER_TEXT
  );

  const { existingTokenPaths, tokens } = useCurrentTokenSetData();

  const circularAliasFilter = useMemo(
    () =>
      getCircularAliasFilter({
        alias: createPath([groupPathValue, tokenNameValue]),
        rawTokenSet: tokens,
      }),
    [groupPathValue, tokenNameValue, tokens]
  );

  const parseAliasOptions = useCallback(
    (tokenPaths: Array<string>) =>
      isAliasToken ? [ALIAS_DESELECTION_TEXT, ...tokenPaths] : tokenPaths,
    [isAliasToken]
  );

  const initialExistingPaths = filterByPrefix({
    paths: existingTokenPaths,
    prefix,
  }).filter(circularAliasFilter);

  const [existingPaths, setExistingPaths] = useState(initialExistingPaths);

  const initialExistingCorePaths =
    isCoreTokenSet || !isCreatedFromDDP
      ? filterByPrefix({
          paths: existingTokenPaths,
          prefix: createPath([CORE, prefix]),
        }).filter(circularAliasFilter)
      : [];

  const [existingCorePaths, setExistingCorePaths] = useState(
    initialExistingCorePaths
  );

  const { hasPaths: hasAliases, optionsProps: aliasOptionsProps } = useMemo(
    () =>
      getSelectFieldPathOptions({
        existingPaths,
        existingCorePaths,
        forceCoreOnly: (isCoreTokenSet && !isCreatedFromDDP) || isGroupCoreSet,
        parseOptions: parseAliasOptions,
      }),
    [
      existingPaths,
      existingCorePaths,
      isCoreTokenSet,
      isCreatedFromDDP,
      isGroupCoreSet,
      parseAliasOptions,
    ]
  );

  // effect to reset form values whenever alias is changed
  useEffect(() => {
    // when deselected - reset the alias field
    if (aliasValue === ALIAS_DESELECTION_TEXT) {
      // just setting values doesn't work without the reset call,
      // and neither the reset call works without calling setValue per field
      form.setValue('alias', '', SET_FIELD_VALUE_OPTIONS);
      // reset while keeping previous state, so the alias values would remain untouched
      form.reset({ alias: '' }, KEEP_STATE_OPTIONS);
    }
    // otherwise, when alias is selected - reset the value field
    else if (aliasValue && isAliasToken) {
      const parser = new DesignTokensParser(tokens);
      const tokenValue = parser.getValue(aliasValue);
      const tokenRawValue = parser.getTokenRawValue(aliasValue);
      const value = parseValue(
        typeof tokenValue === 'object' ? tokenRawValue : tokenValue
      );

      // set the value field to the value of the selected alias
      form.setValue('value', value, SET_FIELD_VALUE_OPTIONS);

      // reset while keeping previous state, otherwise it doesn't work properly
      form.reset({ value }, KEEP_STATE_OPTIONS);
    }

    // if the group path is core, and the alias is not core, reset the alias
    if (isGroupCoreSet && !isAliasCoreSet) {
      form.setValue('alias', '', SET_FIELD_VALUE_OPTIONS);
      form.reset({ alias: '' }, KEEP_STATE_OPTIONS);
    }
  }, [
    aliasValue,
    form,
    isAliasToken,
    isGroupCoreSet,
    isAliasCoreSet,
    parseValue,
    tokens,
  ]);

  const validateCircularAlias = useCallback(() => {
    const aliasResolutionPath = getAliasResolutionPath({
      alias: formatToAlias(form.getValues().alias),
      rawTokenSet: tokens,
    });
    if (
      aliasResolutionPath.includes(
        createPath([form.getValues().groupPath, form.getValues().tokenName])
      )
    ) {
      const message = 'Circular alias is not allowed.\nAlias field cleared.';
      form.setError('alias', {
        message,
      });
      toast.error(message, { autoClose: false });
      form.resetField('alias', { keepError: true });

      setExistingPaths(
        filterByPrefix({
          paths: existingTokenPaths,
          prefix,
        }).filter(circularAliasFilter)
      );

      setExistingCorePaths(
        isCoreTokenSet || !isCreatedFromDDP
          ? filterByPrefix({
              paths: existingTokenPaths,
              prefix: createPath([CORE, prefix]),
            }).filter(circularAliasFilter)
          : []
      );

      return false;
    }

    return true;
  }, [
    circularAliasFilter,
    existingTokenPaths,
    form,
    isCoreTokenSet,
    isCreatedFromDDP,
    prefix,
    tokens,
  ]);

  return {
    aliasOptionsProps,
    aliasValue:
      !aliasValue || aliasValue === ALIAS_DESELECTION_TEXT
        ? ALIAS_PLACEHOLDER_TEXT
        : aliasValue,
    hasAliases,
    isAliasToken,
    validateCircularAlias,
  };
};
