import { FC, useEffect } from 'react';
import { useStore } from '@jux/canjux/core';
import { Loading, Modal, Popper, Sidebar } from '@jux/ui/components';
import {
  useEnterRoomEffect,
  useLiveblocksRoom,
} from '@jux/ui/components/editor/hooks';
import { useAnalytics } from '@jux/ui/hooks';
import { AnalyticsEvents } from '@jux/data-access/analytics/analytics-events';
import { Layout } from '../layouts/Layout';
import { NavigationToken } from './NavigationToken';
import { TokenDrawer } from './token-drawer/base';
import { TokenSetsLayout } from './TokenSetsLayout';

export const TokensLayout: FC = () => {
  const { room } = useLiveblocksRoom();
  const { pageLoadedEvent, trackEvent } = useAnalytics();

  const { isLoading } = useStore((s) => ({
    isLoading: s.liveblocks.isStorageLoading,
  }));

  useEffect(() => {
    pageLoadedEvent(AnalyticsEvents.TOKENS_PAGE, window.location.pathname);
    trackEvent({
      eventName: AnalyticsEvents.TOKENS_PAGE_LOADED,
    });
  }, [room.roomId, pageLoadedEvent, trackEvent]);

  useEnterRoomEffect(room.roomId);

  return (
    <>
      <Loading visible={isLoading} />
      <TokenDrawer hideBackdrop />
      <Modal />
      <Popper />
      <Layout
        sidebar={<Sidebar />}
        navbar={<NavigationToken />}
        content={<TokenSetsLayout />}
      />
    </>
  );
};
