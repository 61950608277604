import { useCallback } from 'react';
import { useStoreActions } from '@jux/canjux/core';
import { DropPosition, DropPositionType } from '../types';
import { ReorderItem } from './ReorderItem.interface';
import { useLayersPanelStateValue } from './useLayersPanelStateValue';
import { useSetLayersPanelState } from './useSetLayersPanelState';

export const useReorderLayersPanel = () => {
  const setState = useSetLayersPanelState();
  const {
    reorderDraggedItem,
    reorderHoveredItemDropZonePosition: dropPosition,
  } = useLayersPanelStateValue();
  const {
    commonActions: { moveNodes },
  } = useStoreActions();

  const setReorderDraggedItem = useCallback(
    (item: ReorderItem | undefined) => {
      setState((prevState) => ({
        ...prevState,
        reorderDraggedItem: item,
      }));
    },
    [setState]
  );

  const setReorderHoveredItem = useCallback(
    (item: ReorderItem | undefined) => {
      setState((prevState) => ({
        ...prevState,
        reorderHoveredItem: item,
      }));
    },
    [setState]
  );

  const setReorderHoveredItemDropZonePosition = useCallback(
    (position: DropPositionType) => {
      setState((prevState) => ({
        ...prevState,
        reorderHoveredItemDropZonePosition: position,
      }));
    },
    [setState]
  );

  const moveTo = useCallback(
    (draggedItem: ReorderItem, targetId?: string | null, dropIndex = 0) => {
      const isDraggingToRoot = targetId === null;
      const { position, absolutePosition, id: draggedId } = draggedItem;

      // to make sure we bring only acceptable values in case we're trying to drop before the first item
      let newIndex = dropIndex;
      if (dropIndex < 0) {
        newIndex = 0;
      }

      moveNodes({
        sourceNodeIds: [draggedId],
        targetNodeId: targetId,
        targetIndex: newIndex,
        targetPosition: {
          xSnapped: isDraggingToRoot
            ? absolutePosition?.x ?? 0
            : position?.x ?? 0,
          ySnapped: isDraggingToRoot
            ? absolutePosition?.y ?? 0
            : position?.y ?? 0,
        },
      });
    },
    [moveNodes]
  );

  const handleDropReorderItem = useCallback(
    (targetItem: ReorderItem) => {
      const draggedItem = reorderDraggedItem;
      setReorderDraggedItem(undefined);
      setReorderHoveredItem(undefined);

      if (
        !draggedItem ||
        !targetItem ||
        !dropPosition ||
        !draggedItem.draggable
      ) {
        return;
      }

      const dropIndex = targetItem.relativeIndex ?? 0;

      switch (dropPosition) {
        case DropPosition.above:
          moveTo(draggedItem, targetItem.parentId, dropIndex);
          break;
        case DropPosition.below:
          moveTo(draggedItem, targetItem.parentId, dropIndex + 1);
          break;
        case DropPosition.inside:
          moveTo(draggedItem, targetItem.id, 0);
          break;
      }
    },
    [
      dropPosition,
      moveTo,
      reorderDraggedItem,
      setReorderDraggedItem,
      setReorderHoveredItem,
    ]
  );

  return {
    setReorderDraggedItem,
    setReorderHoveredItem,
    setReorderHoveredItemDropZonePosition,
    handleDropReorderItem,
  };
};
