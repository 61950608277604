import sortBy from 'lodash/sortBy';
import {
  DesignToken,
  DesignTokens,
  DesignTokensParser,
  DesignTokenValue,
  getAliasMatches,
  isAlias,
  SupportedTokenTypes,
  supportedTokenTypes,
} from '@juxio/design-tokens';
import { createPath } from '@jux/ui/utils/tokensPath';
import { CORE } from '@jux/types';

type Token = {
  name: string;
  path: string;
  groupPath: string;
  originalValue: DesignTokenValue;
  value: DesignTokenValue;
  type: SupportedTokenTypes;
  isAlias: boolean;
  hasAlias: boolean;
  aliasName: string | undefined;
  description: DesignToken['$description'];
  aliasInside: boolean;
};

export type GroupedTokens = {
  name: string;
  path: string;
  hasAliasedTokens: boolean;
  totalTokens: number;
  description: string;
  isLocked: boolean;

  tokens: Array<Token>;
  groups: Array<GroupedTokens>;
};

export type GroupedTokensByType = Record<SupportedTokenTypes, GroupedTokens>;

const sortByCreatedAt = <T extends Record<any, any>>(tokens: T) =>
  sortBy(Object.entries<T>(tokens), ([, t]) => t.$extensions?.createdAt);

const recursivelyFindIfGroupHasAliasedTokens = ({
  tokens,
  groups,
}: {
  tokens: Array<Token>;
  groups: Array<GroupedTokens>;
}): boolean =>
  tokens.some(({ hasAlias }) => hasAlias) ||
  groups.some((group) =>
    recursivelyFindIfGroupHasAliasedTokens({
      tokens: group.tokens,
      groups: group.groups,
    })
  );

const getTotalTokensByGroupPath = ({
  groupPath,
  valuesMap,
}: {
  groupPath: string;
  valuesMap: Record<string, DesignTokenValue>;
}) =>
  Object.keys(valuesMap).filter((tokenPath) =>
    tokenPath.startsWith(`${groupPath}.`)
  ).length;

const getTokensFromGroupData = ({
  groupData,
  path,
  aliasesMap,
  parser,
  isCoreTokenSet,
}: {
  groupData: DesignToken;
  path: Array<string>;
  aliasesMap: Record<string, Array<string>>;
  parser: DesignTokensParser;
  isCoreTokenSet: boolean;
}) =>
  sortByCreatedAt(groupData)
    .filter(([, value]) => Boolean(value.$type))
    .map(([property, value]): Token => {
      const tokenName = property;
      const { $value, $type, $description } = value;
      const tokenIsAlias = isAlias($value);
      const aliasName =
        (tokenIsAlias && getAliasMatches($value).valuePath) || '';

      const groupPath = createPath([
        isCoreTokenSet ? CORE : undefined,
        ...path,
      ]);
      const tokenPath = createPath([groupPath, tokenName]);

      return {
        name: tokenName,
        path: tokenPath,
        groupPath,
        originalValue: $value,
        description: $description,
        value:
          tokenIsAlias && aliasName
            ? parser.getTokenRawValue(aliasName, true)
            : $value,
        type: $type as SupportedTokenTypes,
        isAlias: tokenIsAlias,
        aliasInside:
          typeof $value === 'object' &&
          Object.values($value).some(
            (field) => typeof field === 'string' && isAlias(field)
          ),
        hasAlias: aliasesMap[tokenPath]?.length > 0,
        aliasName,
      };
    });

const recursivelyGetGroupsData = ({
  groupData,
  groupName,
  path,
  aliasesMap,
  parser,
  isCoreTokenSet,
  valuesMap,
}: {
  groupData: DesignTokens;
  groupName: string;
  path: Array<string>;
  aliasesMap: Record<string, Array<string>>;
  parser: DesignTokensParser;
  isCoreTokenSet: boolean;
  valuesMap: Record<string, DesignTokenValue>;
}): GroupedTokens => {
  const tokens = getTokensFromGroupData({
    groupData,
    path,
    aliasesMap,
    parser,
    isCoreTokenSet,
  });

  const groups = sortByCreatedAt(groupData)
    // skip properties such as $description, etc.
    .filter(([name]) => !name.startsWith('$'))
    // skip tokens (if they have $type, they're not groups)
    .filter(([, value]) => !value.$type)
    .map(([name, value]) =>
      recursivelyGetGroupsData({
        groupData: value,
        groupName: name,
        aliasesMap,
        path: [...path, name],
        parser,
        isCoreTokenSet,
        valuesMap,
      })
    )
    .filter(Boolean) as Array<GroupedTokens>;

  const groupPath = createPath([isCoreTokenSet ? CORE : undefined, ...path]);
  return {
    name: groupName,
    path: groupPath,
    hasAliasedTokens: recursivelyFindIfGroupHasAliasedTokens({
      tokens,
      groups,
    }),
    totalTokens: getTotalTokensByGroupPath({ valuesMap, groupPath }),
    description: (groupData as DesignToken).$description || '',
    isLocked: (groupData as DesignToken).$extensions?.isLocked || false,
    tokens: tokens,
    groups,
  };
};

// We're doing this here instead of in the parser to keep
// the parser as simple as possible, and this structure is
// tightly-coupled to how the frontend comprises token sets
export const getGroupedTokens = ({
  aliasesMap,
  tokens,
  isCoreTokenSet,
  valuesMap,
  parser,
}: {
  tokens: DesignTokens;
  aliasesMap: Record<string, Array<string>>;
  valuesMap: Record<string, DesignTokenValue>;
  isCoreTokenSet: boolean;
  parser: DesignTokensParser;
}) =>
  supportedTokenTypes.reduce((acc, tokenType) => {
    acc[tokenType] = recursivelyGetGroupsData({
      groupData: tokens[tokenType],
      groupName: tokenType,
      path: [tokenType],
      aliasesMap,
      parser,
      isCoreTokenSet,
      valuesMap,
    });

    return acc;
  }, {} as GroupedTokensByType);
