import type { Draft as WritableDraft } from 'mutative';
import {
  CanjuxState,
  getVariantDisplayName,
  setLayersData,
} from '@jux/canjux/core';
import { ComponentSourceData, NodeType } from '@jux/data-entities';
import { getMatrixGridColumnsAndRows } from './getVariantsGroupWrapperData';
import { EnumPropValue, VariantsValues } from '@jux/types';
import { deleteNode } from '../wip';

export const updateMatrixNodesOnDeleteProp = ({
  sourceComponentId,
  propName,
  defaultValue,
  state,
}: {
  sourceComponentId: string;
  propName: string;
  defaultValue: EnumPropValue;
  state: WritableDraft<CanjuxState>;
}) => {
  const matrixRootNodes = Object.values(state.components).filter(
    (component) =>
      component?.sourceComponentId === sourceComponentId &&
      component.type === NodeType.VARIANTS_GROUP
  ) as ComponentSourceData[];

  if (!matrixRootNodes.length) return;

  const sourceComponent = state.components[
    sourceComponentId
  ] as ComponentSourceData;
  const { gridTemplateColumns, gridTemplateRows } =
    getMatrixGridColumnsAndRows(sourceComponent);

  for (const matrixRootNode of matrixRootNodes) {
    // Update the grid template columns and rows
    matrixRootNode.styles.root.gridTemplateColumns = gridTemplateColumns;
    matrixRootNode.styles.root.gridTemplateRows = gridTemplateRows;

    matrixRootNode.children = matrixRootNode.children.filter((childId) => {
      const child = state.components[childId];
      if (!child) {
        return false;
      }

      if (child.config.props[propName] !== defaultValue) {
        // Delete this variant
        deleteNode({ nodeId: childId, state });
        return false;
      }

      delete child.config.props[propName];
      child.displayName = getVariantDisplayName({
        interactiveState: child.config.interactiveState ?? 'default',
        variantsConfig: sourceComponent.config.variants ?? [],
        variantValues: child.config.props as VariantsValues,
      });
      return true;
    });
  }

  setLayersData(state);
};

export const updateMatrixNodesOnDeletePropValue = ({
  sourceComponentId,
  propName,
  deletedValue,
  state,
}: {
  sourceComponentId: string;
  propName: string;
  deletedValue: EnumPropValue;
  state: WritableDraft<CanjuxState>;
}) => {
  const matrixRootNodes = Object.values(state.components).filter(
    (component) =>
      component?.sourceComponentId === sourceComponentId &&
      component.type === NodeType.VARIANTS_GROUP
  ) as ComponentSourceData[];

  if (!matrixRootNodes.length) return;

  const sourceComponent = state.components[
    sourceComponentId
  ] as ComponentSourceData;
  const { gridTemplateColumns, gridTemplateRows } =
    getMatrixGridColumnsAndRows(sourceComponent);

  for (const matrixRootNode of matrixRootNodes) {
    // Update the grid template columns and rows
    matrixRootNode.styles.root.gridTemplateColumns = gridTemplateColumns;
    matrixRootNode.styles.root.gridTemplateRows = gridTemplateRows;

    matrixRootNode.children = matrixRootNode.children.filter((childId) => {
      const child = state.components[childId];
      if (!child) {
        return false;
      }

      if (child.config.props[propName] !== deletedValue) {
        return true;
      }

      // Delete this variant
      deleteNode({ nodeId: childId, state });
      return false;
    });
  }

  setLayersData(state);
};
