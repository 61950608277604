import { LayerNodeData } from '@jux/canjux/core';
import { NodeType, XYPosition } from '@jux/data-entities';
import { isVisible } from '@jux/ui/components/editor/utils/isVisible';

export type LayersPanelItem = {
  hasExpander: boolean;
  id: string;
  indentLevel: number;
  isRoot: boolean;
  label: string;
  path: string;
  objectType: NodeType;
  tagName: string;
  isImmutable?: boolean;
  parentId?: string | null;
  isHidden: boolean;
  isParentHidden: boolean;
  isLibraryComponent: boolean;
  // TODO: check if we can identify if an instance's source component
  // is a library component without using a difficult calculation
  hasAncestorLibraryComponent?: boolean;
  isContainsLocalComponentInstance: boolean;
  rootNodeType: NodeType;
  isContainer: boolean; // if true will allow to drop inside the node, otherwise will allow to drop before or after the node
  droppable: boolean; //if true it will render the dropzone and the line
  draggable: boolean; //if true it will allow to drag the node
  sourceComponentId?: string;
  sourceComponentType?: NodeType;
  relativeIndex?: number;
  position?: XYPosition;
  absolutePosition: XYPosition;
  children: Array<string>;
};

const SEPARATOR = '.';

// This is used to transform nodes data into layers panel items data
export const getLayersItemsRecursive = (
  {
    nodes,
    rootNodeIds,
  }: {
    nodes?: Record<string, LayerNodeData>;
    rootNodeIds?: Array<string>;
  } // TODO: replace with the new data structure
): Map<string, LayersPanelItem> => {
  const layersResult = new Map<string, LayersPanelItem>();

  if (
    !rootNodeIds ||
    rootNodeIds.length === 0 ||
    !nodes ||
    Object.keys(nodes).length === 0
  ) {
    return layersResult;
  }

  const traverseTree = ({
    hasAncestorLibraryComponent,
    id,
    index,
    isParentHidden,
    parentPath = '',
  }: {
    id: string;
    index: number;
    isParentHidden: boolean;
    hasAncestorLibraryComponent?: boolean;
    parentPath?: string;
  }) => {
    const layerNodeData = nodes[id];

    if (!layerNodeData) {
      return;
    }

    const isNodeVisible = isVisible(id);

    // Check if the current node is a root node based on the parent path
    const isRoot = !layerNodeData.parentId;

    // Calculate the indent level based on the parent path
    const indentLevel = isRoot ? 0 : parentPath.split(SEPARATOR).length;

    // Calculate the current path based on the parent path
    const currentPath = isRoot ? id : `${parentPath}${SEPARATOR}${id}`;

    const nodeDimensions = layerNodeData.dimensions;
    const nodeProperties = layerNodeData.properties;

    if (!layerNodeData || !nodeDimensions || !nodeProperties) {
      return;
    }

    const isDraggable = nodeProperties.isDraggable;

    layersResult.set(id, {
      id,
      indentLevel,
      isRoot,
      // TODO: Refine the label logic and move to separate function
      label: layerNodeData.displayName || layerNodeData.tagName || id,
      path: currentPath,
      sourceComponentId: layerNodeData?.sourceComponentId,
      hasExpander: Boolean(layerNodeData.children.length),
      objectType: layerNodeData.type,
      tagName: layerNodeData.tagName as string,
      isContainsLocalComponentInstance:
        layerNodeData.isContainsLocalComponentInstance,
      rootNodeType: layerNodeData.rootNodeType,
      parentId: layerNodeData.parentId,
      relativeIndex: index,
      isContainer: nodeProperties.isContainer,
      isHidden: nodeProperties.isHidden || !isNodeVisible,
      isParentHidden,
      absolutePosition: nodeDimensions.positionAbsolute,
      position: layerNodeData.position,
      droppable: nodeProperties.isContainer,
      draggable: isDraggable,
      children: layerNodeData.children,
      isLibraryComponent: layerNodeData.isNodeInLibrary,
      sourceComponentType: layerNodeData.sourceComponentType,
      hasAncestorLibraryComponent,
    });

    layerNodeData.children.forEach((childId, childIndex) =>
      traverseTree({
        hasAncestorLibraryComponent:
          hasAncestorLibraryComponent ?? layerNodeData.isNodeInLibrary,
        id: childId,
        index: childIndex,
        isParentHidden: isParentHidden || nodeProperties.isHidden,
        parentPath: currentPath,
      })
    );
  };

  // Iterate over the nodes and add them to the result map,
  // then call the function recursively for each node's children
  rootNodeIds.forEach((treeNodeId, nodeIndex) => {
    traverseTree({
      id: treeNodeId,
      index: nodeIndex,
      isParentHidden: false,
    });
  });

  // Once we finish iterating over the nodes, return the result map
  return layersResult;
};
