import { CanjuxState } from '@jux/canjux/core';
import { createSelector } from 'reselect';

export const selectNodePosition = (id: string) =>
  createSelector(
    (state: CanjuxState) =>
      state.canvases[state.currentCanvasName].nodes[id]?.position ?? {
        x: 0,
        y: 0,
      },
    (position) => position
  );
