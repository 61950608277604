import type { Draft as WritableDraft } from 'mutative';
import { CanjuxState } from '@jux/canjux/core';
import { addStorageNode } from '../../../store.changes.utils';
import { createInstanceNode } from './createInstanceNode';
import { NodeType, isDynamicSlotChild } from '@jux/data-entities';
import { duplicateNode } from './duplicateNode';

/**
 * Recursively creates instance nodes for a given component and its children on the new parent.
 * @param componentId - The ID of the component.
 * @param parentId - The ID of the parent component.
 * @param targetIndex - The index at which the instance node should be inserted.
 * @param state - The draft of the store.
 * @returns The ID of the newly created instance node.
 */
const recursiveCreateInstanceNodes = ({
  componentId,
  parentId,
  state,
}: {
  componentId: string;
  parentId: string;
  state: WritableDraft<CanjuxState>;
}): string => {
  const { components } = state;

  const component = components[componentId];
  const isInsideDynamicSlot = isDynamicSlotChild({
    componentId,
    components: state.components,
  });

  let newNodeId = '';
  if (isInsideDynamicSlot) {
    newNodeId = duplicateNode({
      sourceComponentId: componentId,
      targetNodeId: parentId,
      targetCanvasName: state.currentCanvasName,
      state,
    });
  } else {
    newNodeId = createInstanceNode({
      sourceComponentId: componentId,
      parentId,
      state,
    });
  }

  for (const childId of component.children) {
    const newChildId = recursiveCreateInstanceNodes({
      componentId: childId,
      parentId: newNodeId,
      state,
    });

    // we don't want to add children to dynamic slots (because they are duplicated and will be added by duplicateNode function)
    if (component.type !== NodeType.DYNAMIC_SLOT) {
      components[newNodeId].children.push(newChildId);
    }
  }

  return newNodeId;
};

/**
 * Adds instance nodes tree of an inserted node to all instances of the root component.
 * This function updates other using components + canvases.
 * @param sourceNodeId - The ID of the source root node - tree of nodes.
 * @param targetNodeId - The ID of the target node.
 * @param targetIndex - The index of insertion on the target node children.
 * @param state - The draft of the store.
 */
export const addInstanceNodes = ({
  sourceNodeId,
  targetNodeId,
  targetIndex,
  state,
}: {
  sourceNodeId: string;
  targetIndex: number;
  targetNodeId: string;
  state: WritableDraft<CanjuxState>;
}) => {
  const { components } = state;

  const nodesToUpdate = Object.values(components).filter(
    (component) =>
      (component.type === NodeType.INSTANCE ||
        component.type === NodeType.VARIANT_INSTANCE) &&
      component?.sourceComponentId === targetNodeId
  );

  for (const nodeToUpdate of nodesToUpdate) {
    // we create a component tree of instances of the source node and put them under each of the instances of the target node
    const newInstanceId = recursiveCreateInstanceNodes({
      componentId: sourceNodeId,
      parentId: nodeToUpdate.id,
      state,
    });

    addStorageNode(nodeToUpdate.children, newInstanceId, targetIndex);

    // set 2nd level and deeper nested instances
    addInstanceNodes({
      sourceNodeId: newInstanceId,
      targetNodeId: nodeToUpdate.id,
      targetIndex,
      state,
    });
  }
};
