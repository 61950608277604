import { useEffect } from 'react';
import {
  getTokenSetsByCreatedAt,
  storeApi,
  useStore,
  useStoreActions,
} from '@jux/canjux/core';

export const useCurrentOrgEditorTokenSetId = () => {
  const {
    tokenSetsActions: { setEditorTokenSet },
  } = useStoreActions();
  const tokenSets = useStore((s) => s.tokenSets);
  const editorTokenSetId = useStore((s) => s.editorTokenSetId);
  const oldestTokenSetId = getTokenSetsByCreatedAt(tokenSets)[0]?.id;

  useEffect(() => {
    if (
      oldestTokenSetId &&
      (!editorTokenSetId ||
        !Object.values(storeApi.getState().tokenSets).some(
          ({ id }) => id === editorTokenSetId
        ))
    ) {
      setEditorTokenSet({
        tokenSetId: oldestTokenSetId,
      });
    }
  }, [editorTokenSetId, setEditorTokenSet, oldestTokenSetId]);

  return { currentOrgTokenSetId: editorTokenSetId || oldestTokenSetId };
};
