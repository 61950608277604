import {
  CompositeTokenTypes,
  compositeTokenTypes,
  DesignTokenTypeEnum,
  formatToAlias,
} from '@juxio/design-tokens';
import { ReplacePaths } from '@jux/canjux/core';
import { CORE } from '@jux/types';
import { createPath } from './tokensPath';
import { compareStrValues } from './compareStrValues';

const TOKEN_NAME_PATTERN = '[a-zA-Z0-9_:+-]+';

// must start with { and end with }
// each token can be separated by .
// after every . there must be a token
// eg. {tokens.button.primary}
const TOKEN_PATH_PATTERN = new RegExp(
  `^{(${TOKEN_NAME_PATTERN})((.${TOKEN_NAME_PATTERN})+)?}$`
);

export const isValidTokenPath = (tokenPath: string) =>
  tokenPath.match(TOKEN_PATH_PATTERN);

const getCompositeTokenTypeByTokenPath = (
  tokenPath: string
): CompositeTokenTypes | null => {
  let tokenType = tokenPath.split('.')[0];
  if (compareStrValues(tokenType, CORE)) {
    tokenType = tokenPath.split('.')[1];
  }

  for (const compositeTokenType of compositeTokenTypes) {
    if (compositeTokenType === tokenType) {
      return compositeTokenType;
    }
  }

  return null;
};

const createTypographyPaths = (
  tokenPath: string
): {
  fontFamily: string;
  fontSize: string;
  fontWeight: string;
  letterSpacing: string;
  lineHeight: string;
} => {
  const fontFamily = createPath([tokenPath, 'fontFamily']);
  const fontSize = createPath([tokenPath, 'fontSize']);
  const fontWeight = createPath([tokenPath, 'fontWeight']);
  const letterSpacing = createPath([tokenPath, 'letterSpacing']);
  const lineHeight = createPath([tokenPath, 'lineHeight']);

  return {
    fontFamily,
    fontSize,
    fontWeight,
    letterSpacing,
    lineHeight,
  };
};

export const prepareFindTokenPaths = (tokenPath: string): Array<string> => {
  const compositeTokenType = getCompositeTokenTypeByTokenPath(tokenPath);

  if (!compositeTokenType) {
    return [formatToAlias(tokenPath)];
  }

  switch (compositeTokenType) {
    case DesignTokenTypeEnum.typography: {
      const typographyPaths = createTypographyPaths(tokenPath);

      return [
        formatToAlias(typographyPaths.fontFamily),
        formatToAlias(typographyPaths.fontSize),
        formatToAlias(typographyPaths.fontWeight),
        formatToAlias(typographyPaths.letterSpacing),
        formatToAlias(typographyPaths.lineHeight),
      ];
    }
  }
};

export const prepareReplaceTokenPaths = ({
  oldPath,
  newPath,
}: {
  oldPath: string;
  newPath: string;
}): Array<ReplacePaths> => {
  const compositeTokenType = getCompositeTokenTypeByTokenPath(oldPath);

  if (!compositeTokenType) {
    return [
      {
        oldPath: formatToAlias(oldPath),
        newPath: formatToAlias(newPath),
      },
    ];
  }

  switch (compositeTokenType) {
    case DesignTokenTypeEnum.typography: {
      const oldTypographyPaths = createTypographyPaths(oldPath);
      const newTypographyPaths = createTypographyPaths(newPath);

      return [
        {
          oldPath: formatToAlias(oldTypographyPaths.fontFamily),
          newPath: formatToAlias(newTypographyPaths.fontFamily),
        },
        {
          oldPath: formatToAlias(oldTypographyPaths.fontSize),
          newPath: formatToAlias(newTypographyPaths.fontSize),
        },
        {
          oldPath: formatToAlias(oldTypographyPaths.fontWeight),
          newPath: formatToAlias(newTypographyPaths.fontWeight),
        },
        {
          oldPath: formatToAlias(oldTypographyPaths.letterSpacing),
          newPath: formatToAlias(newTypographyPaths.letterSpacing),
        },
        {
          oldPath: formatToAlias(oldTypographyPaths.lineHeight),
          newPath: formatToAlias(newTypographyPaths.lineHeight),
        },
      ];
    }
  }
};

// must start with { and end with .
// each token can be separated by .
// after every . there must be a token - other than the last one
// eg. {tokens.button.primary.
const TOKEN_GROUP_PATH_START_PATTERN = new RegExp(
  `^{(${TOKEN_NAME_PATTERN})((.${TOKEN_NAME_PATTERN})+)?[.]$`
);

export const isValidGroupPath = (groupPath: string) =>
  groupPath.match(TOKEN_GROUP_PATH_START_PATTERN);

// in order to find and/or replace a group path in a stringified styles object
// we need to format the group path to match the stringified styles object
// 1. 'color.primary' > '{color.primary}'
// 2. '{color.primary}' > '{color.primary.'
export const prepareGroupPathForStringifyActions = (groupPath: string) =>
  formatToAlias(groupPath).replace(/}$/, '.');

// custom validator util function to make sure that
// the value doesn't have alias characters in it - '{' & '}'
export const hasAliasCharacters = (value: string) =>
  value.match(/[{}]/) !== null;
