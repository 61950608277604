import { selectedNodeComponent } from '@jux/canjux/core';
import { createSelector } from 'reselect';
import { DEFAULT_STATE } from '@jux/types';

export const selectedNodeInteractiveState = createSelector(
  [(state) => selectedNodeComponent(state)],
  (selectedComponent) => {
    return selectedComponent && selectedComponent.config?.interactiveState
      ? selectedComponent.config?.interactiveState
      : DEFAULT_STATE;
  }
);
