export const PLACEHOLDER_PSEUDO_ELEMENT = '&::placeholder';

export const DEFAULT_TRIGGER_WRAPPER_STYLES = {
  // We always need to put this on trigger wrapper elements in order for the trigger boarder to update correctly
  width: 'auto',
  height: 'auto',
  display: 'flex',
};

export const DEFAULT_PADDING = '24px';
export const DEFAULT_TEXT_SIZE = '16px';
