import { useCallback } from 'react';
import {
  getContextParentOfNode,
  getNodeChildrenRecursive,
  getRectOfNodes,
  getResolvedContextStyles,
  JuxRect,
  selectResolvedComponentProps,
  selectSourceComponent,
  storeApi,
  useStore,
} from '@jux/canjux/core';
import {
  ComponentConfigData,
  JuxComponentData,
  XYPosition,
} from '@jux/data-entities';
import logger from '@jux/ui-logger';
import {
  deserializeHTMLClipboardData,
  readClipboardRawData,
  serializeHTMLClipboardData,
} from '../utils';

const TEXT_PLAIN_NODE_TYPE = 'text/plain';
const HTML_NODE_TYPE = 'text/html';

export type CopiedNodeData = {
  /** The source canvas id that the copied node originated from */
  canvasName: string;
  /** The node & component unique identifier that is being copied */
  id: string;
  /** The source component that the copied node originated from or the component itself */
  sourceComponent: JuxComponentData;
  /** The children of the copied source node */
  sourceComponentChildren?: JuxComponentData[];
  /** If copied node was an instance and had props overrides */
  propsOverrides?: ComponentConfigData['props'];
  /** If copied node was a root node, save it's position on paste **/
  position?: XYPosition;
  /** If copied node was a child node, save it's index on copy **/
  childIndex?: number;
};

type NodesClipboardData = {
  nodes: CopiedNodeData[];
  nodesRect: JuxRect;
};

export const useClipboardStore = () => {
  const getSourceComponentById = useStore(selectSourceComponent);

  /**
   * Store details of a node to be copied to the clipboard.
   * @param nodeId the identifier of the node to be copied.
   */
  const writeToClipboard = useCallback(
    async ({
      nodeIds,
      canvasName,
    }: {
      nodeIds: string[];
      canvasName: string;
    }) => {
      const items: NodesClipboardData = {
        nodes: [],
        nodesRect: { x: 0, y: 0, width: 0, height: 0 },
      };

      for (const id of nodeIds) {
        const state = storeApi.getState();
        const nodeData = state.canvases[canvasName]?.nodes[id];
        const sourceComponent = getSourceComponentById(id);
        if (!sourceComponent) {
          return;
        }

        // If copied node source has context styles, apply them to the copied node root styles (we have to copy it with it's full styles)
        const sourceContextParent = getContextParentOfNode({
          components: state.components,
          nodeId: sourceComponent.id,
        });
        if (
          sourceContextParent &&
          sourceContextParent?.styles?.contextStyles &&
          sourceComponent?.styles?.root
        ) {
          const currentItemContextStyles = getResolvedContextStyles()({
            id: sourceComponent.id,
            parentContextId: sourceContextParent.id,
          });
          if (currentItemContextStyles) {
            currentItemContextStyles.forEach((contextStyle) => {
              sourceComponent.styles.root = {
                ...sourceComponent.styles.root,
                ...contextStyle,
              };
            });
          }
        }

        const propsOverrides = selectResolvedComponentProps({
          id,
          onlyVariantsProps: false, // we want to include other props like text content
        })(state);

        const sourceComponentChildren: JuxComponentData[] =
          getNodeChildrenRecursive({
            components: state.components,
            nodeId: id,
          }).reduce((acc: JuxComponentData[], childId) => {
            const child = state.components[childId];
            if (child) {
              acc.push(child);
            }
            return acc;
          }, []);

        const parentComponent = sourceComponent.parentId
          ? state.components[sourceComponent.parentId]
          : null;

        items.nodes.push({
          canvasName,
          id,
          sourceComponent,
          sourceComponentChildren,
          propsOverrides,
          position: nodeData?.position,
          childIndex: parentComponent
            ? parentComponent.children.indexOf(id)
            : undefined,
        });
      }

      if (!items.nodes.length) {
        return;
      }

      // sort copied nodes by their child index so that siblings are copied in the correct order
      items.nodes.sort((a, b) => {
        if (a.childIndex !== undefined && b.childIndex !== undefined) {
          return a.childIndex - b.childIndex;
        } else {
          return 0; // doesn't matter
        }
      });

      items.nodesRect = getRectOfNodes({
        nodeIds,
        nodeOrigin: storeApi.getState().nodeOrigin,
      });

      try {
        // Write the data to the clipboard
        await navigator.clipboard.write([
          serializeHTMLClipboardData<NodesClipboardData>(items),
        ]);
      } catch (error) {
        logger.error('Failed to copy to clipboard', error as object);
      }
    },
    [getSourceComponentById]
  );

  const readClipboardData = useCallback(async () => {
    const clipboardItems = await navigator.clipboard.read();

    const htmlClipboardData = await readClipboardRawData({
      type: HTML_NODE_TYPE,
      clipboardItems,
    });

    if (htmlClipboardData) {
      const nodes =
        deserializeHTMLClipboardData<NodesClipboardData>(htmlClipboardData);

      if (nodes) {
        return { nodes };
      }
    }

    // trying to parse text clipboard data
    const textClipboardData = await readClipboardRawData({
      type: TEXT_PLAIN_NODE_TYPE,
      clipboardItems,
    });

    return textClipboardData ? { text: textClipboardData } : {};
  }, []);

  return {
    writeToClipboard,
    readClipboardData,
  };
};
