import { FC, JSX, ElementRef, ForwardedRef, PropsWithChildren } from 'react';
import { ComponentProp } from './component-props';
import { ComponentTagName } from './componentTagName';

/**
 Component variants
 key: variant prop name
 value: list of possible values for the variant prop
 TODO: variantProps should support a wider range of value types, like a color, dimensions, etc.
 TODO2: Variants config should hold config to pass to children nodes on each variant combination
 */
export type VariantsConfig = Array<ComponentProp>;

/**
 * HTML element type
 */
export type HTMLBaseElement =
  | 'input'
  | 'button'
  | 'div'
  | 'p'
  | 'svg'
  | 'textarea'
  | 'label';

export type BaseHTMLAttributes<E extends HTMLBaseElement> =
  JSX.IntrinsicElements[E];

export const tagNameToNodeDisplayName = (tagName: ComponentTagName) => {
  return tagName.substring(3).toLowerCase();
};

export type ComponentElementType<
  T extends ComponentTagName = ComponentTagName
> = {
  JuxButton: ElementRef<'button'>;
  JuxCheckboxField: ElementRef<'div'>;
  JuxCheckbox: ElementRef<'button'>;
  JuxLabelTrigger: ElementRef<'label'>;
  JuxChip: ElementRef<'button'>;
  JuxDiv: ElementRef<'div'>;
  JuxDivider: ElementRef<'div'>;
  JuxIconButton: ElementRef<'button'>;
  JuxInput: ElementRef<'input'>;
  JuxTextField: ElementRef<'div'>;
  JuxRadio: ElementRef<'button'>;
  JuxSlot: ElementRef<'div'>;
  JuxSvg: ElementRef<'svg'>;
  JuxText: ElementRef<'p'>;
  JuxTextarea: ElementRef<'textarea'>;
  JuxTextareaField: ElementRef<'div'>;
  JuxToggle: ElementRef<'button'>;
}[T];

// TODO: find better way to resolve this type from ComponentData
export type ComponentHTMLProps<T extends ComponentTagName = ComponentTagName> =
  {
    JuxButton: BaseHTMLAttributes<'button'>;
    JuxCheckboxField: BaseHTMLAttributes<'div'>;
    JuxCheckbox: BaseHTMLAttributes<'button'>;
    JuxLabelTrigger: BaseHTMLAttributes<'label'>;
    JuxChip: BaseHTMLAttributes<'button'>;
    JuxDiv: BaseHTMLAttributes<'div'>;
    JuxDivider: BaseHTMLAttributes<'div'>;
    JuxIconButton: BaseHTMLAttributes<'button'>;
    JuxInput: BaseHTMLAttributes<'input'>;
    JuxTextField: BaseHTMLAttributes<'div'>;
    JuxRadio: BaseHTMLAttributes<'button'>;
    JuxSlot: BaseHTMLAttributes<'div'>;
    JuxSvg: BaseHTMLAttributes<'svg'>;
    JuxText: BaseHTMLAttributes<'p'>;
    JuxTextarea: BaseHTMLAttributes<'textarea'>;
    JuxTextareaField: BaseHTMLAttributes<'div'>;
    JuxToggle: BaseHTMLAttributes<'button'>;
  }[T];

type CheckboxCheckedState = boolean | 'indeterminate';

export type ComponentDataConfigProps<
  T extends ComponentTagName = ComponentTagName
> = {
  JuxButton: object;
  JuxCheckboxField: {
    checked?: CheckboxCheckedState;
    onCheckedChange?(checked: CheckboxCheckedState): void;
  };
  JuxCheckbox: {
    checked?: CheckboxCheckedState;
    onCheckedChange?(checked: CheckboxCheckedState): void;
  };
  JuxLabelTrigger: object;
  JuxChip: { selected?: boolean };
  JuxDiv: object;
  JuxDivider: object;
  JuxIconButton: object;
  JuxInput: { label?: string; placeholder?: string };
  JuxTextField: { label?: string; placeholder?: string };
  JuxRadio: {
    value?: string;
    checked?: boolean;
    onChange?: BaseHTMLAttributes<'input'>['onChange'];
    inputRef?: ForwardedRef<HTMLInputElement>;
  };
  JuxSlot: PropsWithChildren;
  JuxSvg: { content?: string };
  JuxText: { text?: string };
  JuxToggle: {
    checked?: boolean | 'indeterminate';
    onChange?: BaseHTMLAttributes<'input'>['onChange'];
    inputRef?: ForwardedRef<HTMLInputElement>;
  };
  JuxTextarea: { label?: string; placeholder?: string };
  JuxTextareaField: { label?: string; placeholder?: string };
}[T];

// TODO: maybe change the name to a name that is not already used in react
export type ComponentProps<T extends ComponentTagName = ComponentTagName> =
  ComponentHTMLProps<T> &
    ComponentDataConfigProps<T> & {
      rootElement?: string | FC<Omit<ComponentProps<T>, 'rootElement'>>;
    };

/**
 * This map is used to access the list of component tag names as reference instead of raw value.
 * This is useful for type safety and refactoring.
 */
export const COMPONENT_TAG_NAME: {
  [TagName in ComponentTagName]: TagName;
} = {
  JuxButton: 'JuxButton',
  JuxCheckboxField: 'JuxCheckboxField',
  JuxCheckbox: 'JuxCheckbox',
  JuxLabelTrigger: 'JuxLabelTrigger',
  JuxChip: 'JuxChip',
  JuxDiv: 'JuxDiv',
  JuxDivider: 'JuxDivider',
  JuxIconButton: 'JuxIconButton',
  JuxInput: 'JuxInput',
  JuxTextField: 'JuxTextField',
  JuxRadio: 'JuxRadio',
  JuxSlot: 'JuxSlot',
  JuxSvg: 'JuxSvg',
  JuxText: 'JuxText',
  JuxTextareaField: 'JuxTextareaField',
  JuxTextarea: 'JuxTextarea',
  JuxToggle: 'JuxToggle',
};

export const COMPONENT_ROOT_ELEMENT_TAG = {
  JuxButton: 'button',
  JuxCheckboxField: 'div',
  JuxCheckbox: 'button',
  JuxLabelTrigger: 'label',
  JuxChip: 'button',
  JuxDiv: 'div',
  JuxDivider: 'div',
  JuxIconButton: 'button',
  JuxInput: 'input',
  JuxRadio: 'button',
  JuxSlot: 'div',
  JuxSvg: 'svg',
  JuxText: 'p',
  JuxTextarea: 'textarea',
  JuxTextareaField: 'div',
  JuxToggle: 'button',
} as const;
