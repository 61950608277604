import { SupportedTokenTypes } from '@juxio/design-tokens';
import { GroupedTokensByType } from './getGroupedTokens';

export const hasGroups = ({
  groupsByType,
  currentTokenType,
}: {
  groupsByType: GroupedTokensByType | undefined;
  currentTokenType: SupportedTokenTypes;
}): boolean => Boolean(groupsByType?.[currentTokenType].groups.length);
