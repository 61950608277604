import { FC, useCallback } from 'react';
import { Typography, useOpenInviteMembersModal } from '@jux/ui/components';
import { useTheme } from '@jux/ui/components/common/mui';
import { SegmentEventSourcePage } from '@jux/data-access/analytics/track-events-properties';

export const InviteMemberToast: FC<{
  closeToast?: () => void;
}> = ({ closeToast }) => {
  const theme = useTheme();

  const { openInviteMembersModal } = useOpenInviteMembersModal(
    SegmentEventSourcePage.editor
  );

  const handleInviteClick = useCallback(() => {
    openInviteMembersModal();
    closeToast?.();
  }, [closeToast, openInviteMembersModal]);

  return (
    <Typography variant="label-s-regular">
      Congrats! Your first component is in your library!{' '}
      <span role="img" aria-label="party popper">
        🎉
      </span>
      <br />
      <span
        style={{
          textDecoration: 'underline',
          color: theme.drimz.palette.primary.main,
          cursor: 'pointer',
        }}
        onClick={handleInviteClick}
      >
        Invite
      </span>{' '}
      a developer to pull and use it in production.
    </Typography>
  );
};
