import { z } from 'zod';
import { capitalize } from 'lodash';
import { isAliasPattern } from '@juxio/design-tokens';
import logger from '@jux/ui-logger';
import { compareStrValues } from '@jux/ui/utils/compareStrValues';
import { toFirstUpperCase } from '@jux/ui/utils/toFirstUpperCase';
import { DEFAULT_UNIT, Units, UnitsType, UnitType } from '../useUnitState';
import { DimensionFieldOptions } from './useDimensionField.interface';

export const DEFAULT_DIMENSION_OPTIONS: Required<DimensionFieldOptions> = {
  supportAutoValue: false,
  supportMixedValue: false,
  supportNoneValue: false,
  supportNegativeValue: false,
  disablePercentValue: false,
  supportNegativePercentValue: false,
};

export const DEFAULT_DIMENSION_VALUE = '0';

export const ValidDimensionTextValue = {
  auto: 'Auto',
  none: 'None',
  mixed: 'Mixed',
} as const;

// TODO: support better schema for dimension field (e.g. support 'auto', 'none', 'inherit', 'initial' etc...)
export const dimensionFieldSchema = ({
  supportNoneValue,
  supportMixedValue,
  supportAutoValue,
  supportNegativeValue,
  supportNegativePercentValue,
  disablePercentValue,
}: DimensionFieldOptions) => {
  const multi = supportMixedValue ? '|mixed' : '';
  const none = supportNoneValue ? '|none' : '';
  const auto = supportAutoValue ? '|auto' : '';
  let negative = '';

  if (
    (supportNegativeValue && disablePercentValue) ||
    (!disablePercentValue && supportNegativePercentValue)
  ) {
    negative = '-?';
  }

  const supportedConstValues = `^((${negative}\\d+(\\.(\\d+))?)${auto}${none}${multi})$`;

  return z.string().regex(new RegExp(supportedConstValues, 'i')).optional();
};

export const roundFloatValue = (value: any) => {
  const roundedValue = parseFloat(parseFloat(value).toFixed(2));

  if (Number.isNaN(roundedValue)) {
    return value;
  }

  return roundedValue;
};

const parseStrToValidUnit = (unit: string | undefined): UnitType => {
  for (const validUnit of Units) {
    if (unit?.toLowerCase() === validUnit?.toLowerCase()) {
      return validUnit;
    }
  }
  return UnitsType.none;
};

export const isValidDimensionTextValue = (
  value: string | undefined,
  supportNoneValue: DimensionFieldOptions['supportNoneValue']
) => {
  for (const validValue of Object.values(ValidDimensionTextValue)) {
    if (!compareStrValues(value, validValue)) continue;

    if (
      compareStrValues(validValue, ValidDimensionTextValue.none) &&
      !supportNoneValue
    ) {
      return false;
    }

    return true;
  }

  return false;
};

export const formatDimensionDisplayValue = (value: string | undefined) => {
  if (value === undefined) return value;

  if (isValidDimensionTextValue(value, true)) {
    return toFirstUpperCase(value);
  }

  return value;
};

const parseValueStrToValidValueUnit = (
  value: string | undefined
): {
  value: string;
  unit: UnitType;
} => {
  if (!value) {
    return {
      value: DEFAULT_DIMENSION_VALUE,
      unit: DEFAULT_UNIT,
    };
  }

  const parsedValue = `${roundFloatValue(value)}`;
  const unitByParsedValue = value.replace(parsedValue, '');
  const parsedUnit = parseStrToValidUnit(unitByParsedValue);

  return {
    value: parsedValue,
    unit: parsedUnit ?? DEFAULT_UNIT,
  };
};

export const isMixedMultiDirectionValue = (
  value: string | undefined
): boolean => {
  const valueArray = value?.split(' ') ?? [];
  if (valueArray.length <= 1) return false;

  return !valueArray.every((val) => val === valueArray[0]);
};

export const parseIntoField = ({
  value,
  supportNoneValue,
  key,
}: {
  value: string | undefined;
  supportNoneValue: DimensionFieldOptions['supportNoneValue'];
  key: string;
}): {
  value: string;
  unit: UnitType;
} => {
  let fallbackValue = DEFAULT_DIMENSION_VALUE;
  let fallbackUnit: UnitType = DEFAULT_UNIT;
  if (supportNoneValue) {
    fallbackValue = ValidDimensionTextValue.none;
    fallbackUnit = UnitsType.none;
  }

  const returnFallbackWithLogger = (val: any) => {
    logger.warn('useDimensionField: value is not valid', { [key]: val });
    return {
      value: fallbackValue,
      unit: fallbackUnit,
    };
  };

  // in case we don't get a supported value (undefined, object etc...), we return 'None'
  if (typeof value !== 'string') {
    return returnFallbackWithLogger(value);
  }

  // in case value is multi-directional, we have few options of returning it
  // 1. if not all values are the same, we return 'Mixed' => 10px 20px 10px 20px => value: 'Mixed', unit: '-'
  // 2. if all values are the same, we return the single value and the unit => 10px 10px 10px 10px => value: 10, unit: 'px'
  const valueArray = value.split(' ');
  if (valueArray.length > 1) {
    const firstValue = valueArray[0];

    // 1. if values are not the same
    if (!valueArray.every((val) => val === firstValue)) {
      return {
        value: ValidDimensionTextValue.mixed,
        unit: UnitsType.none,
      };
    }

    // 2. if all values are the same, and we need to continue the parsing with the first value
    value = firstValue;
  }

  // in case we get a valid dimension text value, we return it with first letter uppercase and unit 'None'
  if (isValidDimensionTextValue(value, supportNoneValue)) {
    return {
      value: capitalize(value),
      unit: UnitsType.none,
    };
  }

  // check if value is an invalid number - which means it could be a token
  if (value && isNaN(parseFloat(value))) {
    // check if it's actually a token
    if (isAliasPattern(value)) {
      return {
        value: value,
        unit: UnitsType.none,
      };
    }

    return returnFallbackWithLogger(value);
  }

  // in case we get a valid number, we return parse it to a valid value and unit (e.g. 10px => value: 10, unit: 'px')
  const { value: parsedValue, unit: parsedUnit } =
    parseValueStrToValidValueUnit(value);

  return {
    value: parsedValue,
    unit: parsedUnit,
  };
};

export const parseFromField = (value: string | undefined) =>
  capitalize(value?.trim() ?? '').replace(/^0+(?=\d)/, '');

export const combineValueWithUnit = (
  value: string | undefined,
  unit: UnitType
) =>
  unit === UnitsType.none || value === undefined ? value : `${value}${unit}`;

const shouldValueHaveUnit = (
  value: string | undefined,
  supportNoneValue: DimensionFieldOptions['supportNoneValue']
) => {
  if (isValidDimensionTextValue(value, supportNoneValue)) {
    return false;
  }
  return true;
};

export const changeToProperUnit = (
  value: string | undefined,
  unit: UnitType,
  supportNoneValue: DimensionFieldOptions['supportNoneValue']
): UnitType => {
  const shouldHaveUnit = shouldValueHaveUnit(value, supportNoneValue);

  if (shouldHaveUnit) {
    if (unit === UnitsType.none) {
      return DEFAULT_UNIT;
    }
    return unit;
  }

  return UnitsType.none;
};

export const adjustValueByAmount = (
  value: string | undefined,
  amount: number,
  { supportNegativeValue }: DimensionFieldOptions | undefined = {}
): { success: boolean; value: string | undefined } => {
  const parsedValue = roundFloatValue(value ?? '');

  if (!Number.isFinite(parsedValue)) {
    return { success: false, value };
  }

  const isNegative = parsedValue + amount < 0;
  if (isNegative && !supportNegativeValue) {
    return { success: false, value };
  }

  return {
    success: true,
    value: `${parsedValue + amount}`,
  };
};
