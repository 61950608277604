import { FC, PropsWithChildren, useCallback } from 'react';
import { ComponentTagName } from '@jux/data-entities';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { CANVAS_ITEMS, useDragItem } from '@jux/ui/components/editor/hooks';
import { Typography } from '@jux/ui/components';
import { useAddElementToCanvasOnClick } from '../../../hooks';
import * as S from './ElementPreviewItem.style';

export const ElementPreviewItem: FC<
  PropsWithChildren<{
    displayName?: string;
    tagName: ComponentTagName;
  }>
> = ({ displayName, tagName, children }) => {
  const { dragRef, isDragging } = useDragItem(CANVAS_ITEMS.Element, {
    tagName,
    type: CANVAS_ITEMS.Element,
  });

  const { handleAddElementToCanvasOnClick } = useAddElementToCanvasOnClick();

  const handleClick = useCallback(() => {
    handleAddElementToCanvasOnClick(tagName);
  }, [handleAddElementToCanvasOnClick, tagName]);

  return (
    <S.PreviewItemWrapper
      // @ts-expect-error - react-dnd has type issues with React 18.3.1
      ref={dragRef}
      onClick={handleClick}
      isDragging={isDragging}
    >
      <MuiGrid item>{children}</MuiGrid>

      <S.DisplayNameContainer item>
        <Typography
          variant="elementDisplayNameTypography"
          color={(theme) => theme.drimz.palette.text.secondary}
          textAlign="center"
          overflow="hidden"
        >
          {displayName}
        </Typography>
      </S.DisplayNameContainer>
    </S.PreviewItemWrapper>
  );
};
