import { useEffect, useMemo } from 'react';
import { atomWithStorage } from 'jotai/utils';
import { useSetAtom } from 'jotai/index';
import { focusAtom } from 'jotai-optics';
import { setTags } from '@sentry/nextjs';
import { Organization } from '@jux/data-entities';
import { api } from '@jux/ui/trpc/client/api';

type UserOrganizations = {
  current: Organization;
  organizations: Organization[];
};

export const userOrganizationsAtom = atomWithStorage<UserOrganizations>(
  'organizations',
  {
    current: {} as Organization,
    organizations: [],
  }
);

export const currentOrganizationAtom = focusAtom(userOrganizationsAtom, (s) =>
  s.prop('current')
);

export const useOrganizations = () => {
  const {
    data: userOrganizations,
    isLoading,
    error,
  } = api.user.getUserOrganizations.useQuery();

  const setUserOrganizations = useSetAtom(userOrganizationsAtom);

  useEffect(() => {
    if (userOrganizations) {
      // TODO: It's pretty hacky to set the organization_id tag here. We should find a better way to do this once we support multi-org selection.
      if (userOrganizations.length > 0) {
        setTags({
          organization_id: userOrganizations[0].id,
        });
      }

      setUserOrganizations({
        // For now, we are setting the first organization as the current one as we don't support multiple organizations yet
        current:
          userOrganizations.length > 0
            ? userOrganizations[0]
            : ({} as Organization),
        organizations: userOrganizations,
      });
    }
  }, [setUserOrganizations, userOrganizations]);

  const currentOrganization = useMemo(() => {
    return userOrganizations && userOrganizations.length > 0
      ? userOrganizations[0]
      : ({} as Organization);
  }, [userOrganizations]);

  return {
    isLoading,
    userOrganizations: userOrganizations || [],
    currentOrganization,
    error,
  };
};
