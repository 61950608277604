import {
  JuxStore,
  JuxStoreActionFn,
  PropsActionsParams,
} from '../../store.interface';
import type { Draft as WritableDraft } from 'mutative';
import { ComponentConfigWithStates } from '@jux/types';
import { NodeType } from '@jux/data-entities';
import {
  fixInstancesOnRenameProp,
  updateContextStylesOnRenameProp,
  updateStylesOnRenameProp,
} from '../utils/renamePropUtils';

/**
 * Rename a component property.
 */
export const renameComponentProp: JuxStoreActionFn<
  PropsActionsParams['renameComponentProp'],
  JuxStore
> = ({ componentId, propName, newPropName, state }) => {
  const component = state.components[componentId];
  if (!component) return state;

  switch (component.type) {
    case NodeType.LOCAL_COMPONENT:
    case NodeType.LIBRARY_COMPONENT:
      const variantConfig = component.config.variants?.find(
        (variant) => variant.variant === propName
      );
      if (!variantConfig) {
        return state;
      }

      const oldVariantName = variantConfig.variant;
      variantConfig.variant = newPropName;
      variantConfig.displayName = newPropName;

      if (oldVariantName in component.config.props) {
        // copy current value to the new prop name
        component.config.props[newPropName] =
          component.config.props[oldVariantName];
        // delete the old prop name value
        delete component.config.props[oldVariantName];
      }

      // Edit existing styles and duplicate default styles for new value options
      const styles =
        component.styles as WritableDraft<ComponentConfigWithStates>;
      if (styles.variants) {
        updateStylesOnRenameProp({
          variantsStyles: styles.variants,
          propName,
          newPropName,
        });
      }

      // Go over state styles and do the same
      for (const stateStyles of Object.values(styles.states)) {
        if (stateStyles.variants) {
          updateStylesOnRenameProp({
            variantsStyles: stateStyles.variants,
            propName: propName,
            newPropName: newPropName,
          });
        }
      }

      if (styles.contextStyles) {
        updateContextStylesOnRenameProp({
          contextStyles: styles.contextStyles,
          propName: propName,
          newPropName: newPropName,
        });
      }

      fixInstancesOnRenameProp({
        componentId,
        propName,
        newPropName,
        components: state.components,
      });

      component.updatedAt = new Date().getTime();

      break;
    default:
      throw new Error(
        `Cannot rename prop for component type ${component.type}`
      );
  }

  return state;
};
