import { CanjuxState, JuxStoreActionFn } from '@jux/canjux/core';
import logger from '@jux/ui-logger';
import type { Draft as WritableDraft, Immutable } from 'mutative';
import { apply, create } from 'mutative';
import * as Sentry from '@sentry/nextjs';

export const handleErrors =
  <T extends CanjuxState>(actionFn: JuxStoreActionFn<any, T>) =>
  <P extends { state: WritableDraft<T> }>(
    params: P
  ): WritableDraft<T> | Immutable<WritableDraft<T>> => {
    let result: WritableDraft<T> | Immutable<WritableDraft<T>>;
    result = params.state;

    try {
      // Run the action in a create block so that if it throws, the state is not partially mutated
      const [, patches] = create(
        params.state,
        (draft) => {
          actionFn({ ...params, state: draft });
        },
        {
          enablePatches: true,
        }
      );

      // if the action was successful, apply the patches
      result = apply(params.state, patches);
    } catch (e) {
      // Log the error and continue - we don't want to break the app
      logger.error(`Error in action ${actionFn.name}:`, e as object);
      Sentry.captureException(e);
    }

    return result;
  };
