type MessageConstructor = (text: string) => string;

export type TextValidatorFunction = (text: string) => {
  isValid: boolean;
  message?: string;
  autoCloseDelay?: number;
};

type TextValidatorInput<Input = undefined> = Input extends undefined
  ? {
      message?: MessageConstructor;
    }
  : Input & {
      message?: MessageConstructor;
    };

export type TextValidator<Input = undefined> = (
  input: TextValidatorInput<Input>
) => TextValidatorFunction;

const nameAlreadyExistsValidator: TextValidator<{
  namesArray?: Array<string> | (() => Array<string>);
  errorMessages?: {
    namesArrayUndefined?: string;
    nameAlreadyExists?: string;
  };
}> =
  ({ namesArray, errorMessages }) =>
  (newName: string) => {
    if (!namesArray)
      return {
        isValid: false,
        message: errorMessages?.namesArrayUndefined,
      };

    if (typeof namesArray === 'function') namesArray = namesArray();

    const isValid = !namesArray.some((name) => name === newName);

    return {
      isValid,
      message: !isValid ? errorMessages?.nameAlreadyExists : undefined,
    };
  };

export const minValidLengthValidator: TextValidator<{ minLength: number }> =
  ({ minLength, message }) =>
  (text: string) => ({
    isValid: text.length >= minLength,
    message: message ? message(text) : undefined,
  });

export const maxValidLengthValidator: TextValidator<{ maxLength: number }> =
  ({ maxLength, message }) =>
  (text: string) => ({
    isValid: text.length <= maxLength,
    message: message ? message(text) : undefined,
  });

export const isNotEmptyValidator: TextValidator = ({ message }) =>
  minValidLengthValidator({ minLength: 1, message });

export const textValidators = {
  nameAlreadyExistsValidator,
  minValidLengthValidator,
  maxValidLengthValidator,
  isNotEmptyValidator,
};
