import type { Draft as WritableDraft } from 'mutative';
import { JuxStore } from '@jux/canjux/core';
import logger from '@jux/ui-logger';
import { StateValidator } from './validator.interface';

export const validateRootNodes: StateValidator = ({
  state,
  recover = false,
}: {
  state: WritableDraft<JuxStore>;
  recover?: boolean;
}) => {
  for (const canvas of Object.values(state.canvases)) {
    const rootNodes = canvas.rootNodesOrder;

    // get root nodes that appear more than once
    const duplicateRootNodes = rootNodes.filter(
      (nodeId, index) => rootNodes.indexOf(nodeId) !== index
    );

    if (duplicateRootNodes.length) {
      if (recover) {
        // Remove all occurrences of duplicate root nodes
        canvas.rootNodesOrder = canvas.rootNodesOrder.filter(
          (nodeId) => !duplicateRootNodes.includes(nodeId)
        );

        // Readd them at the end of the list only once
        canvas.rootNodesOrder =
          canvas.rootNodesOrder.concat(duplicateRootNodes);

        logger.info(
          'data recovery: removing duplicate root nodes',
          duplicateRootNodes
        );
      } else {
        throw new Error(
          `Root nodes ${duplicateRootNodes.join(
            ', '
          )} are listed more than once`
        );
      }
    }

    const nodesWithoutParent = Object.keys(canvas.nodes).filter(
      (nodeId) => !state.components[nodeId]?.parentId
    );

    // Check if root nodes are valid
    for (const rootNodeId of rootNodes) {
      const hasNode = Boolean(canvas.nodes[rootNodeId]);
      const hasComponent = Boolean(state.components[rootNodeId]);

      if (!hasNode || !hasComponent) {
        if (recover) {
          // We don't know what else is wrong with this node, so we delete it
          canvas.rootNodesOrder = canvas.rootNodesOrder.filter(
            (nodeId) => nodeId !== rootNodeId
          );
          logger.info(
            'data recovery: removing bad root node that does not have data',
            { rootNodeId }
          );
        } else {
          throw new Error(`Root node ${rootNodeId} does not exist`);
        }
      }
    }

    // Check if nodes without parent are all listed as root nodes
    for (const nodeId of nodesWithoutParent) {
      if (!rootNodes.includes(nodeId)) {
        if (recover) {
          canvas.rootNodesOrder.push(nodeId);
          logger.info(
            `data recovery: add root node ${nodeId} that has no parent but is not listed as root node`
          );
        } else {
          throw new Error(
            `Node ${nodeId} is without a parent but is not a root node`
          );
        }
      }
    }
  }
};
