import * as uuid from 'uuid';
import { DEFAULT_STATE, StylesObject } from '@jux/types';
import { ComponentPropType, NodeType } from '@jux/data-entities';
import { COMPONENT_TAG_NAME } from '@jux/data-entities';
import { Colors } from '../common/colors';
import { ElementDataInit } from '../types/ElementDataInit';
import { getJuxDivElementData } from './div';

export const DIVIDER_ELEMENT_DEFAULT_ROOT_STYLES: StylesObject = {
  width: 'auto',
  minWidth: '100px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  padding: '10px 0',
  justifyContent: 'center',
  borderColor: '', // this is set so that DDP would not show browser default #767676 and show empty value instead
};

export const DIVIDER_ELEMENT_DEFAULT_DISPLAY_NAME = 'divider';

const Thickness = {
  thin: 'thin',
  medium: 'medium',
  thick: 'thick',
} as const;
const PaddingSizes = {
  none: 'none',
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const;

export const getJuxDividerElementData: ElementDataInit<{
  rootStyles?: StylesObject;
  displayName?: string;
}> = ({
  rootStyles = DIVIDER_ELEMENT_DEFAULT_ROOT_STYLES,
  displayName = DIVIDER_ELEMENT_DEFAULT_DISPLAY_NAME,
  parentId,
} = {}) => {
  const rootDividerId = uuid.v4();
  const divDivider = getJuxDivElementData({
    styles: {
      root: {
        width: 'auto',
        height: '1px',
        backgroundColor: Colors.Neutrals_300,
      },
      states: {},
    },
    parentId: rootDividerId,
    displayName: 'divider_line',
  }).root;

  return {
    root: {
      tagName: COMPONENT_TAG_NAME.JuxDiv,
      type: NodeType.LOCAL_COMPONENT,
      id: rootDividerId,
      parentId,
      scopes: [],
      styles: {
        root: rootStyles,
        states: {},
        variants: [
          // none + thin
          {
            propsValues: {
              paddingSize: PaddingSizes.none,
              thickness: Thickness.thin,
            },
            styles: {
              ...DIVIDER_ELEMENT_DEFAULT_ROOT_STYLES,
              padding: 0,
            },
          },
          // none + medium
          {
            propsValues: {
              paddingSize: PaddingSizes.none,
              thickness: Thickness.medium,
            },
            styles: {
              ...DIVIDER_ELEMENT_DEFAULT_ROOT_STYLES,
              padding: 0,
            },
          },
          // none + thick
          {
            propsValues: {
              paddingSize: PaddingSizes.none,
              thickness: Thickness.thick,
            },
            styles: {
              ...DIVIDER_ELEMENT_DEFAULT_ROOT_STYLES,
              padding: 0,
            },
          },
          // small + thin
          {
            propsValues: {
              paddingSize: PaddingSizes.small,
              thickness: Thickness.thin,
            },
            styles: DIVIDER_ELEMENT_DEFAULT_ROOT_STYLES,
          },
          // small + medium
          {
            propsValues: {
              paddingSize: PaddingSizes.small,
              thickness: Thickness.medium,
            },
            styles: DIVIDER_ELEMENT_DEFAULT_ROOT_STYLES,
          },
          // small + thick
          {
            propsValues: {
              paddingSize: PaddingSizes.small,
              thickness: Thickness.thick,
            },
            styles: DIVIDER_ELEMENT_DEFAULT_ROOT_STYLES,
          },
          // medium + thin
          {
            propsValues: {
              paddingSize: PaddingSizes.medium,
              thickness: Thickness.thin,
            },
            styles: {
              ...DIVIDER_ELEMENT_DEFAULT_ROOT_STYLES,
              padding: '20px 0',
            },
          },
          // medium + medium
          {
            propsValues: {
              paddingSize: PaddingSizes.medium,
              thickness: Thickness.medium,
            },
            styles: {
              ...DIVIDER_ELEMENT_DEFAULT_ROOT_STYLES,
              padding: '20px 0',
            },
          },
          // medium + thick
          {
            propsValues: {
              paddingSize: PaddingSizes.medium,
              thickness: Thickness.thick,
            },
            styles: {
              ...DIVIDER_ELEMENT_DEFAULT_ROOT_STYLES,
              padding: '20px 0',
            },
          },
          // large + thin
          {
            propsValues: {
              paddingSize: PaddingSizes.large,
              thickness: Thickness.thin,
            },
            styles: {
              ...DIVIDER_ELEMENT_DEFAULT_ROOT_STYLES,
              padding: '30px 0',
            },
          },
          // large + medium
          {
            propsValues: {
              paddingSize: PaddingSizes.large,
              thickness: Thickness.medium,
            },
            styles: {
              ...DIVIDER_ELEMENT_DEFAULT_ROOT_STYLES,
              padding: '30px 0',
            },
          },
          // large + thick
          {
            propsValues: {
              paddingSize: PaddingSizes.large,
              thickness: Thickness.thick,
            },
            styles: {
              ...DIVIDER_ELEMENT_DEFAULT_ROOT_STYLES,
              padding: '30px 0',
            },
          },
        ],
        contextStyles: [
          // none
          {
            contextChildUuid: divDivider.id,
            condition: {
              state: 'default',
              propsValues: {
                paddingSize: PaddingSizes.none,
                thickness: Thickness.thin,
              },
            },
            styles: {
              height: '1px',
            },
          },
          {
            contextChildUuid: divDivider.id,
            condition: {
              state: 'default',
              propsValues: {
                paddingSize: PaddingSizes.none,
                thickness: Thickness.medium,
              },
            },
            styles: {
              height: '2px',
            },
          },
          {
            contextChildUuid: divDivider.id,
            condition: {
              state: 'default',
              propsValues: {
                paddingSize: PaddingSizes.none,
                thickness: Thickness.thick,
              },
            },
            styles: {
              height: '4px',
            },
          },
          // small
          {
            contextChildUuid: divDivider.id,
            condition: {
              state: 'default',
              propsValues: {
                paddingSize: PaddingSizes.small,
                thickness: Thickness.thin,
              },
            },
            styles: {
              height: '1px',
            },
          },
          {
            contextChildUuid: divDivider.id,
            condition: {
              state: 'default',
              propsValues: {
                paddingSize: PaddingSizes.small,
                thickness: Thickness.medium,
              },
            },
            styles: {
              height: '2px',
            },
          },
          {
            contextChildUuid: divDivider.id,
            condition: {
              state: 'default',
              propsValues: {
                paddingSize: PaddingSizes.small,
                thickness: Thickness.thick,
              },
            },
            styles: {
              height: '4px',
            },
          },
          // Medium
          {
            contextChildUuid: divDivider.id,
            condition: {
              state: 'default',
              propsValues: {
                paddingSize: PaddingSizes.medium,
                thickness: Thickness.thin,
              },
            },
            styles: {
              height: '1px',
            },
          },
          {
            contextChildUuid: divDivider.id,
            condition: {
              state: 'default',
              propsValues: {
                paddingSize: PaddingSizes.medium,
                thickness: Thickness.medium,
              },
            },
            styles: {
              height: '2px',
            },
          },
          {
            contextChildUuid: divDivider.id,
            condition: {
              state: 'default',
              propsValues: {
                paddingSize: PaddingSizes.medium,
                thickness: Thickness.thick,
              },
            },
            styles: {
              height: '4px',
            },
          },
          // Large
          {
            contextChildUuid: divDivider.id,
            condition: {
              state: 'default',
              propsValues: {
                paddingSize: PaddingSizes.large,
                thickness: Thickness.thin,
              },
            },
            styles: {
              height: '1px',
            },
          },
          {
            contextChildUuid: divDivider.id,
            condition: {
              state: 'default',
              propsValues: {
                paddingSize: PaddingSizes.large,
                thickness: Thickness.medium,
              },
            },
            styles: {
              height: '2px',
            },
          },
          {
            contextChildUuid: divDivider.id,
            condition: {
              state: 'default',
              propsValues: {
                paddingSize: PaddingSizes.large,
                thickness: Thickness.thick,
              },
            },
            styles: {
              height: '4px',
            },
          },
        ],
      },
      config: {
        // starting values of props
        props: {
          paddingSize: PaddingSizes.small,
          thickness: Thickness.thin,
        },
        interactiveState: DEFAULT_STATE,
        variants: [
          {
            variant: 'paddingSize',
            displayName: 'Padding size',
            propType: ComponentPropType.Enum,
            options: [
              { value: PaddingSizes.none, label: 'No padding' },
              { value: PaddingSizes.small, label: 'Small' },
              { value: PaddingSizes.medium, label: 'Medium' },
              { value: PaddingSizes.large, label: 'Large' },
            ],
            defaultValue: PaddingSizes.small,
            isFixed: false,
          },
          {
            variant: 'thickness',
            propType: ComponentPropType.Enum,
            options: [
              { value: Thickness.thin, label: 'Thin' },
              { value: Thickness.medium, label: 'Medium' },
              { value: Thickness.thick, label: 'Thick' },
            ],
            defaultValue: Thickness.thin,
            isFixed: false,
          },
        ],
      },
      displayName,
      children: [divDivider.id], // only set id's not components because they are not supplied
    },
    subComponents: [divDivider],
  };
};
