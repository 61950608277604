import { createSelector } from 'reselect';
import { StylesState, VariantsValues } from '@jux/types';
import { calculateStyles } from '@jux/calculate-styles';
import { CanjuxState, storeApi } from '@jux/canjux/core';
import { getNodeComponentById } from './utils';

export const getComponentStylesSelector = createSelector(
  [(state: CanjuxState) => state.components],
  (components) =>
    ({
      id,
      interactiveState,
      variantsValues,
    }: {
      id: string;
      interactiveState: StylesState;
      variantsValues: VariantsValues;
    }) => {
      // node component
      const { styles } = getNodeComponentById({ components, id }) || {};

      // TODO: add error message
      if (!styles) return undefined;

      // component styles
      const { root: rootStyles, variants } = calculateStyles(
        styles,
        interactiveState
      );

      // find all the variants that have their props values condition met by the current variant values
      const variantsStyles = variants
        ?.filter((variant) =>
          Object.entries(variant.propsValues).every(
            ([propName, propValue]) =>
              propName in variantsValues &&
              variantsValues[propName] === propValue
          )
        )
        .map((variant) => variant.styles);

      // resolve styles
      return {
        rootStyles,
        variantsStyles,
      };
    }
);

export const getComponentStyles = () =>
  getComponentStylesSelector(storeApi.getState());
