// implement the cartesian product to get the following structure:
// eg. [{hierarchy: 'primary', disabled: 'false'}, {hierarchy: 'primary', disabled: 'true'}, {hierarchy: 'secondary', disabled: 'false'}, {hierarchy: 'secondary', disabled: 'true'}, ]
import { VariantsConfig } from '@jux/data-entities';
import { EnumPropValue } from '@jux/types';

/**
 * Reduces an array of variant value options arrays to their cartesian product.
 *
 * @param {EnumPropValue[][]} variantsValuesOptions - An array of arrays, where each inner array contains possible values for a variant prop.
 * @returns {EnumPropValue[][]} - The cartesian product of the input arrays.
 *
 * Example:
 * cartesianProduct([['primary', 'secondary'], ['small', 'medium']])
 * => [['primary', 'small'], ['primary', 'medium'], ['secondary', 'small'], ['secondary', 'medium']]
 */
export const cartesianProduct = (
  ...variantsValuesOptions: EnumPropValue[][]
) => {
  if (variantsValuesOptions.length === 0) return [];
  if (variantsValuesOptions.length === 1)
    return variantsValuesOptions[0].map((variantValue) => [variantValue]);

  return variantsValuesOptions.reduce(
    (accumulatedOptions: any, currentOptions: any) =>
      accumulatedOptions?.flatMap((accumulatedOption: any) =>
        currentOptions?.map((currentOption: any) => [
          ...(Array.isArray(accumulatedOption)
            ? accumulatedOption
            : [accumulatedOption]),
          currentOption,
        ])
      )
  );
};

export const getCartesianVariantsWithoutDisabled = (
  variants: VariantsConfig
) => {
  const variantsWithoutDisabled: VariantsConfig = variants;
  const variantsOptionsValues = Object.values(variantsWithoutDisabled).map(
    (variant) => {
      if (variant.variant === 'disabled' && variant.isFixed) {
        return [false];
      }
      return variant.options.map((option) => option.value);
    } // eg. [['primary', 'secondary'], [true, false]]
  );

  // TODO: understand type mismatch
  return cartesianProduct(
    ...variantsOptionsValues
  ) as unknown as EnumPropValue[][];
};
