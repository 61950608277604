import { createSelector } from 'reselect';
import { CanjuxState } from '@jux/canjux/core';
import { getSelectedNodeId } from './utils';
import { NodeType } from '@jux/data-entities';

export const selectedSourceComponentCanvas = createSelector(
  [
    (state: CanjuxState) => state.components,
    (state: CanjuxState) => state.canvases,
    (state: CanjuxState) => state.selectedNodesStack,
  ],
  (components, canvases, selectedNodesStack) => {
    const selectedNodeId = getSelectedNodeId(selectedNodesStack);
    if (!selectedNodeId) return null;

    const sourceComponentId =
      components[selectedNodeId]?.sourceComponentId || selectedNodeId;

    for (const canvas of Object.values(canvases)) {
      if (sourceComponentId in canvas.nodes) {
        return canvas;
      }

      // Check if there is a variant group with the same source component id
      for (const nodeId of Object.keys(canvas.nodes)) {
        const component = components[nodeId];
        if (
          component.type === NodeType.VARIANTS_GROUP &&
          component?.sourceComponentId === sourceComponentId
        ) {
          return canvas;
        }
      }
    }
    return null;
  }
);
