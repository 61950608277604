import { type ThemeConfig } from '@jux/types';
import { isAliasPattern, isMultiValues } from '@juxio/design-tokens';
import { getMultiValuesTokenResolver } from './getMultiValuesTokenResolver';
import { getTokenResolver } from './getTokenResolver';

export const transformAndResolveTokens = (
  value: string,
  theme: ThemeConfig
) => {
  // if value is a combination of multiple values, return a function that uses the theme
  // to resolve each value and returns the combined string
  // e.g. "1px solid {color.primary}" => "1px solid lightsalmon"
  if (isMultiValues(value)) {
    return getMultiValuesTokenResolver(value, theme);
  }
  // if the value is an alias, return a function that uses the theme
  if (isAliasPattern(value)) {
    return getTokenResolver(value, theme);
  }
  // otherwise return the value as is
  return value;
};
