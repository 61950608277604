import { type ThemeConfig } from '@jux/types';
import { getAliasMatches, isAliasPattern } from '@juxio/design-tokens';
import { get } from 'lodash';
import { CSSProperties } from 'react';

/**
 * Returns a function that resolves a token value from the theme.
 */
export const getTokenResolver = (
  value: string,
  theme: ThemeConfig
): CSSProperties[keyof CSSProperties] => {
  const result = get(theme?.tokenSet, getAliasMatches(value).valuePath);
  if (isAliasPattern(result)) {
    return getTokenResolver(result, theme);
  }
  return result;
};
