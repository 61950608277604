import { LiveblocksData } from '../components-service/liveblocks/liveblocks.interface';
import { JuxComponentData } from '../components-service/jux-component-data';
import { NodeType } from '../components-service/nodetypes.enum';

export const isDynamicSlotOrSlotInstance = ({
  componentToCheck,
  components,
}: {
  components: LiveblocksData['components'];
  componentToCheck: JuxComponentData;
}) => {
  return (
    componentToCheck?.type === NodeType.DYNAMIC_SLOT ||
    (componentToCheck.sourceComponentId &&
      components[componentToCheck.sourceComponentId]?.type ===
        NodeType.DYNAMIC_SLOT)
  );
};
