import { ThemeOptions } from '@jux/ui/components/common/mui';
import { withImportant } from '@jux/ui/utils/css';
import {
  COMMON_SELECT_FIELD_BORDER,
  COMMON_SELECT_FIELD_BORDER_FOCUSED,
} from '../palette';
import { DrimzPalette } from './base-theme-palette.interface';

export const componentsColors = (
  drimzPalette: DrimzPalette
): ThemeOptions['components'] => ({
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        backgroundColor: drimzPalette.background.default,
      },
    },
  },

  // INPUT
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        color: drimzPalette.text.secondary,
      },
    },
  },
  MuiTextField: {
    variants: [
      {
        props: { variant: 'outlined' },
        style: {
          '& .MuiOutlinedInput-root': {
            input: {
              color: drimzPalette.text.primary,
              WebkitTextFillColor: drimzPalette.text.primary,

              '&::placeholder': {
                opacity: 1,
                color: drimzPalette.text.secondary,
                WebkitTextFillColor: drimzPalette.text.secondary,
              },
            },

            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: drimzPalette.divider.primary,
            },

            '&.Mui-disabled': {
              opacity: 0.5,
            },
          },
        },
      },
    ],
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '&:has(.MuiSelect-select)': {
          // idle
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: COMMON_SELECT_FIELD_BORDER,
          },
          '& .MuiInputBase-input': {
            color: drimzPalette.text.primary,
          },

          // hover
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: drimzPalette.divider.primary,
            },
          },

          // disabled
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: COMMON_SELECT_FIELD_BORDER,
            },
            '& .MuiInputBase-input': {
              color: drimzPalette.text.secondary,
            },
          },

          // focused
          '&:focus': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: COMMON_SELECT_FIELD_BORDER_FOCUSED,
            },
            '& .MuiInputBase-input': {
              color: drimzPalette.text.primary,
            },
          },
        },
      },
    },
  },

  // BUTTON
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: drimzPalette.text.button,
      },
    },
  },
  MuiButton: {
    variants: [
      {
        props: { variant: 'contained' },
        style: {
          color: drimzPalette.text.button,
          backgroundColor: drimzPalette.primary.main,

          '&:hover': {
            backgroundColor: drimzPalette.primary.main,
          },

          '&.Mui-disabled': {
            color: drimzPalette.text.disabled,
            backgroundColor: drimzPalette.background.hover,
          },
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          color: drimzPalette.primary.main + ' !important',
          borderColor: drimzPalette.primary.main + ' !important',

          '&.Mui-disabled': {
            opacity: 0.5,
          },
        },
      },
    ],
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        color: drimzPalette.text.secondary + ' !important',
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: drimzPalette.primary.main,
          color: drimzPalette.text.primary,

          '&:hover': {
            backgroundColor: drimzPalette.primary.main,
          },
        },
      },
    },
  },

  // TEXT
  MuiTypography: {
    styleOverrides: {
      root: {
        color: drimzPalette.text.primary,
      },
    },
    variants: [
      {
        props: { variant: 'subtitle1' },
        style: { color: drimzPalette.text.secondary },
      },
      {
        props: { variant: 'subtitle2' },
        style: { color: drimzPalette.text.secondary },
      },
      {
        props: { variant: 'caption' },
        style: { color: drimzPalette.text.secondary },
      },
    ],
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        color: drimzPalette.tooltip.color,
        backgroundColor: drimzPalette.tooltip.background,
      },
    },
  },

  // MENU & LIST
  MuiMenu: {
    styleOverrides: {
      paper: {
        border: 'none',
        borderColor: drimzPalette.divider.primary,
      },
      list: {
        backgroundColor: drimzPalette.background.default,
        color: drimzPalette.text.primary,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        backgroundColor: drimzPalette.background.navigation,
        color: drimzPalette.text.primary,

        '&:hover': {
          backgroundColor: drimzPalette.background.hover,
        },

        '&.Mui-selected': {
          '&:hover': {
            backgroundColor: drimzPalette.background.selectedHover,
          },
          background: drimzPalette.background.selected,
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        borderColor: drimzPalette.divider.primary,
      },
    },
  },

  MuiListSubheader: {
    styleOverrides: {
      root: {
        backgroundColor: drimzPalette.background.default,
        color: drimzPalette.text.secondary,
      },
    },
  },

  //
  MuiPopover: {
    styleOverrides: {
      paper: {
        background: drimzPalette.background.default,
        borderColor: drimzPalette.divider.primary,
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        '& .MuiDialog-paper': {
          backgroundColor: drimzPalette.background.navigation,

          '& .MuiCard-root': {
            backgroundColor: drimzPalette.background.navigation,

            '& .MuiCardHeader-root': {
              color: drimzPalette.text.primary,
            },
          },
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        backgroundColor: drimzPalette.background.navigation,
        color: drimzPalette.text.primary,
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        backgroundColor: drimzPalette.background.default,
      },
    },
  },

  // TABLE
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&:not(:last-child)': {
          borderBottomColor: drimzPalette.divider.primary,
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        color: drimzPalette.text.primary,
        borderBottom: 'none',
      },
    },
  },

  // OTHER
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        backgroundColor: drimzPalette.background.default,
        borderBottomColor: drimzPalette.divider.primary,
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        backgroundColor: drimzPalette.divider.primary,
      },
    },
  },
  MuiBackdrop: {
    styleOverrides: {
      root: {
        backgroundColor: withImportant(drimzPalette.transparent),
      },
    },
  },

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  MuiTreeItem: {
    styleOverrides: {
      content: {
        '&.Mui-selected': {
          backgroundColor: drimzPalette.primary.main,
          color: drimzPalette.text.button,

          '&.Mui-focused': {
            backgroundColor: drimzPalette.primary.main,
          },
        },
      },
    },
  },

  MuiDrawer: {
    styleOverrides: {
      root: {
        '& .MuiPaper-root': {
          backgroundColor: drimzPalette.background.navigation,
          color: drimzPalette.text.button,
          minWidth: 280,
        },
      },
    },
  },
});
