import { createSelector } from 'reselect';
import { CanjuxState } from '../../store';
import { getNodeComponentById } from './utils';
import { SOURCE_COMPONENT_TYPES } from '@jux/data-entities';
import { DEFAULT_STATE } from '@jux/types';

export const nodeComponentInteractiveStateByNodeId = (id: string) =>
  createSelector([(state: CanjuxState) => state.components], (components) => {
    const nodeComponent = getNodeComponentById({ components, id });
    if (!nodeComponent) {
      return undefined;
    }
    if (nodeComponent.config?.interactiveState) {
      return nodeComponent.config?.interactiveState;
    }

    if (SOURCE_COMPONENT_TYPES.includes(nodeComponent.type)) {
      // For backward compatibility, we don't have this field for old components
      return DEFAULT_STATE;
    }

    return undefined;
  });
