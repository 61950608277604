import { LiveblocksData } from '../components-service/liveblocks/liveblocks.interface';
import { NodeType } from '../components-service/nodetypes.enum';

/**
 * Recursively finds all unique component IDs that match the specified node type
 */
export function findComponentIdsWithNodeType({
  componentId,
  nodeType,
  components,
}: {
  componentId: string;
  nodeType: NodeType;
  components: LiveblocksData['components'];
}): string[] {
  const matchingIds = new Set<string>();

  function traverse(id: string) {
    const component = components[id];
    if (!component) return;

    if (component.type === nodeType) {
      matchingIds.add(id);
    }

    component.children.forEach((childId) => traverse(childId));
  }

  traverse(componentId);
  return Array.from(matchingIds);
}
