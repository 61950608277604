import { useMemo } from 'react';
import {
  CompositeTokenTypes,
  isAlias,
  isAliasPattern,
  isAliasToTokenType,
  SupportedTokenTypes,
} from '@juxio/design-tokens';
import { DDPCompositeFieldsKeys } from '@jux/ui/components/editor/state';
import { NO_TOKEN } from '@jux/ui/components/editor/components/panels/DDP/base/fields/common';
import { useTokensByType } from './useTokensByType';

const isCompositeField = (tokenType: SupportedTokenTypes) => {
  return tokenType in DDPCompositeFieldsKeys;
};
export const useParseFieldValue = ({
  fieldName,
  value,
  initialValue,
  tokenType,
  parentFieldTokenType,
}: {
  fieldName: string;
  value?: string;
  initialValue: string;
  tokenType: SupportedTokenTypes | null;
  parentFieldTokenType?: CompositeTokenTypes;
}) => {
  const { resolveTokenValue } = useTokensByType(
    fieldName,
    tokenType,
    parentFieldTokenType
  );

  // we're storing this in a variable because without it - typescript don't know the resolved type and sticks with any
  const resolvedTokenValue = useMemo(
    () => resolveTokenValue(value),
    [resolveTokenValue, value]
  );

  const rawValue = useMemo((): string => {
    if (value === undefined && tokenType && isCompositeField(tokenType)) {
      return NO_TOKEN;
    }

    if (
      parentFieldTokenType &&
      isAlias(value) &&
      isAliasToTokenType(value, parentFieldTokenType) &&
      resolvedTokenValue
    ) {
      if (isAliasPattern(resolvedTokenValue)) {
        return resolveTokenValue(resolvedTokenValue) ?? NO_TOKEN;
      }

      return resolvedTokenValue;
    }

    return resolvedTokenValue ?? value ?? initialValue;
  }, [
    initialValue,
    resolveTokenValue,
    resolvedTokenValue,
    value,
    tokenType,
    parentFieldTokenType,
  ]);

  const isComputed = useMemo(() => {
    // if value is a token, or the actual value from db - it's not computed
    if (Boolean(resolvedTokenValue) || Boolean(value)) {
      return false;
    }

    // value is undefined... if initial value is not undefined - it's computed
    return initialValue === '' || Boolean(initialValue);
  }, [initialValue, resolvedTokenValue, value]);

  return {
    rawValue,
    isComputed,
  };
};
