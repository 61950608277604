import { FC, useState } from 'react';
import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import * as Yup from 'yup';
import {
  LoadingButtonWithIcon as Button,
  Typography,
} from '@jux/ui/components';
import { api } from '@jux/ui/trpc/client/api';
import * as S from './Invite.style';

const acceptInviteValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .max(40, 'Password must be less than 40 characters'),
  repeat_password: Yup.string()
    .required('Repeat password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const AcceptUserInviteForm: FC<{
  inviteToken: string;
}> = ({ inviteToken }) => {
  const acceptUserInviteMutation = api.organizations.acceptInvite.useMutation();
  const [serverError, setServerError] = useState<string>();
  const router = useRouter();

  const formState = useFormik({
    initialValues: {
      password: '',
      repeat_password: '',
    },
    validationSchema: acceptInviteValidationSchema,
    onSubmit: (data, { setSubmitting }) => {
      setServerError('');
      acceptUserInviteMutation.mutate(
        {
          password: data.password,
          invite_token: inviteToken,
        },
        {
          onSuccess: () => {
            // Change location so user will log in to new organization
            setSubmitting(false);
            router.push('/api/auth/login');
          },
          onError: (error) => {
            setSubmitting(false);
            setServerError(error.message || 'Something went wrong');
          },
        }
      );
    },
  });

  return (
    <S.InviteForm onSubmit={formState.handleSubmit}>
      <Typography
        variant="label-s-regular"
        sx={{
          color: (theme) => theme.drimz.palette.text.primary,
        }}
      >
        Please set your password
      </Typography>

      <S.InviteTextField
        id="password"
        placeholder="Password"
        name="password"
        type="password"
        error={Boolean(
          formState.values.password !== '' &&
            formState.touched.password &&
            formState.errors.password
        )}
        helperText={
          formState.values.password !== '' &&
          formState.touched.password &&
          formState.errors.password
        }
        onBlur={formState.handleBlur}
        onChange={formState.handleChange}
        value={formState.values.password}
      />

      <S.InviteTextField
        id="repeat_password"
        placeholder="Repeat password"
        name="repeat_password"
        type="password"
        error={Boolean(
          formState.values.repeat_password !== '' &&
            formState.touched.repeat_password &&
            formState.errors.repeat_password
        )}
        helperText={
          formState.values.repeat_password !== '' &&
          formState.touched.repeat_password &&
          formState.errors.repeat_password
        }
        onBlur={formState.handleBlur}
        onChange={formState.handleChange}
        value={formState.values.repeat_password}
      />

      {serverError && (
        <Typography
          variant="label-s-regular"
          color={(theme) => theme.drimz.palette.error.main}
        >
          {serverError}
        </Typography>
      )}

      <Button
        loading={formState.isSubmitting}
        variant="contained"
        fullWidth
        type="submit"
        sx={{
          padding: '9px 12px',
        }}
        typographyVariant="body-semi"
      >
        Accept Invite
      </Button>
    </S.InviteForm>
  );
};
