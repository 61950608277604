import { useStoreActions } from '@jux/canjux/core';
import { COMPONENT_TAG_NAME } from '@jux/data-entities';
import { AnalyticsEvents } from '@jux/data-access/analytics';
import { useTrackEvents } from '@jux/ui/hooks';
import { forEach } from 'lodash';
import { useCallback } from 'react';
import { keyboardActionKeys, storeApi } from '../..';
import {
  selectIsLiveMode,
  selectResolvedComponentById,
  selectSelectedNodeIds,
} from '../../store';
import { useStore } from '../useStore';

export const useDrillInOut = () => {
  const selectedNodes = useStore(selectSelectedNodeIds);
  const components = useStore((s) => s.components);
  const isLive = useStore(selectIsLiveMode());

  const {
    commonActions: { setEditModeInTextNode, setSelectedNodes },
  } = useStoreActions();
  const { trackKeyboardShortcut } = useTrackEvents();

  const handleEnterPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (!selectedNodes.length || isLive) {
        return;
      }

      const sourceComponent = selectResolvedComponentById({
        id: selectedNodes[0],
      })(storeApi.getState());
      const isTextElement =
        sourceComponent?.tagName === COMPONENT_TAG_NAME.JuxText;

      const selectionDrillIn = () => {
        const nodesToSelect: Array<string> = [];

        forEach(selectedNodes, (nodeId) => {
          if (components?.[nodeId]?.children.length > 0) {
            nodesToSelect.push(components[nodeId].children[0]);
          }
        });

        if (nodesToSelect.length) {
          setSelectedNodes({ nodeIds: nodesToSelect });
        }
      };

      const setEditModeInText = () => {
        // Prevent the default behavior of the enter key so it wont be "including" in the editing text itself
        e.preventDefault();
        e.stopPropagation();
        setEditModeInTextNode({ nodeId: selectedNodes[0], editable: true });
      };

      if (selectedNodes.length === 1 && isTextElement) {
        setEditModeInText();
      } else {
        selectionDrillIn();
      }

      const nodesToSelect: Array<string> = [];

      forEach(selectedNodes, (nodeId) => {
        if (components?.[nodeId]?.children.length > 0) {
          nodesToSelect.push(components[nodeId].children[0]);
        }
      });
      setSelectedNodes({ nodeIds: nodesToSelect });
      trackKeyboardShortcut({
        shortcut: AnalyticsEvents.KBS_DRILL_IN,
        hotKey: keyboardActionKeys.DRILL_IN,
      });
    },
    [
      selectedNodes,
      isLive,
      setSelectedNodes,
      trackKeyboardShortcut,
      components,
      setEditModeInTextNode,
    ]
  );

  const handleDrillUp = useCallback(() => {
    if (!selectedNodes.length) {
      return;
    }

    const nodesToSelect: Array<string> = [];

    forEach(selectedNodes, (nodeId) => {
      const currentNode = components?.[nodeId];
      if (currentNode?.parentId) {
        nodesToSelect.push(currentNode?.parentId);
      }
    });
    setSelectedNodes({ nodeIds: nodesToSelect });
    trackKeyboardShortcut({
      shortcut: AnalyticsEvents.KBS_DRILL_UP,
      hotKey: keyboardActionKeys.DRILL_OUT,
    });
  }, [components, selectedNodes, setSelectedNodes, trackKeyboardShortcut]);

  return { handleEnterPress, handleDrillUp };
};
