/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import { styled, css, type Merge } from '@juxio/react-styled';
import { useCallback, useRef } from 'react';
import { useToggleSidebar, useTrackEvents } from '@jux/ui/hooks';
import { useAddElementToCanvasOnClick } from '@jux/ui/components/editor/components/navbar/hooks';
import {
  KeyboardShortcutPopover,
  Tooltip,
  useMenuPopper,
  useOpenInviteMembersModal,
} from '@jux/ui/components';
import { MiddleSlot } from '@jux/ui/components/editor/components/navbar/components';
import {
  Logoicon,
  DivIcon,
  TextIcon,
  InviteIcon,
  MenuVerticalIcon,
  SlotIcon,
} from '@jux/ui-core';
import {
  TEST_ID_DIV_ICON_BUTTON,
  TEST_ID_INVITE_USERS_ICON_BUTTON,
  TEST_ID_MENU_VERTICAL_ICON,
  TEST_ID_TEXT_ICON_BUTTON,
  TEST_ID_SLOT_ICON_BUTTON,
} from '@jux/constants';
import { SegmentEventSourcePage } from '@jux/data-access/analytics/track-events-properties';
import { slotTooltipContent } from '@jux/ui/components/editor/components/navbar/components/slotTooltipContent';
import { IconButton } from '../../../../../../ui-core/src/lib/components/jux/IconButton';
import { LiveOrEdit } from '../panels';
import { NavLeftActions } from './NavLeftActions';

export interface NavigationEditorVariants {
  type: 'Editor' | 'Tokens';
}

export type NavigationEditorProps = Merge<
  React.HTMLAttributes<HTMLDivElement>,
  NavigationEditorVariants
>;
const div_97c4e3b6 = css({
  width: 'auto',
  height: 'auto',
  display: 'flex',
  padding: '0px 0px 0px 0px',
  alignItems: 'center',
  borderStyle: 'none',
  flexDirection: 'row',
  justifyContent: 'center',
  backgroundColor: '#FFFFFF',
  _name: 'div_97c4e3b6',
});

const actions_slot_a44c2022 = css({
  width: 'auto',
  height: 'auto',
  display: 'flex',
  padding: '0px 0px 0px 0px',
  gap: 'normal {dimension.core.size_sm}',
  alignItems: 'center',
  borderStyle: 'none',
  flexDirection: 'row',
  justifyContent: 'center',
  backgroundColor: '#FFFFFF',
  _name: 'actions_slot_a44c2022',
});

const logoicon_ea0efef6 = css({
  cursor: 'pointer',
});

const div_306366b6 = css({
  width: 'auto',
  height: 'auto',
  display: 'flex',
  padding: '80px',
  alignItems: 'center',
  borderStyle: 'none',
  flexDirection: 'row',
  justifyContent: 'center',
  backgroundColor: '#FFFFFF',
});

const right_actions_976c1870 = css({
  gap: 'normal {dimension.core.size_sm}',
  width: 'auto',
  height: 'auto',
  display: 'flex',
  padding: '0px 0px 0px 0px',
  alignItems: 'center',
  borderStyle: 'none',
  flexDirection: 'row',
  justifyContent: 'center',
  backgroundColor: '#FFFFFF',
});

const StyledNavigationEditor = styled<'div', NavigationEditorProps>('div', {
  root: {
    gap: 'normal {dimension.core.size_lg}',
    width: '100%',
    height: '48px',
    display: 'flex',
    padding: '0px 10px 0px 10px',
    alignItems: 'center',
    borderStyle: 'none',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
  },
  variants: [
    {
      props: {
        type: 'Editor',
      },
      style: {
        [`.${div_97c4e3b6}`]: {
          gap: 'normal {dimension.core.size_sm}',
          padding: '0px 0px 0px 0px',
        },
        [`.${div_306366b6}`]: {
          gap: 'normal {dimension.core.size_sm}',
          height: '48px',
          padding: '0px 0px 0px 0px',
        },
      },
    },
    {
      props: {
        type: 'Tokens',
      },
      style: {
        [`.${div_97c4e3b6}`]: {
          gap: 'normal {dimension.core.size_sm}',
          padding: '0px 0px 0px 0px',
        },
        [`.${div_306366b6}`]: {
          gap: 'normal 8px',
          height: '48px',
          padding: '0px 0px 0px 0px',
        },
      },
    },
  ],
});

const icon_f8629381 = css({
  color: 'rgba(26,26,26,1)',
});

export const NavigationEditor = React.forwardRef<
  HTMLDivElement,
  NavigationEditorProps
>(function NavigationEditor({ type = 'Editor', ...otherProps }, ref) {
  const { open: openSidebar } = useToggleSidebar();
  const { trackOpenSidebar } = useTrackEvents();

  const handleLogoClick = useCallback(() => {
    openSidebar();
    trackOpenSidebar();
  }, [openSidebar, trackOpenSidebar]);

  const divIconButtonRef = useRef<HTMLButtonElement>(null);
  const textIconButtonRef = useRef<HTMLButtonElement>(null);
  const slotIconButtonRef = useRef<HTMLButtonElement>(null);
  const inviteUsersIconButtonRef = useRef<HTMLButtonElement>(null);
  const menuVerticalIconButtonRef = useRef<HTMLButtonElement>(null);

  const { handleAddElementToCanvasOnClick } = useAddElementToCanvasOnClick();

  const handleDivIconClick = useCallback(() => {
    handleAddElementToCanvasOnClick('JuxDiv');
  }, [handleAddElementToCanvasOnClick]);

  const handleTextIconClick = useCallback(() => {
    handleAddElementToCanvasOnClick('JuxText');
  }, [handleAddElementToCanvasOnClick]);

  const handleSlotClick = useCallback(() => {
    handleAddElementToCanvasOnClick('JuxSlot');
  }, [handleAddElementToCanvasOnClick]);

  const { openInviteMembersModal } = useOpenInviteMembersModal(
    SegmentEventSourcePage.editor
  );
  const handleInviteUsersIconClick = useCallback(() => {
    openInviteMembersModal();
  }, [openInviteMembersModal]);

  const { openMenuPopper } = useMenuPopper();

  const handleMenuVerticalIconClick = useCallback(() => {
    openMenuPopper({ sourceElementRef: menuVerticalIconButtonRef });
  }, [openMenuPopper]);

  return (
    <>
      <StyledNavigationEditor type={type} ref={ref} {...otherProps}>
        <div className={div_97c4e3b6}>
          <Logoicon onClick={handleLogoClick} className={logoicon_ea0efef6} />
          <NavLeftActions />
          <div className={div_306366b6}>
            <IconButton
              onClick={handleDivIconClick}
              ref={divIconButtonRef}
              disabled={false}
              hierarchy={'primary'}
              data-testid={TEST_ID_DIV_ICON_BUTTON}
            >
              <DivIcon className={icon_f8629381} />
            </IconButton>
            <IconButton
              onClick={handleTextIconClick}
              ref={textIconButtonRef}
              disabled={false}
              hierarchy={'primary'}
              data-testid={TEST_ID_TEXT_ICON_BUTTON}
            >
              <TextIcon className={icon_f8629381} />
            </IconButton>
            <IconButton
              onClick={handleSlotClick}
              ref={slotIconButtonRef}
              disabled={false}
              hierarchy={'primary'}
              data-testid={TEST_ID_SLOT_ICON_BUTTON}
            >
              <SlotIcon className={icon_f8629381} />
            </IconButton>
          </div>
        </div>
        <MiddleSlot />
        <div className={right_actions_976c1870}>
          <LiveOrEdit />
          <div className={actions_slot_a44c2022}>
            <IconButton
              onClick={handleInviteUsersIconClick}
              ref={inviteUsersIconButtonRef}
              disabled={false}
              hierarchy={'primary'}
              data-testid={TEST_ID_INVITE_USERS_ICON_BUTTON}
            >
              <InviteIcon className={icon_f8629381} />
            </IconButton>
            <IconButton
              onClick={handleMenuVerticalIconClick}
              ref={menuVerticalIconButtonRef}
              disabled={false}
              hierarchy={'primary'}
              data-testid={TEST_ID_MENU_VERTICAL_ICON}
            >
              <MenuVerticalIcon className={icon_f8629381} />
            </IconButton>
          </div>
        </div>
      </StyledNavigationEditor>
      <KeyboardShortcutPopover />
      <Tooltip content="Div" reference={divIconButtonRef} />
      <Tooltip content="Text" reference={textIconButtonRef} />
      <Tooltip
        content={slotTooltipContent}
        maxWidth="224px"
        header="Dynamic content slot"
        reference={slotIconButtonRef}
      />
      <Tooltip
        content="Invite new members"
        reference={inviteUsersIconButtonRef}
      />
      <Tooltip
        content="Knowledge base and more..."
        reference={menuVerticalIconButtonRef}
      />
    </>
  );
});
