import { FC } from 'react';
import { Tooltip, Typography } from '@jux/ui/components';
import { MuiBox, MuiMenuItem } from '@jux/ui/components/common/mui';
import { DisableDeleteReturnType } from '../../interface';

export const DeleteTokenActionItem: FC<{
  onDeleteToken: () => void;
  shouldDisableDeleteToken: () => DisableDeleteReturnType;
}> = ({ shouldDisableDeleteToken, onDeleteToken }) => {
  const disableDeleteToken = shouldDisableDeleteToken();

  return (
    <Tooltip content={disableDeleteToken.reason || ''}>
      <MuiBox>
        <MuiMenuItem
          onClick={onDeleteToken}
          disabled={disableDeleteToken.disabled}
          disableRipple
        >
          <Typography variant="body-regular">Delete</Typography>
        </MuiMenuItem>
      </MuiBox>
    </Tooltip>
  );
};
