import { UserProvider } from '@auth0/nextjs-auth0/client';
import { CacheProvider } from '@emotion/react';
import {
  Hydrate,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import Head from 'next/head';
import Script from 'next/script';
import { FC, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import logger from '@jux/ui-logger';
import { AppLayoutProvider, ErrorBoundary } from '@jux/ui/components';
import { HydrateAtoms, JotaiProvider, jotaiStore } from '@jux/ui/jotai';
import { api } from '@jux/ui/trpc/client/api';
import createEmotionCache, {
  EnhancedAppProps,
} from '@jux/ui/utils/CreateEmotionCache';

// css imports
import 'react-toastify/dist/ReactToastify.css';
import '@fontsource/nunito-sans';
import '../canjux.style.css';
import './main.css';
import { getCanduSctipt } from '../lib/candu-script';

const clientSideEmotionCache = createEmotionCache();

// Candu
const isProd = process.env.NODE_ENV === 'production';
const CANDU_SCRIPT = getCanduSctipt(uuidv4(), isProd);

const FrontendService: FC<EnhancedAppProps> = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onError: (error: any) => {
            logger.error(`Error: ${error.message}`);
            // https://react-query.tanstack.com/reference/setLogger
          },
        }),
      })
  );

  // const GTM_KEY = process.env.NEXT_PUBLIC_GTM_KEY;
  const isStaging =
    typeof window !== 'undefined'
      ? window.location.hostname.includes('staging')
      : false;

  const theme =
    typeof window !== 'undefined'
      ? JSON.parse(localStorage.getItem('settings') as string)?.theme
      : null;
  const isDarkTheme = theme === 'dark';

  return (
    <>
      <ErrorBoundary>
        <CacheProvider value={emotionCache}>
          <Script
            src="https://kit.fontawesome.com/c0c753c6b3.js"
            crossOrigin="anonymous"
          />
          <Script
            id="candu-script"
            dangerouslySetInnerHTML={{ __html: CANDU_SCRIPT }}
            strategy="afterInteractive"
          />
          <Head>
            <title>JUX</title>
            <meta
              name="viewport"
              content="initial-scale=1, width=device-width"
            />
            <link
              rel="icon"
              href={
                isStaging
                  ? '/favicon-staging.ico'
                  : isDarkTheme
                  ? '/favicon-dark-theme.ico'
                  : '/favicon.ico'
              }
            />
          </Head>
          <UserProvider>
            <QueryClientProvider client={queryClient}>
              <JotaiProvider store={jotaiStore}>
                <Hydrate state={pageProps.dehydratedState}>
                  <HydrateAtoms queryClient={queryClient}>
                    <AppLayoutProvider>
                      <Component {...pageProps} />
                    </AppLayoutProvider>
                  </HydrateAtoms>
                </Hydrate>
              </JotaiProvider>
            </QueryClientProvider>
          </UserProvider>
        </CacheProvider>
      </ErrorBoundary>
    </>
  );
};

export default api.withTRPC(FrontendService);
