import { FC } from 'react';
import { Icons } from '@jux/ui/components';
import { NodeType } from '@jux/data-entities';
import { getIconsVariant } from './helpers';

const DEFAULT_ICON_SIZE = 16;

export const ObjectNavigatorItemTypeIcon: FC<{
  objectType: NodeType;
  tagName: string;
  sourceComponentType?: NodeType;
}> = ({ objectType, tagName, sourceComponentType }) => (
  <Icons
    size={DEFAULT_ICON_SIZE}
    variant={getIconsVariant({
      objectType,
      tagName,
      sourceComponentType,
    })}
  />
);
