import { FC } from 'react';
import { IconButton, ModalWrapper, Typography } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { ConfirmationModalContentProps } from './ConfirmationModal.interface';

export const ConfirmationModalContent: FC<ConfirmationModalContentProps> = ({
  title,
  children,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
}) => (
  <ModalWrapper onClose={onCancel}>
    <MuiGrid item container>
      <Typography
        variant="h2"
        color={(theme) => theme.drimz.palette.text.primary}
      >
        {title}
      </Typography>
    </MuiGrid>
    {children && (
      <MuiGrid item container marginTop="24px">
        {children}
      </MuiGrid>
    )}
    <MuiGrid
      display="flex"
      marginTop="24px"
      gap="16px"
      alignItems="flex-start"
      alignSelf="stretch"
      width="100%"
    >
      <IconButton
        fullWidth
        onClick={onCancel}
        tabIndex={1}
        variant="secondary"
        size="big"
      >
        {cancelText}
      </IconButton>
      <IconButton
        fullWidth
        onClick={onConfirm}
        tabIndex={1}
        variant="primary"
        size="big"
      >
        {confirmText}
      </IconButton>
    </MuiGrid>
  </ModalWrapper>
);
