import { type ThemeConfig } from '@jux/types';
import { isAliasPattern } from '@juxio/design-tokens';
import { CSSProperties } from 'react';
import { getTokenResolver } from './getTokenResolver';

/**
 * Returns a function that resolves a token value from the theme.
 */
export const getMultiValuesTokenResolver = (
  value: string,
  theme: ThemeConfig
): CSSProperties[keyof CSSProperties] =>
  value
    .split(' ')
    .map((val) => (isAliasPattern(val) ? getTokenResolver(val, theme) : val))
    .join(' ');
