import * as uuid from 'uuid';
import {
  ComponentInstanceData,
  INHERITED_FROM_PARENT,
  JuxComponentData,
  NodeType,
} from '@jux/data-entities';
import { getJuxCheckboxElementData } from '../checkbox/checkbox';

export const getJuxCheckboxInstanceElementData = ({
  parentId,
}: {
  parentId?: string;
}) => {
  const rootCheckboxInstanceId = uuid.v4();

  const {
    root: newCheckboxLocalComponent,
    subComponents: newCheckboxSubComponents,
  } = getJuxCheckboxElementData({});

  const checkboxInstanceCheckedIconChild: ComponentInstanceData = {
    type: NodeType.INSTANCE,
    id: uuid.v4(),
    parentId: rootCheckboxInstanceId,
    children: [],
    config: {
      props: {},
    },
    displayName: 'check_icon',
    sourceComponentId: newCheckboxLocalComponent.children[0],
  };

  const checkboxInstanceIndeterminateIcon: ComponentInstanceData = {
    type: NodeType.INSTANCE,
    id: uuid.v4(),
    parentId: rootCheckboxInstanceId,
    children: [],
    config: {
      props: {},
    },
    displayName: 'indeterminate_icon',
    sourceComponentId: newCheckboxLocalComponent.children[1],
  };

  const checkboxInstance: ComponentInstanceData = {
    type: NodeType.INSTANCE,
    id: rootCheckboxInstanceId,
    parentId,
    children: [
      checkboxInstanceCheckedIconChild.id,
      checkboxInstanceIndeterminateIcon.id,
    ],
    config: {
      props: {
        // starting values of props
        checked: INHERITED_FROM_PARENT,
        disabled: INHERITED_FROM_PARENT,
      },
    },
    displayName: 'checkbox',
    sourceComponentId: newCheckboxLocalComponent.id,
  };

  return {
    root: checkboxInstance,
    subComponents: (
      [
        newCheckboxLocalComponent,
        checkboxInstanceCheckedIconChild,
        checkboxInstanceIndeterminateIcon,
      ] as Array<JuxComponentData>
    ).concat(newCheckboxSubComponents || []),
  };
};
