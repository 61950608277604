import {
  CommonActionsParams,
  ComponentPlacement,
  findFirstTargetUpTree,
  getRootNodeValidPlacement,
  JuxStoreActionFn,
  selectCurrentCanvas,
  setLayersData,
  storeApi,
} from '@jux/canjux/core';
import { ComponentTagNames } from '@jux/data-entities';
import { ELEMENTS_DATA } from '@jux/elements';
import type { Draft as WritableDraft } from 'mutative';
import { CanjuxState } from '../../store';
import { getDefaultNodeData } from '../helpers';
import { createNode } from './createNode';

const createTextNodeInternal = ({
  state,
  targetCanvasName,
  targetNodeId,
  text,
}: Pick<CommonActionsParams['createTextNode'], 'text' | 'targetCanvasName'> & {
  state: WritableDraft<CanjuxState>;
  targetNodeId?: string;
}) => {
  const createTextElement = ELEMENTS_DATA[ComponentTagNames.JuxText];
  const textElementData = createTextElement({ text });

  const { width, height, transform } = state;
  const currentCanvas = selectCurrentCanvas(storeApi.getState());

  let targetPlacement: ComponentPlacement;
  if (!targetNodeId) {
    targetPlacement = getRootNodeValidPlacement({
      components: state.components,
      canvasNodesDimensions: state.canvasNodesDimensions,
      canvas: currentCanvas,
      transform: transform,
      canvasDimensions: { width, height },
    });
  } else {
    targetPlacement = findFirstTargetUpTree({
      componentId: targetNodeId,
      components: state.components,
      canvas: currentCanvas,
    });
    if (!targetPlacement.targetNodeId) {
      // if there was a target node id but no valid parent target node was found, skip it
      return state;
    }
  }

  const component = textElementData.root;
  component.parentId = targetPlacement.targetNodeId;
  const nodeData = getDefaultNodeData({
    parentId: targetPlacement.targetNodeId,
    position: targetPlacement.position,
  });

  createNode({
    canvasName: targetCanvasName,
    data: {
      node: nodeData,
      component,
      targetIndex: targetPlacement.targetChildIndex,
    },
    nodeId: component.id,
    state,
  });

  return state;
};

export const createTextNode: JuxStoreActionFn<
  CommonActionsParams['createTextNode'],
  CanjuxState
> = ({ targetCanvasName, targetNodeIds, text, state }) => {
  if (targetNodeIds?.length) {
    for (const targetNodeId of targetNodeIds) {
      createTextNodeInternal({
        state,
        targetCanvasName,
        targetNodeId,
        text,
      });
    }
  } else {
    createTextNodeInternal({
      state,
      targetCanvasName,
      text,
    });
  }

  setLayersData(state);

  return state;
};
