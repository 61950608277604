import { CanjuxState } from '@jux/canjux/core';
import {
  ComponentSourceData,
  ComponentTagNames,
  JuxComponentData,
  NodeType,
} from '@jux/data-entities';

export const getResolvedSourceComponentData = ({
  component,
  components,
  shouldStopOnVariantMatrix = false,
}: {
  component: JuxComponentData;
  components: CanjuxState['components'];
  shouldStopOnVariantMatrix?: boolean;
}) => {
  let lastAncestor = component;

  while (
    lastAncestor?.sourceComponentId &&
    lastAncestor?.tagName !== ComponentTagNames.JuxSvg && // Svgs have source id for the asset data &&
    !(
      lastAncestor.type === NodeType.VARIANTS_GROUP && shouldStopOnVariantMatrix
    )
  ) {
    lastAncestor = components[lastAncestor.sourceComponentId];
  }

  return lastAncestor as ComponentSourceData;
};
