import * as https from 'https';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { NextApiRequest, NextApiResponse } from 'next';
import { getAccessToken } from '@auth0/nextjs-auth0';
import Router from 'next/router';

const axiosInstance = axios.create({
  baseURL: process.env.APP_URL,
  httpsAgent: new https.Agent({
    rejectUnauthorized: process.env.NODE_ENV === 'production',
  }),
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      return Router.push(`/api/auth/login?returnTo=${Router.asPath}`);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

export async function withAccessToken(
  req: NextApiRequest,
  res: NextApiResponse
): Promise<AxiosInstance> {
  const { accessToken } = await getAccessToken(req, res);

  return axios.create({
    withCredentials: true,
    httpsAgent: new https.Agent({
      rejectUnauthorized: process.env.NODE_ENV === 'production',
    }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'User-Agent': req.headers['user-agent'] as string,
    },
  });
}

export function guestOnly() {
  return axios.create({
    httpsAgent: new https.Agent({
      rejectUnauthorized: process.env.NODE_ENV === 'production',
    }),
  });
}
