import {
  CanjuxState,
  DDPActionsParams,
  getContextParentByNodeId,
  getRootVariantConfig,
  JuxStoreActionFn,
  resolvedProps,
  setLayersData,
} from '@jux/canjux/core';
import {
  ComponentPropValue,
  JuxComponentData,
  NodeInteractiveState,
  NodeType,
} from '@jux/data-entities';
import { ContextStyle, DEFAULT_STATE } from '@jux/types';
import * as CSS from 'csstype';
import type { Draft as WritableDraft } from 'mutative';
import { updateStylesSheet } from './utils/updateStylesSheet';
import deepmerge from 'deepmerge';

const isMatchCondition = ({
  condition,
  editingContextProps,
  contextParentInteractiveState,
}: {
  condition: ContextStyle['condition'] | undefined;
  editingContextProps: Record<string, any>;
  contextParentInteractiveState: NodeInteractiveState;
}) => {
  if (!condition) {
    return (
      Object.keys(editingContextProps).length === 0 &&
      contextParentInteractiveState === DEFAULT_STATE
    );
  }

  const conditionProps = condition.propsValues ?? {};
  const isPropsMatch =
    Object.keys(conditionProps).length ===
      Object.keys(editingContextProps).length &&
    Object.entries(conditionProps).every(
      ([propName, propValue]) =>
        propName in editingContextProps &&
        editingContextProps[propName] === propValue
    );

  const conditionState = condition.state ?? DEFAULT_STATE;
  const isInteractiveStateMatch =
    conditionState === contextParentInteractiveState;

  return isPropsMatch && isInteractiveStateMatch;
};

const updateParentContextStyles = ({
  targetComponent,
  contextParentProps,
  interactiveState,
  newStyles,
  state,
}: {
  targetComponent: JuxComponentData;
  newStyles: CSS.Properties;
  state: WritableDraft<CanjuxState>;
  contextParentProps?: Record<string, ComponentPropValue>;
  interactiveState?: NodeInteractiveState;
}) => {
  const contextParentId = getContextParentByNodeId({
    id: targetComponent.id,
    components: state.components,
  });
  if (!contextParentId) {
    throw new Error(
      'Cannot save context styles on node with no parent context'
    );
  }

  const contextParentComponent = state.components[contextParentId];
  if (!contextParentComponent || !contextParentComponent.styles) {
    throw new Error(
      'Cannot save context styles on node with no parent context component or styles'
    );
  }

  const editingContextProps =
    contextParentProps ??
    resolvedProps({
      id: contextParentId,
      assets: state.assets,
      components: state.components,
      onlyVariantsProps: true,
    });

  const contextParentInteractiveState =
    interactiveState ??
    contextParentComponent.config.interactiveState ??
    DEFAULT_STATE;

  const effectiveContextId =
    targetComponent.config.contextId ?? targetComponent.id;

  const contextStyle = contextParentComponent.styles.contextStyles?.find(
    ({ contextChildUuid, condition }) =>
      contextChildUuid === effectiveContextId &&
      isMatchCondition({
        condition,
        editingContextProps,
        contextParentInteractiveState,
      })
  );

  if (contextStyle) {
    contextStyle.styles = deepmerge(contextStyle.styles, newStyles);
  } else {
    if (!contextParentComponent.styles.contextStyles) {
      contextParentComponent.styles.contextStyles = [];
    }

    contextParentComponent.styles.contextStyles =
      contextParentComponent.styles.contextStyles.concat([
        {
          styles: newStyles,
          contextChildUuid: effectiveContextId,
          condition: {
            state: contextParentInteractiveState,
            propsValues: editingContextProps,
          },
        },
      ]);
  }
};

const updateVariantInstanceStyles = ({
  component,
  newStyles,
  state,
}: {
  component: JuxComponentData;
  newStyles: CSS.Properties;
  state: WritableDraft<CanjuxState>;
}) => {
  if (!component.sourceComponentId) {
    throw new Error('Variant instance without source component id');
  }

  const variantConfig = getRootVariantConfig({
    component: component,
    components: state.components,
    assets: state.assets,
  });

  const sourceComponent = state.components[component.sourceComponentId];
  if (sourceComponent.type === NodeType.INSTANCE) return; // cannot change this node

  const shouldSaveNewStylesOnParentContext = Boolean(sourceComponent.parentId);
  if (shouldSaveNewStylesOnParentContext) {
    updateParentContextStyles({
      targetComponent: sourceComponent,
      newStyles,
      contextParentProps: variantConfig.variantValues,
      interactiveState: variantConfig.interactiveState,
      state,
    });
  } else {
    if (!sourceComponent.styles)
      throw new Error('No styles on source component');

    // Save styles on the current node
    updateStylesSheet({
      currentStyles: sourceComponent.styles,
      variantsEditingContext: variantConfig.variantValues,
      nodeInteractiveState: variantConfig.interactiveState,
      newCss: newStyles,
    });
  }
};

/**
 * Update a component style.
 */
export const updateComponentStyles: JuxStoreActionFn<
  DDPActionsParams['updateComponentStyles'],
  CanjuxState
> = ({ nodeIds, newStyles, parentContextState, state }) => {
  for (const nodeId of nodeIds) {
    const targetComponent = state.components[nodeId];
    if (!targetComponent || targetComponent.type === NodeType.INSTANCE)
      continue;

    if (targetComponent.type === NodeType.VARIANT_INSTANCE) {
      updateVariantInstanceStyles({
        component: targetComponent,
        newStyles,
        state,
      });
      continue;
    }

    const shouldSaveNewStylesOnCurrentComponent = !parentContextState[nodeId];
    if (shouldSaveNewStylesOnCurrentComponent && targetComponent.styles) {
      const variantsEditingContext = resolvedProps({
        assets: state.assets,
        components: state.components,
        id: targetComponent.id,
        onlyVariantsProps: true,
      });

      updateStylesSheet({
        currentStyles: targetComponent.styles,
        nodeInteractiveState:
          targetComponent.config.interactiveState ?? DEFAULT_STATE,
        variantsEditingContext,
        newCss: newStyles,
      });
    } else {
      updateParentContextStyles({
        targetComponent,
        newStyles,
        state,
      });
    }
  }

  setLayersData(state);

  return state;
};
