import { ComponentConfigWithStates } from '@jux/types';

export type ReplacePaths = {
  oldPath: string;
  newPath: string;
};

export const replacePathsInStyles = ({
  paths,
  styles,
}: {
  paths: Array<ReplacePaths>;
  styles: ComponentConfigWithStates;
}): ComponentConfigWithStates | undefined => {
  let stringifiedStyles = JSON.stringify(styles);

  for (const { oldPath, newPath } of paths) {
    if (!stringifiedStyles.includes(oldPath)) {
      continue;
    }

    stringifiedStyles = stringifiedStyles.replaceAll(oldPath, newPath);
  }

  return JSON.parse(stringifiedStyles) as ComponentConfigWithStates;
};
