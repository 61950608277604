import { useCallback, useMemo } from 'react';
import {
  CompositeTokenTypes,
  concatTokenPath,
  DesignTokenValue,
  formatToAlias,
  getAliasMatches,
  isAliasPattern,
  SupportedTokenTypes,
} from '@juxio/design-tokens';
import {
  formatTokenSetData,
  GroupedTokens,
  toRecordBy,
  useStore,
} from '@jux/canjux/core';
import { CORE } from '@jux/types';
import { createPath } from '@jux/ui/utils/tokensPath';

export const useTokensByType = (
  fieldName: string,
  tokenType: SupportedTokenTypes | null,
  parentFieldTokenType?: CompositeTokenTypes
) => {
  const { editorTokenSetId, tokenSets } = useStore((s) => ({
    editorTokenSetId: s.editorTokenSetId,
    tokenSets: s.tokenSets,
  }));

  const tokenSetData = useMemo(() => {
    if (!editorTokenSetId) return undefined;

    const tokenSet = toRecordBy(tokenSets, 'id')[editorTokenSetId];
    return formatTokenSetData({ tokenSet });
  }, [editorTokenSetId, tokenSets]);

  const valuesMapByType = useMemo(() => {
    if (!tokenSetData?.valuesMap || !tokenType) return undefined;

    return Object.keys(tokenSetData.valuesMap).reduce((acc, key) => {
      if (
        key.startsWith(tokenType) ||
        key.startsWith(createPath([CORE, tokenType]))
      ) {
        acc[key] = tokenSetData.valuesMap[key];
      }
      return acc;
    }, {} as Record<string, DesignTokenValue>);
  }, [tokenSetData?.valuesMap, tokenType]);

  const flattenTokensGroupedByType = useCallback(
    (type: CompositeTokenTypes | SupportedTokenTypes | null | undefined) => {
      if (!type) {
        return [];
      }

      const tokenGroupsByType = tokenSetData?.groupsByType?.[type];
      const coreTokenGroupsByType = tokenSetData?.core?.groupsByType?.[type];

      if (!tokenGroupsByType && !coreTokenGroupsByType) {
        return [];
      }

      const recursivelyFindAllTokensInGroup = (
        group: GroupedTokens | undefined,
        allTokens: GroupedTokens['tokens']
      ) => {
        const tokens: GroupedTokens['tokens'] = [];
        group?.groups.forEach((g) =>
          tokens.push(...recursivelyFindAllTokensInGroup(g, allTokens))
        );

        return allTokens.concat(group?.tokens ?? [], tokens);
      };

      return [
        ...recursivelyFindAllTokensInGroup(tokenGroupsByType, []),
        ...recursivelyFindAllTokensInGroup(coreTokenGroupsByType, []),
      ];
    },
    [tokenSetData?.core?.groupsByType, tokenSetData?.groupsByType]
  );

  const flattenTokensByType = useMemo(
    () => flattenTokensGroupedByType(tokenType),
    [flattenTokensGroupedByType, tokenType]
  );
  const flattenParentFieldTokenByType = useMemo(
    () => flattenTokensGroupedByType(parentFieldTokenType),
    [flattenTokensGroupedByType, parentFieldTokenType]
  );

  const tokensByTypeInDesignTokenDataShape = useMemo(
    () =>
      flattenTokensByType.map((token) => ({
        name: token.path,
        value: token.value,
      })),
    [flattenTokensByType]
  );

  const tokensOfCompositeParentField = useMemo(
    () =>
      flattenParentFieldTokenByType
        .map((fullToken) => {
          if (
            fullToken.value &&
            typeof fullToken.value === 'object' &&
            !Array.isArray(fullToken.value) && // make sure value is an object and not array of strings
            fieldName in fullToken.value
          ) {
            // TODO: check that all fullToken.value[fieldName] are correct
            return {
              ...fullToken,
              path: concatTokenPath(fullToken.path, fieldName),
              value: fullToken.value[fieldName]?.toString() ?? '',
            };
          }

          return fullToken;
        })
        .filter(Boolean),
    [flattenParentFieldTokenByType, fieldName]
  );

  const getTokenNameByValue = useCallback(
    (value: string) => getAliasMatches(value)?.valuePath,
    []
  );

  // TODO: give this a better name
  const resolveValueFromTokens = useCallback(
    (value: string) => {
      if (
        !isAliasPattern(value) ||
        (flattenTokensByType.length === 0 && parentFieldTokenType?.length === 0)
      ) {
        return undefined;
      }

      const resolvedMatches = flattenTokensByType.find(
        ({ path }) => path === getTokenNameByValue(value)
      );
      if (resolvedMatches) return resolvedMatches;

      const resolvedParentMatches = tokensOfCompositeParentField.find(
        ({ path }) => formatToAlias(path) === value
      );

      if (resolvedParentMatches) return resolvedParentMatches;

      return undefined;
    },
    [
      flattenTokensByType,
      parentFieldTokenType?.length,
      tokensOfCompositeParentField,
      getTokenNameByValue,
    ]
  );

  const resolveTokenValue = useCallback(
    (alias?: string): string | undefined => {
      // if it's not a token
      if (!alias || !isAliasPattern(alias)) {
        return undefined;
      }

      const aliasArray = alias.split(' ');

      // if it's not a multiple values
      if (aliasArray.length <= 1) {
        const resolved = resolveValueFromTokens(alias);
        if (!resolved) return undefined;

        return resolved.value?.toString();
      }

      const resolvedAlias = aliasArray.map(
        (ali) => resolveValueFromTokens(ali)?.value ?? ali
      );

      return resolvedAlias
        .map((a) => a)
        .join(' ')
        .trim();
    },
    [resolveValueFromTokens]
  );

  const resolveFieldValueFromTokens = useCallback(
    (fieldValue: string) => {
      // if it's not a token
      if (!isAliasPattern(fieldValue)) {
        return undefined;
      }

      const multiFieldsValuesArray = fieldValue.split(' ');

      // if it's not a multiple values
      if (multiFieldsValuesArray.length <= 1) {
        const resolved = resolveValueFromTokens(fieldValue);
        if (!resolved) return undefined;

        return resolved;
      }

      // if it's a multiple values and all values are the same
      const firstValue = multiFieldsValuesArray[0];
      if (multiFieldsValuesArray.every((val) => val === firstValue)) {
        const resolved = resolveValueFromTokens(firstValue);
        if (!resolved) return undefined;

        return resolved;
      }
      return undefined;
    },
    [resolveValueFromTokens]
  );

  const getTokenValueFromValuesMapByPath = useCallback(
    (path: string) => {
      if (!valuesMapByType) return undefined;

      return valuesMapByType[path];
    },
    [valuesMapByType]
  );

  return {
    parsedTokens: tokensByTypeInDesignTokenDataShape,
    tokensOfCompositeParentField,
    resolveFieldValueFromTokens,
    resolveTokenValue,
    getTokenValueFromValuesMapByPath,
  };
};
