import { useCallback, useMemo } from 'react';
import { DesignTokenTypeEnum } from '@juxio/design-tokens';
import { usePopupState } from '@jux/ui/components/common/mui';
import { useColorFieldValue } from '@jux/ui/components/editor/components/panels/DDP/hooks/useColorField/useColorFieldValue';
import { useFieldTokens } from '@jux/ui/components/editor/components/panels/DDP/hooks/useFieldTokens';
import { useParseFieldValue } from '@jux/ui/components/editor/components/panels/DDP/hooks/useParseFieldValue';
import { compareStrValues } from '@jux/ui/utils/compareStrValues';
import {
  UseColorFieldProps,
  UseColorFieldReturnProps,
} from './useColorField.interface';
import {
  DEFAULT_COLOR_CSS_VALUE,
  isNoneColorValue,
  parseFieldValueToTokenValue,
} from './useColorField.utils';
import { useColorFieldInputs } from './useColorFieldInputs';

export const ALPHA_FIELD_NAME = 'alpha';
const TOKEN_TYPE = DesignTokenTypeEnum.color;

export const useColorField = ({
  value,
  initialValue,
  fieldName,
  saveChanges: setFieldValue,
}: UseColorFieldProps): UseColorFieldReturnProps => {
  const { rawValue, isComputed } = useParseFieldValue({
    fieldName,
    value,
    initialValue,
    tokenType: TOKEN_TYPE,
  });

  const { parseValue, parsedValue } = useColorFieldValue(rawValue);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'ddp-color-picker-popover',
  });

  const finalSaveChanges = useCallback(
    (newValue: string | undefined) => {
      newValue = isNoneColorValue(newValue)
        ? DEFAULT_COLOR_CSS_VALUE
        : newValue;

      // If the value didn't change, do not save
      if (compareStrValues(newValue, value)) return;

      setFieldValue(newValue);
    },
    [setFieldValue, value]
  );

  const saveChanges = useCallback(
    (newValue: any, isChangeComplete?: boolean) => {
      if (isChangeComplete) {
        finalSaveChanges(newValue);
      }
    },
    [finalSaveChanges]
  );

  const setFieldValueOnTokenDrawerChange = useCallback(
    ({
      newValue,
      isChangeComplete,
    }: {
      newValue: string;
      isChangeComplete?: boolean;
      subFieldName?: string;
    }) => {
      saveChanges(newValue, isChangeComplete);
    },
    [saveChanges]
  );

  const {
    hexValueInputRegister,
    alphaValueInputRegister,
    hexInputProps,
    alphaInputProps,
    updateStateByTokenValue,
  } = useColorFieldInputs({
    fieldName,
    parsedValue,
    initialValue,
    saveChanges: finalSaveChanges,
    parseValue,
  });

  const {
    isTokenized,
    tokens,
    selectedToken,
    tokenDisplayName,
    tokenParsedValue,
    handleCreateNewTokenSaved,
    handleTokenSelection,
    handleTokenDetach,
    tokensPopper,
  } = useFieldTokens({
    fieldName,
    setStateByTokenValue: updateStateByTokenValue,
    setFieldValue: finalSaveChanges,
    value,
    tokenType: TOKEN_TYPE,
  });

  const tokenCreationInitialValue = useMemo(
    () => parseFieldValueToTokenValue(parsedValue),
    [parsedValue]
  );

  const parsedTokenParsedValue = useMemo(
    () => parseValue(tokenParsedValue?.toString() ?? ''),
    [parseValue, tokenParsedValue]
  );

  const onTokenDrawerCancel = useCallback(
    (initialVal: string) => {
      finalSaveChanges(initialVal);
    },
    [finalSaveChanges]
  );

  return {
    isComputed,
    setValueByRGBA: saveChanges,
    parsedValue,
    popupState,
    hexValueInputRegister,
    alphaValueInputRegister,
    hexInputProps,
    alphaInputProps,

    tokensProps: {
      tokenType: TOKEN_TYPE,
      tokens,
      isTokenized,
      selectedToken,
      tokenDisplayName,
      tokenParsedValue: parsedTokenParsedValue,
      onTokenSelection: handleTokenSelection,
      onTokenDetach: handleTokenDetach,
      tokensPopperProps: tokensPopper,
      tokenDrawerProps: {
        initialValue: tokenCreationInitialValue,
        onValueChange: setFieldValueOnTokenDrawerChange,
        onSave: handleCreateNewTokenSaved,
        onCancelButton: onTokenDrawerCancel,
      },
    },
  };
};
