import { FC } from 'react';
import { DesignTokenTypeEnum } from '@juxio/design-tokens';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import { SelectField } from '../../../../base';
import {
  useFieldValues,
  useSelectField,
  useSetFieldValue,
} from '../../../../hooks';

const FIELD_KEY = DDPPrimitiveFieldsKeys.fontWeight;

// TODO: get actual list and parse it properly
const weights = [
  {
    value: '100',
    label: '100',
  },
  {
    value: '200',
    label: '200',
  },
  {
    value: '300',
    label: '300',
  },
  {
    value: '400',
    label: '400',
  },
  {
    value: '500',
    label: '500',
  },
  {
    value: '600',
    label: '600',
  },
  {
    value: '700',
    label: '700',
  },
  {
    value: '800',
    label: '800',
  },
  {
    value: '900',
    label: '900',
  },
];

export const FontWeightField: FC<{ isFieldDisabled?: boolean }> = ({
  isFieldDisabled,
}) => {
  const { value, initialValue } = useFieldValues(FIELD_KEY);
  const { saveValue: setFieldValue } = useSetFieldValue(FIELD_KEY);

  const props = useSelectField({
    fieldName: FIELD_KEY,
    value,
    initialValue,
    saveChanges: setFieldValue,
    tokenType: null,
    initSelectionOptions: weights,
    parentFieldTokenType: DesignTokenTypeEnum.typography,
  });

  return (
    <SelectField
      icon="DDP_FIELD_FONT_WEIGHT"
      tooltipTitle="Font weight"
      isFieldDisabled={isFieldDisabled}
      {...props}
    />
  );
};
