import { PopperDirection } from '@jux/ui/components';
import { MuiGrid, MuiPaper, styled } from '@jux/ui/components/common/mui';
import { withPx } from '@jux/ui/utils/css';

const MIN_HEIGHT = 450;
const MAX_HEIGHT = 900;
const VH_HEIGHT = '80vh';

const shadowSpace = (radius: number) => withPx(radius * 2);
const minusShadowSpace = (radius: number) => `-${shadowSpace(radius)}`;

export const Wrapper = styled(MuiGrid, {
  shouldForwardProp: (prop) => prop !== 'popperDirection',
})<{ popperDirection: PopperDirection }>(({ theme, popperDirection }) => ({
  // This is a hack to disable the box shadow effect on top of the element, so it will not be shown on top of other elements
  [popperDirection === 'rtl' ? 'marginRight' : 'marginLeft']: minusShadowSpace(
    theme.drimz.shadow.editor.navbar.menuTab.content.spreadRadius
  ),
  padding: shadowSpace(
    theme.drimz.shadow.editor.navbar.menuTab.content.spreadRadius
  ),
  paddingTop: 0,
  overflow: 'hidden',
}));

export const WrapperContent = styled(MuiPaper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 256,
  backgroundColor: theme.drimz.palette.background.default,
  minWidth: theme.drimz.size.editor.navbar.menuTab.content.minWidth,

  borderRadius: `0 0 ${theme.drimz.size.borderRadius.main} ${theme.drimz.size.borderRadius.main}`,
  borderTop: `1px solid ${theme.drimz.palette.background.secondary}`,
  boxShadow: theme.drimz.shadow.editor.navbar.menuTab.content.boxShadow,

  // enable vertical scroll -scrollbar should be visible only when scrolling
  overflowY: 'auto',
  overflowX: 'hidden',

  maxHeight: MAX_HEIGHT,
  minHeight: MIN_HEIGHT,
  height: VH_HEIGHT,
}));

export const NavigationItemGrid = styled(MuiGrid)(({ theme }) => ({
  paddingLeft: theme.drimz.size.editor.navbar.menuTab.content.padding,
  paddingRight: theme.drimz.size.editor.navbar.menuTab.content.padding,
}));

export const ContentHeader = styled(MuiGrid)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.drimz.palette.background.default,
  zIndex: 1,
}));

export const ItemsListGrid = styled(NavigationItemGrid)(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  paddingBottom: theme.drimz.size.editor.navbar.menuTab.content.padding,
}));
