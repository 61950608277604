import { CanjuxState } from '@jux/canjux/core';
import { NodeType } from '@jux/data-entities';

export const getComponentSlotNames = ({
  components,
  componentId,
  slotNames = [],
}: {
  components: CanjuxState['components'];
  componentId: string;
  slotNames?: Array<string>;
}) => {
  const componentToCheck = components[componentId];

  componentToCheck.children.forEach((cId) => {
    const comp = components[cId];
    if (comp.type === NodeType.DYNAMIC_SLOT) {
      slotNames.push(comp.displayName);
    }

    if (comp.type !== NodeType.INSTANCE) {
      const slots = getComponentSlotNames({
        components,
        componentId: comp.id,
        slotNames,
      });
      slotNames.concat(...slots);
    }
  });

  return slotNames;
};
