import { ComponentSourceData, NodeType } from '@jux/data-entities';
import type { Draft as WritableDraft } from 'mutative';
import {
  createVariantInMatrixNodes,
  getVariantDisplayName,
  getMatrixVariantsConfig,
  JuxStore,
  setLayersData,
} from '@jux/canjux/core';
import { getMatrixGridColumnsAndRows } from './getVariantsGroupWrapperData';

/**
 * This function adds new variants to the matrix nodes when a new prop value is added
 * @param sourceComponentId
 * @param state
 */
export const updateMatrixNodesOnAddPropValue = ({
  sourceComponentId,
  state,
}: {
  sourceComponentId: string;
  state: WritableDraft<JuxStore>;
}) => {
  const matrixRootNodes = Object.values(state.components).filter(
    (component) =>
      component?.sourceComponentId === sourceComponentId &&
      component.type === NodeType.VARIANTS_GROUP
  ) as ComponentSourceData[];

  if (!matrixRootNodes.length) return;

  const sourceComponent = state.components[
    sourceComponentId
  ] as ComponentSourceData;
  const { gridTemplateColumns, gridTemplateRows } =
    getMatrixGridColumnsAndRows(sourceComponent);

  for (const matrixRootNode of matrixRootNodes) {
    // Update the grid template columns and rows
    matrixRootNode.styles.root.gridTemplateColumns = gridTemplateColumns;
    matrixRootNode.styles.root.gridTemplateRows = gridTemplateRows;

    const originalChildrenLength = matrixRootNode.children.length;
    let variantIndex = 0;

    const matrixVariants = getMatrixVariantsConfig(
      sourceComponent as ComponentSourceData
    );
    for (const variantCombination of matrixVariants) {
      // Since we are adding a new prop value we go over every existing variant and just update it
      // to the the right variant display name, values, etc (making sure it is up to date in it's config)..
      // Only after we are passed all the existing variants we need to start creating new ones
      if (variantIndex < originalChildrenLength) {
        const currentVariant =
          state.components[matrixRootNode.children[variantIndex]];

        // Update existing variant
        currentVariant.config.interactiveState =
          variantCombination.interactiveState;
        currentVariant.config.props = { ...variantCombination.variantValues };
        currentVariant.displayName = getVariantDisplayName({
          interactiveState: variantCombination.interactiveState,
          variantsConfig: sourceComponent.config.variants ?? [],
          variantValues: variantCombination.variantValues,
        });
      } else {
        // Create a new variant
        createVariantInMatrixNodes({
          sourceComponentId,
          variantValues: variantCombination.variantValues,
          interactiveState: variantCombination.interactiveState,
          variantsGroupId: matrixRootNode.id,
          state,
        });
      }

      variantIndex++;
    }
  }

  setLayersData(state);
};
