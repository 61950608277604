import { BaseSyntheticEvent, KeyboardEvent, useCallback } from 'react';
import { useEffectOnSelectedNodeChange } from '@jux/ui/components/editor/hooks';
import { useEffectOnSelectedNodeStateChange } from '../useEffectOnSelectedNodeStateChange';
import { useDDPFieldForm } from '../useDDPFieldForm';
import { handleDimensionInputArrows } from '../handleDimensionInputArrows.utils';
import { UseNumericFieldInputsProps } from './useNumericField.interface';
import {
  parseFromField,
  parseIntoField,
  parseNumber,
  parseNumberWithBaseUnit,
} from './useNumericField.utils';

const MAX_OPACITY_VALUE = 100;
const MIN_OPACITY_VALUE = 0;

export const useNumericFieldInputs = ({
  parsedValue,
  initialValue,
  fieldName,
  saveChanges,
  fieldSchema,
  parseValue,
  validateField,
  options,
}: UseNumericFieldInputsProps) => {
  const { handleSubmit, registerField, setValue, getValue } = useDDPFieldForm({
    fieldName,
    initialValue: parsedValue,
    fieldSchema,
  });

  const updateFieldStateByValue = useCallback(
    (newValue: string | number) => {
      setValue(parseNumberWithBaseUnit(newValue, options));
    },
    [options, setValue]
  );

  const handleEffectChanges = useCallback(() => {
    setValue(parsedValue);
  }, [parsedValue, setValue]);

  useEffectOnSelectedNodeChange(handleEffectChanges);
  useEffectOnSelectedNodeStateChange(handleEffectChanges);

  const saveOnValid = useCallback(
    (newNumeric: string | { [key: string]: string }) => {
      const val =
        typeof newNumeric === 'string'
          ? newNumeric
          : Object.values(newNumeric)[0];

      const parsedFromFieldValue = parseFromField(val, options);
      const parsedIntoFieldValue = parseIntoField({
        value: parsedFromFieldValue,
        options,
        key: fieldName,
      });

      updateFieldStateByValue(parsedIntoFieldValue);
      saveChanges(parsedFromFieldValue);
    },
    [fieldName, options, saveChanges, updateFieldStateByValue]
  );

  const discardChanges = useCallback(() => {
    updateFieldStateByValue(parsedValue);
  }, [updateFieldStateByValue, parsedValue]);

  const handleChanges = useCallback(
    (e: BaseSyntheticEvent) => {
      if (!e) return;

      const hasValueChanged = parsedValue !== e.target.value;

      if (!hasValueChanged) return;

      const currentValue = parseNumberWithBaseUnit(e.target.value, options);

      e.target.value = currentValue;

      const submitCallback = handleSubmit(saveOnValid, discardChanges);
      submitCallback(e);
    },
    [options, discardChanges, handleSubmit, parsedValue, saveOnValid]
  );

  const handleRevertToInitial = useCallback(() => {
    const parsedInitialValue = parseValue(initialValue);
    const { success } = validateField(parsedInitialValue);

    if (success) {
      setValue(parsedInitialValue);
      saveOnValid(parsedInitialValue);
    }
  }, [initialValue, parseValue, saveOnValid, setValue, validateField]);

  const handleArrows = useCallback(
    (addition: number) => {
      const opacity = parseNumber(parsedValue);

      let newValue = opacity + addition;
      if (newValue < MIN_OPACITY_VALUE) {
        newValue = MIN_OPACITY_VALUE;
      }
      if (newValue > MAX_OPACITY_VALUE) {
        newValue = MAX_OPACITY_VALUE;
      }
      if (newValue === opacity) return;

      saveOnValid(parseNumberWithBaseUnit(newValue, options));
    },
    [parsedValue, saveOnValid, options]
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      handleDimensionInputArrows(e, handleArrows);
    },
    [handleArrows]
  );

  return {
    fieldValue: getValue(),
    setValue,
    registerField,
    handleSubmit: handleChanges,
    handleBlur: handleChanges,
    handleEnter: handleChanges,
    handleEscape: discardChanges,
    handleKeyDown,
    handleRevertToInitial,
  };
};
