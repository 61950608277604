import { JuxStoreActionFn, SetStateFn, JuxStore } from '../store.interface';
import { handleErrors } from './handleErrors';
import { injectActionState } from './injectActionState';

/**
 * Composes functions from right to left, keeping the original action name,
 *  because the action will be handled by multiple helper functions,
 *  which may perform specific logic based on the action name.
 * compose(f, g, h)(...args) is equivalent to f(g(h(...args)))
 * @param fns - Functions to compose
 * @returns Composed function
 */
const compose = (...fns: any[]) =>
  fns.reduceRight(
    (a, f) =>
      // Return a new function with the same name
      ({
        [a.name]: (...args: any[]) => f(a)(...args),
      }[a.name])
  );

/**
 * Wraps each action with the necessary boilerplate: error handling, state injection.
 */
export const composeAction = ({
  actionFn,
  setState,
}: {
  actionFn: JuxStoreActionFn<any, JuxStore>;
  setState: SetStateFn<JuxStore>;
}) => compose(injectActionState(setState), handleErrors, actionFn);
