import {
  selectedNodeComponent,
  selectedNodeSourceComponent,
  useStore,
} from '@jux/canjux/core';

export const useSelectedNodeComponent = () => ({
  component: useStore(selectedNodeComponent),
  sourceComponent: useStore(selectedNodeSourceComponent).resolvedSource,
});
