import { JuxStore } from '@jux/canjux/core';
import { getSelectedNodeComponent } from './getSelectedNodeComponent';
import { NodeType } from '@jux/data-entities';

export const getSelectedNodeInstances = ({
  components,
  selectedNodesStack,
}: {
  components: JuxStore['components'];
  selectedNodesStack: Array<string>;
}) => {
  const selectedNodeComponent = getSelectedNodeComponent({
    components,
    selectedNodesStack,
  });

  if (
    !selectedNodeComponent ||
    (selectedNodeComponent.type !== NodeType.LOCAL_COMPONENT &&
      selectedNodeComponent.type !== NodeType.LIBRARY_COMPONENT &&
      selectedNodeComponent.type !== NodeType.VARIANTS_GROUP)
  ) {
    return [];
  }

  const selectedSourceComponentId =
    selectedNodeComponent.type === NodeType.VARIANTS_GROUP
      ? selectedNodeComponent.sourceComponentId
      : selectedNodeComponent.id;

  // find all components instances of the selected node
  const instancesOfSelectedNode = Object.values(components).filter(
    (component) => {
      const isInstance = component.type === NodeType.INSTANCE;

      const isSelectedNodeSourceComponentOfThisComponent =
        component.sourceComponentId === selectedSourceComponentId;

      return isInstance && isSelectedNodeSourceComponentOfThisComponent;
    }
  );

  return instancesOfSelectedNode;
};
