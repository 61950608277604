import { useCallback } from 'react';
import {
  selectedNodeComponentInstancesCount,
  useStore,
  useStoreActions,
} from '@jux/canjux/core';
import { ComponentTagName } from '@jux/data-entities';
import { useTrackEvents } from '@jux/ui/hooks';
import { useDeletePropConfirmationModal } from './useDeletePropConfirmationModal';

export const useHandleDeleteProp = ({
  componentId,
  tagName,
}: {
  componentId: string | undefined;
  tagName: ComponentTagName | undefined;
}) => {
  const {
    propsActions: { deleteComponentProp },
  } = useStoreActions();

  const selectedComponentInstancesCount = useStore(
    selectedNodeComponentInstancesCount
  );

  const { openDeletePropConfirmationModal } = useDeletePropConfirmationModal();

  const { trackPropDeletedEvent } = useTrackEvents();

  const deleteProp = useCallback(
    (propName: string) => {
      if (!componentId || !tagName) return;

      deleteComponentProp({ componentId, propName });

      trackPropDeletedEvent({
        componentUUID: componentId,
        tagName,
        deletedPropName: propName,
      });
    },
    [componentId, deleteComponentProp, tagName, trackPropDeletedEvent]
  );

  const handleDeleteProp = useCallback(
    ({
      defaultValue,
      propName,
    }: {
      propName: string;
      defaultValue: string;
    }) => {
      if (selectedComponentInstancesCount === 0) {
        deleteProp(propName);
      } else {
        openDeletePropConfirmationModal({
          defaultValue,
          propName,
          onConfirm: () => {
            deleteProp(propName);
          },
        });
      }
    },
    [
      deleteProp,
      openDeletePropConfirmationModal,
      selectedComponentInstancesCount,
    ]
  );

  return {
    deleteProp,
    handleDeleteProp,
  };
};
