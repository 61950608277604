import { CanvasData } from '@jux/data-entities';
import { CanjuxState } from '../../store';
import { calculateCenterPosition } from '../selectors/utils';
import { getRootNodeOfNode } from './getRootNodeOfNode';
import { CREATE_NEW_CANVAS_NODE_DISTANCE } from '../../../constants';
import { ComponentPlacement } from '../../../types';

export const getRootNodeValidPlacement = ({
  originalTargetNodeId,
  components,
  canvasNodesDimensions,
  canvas,
  transform,
  canvasDimensions: { width, height },
}: {
  originalTargetNodeId?: string;
  components: CanjuxState['components'];
  canvasNodesDimensions: CanjuxState['canvasNodesDimensions'];
  canvas: CanvasData;
  transform: CanjuxState['transform'];
  canvasDimensions: { width: number; height: number };
}): ComponentPlacement => {
  const resultPlacement: ComponentPlacement = {
    targetNodeId: undefined,
    targetChildIndex: 0,
    position: calculateCenterPosition({
      container: {
        width,
        height,
        position: {
          x: transform.x,
          y: transform.y,
        },
      },
      zoom: transform.zoom,
      // TODO: calculate the right dimensions
      targetDimensions: { width: 0, height: 0 },
    }),
  };

  if (originalTargetNodeId) {
    // selected target is invalid and no valid parent target node was found.
    // Placing the new node as root node, 80px to the right of the root node of the intended target
    const rootNodeComponent = getRootNodeOfNode({
      components,
      nodeId: originalTargetNodeId,
    });
    if (!rootNodeComponent) {
      return resultPlacement;
    }

    const rootNodeWidth =
      canvasNodesDimensions[rootNodeComponent.id]?.width || 0;
    const rootNodePosition = canvas.nodes[rootNodeComponent.id]?.position;

    if (rootNodePosition?.x) {
      resultPlacement.position = {
        x:
          rootNodePosition?.x + rootNodeWidth + CREATE_NEW_CANVAS_NODE_DISTANCE,
        y: rootNodePosition?.y,
      };
    }
  }

  return resultPlacement;
};
