import {
  NodeInteractiveState,
  nodeInteractiveStateOptions,
} from '@jux/data-entities';

export const isSelectedNodeValidInteractiveStateOptions = (
  nodeInteractiveState: string
): nodeInteractiveState is NodeInteractiveState =>
  nodeInteractiveStateOptions.includes(
    nodeInteractiveState as NodeInteractiveState
  );
