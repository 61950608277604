import { useMemo } from 'react';
import { DesignTokenTypeEnum, SupportedTokenTypes } from '@juxio/design-tokens';
import { useTokenSetState } from '@jux/ui/state';
import { CORE } from '@jux/types';
import { createPath } from '../utils/tokensPath';

const getDefaultGroupPathOptions = (): Record<
  SupportedTokenTypes,
  Array<string>
> => ({
  [DesignTokenTypeEnum.color]: [],
  [DesignTokenTypeEnum.dimension]: [],
  [DesignTokenTypeEnum.fontFamily]: [],
  [DesignTokenTypeEnum.typography]: [],
  [DesignTokenTypeEnum.border]: [],
});

export const useTokens = () => {
  const { tokenSetData, currentTokenType, isCoreTokenSet } = useTokenSetState();

  const tokenSet = isCoreTokenSet ? tokenSetData?.core : tokenSetData;

  const groupPathOptionsByType = useMemo(() => {
    const result = getDefaultGroupPathOptions();

    if (!tokenSetData?.existingGroupsPaths) {
      return result;
    }

    Object.keys(result).forEach((typeName) => {
      result[typeName as SupportedTokenTypes] =
        tokenSetData.existingGroupsPaths.filter((path) =>
          path.startsWith(typeName)
        );
    });

    return result;
  }, [tokenSetData?.existingGroupsPaths]);

  const groupPathCoreOptionsByType = useMemo(() => {
    const result = getDefaultGroupPathOptions();

    if (!tokenSetData?.core.existingGroupsPaths) {
      return result;
    }

    Object.keys(result).forEach((typeName) => {
      result[typeName as SupportedTokenTypes] =
        tokenSetData.core.existingGroupsPaths.filter((path) =>
          path.startsWith(createPath([CORE, typeName]))
        );
    });

    return result;
  }, [tokenSetData?.core.existingGroupsPaths]);

  return useMemo(
    () => ({
      tokenSetDataByType: tokenSet?.groupsByType[currentTokenType],
      groupPathOptionsByType,
      groupPathCoreOptionsByType,
    }),
    [
      tokenSet?.groupsByType,
      currentTokenType,
      groupPathOptionsByType,
      groupPathCoreOptionsByType,
    ]
  );
};
