import { useState } from 'react';

export const UnitsType = {
  px: 'px',
  none: undefined,
  vw: 'vw',
  vh: 'vh',
  rem: 'rem',
  percentage: '%',
} as const;

// this type should be in a shared place like tokens
export const Units = Object.values(UnitsType);
export const filteredUnits = ({
  disablePercentValue,
}: {
  disablePercentValue?: boolean;
}) =>
  Units.filter((unitOption) => {
    if (unitOption === UnitsType.percentage) {
      return !disablePercentValue;
    }
    return Boolean(unitOption);
  });
export type UnitType = typeof Units[number];

export const DEFAULT_UNIT: UnitType = UnitsType.px;

export const useUnitState = () => {
  const [unit, setUnit] = useState<UnitType>(DEFAULT_UNIT);

  return {
    unit,
    setUnit,
  };
};
