import { DesignTokens, formatToAlias } from '@juxio/design-tokens';
import { getAliasResolutionPath } from '@jux/ui/components/tokens/token-drawer/forms/helpers/getAliasResolutionPath';

export const getCircularAliasFilter =
  ({ rawTokenSet, alias }: { rawTokenSet: DesignTokens; alias: string }) =>
  (path: string) =>
    !getAliasResolutionPath({
      alias: formatToAlias(path),
      rawTokenSet: rawTokenSet || {},
    }).includes(alias);
