import { FC, useMemo } from 'react';
import {
  ComponentPreviewItem,
  Icons,
  Typography,
  usePreviewPortal,
} from '@jux/ui/components';
import { CurrentOrgThemeProvider } from '@jux/ui/components/editor/components/CurrentOrgThemeProvider';
import {
  libraryComponents as libraryComponentsSelector,
  localComponents as localComponentsSelector,
  useStore,
} from '@jux/canjux/core';
import { NAVIGATOR_POPPER_EMPTY_TEXT_COLOR } from '@jux/ui/theme/palette';
import { MuiGrid, useTheme } from '@jux/ui/components/common/mui';
import { TEST_ID_COMPONENTS_POPPER } from '@jux/constants';
import { NavigationPopper, S } from '../common';
import { DeleteComponentMenuItem } from './DeleteComponentMenuItem';
import { useSelectedComponentsTabs } from './hooks';
import { ComponentsHeader } from './ComponentsHeader';

export const ComponentsPopper: FC = () => {
  const theme = useTheme();

  const { previewPortalContainer } = usePreviewPortal();
  const localComponents = useStore(localComponentsSelector);
  const libraryComponent = useStore(libraryComponentsSelector);

  const {
    isLibraryComponents,
    isLocalComponents,
    setLibraryComponents,
    setLocalComponents,
  } = useSelectedComponentsTabs();

  const componentsArray = useMemo(
    () => (isLibraryComponents ? libraryComponent : localComponents),
    [isLibraryComponents, libraryComponent, localComponents]
  );

  const hasComponents = useMemo(
    () => Boolean(componentsArray.length),
    [componentsArray]
  );

  return (
    <NavigationPopper
      testId={TEST_ID_COMPONENTS_POPPER}
      header={
        <ComponentsHeader
          isLocalComponents={isLocalComponents}
          isLibraryComponents={isLibraryComponents}
          setLocalComponents={setLocalComponents}
          setLibraryComponents={setLibraryComponents}
        />
      }
    >
      <MuiGrid
        container
        gap="16px"
        paddingTop={theme.drimz.size.editor.navbar.menuTab.content.padding}
      >
        {hasComponents ? (
          <S.ItemsListGrid item>
            <CurrentOrgThemeProvider>
              {componentsArray.map((component) => (
                <ComponentPreviewItem
                  key={component.id}
                  loadingRenderer={<Icons size={32} variant="COMPONENTS" />}
                  componentId={component.id}
                  component={component}
                  actions={
                    <DeleteComponentMenuItem componentUuid={component.id} />
                  }
                  previewPortalContainer={previewPortalContainer}
                />
              ))}
            </CurrentOrgThemeProvider>
          </S.ItemsListGrid>
        ) : (
          <S.NavigationItemGrid item>
            {isLibraryComponents ? (
              <Typography
                variant="label-s-regular"
                color={NAVIGATOR_POPPER_EMPTY_TEXT_COLOR}
              >
                Make a local component into a library component so you can reuse
                it and share it with developers.
              </Typography>
            ) : (
              <Typography
                variant="label-s-regular"
                color={NAVIGATOR_POPPER_EMPTY_TEXT_COLOR}
              >
                Your local component list will be updated automatically every
                time you place an element on the canvas. When a Div is selected,
                it can also be saved as a local component.
              </Typography>
            )}
          </S.NavigationItemGrid>
        )}
      </MuiGrid>
    </NavigationPopper>
  );
};
