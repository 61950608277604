import { ElementDataInit } from '../../types/ElementDataInit';
import { getJuxTextElementData } from '../text';
import { Colors } from '../../common/colors';
import { StylesObject } from '@jux/types';

const JUX_LABEL_DEFAULT_CONTENT = 'label';

const JUX_HELPER_TEXT_LABEL_DEFAULT_STYLES: StylesObject = {
  fontFamily: 'Inter',
  fontWeight: '500',
  fontStyle: 'normal',
  fontSize: '14px',
  lineHeight: '17px',
  display: 'inline-block',
  color: Colors.Neutrals_500,
  width: 'auto',
  height: 'auto',
};

export const getTextLabelElement: ElementDataInit<undefined> = ({
  parentId,
} = {}) => {
  return getJuxTextElementData({
    parentId,
    rootStyles: JUX_HELPER_TEXT_LABEL_DEFAULT_STYLES,
    text: JUX_LABEL_DEFAULT_CONTENT,
    displayName: 'label',
  });
};
