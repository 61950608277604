import { FC, PropsWithChildren } from 'react';
import { ThemeProvider as JuxThemeProvider } from '@jux/ui-core';
import { AppGlobalStyles } from '@jux/ui/components/common';
import { CssBaseline, ThemeProvider } from '@jux/ui/components/common/mui';
import { useSettingsState } from '@jux/ui/state';
import { createTheme } from '@jux/ui/theme';
import { CANJUX_SELECTION_COLOR } from '@jux/ui/theme/palette';
import { ToastContainer } from '@jux/ui/toast';

export const AppLayoutProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    settings: { themeConfig },
  } = useSettingsState();

  const theme = createTheme({
    direction: themeConfig.direction,
    responsiveFontSizes: themeConfig.responsiveFontSizes,
    mode: themeConfig.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <JuxThemeProvider>
        <CssBaseline />
        <ToastContainer />
        <AppGlobalStyles selectionBackgroundColor={CANJUX_SELECTION_COLOR} />
        {children}
      </JuxThemeProvider>
    </ThemeProvider>
  );
};
