export const DATA_JUX_ID_ATTRIBUTE = 'data-jux-id';

export const DATA_JUX_NODE_ID_ATTRIBUTE = 'data-jux-node-id';

export const canjuxElementSelector = (id: string) =>
  `[${DATA_JUX_NODE_ID_ATTRIBUTE}="${id}"]`;

export const getCanjuxElementById = (id: string) =>
  document.querySelector(canjuxElementSelector(id));

export const getDataJuxNodeId = (element: HTMLElement) =>
  element.getAttribute(DATA_JUX_NODE_ID_ATTRIBUTE);

export const CANJUX_MAIN_WRAPPER = 'jux__wrapper';

export const CANJUX_CONTAINER_PANE = 'jux_container_pane';

export const CANJUX_NODES_CONTAINER = 'jux_nodes';

export const CREATE_NEW_CANVAS_NODE_DISTANCE = 80; // px
