import {
  JuxStore,
  JuxStoreActionFn,
  PropsActionsParams,
} from '../../store.interface';
import type { Draft as WritableDraft } from 'mutative';
import { ComponentConfigWithStates } from '@jux/types';
import {
  renameContextStylesForNewPropValue,
  renameStylesForPropValue,
} from '../utils/renamePropValueUtils';
import { replacePropValueInAllInstances } from '../utils/replacePropValueInAllInstances';
import { NodeType } from '@jux/data-entities';
import { setLayersData } from '@jux/canjux/core';

/**
 * Rename a component property value option.
 */
export const renameComponentPropValue: JuxStoreActionFn<
  PropsActionsParams['renameComponentPropValue'],
  JuxStore
> = ({ componentId, propName, propValueLabel, newPropValue, state }) => {
  const component = state.components[componentId];
  if (!component) return state;

  switch (component.type) {
    case NodeType.LIBRARY_COMPONENT:
    case NodeType.LOCAL_COMPONENT:
      const variantConfig = component.config.variants?.find(
        (variant) => variant.variant === propName
      );
      if (!variantConfig) {
        return state;
      }

      const optionToRename = variantConfig.options.find(
        (option) => option.label === propValueLabel
      );
      if (!optionToRename) {
        return state;
      }

      const oldPropValue = optionToRename.value;

      optionToRename.value = newPropValue;
      optionToRename.label = newPropValue.toString();

      if (variantConfig.defaultValue === oldPropValue) {
        variantConfig.defaultValue = newPropValue;
      }
      if (
        propName in component.config.props &&
        component.config.props[propName] === oldPropValue
      ) {
        component.config.props[propName] = newPropValue;
      }

      // Edit existing styles and duplicate default styles for new value options
      const styles =
        component.styles as WritableDraft<ComponentConfigWithStates>;
      if (styles.variants) {
        renameStylesForPropValue({
          variantsStyles: styles.variants,
          propName: propName,
          oldPropValue,
          newPropValue: newPropValue,
        });
      }

      // Go over state styles and do the same
      for (const stateStyles of Object.values(styles.states)) {
        if (stateStyles.variants) {
          renameStylesForPropValue({
            variantsStyles: stateStyles.variants,
            propName: propName,
            oldPropValue,
            newPropValue: newPropValue,
          });
        }
      }

      if (styles.contextStyles) {
        renameContextStylesForNewPropValue({
          contextStyles: styles.contextStyles,
          propName: propName,
          oldPropValue,
          newPropValue: newPropValue,
        });
      }

      replacePropValueInAllInstances({
        componentId,
        propName,
        propValue: oldPropValue,
        newPropValue,
        components: state.components,
      });

      // This is needed to update the layers data in case there was a matrix update
      setLayersData(state);

      component.updatedAt = new Date().getTime();

      break;
    default:
      throw new Error(
        `Cannot rename prop value for component type ${component.type}`
      );
  }

  return state;
};
