import { DesignTokenData, formatToAlias } from '@juxio/design-tokens';

export const parseSelectedTokenToValidTokenName = ({ name }: DesignTokenData) =>
  formatToAlias(name);

export const pickValueAsStringFromToken = ({
  value,
}: DesignTokenData): string => {
  if (!value) return '';

  // remove "" if exists on token value (fontFamily with spaces)
  return value.toString().replaceAll('"', '');
};
