import { useCallback } from 'react';
import {
  getComponentsThatUseGroupPathSelector,
  storeApi,
  useStoreActions,
} from '@jux/canjux/core';
import { useTokenSetState } from '@jux/ui/state';
import { DisableDeleteReturnType } from '../interface';

export const useDeleteGroup = () => {
  const { currentTokenSetId } = useTokenSetState();
  const {
    tokenSetsActions: { deleteTokenOrGroup },
  } = useStoreActions();

  const deleteGroup = useCallback(
    (groupPath: string) => {
      if (!currentTokenSetId) return;

      deleteTokenOrGroup({
        path: groupPath,
      });
    },
    [currentTokenSetId, deleteTokenOrGroup]
  );

  const shouldDisableDeleteGroup = useCallback(
    ({
      path,
      hasAliasedTokens,
      isLocked,
    }: {
      path: string;
      hasAliasedTokens: boolean;
      isLocked?: boolean;
    }): DisableDeleteReturnType => {
      if (!isLocked && hasAliasedTokens) {
        return {
          disabled: true,
          reason: `Group with a token that used as alias can't be deleted`,
        };
      }

      const componentsThatUsesTokenPath = getComponentsThatUseGroupPathSelector(
        storeApi.getState()
      )(path);
      const isUsedByComponents =
        Object.keys(componentsThatUsesTokenPath).length > 0;

      if (isUsedByComponents) {
        return {
          disabled: true,
          reason: `Group with a token that used by a component can't be deleted`,
        };
      }

      return {
        disabled: false,
        reason: '',
      };
    },
    []
  );

  return {
    deleteGroup,
    shouldDisableDeleteGroup,
  };
};
