import { useCallback } from 'react';
import { useStoreActions } from '@jux/canjux/core';
import { useTrackEvents } from '@jux/ui/hooks';
import { ComponentTagName } from '@jux/data-entities';

export const useHandleRenamePropName = ({
  componentId,
  tagName,
}: {
  componentId: string | undefined;
  tagName: ComponentTagName | undefined;
}) => {
  const {
    propsActions: { renameComponentProp },
  } = useStoreActions();

  const { trackPropRenamedEvent } = useTrackEvents();

  const renamePropName = useCallback(
    (propName: string, newPropName: string): boolean => {
      if (!componentId || !tagName) return false;

      try {
        renameComponentProp({
          componentId,
          propName,
          newPropName,
        });

        trackPropRenamedEvent({
          componentUUID: componentId,
          tagName,
          oldPropName: propName,
          newPropName,
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    [componentId, renameComponentProp, tagName, trackPropRenamedEvent]
  );

  return {
    handleRenamePropName: renamePropName,
  };
};
