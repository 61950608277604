import * as React from 'react';
import { styled, css, type Merge } from '@juxio/react-styled';
import { DropdownArrowSmall } from './DropdownArrowSmall';

export interface TopNavigationItemVariants {
  selected: boolean;
}

export interface TopNavigationItemProps
  extends Merge<
    React.HTMLAttributes<HTMLDivElement>,
    TopNavigationItemVariants
  > {
  text?: string;
}

const ITEMS_FOCUS_COLOR = '#F5F5F5';

const text_b0a4def1 = css({
  color: '#000000',
  width: 'auto',
  height: 'auto',
  display: 'inline-block',
  fontSize: '20px',
  fontFamily: 'Inter',
  lineHeight: '130%',
  whiteSpace: 'pre-wrap',
  overflowWrap: 'break-word',
});

const dropdown_arrow_small_e3ae5aac = css({
  color: 'rgba(97,97,97,1)',
});

const StyledTopNavigationItem = styled<'div', TopNavigationItemProps>('div', {
  root: {
    gap: 'normal 2px',
    width: 'auto',
    height: '48px',
    display: 'flex',
    padding: '10px {dimension.core.size_xs} 10px {dimension.core.size_sm}',
    alignItems: 'center',
    borderStyle: 'none',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    outline: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '{core.color.greys.150}',
    },
    '&:focus-visible': {
      backgroundColor: ITEMS_FOCUS_COLOR,
    },
  },
  variants: [
    {
      props: {
        selected: true,
      },
      style: {
        backgroundColor: '{core.color.greys.150}',
        padding: '0px {dimension.core.size_xs} 0px {dimension.core.size_sm}',
        [`.${text_b0a4def1}`]: {
          fontSize: '12px',
        },
      },
    },
    {
      props: {
        selected: false,
      },
      style: {
        padding: '0px {dimension.core.size_xs} 0px {dimension.core.size_sm}',
        [`.${text_b0a4def1}`]: {
          fontSize: '12px',
        },
        '&:hover': {
          backgroundColor: '{core.color.greys.150}',
        },
      },
    },
  ],
});

export const TopNavigationItem = React.forwardRef<
  HTMLDivElement,
  TopNavigationItemProps
>(function TopNavigationItem(
  { selected = false, text = 'Nav item', ...otherProps },
  ref
) {
  return (
    <StyledTopNavigationItem
      tabIndex={0}
      selected={selected}
      ref={ref}
      {...otherProps}
    >
      <span className={text_b0a4def1}>{text}</span>
      <DropdownArrowSmall className={dropdown_arrow_small_e3ae5aac} />
    </StyledTopNavigationItem>
  );
});
