import { isMac } from './isMac';

export type ActionKey =
  | 'ACCEPT_TEXT_CHANGE'
  | 'ACTIVATE_CANVAS_PAN'
  | 'ACTIVATE_CANVAS_ZOOM'
  | 'ACTIVATE_DIRECT_SELECTION'
  | 'BACKSPACE_DELETE'
  | 'COPY'
  | 'DELETE'
  | 'DESELECT'
  | 'DISCARD_CHANGES'
  | 'DRILL_IN'
  | 'DRILL_OUT'
  | 'DUPLICATE_COMPONENT'
  | 'PASTE'
  | 'REDO'
  | 'UNDO'
  | 'WRAP_WITH_DIV'
  | 'OPEN_CLOSE_MATRIX';

type CommonKeysType = keyof typeof CommonKeys;

export type CommonKeysValueType = typeof CommonKeys[CommonKeysType];

type OSActionKeys = Record<ActionKey, CommonKeysValueType>;

export const CommonKeys = {
  BACKSPACE: 'backspace',
  CMD: 'meta',
  CMD_C: 'meta+c',
  CMD_D: 'meta+d',
  CMD_ENTER: 'meta+enter',
  CMD_SHIFT_Z: 'meta+shift+z',
  CMD_M: 'meta+m',
  CMD_V: 'meta+v',
  CMD_Z: 'meta+z',
  CTRL: 'ctrl',
  CTRL_C: 'ctrl+c',
  CTRL_D: 'ctrl+d',
  CTRL_M: 'ctrl+m',
  CTRL_ENTER: 'ctrl+enter',
  CTRL_SHIFT_Z: 'ctrl+shift+z',
  CTRL_V: 'ctrl+v',
  CTRL_Z: 'ctrl+z',
  DELETE: 'delete',
  ENTER: 'enter',
  ESC: 'esc',
  SHIFT_D: 'shift+d',
  SHIFT_ENTER: 'shift+enter',
  SHIFT_Z: 'shift+z',
  SPACE: 'space',
} as const;

export const MacActionKeys: OSActionKeys = {
  ACCEPT_TEXT_CHANGE: CommonKeys.CMD_ENTER,
  ACTIVATE_CANVAS_PAN: CommonKeys.SPACE,
  ACTIVATE_CANVAS_ZOOM: CommonKeys.CMD,
  ACTIVATE_DIRECT_SELECTION: CommonKeys.CMD,
  BACKSPACE_DELETE: CommonKeys.BACKSPACE,
  COPY: CommonKeys.CMD_C,
  DELETE: CommonKeys.DELETE,
  DESELECT: CommonKeys.ESC,
  DISCARD_CHANGES: CommonKeys.ESC,
  DRILL_IN: CommonKeys.ENTER,
  DRILL_OUT: CommonKeys.SHIFT_ENTER,
  DUPLICATE_COMPONENT: CommonKeys.CMD_D,
  PASTE: CommonKeys.CMD_V,
  REDO: CommonKeys.CMD_SHIFT_Z,
  UNDO: CommonKeys.CMD_Z,
  WRAP_WITH_DIV: CommonKeys.SHIFT_D,
  OPEN_CLOSE_MATRIX: CommonKeys.CMD_M,
} as const;

export const WindowsActionKeys: OSActionKeys = {
  ACCEPT_TEXT_CHANGE: CommonKeys.CTRL_ENTER,
  ACTIVATE_CANVAS_PAN: CommonKeys.SPACE,
  ACTIVATE_CANVAS_ZOOM: CommonKeys.CTRL,
  ACTIVATE_DIRECT_SELECTION: CommonKeys.CTRL,
  BACKSPACE_DELETE: CommonKeys.BACKSPACE,
  COPY: CommonKeys.CTRL_C,
  DELETE: CommonKeys.DELETE,
  DESELECT: CommonKeys.ESC,
  DISCARD_CHANGES: CommonKeys.ESC,
  DRILL_IN: CommonKeys.ENTER,
  DRILL_OUT: CommonKeys.SHIFT_ENTER,
  DUPLICATE_COMPONENT: CommonKeys.CTRL_D,
  PASTE: CommonKeys.CTRL_V,
  REDO: CommonKeys.CTRL_SHIFT_Z,
  UNDO: CommonKeys.CTRL_Z,
  WRAP_WITH_DIV: CommonKeys.SHIFT_D,
  OPEN_CLOSE_MATRIX: CommonKeys.CTRL_M,
} as const;

export const keyboardActionKeys = {
  ...(isMac() ? MacActionKeys : WindowsActionKeys),
};
