import {
  CommonActionsParams,
  JuxStore,
  JuxStoreActionFn,
} from '@jux/canjux/core';
import { getValidCanvasName } from './utils';

/**
 * Set canvas name
 */
export const setCanvasName: JuxStoreActionFn<
  CommonActionsParams['setCanvasName'],
  JuxStore
> = ({ originalCanvasName, newName, state }) => {
  const canvasName = getValidCanvasName(newName);

  if (Object.keys(state.canvases).includes(canvasName)) {
    throw new Error(`Canvas "${canvasName}" already exists`);
  }

  const oldData = state.canvases[originalCanvasName];
  state.canvases[canvasName] = oldData;
  state.canvases[canvasName].name = canvasName;

  delete state.canvases[originalCanvasName];

  return state;
};
