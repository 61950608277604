import { FC, useRef } from 'react';
import { useOnKeyEvent } from '@jux/ui/hooks';
import {
  usePanelStateByKey,
  useEffectOnSelectedNodeChange,
} from '@jux/ui/components/editor/hooks';
import { PanelsKeys } from '../../../state';
import { PropertiesPanelWrapper } from './PropertiesPanelWrapper';

export const PropertiesPanel: FC = () => {
  const {
    shouldRender: shouldRenderPropertiesPanel,
    closePanel: closePropertiesPanel,
  } = usePanelStateByKey(PanelsKeys.properties);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useOnKeyEvent(wrapperRef, closePropertiesPanel, 'Escape');
  useEffectOnSelectedNodeChange(closePropertiesPanel);

  if (!shouldRenderPropertiesPanel) return null;

  return (
    <PropertiesPanelWrapper
      wrapperRef={wrapperRef}
      onClose={closePropertiesPanel}
    />
  );
};
