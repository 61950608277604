import { FC, useMemo } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { Typography } from '@jux/ui/components';
import {
  TOKENS_IN_USED_BY_COMPONENTS_BACKGROUND_COLOR,
  TOKENS_IN_USED_BY_COMPONENTS_BORDER_COLOR,
  TOKENS_IN_USED_BY_COMPONENTS_TEXT_COLOR,
} from '@jux/ui/theme/palette';
import { TokenUsages } from '@jux/canjux/core';

export const TokenUsedByComponents: FC<{
  tokenUsages: TokenUsages;
}> = ({ tokenUsages }) => {
  const resolvedTokensUsageDisplayText = useMemo(() => {
    const libraryComponentsCount = tokenUsages.libraryComponents.length;
    const localComponentsCount = tokenUsages.localComponents.length;
    const otherObjectsCount = tokenUsages.otherObjects.length;

    const totalUsages =
      libraryComponentsCount + localComponentsCount + otherObjectsCount;

    if (totalUsages < 1) return 'Token is not in use';

    if (libraryComponentsCount === totalUsages) {
      const singularOrPlural =
        libraryComponentsCount > 1 ? 'components' : 'component';

      return `Token is in use in ${libraryComponentsCount} library ${singularOrPlural}`;
    }
    if (localComponentsCount === totalUsages) {
      const singularOrPlural =
        localComponentsCount > 1 ? 'components' : 'component';

      return `Token is in use in ${localComponentsCount} local ${singularOrPlural}`;
    }
    if (otherObjectsCount === totalUsages) {
      const singularOrPlural = otherObjectsCount > 1 ? 'objects' : 'object';

      return `Token is in use in ${otherObjectsCount} ${singularOrPlural}`;
    }

    return (
      <>
        Token is in use in {totalUsages} objects.
        <br />
        {libraryComponentsCount > 0 && (
          <>
            Library components: {libraryComponentsCount}
            <br />
          </>
        )}
        {localComponentsCount > 0 && (
          <>
            Local components: {localComponentsCount}
            <br />
          </>
        )}
        {otherObjectsCount > 0 && (
          <>
            Other objects: {otherObjectsCount}
            <br />
          </>
        )}
      </>
    );
  }, [tokenUsages]);

  return (
    <MuiGrid container padding="16px 20px 0" gap="16px">
      <MuiGrid
        item
        container
        sx={{
          height: '1px',
          backgroundColor: (theme) => theme.drimz.palette.divider.secondary,
        }}
      />
      <MuiGrid
        item
        container
        sx={{
          border: '1px solid',
          padding: '8px',
          borderColor: TOKENS_IN_USED_BY_COMPONENTS_BORDER_COLOR,
          backgroundColor: TOKENS_IN_USED_BY_COMPONENTS_BACKGROUND_COLOR,
        }}
      >
        <Typography
          variant="label-s-medium"
          color={TOKENS_IN_USED_BY_COMPONENTS_TEXT_COLOR}
        >
          {resolvedTokensUsageDisplayText}
        </Typography>
      </MuiGrid>
    </MuiGrid>
  );
};
