import { useMemo } from 'react';
import {
  CANJUX_NODES_CONTAINER,
  DATA_JUX_NODE_ID_ATTRIBUTE,
} from '../constants';
import { NodeChange } from '../store';
import { getNodesAbsolutePositionUpdates } from '../utils';
import { useStoreActions } from './useStoreActions';

/**
 * Custom hook that creates a global mutation observer to track.
 */
export const useGlobalMutationObserver = () => {
  const {
    commonActions: { updateNodesIndicatorsPosition },
  } = useStoreActions();

  return useMemo(() => {
    return new MutationObserver((mutations) => {
      const changes: Array<NodeChange<'position_absolute'>> = [];

      mutations.forEach((entry) => {
        const node =
          entry.type === 'characterData'
            ? entry.target.parentElement
            : (entry.target as HTMLElement);

        const nodeId = node?.getAttribute(DATA_JUX_NODE_ID_ATTRIBUTE);

        switch (entry.type) {
          case 'characterData':
          case 'childList': {
            // TODO: Fix bug deleting while dragging node
            // The nodes container has changed (added / removed child-nodes), so we need to update the of the added nodes.
            if (nodeId) {
              // TODO: check this -
              // we currently have coupling between the logic that provides the list of visible nodes to render in the DOM
              // that relies on the dimensions of each node, and the logic that calculates the dimensions of each node,
              // that relies on the node being rendered on the DOM.
              // This is a problem because the dimensions of a node are not available until the node is rendered on the DOM.
              // which creates some chicken-and-egg problems that makes nodes to not be rendered at certain times.

              // The nodes container has changed, so we need to update the of the added nodes.
              const element = document.getElementById(nodeId);

              if (element) {
                changes.push(...getNodesAbsolutePositionUpdates([element]));
              }
            } else if (
              (entry.target as HTMLElement).id === CANJUX_NODES_CONTAINER
            ) {
              // The nodes container has changed, so we need to update the of the added nodes.
              changes.push(
                ...getNodesAbsolutePositionUpdates(Array.from(entry.addedNodes))
              );
            }

            break;
          }

          case 'attributes': {
            // Only track changes in the position of nodes.
            if (nodeId) {
              // The nodes container has changed, so we need to update the of the added nodes.
              const element = document.getElementById(nodeId);

              if (element) {
                changes.push(...getNodesAbsolutePositionUpdates([element]));
              }
            }

            break;
          }
        }
      });

      if (changes.length) {
        updateNodesIndicatorsPosition({
          payload: changes.map(({ id, data: { x, y } }) => ({
            nodeId: id,
            position: { x, y },
          })),
        });
      }
    });
  }, [updateNodesIndicatorsPosition]);
};
