import { useCallback } from 'react';
import { useAtomValue } from 'jotai/index';
import { useUser } from '@auth0/nextjs-auth0/client';
import { analyticsClient } from '@jux/data-access/analytics';
import { AnalyticsEvents, Organization } from '@jux/data-access';
import { currentOrganizationAtom } from '@jux/ui/hooks/currentOrganization';
import { SegmentTrackPropertiesEvents } from '@jux/data-access/analytics/track-events-properties';
import { currentUserAtom } from '@jux/ui/tools/Segment';
import { useAnalyticsContextValue } from '../state';

export const useAnalytics = () => {
  const { user } = useAtomValue(currentUserAtom);
  const auth0User = useUser();
  const currentOrganization = useAtomValue(currentOrganizationAtom);
  const context = useAnalyticsContextValue();

  // custom track function to keep a closed list of events
  const trackEvent = useCallback(
    ({
      eventName,
      properties,
    }: {
      eventName: AnalyticsEvents;
      properties?: SegmentTrackPropertiesEvents;
    }) => {
      if (!user) {
        return;
      }
      analyticsClient.track({
        userId: user.id.toString(),
        event: eventName,
        properties: {
          ...properties,
          ...context,
          org_id: currentOrganization.id,
        },
      });
    },
    [context, currentOrganization.id, user]
  );

  // custom page loaded event
  const pageLoadedEvent = useCallback(
    (pageName: string, path: string) => {
      if (!user) {
        return;
      }

      analyticsClient.page({
        userId: user.id.toString(),
        name: pageName,
        properties: {
          path,
        },
      });
    },
    [user]
  );

  // custom group event
  const groupEvent = useCallback(
    (organization: Organization) => {
      if (!user) {
        return;
      }

      // Associate the user with the organization
      analyticsClient.group({
        groupId: organization.id.toString(),
        userId: user.id.toString(),
        traits: {
          name: organization.name,
          createdAt: organization.createdAt,
        },
      });
    },
    [user]
  );

  const identifyUser = useCallback(() => {
    if (!user || !auth0User.user) {
      return;
    }

    analyticsClient.identify({
      userId: user.id.toString(),
      traits: {
        email: user.email,
        createdAt: user.createdAt,
        name: auth0User.user?.name,
        firstName: auth0User.user.given_name as string | undefined,
        lastName: auth0User.user.family_name as string | undefined,
      },
    });
  }, [user, auth0User]);

  return {
    trackEvent,
    pageLoadedEvent,
    groupEvent,
    identifyUser,
  };
};
