import { DEFAULT_STATE, EnumPropValue } from '@jux/types';
import type { Draft as WritableDraft } from 'mutative';
import { getVariantDisplayName, JuxStore } from '@jux/canjux/core';
import { NodeType } from '@jux/data-entities';

export const replacePropValueInAllInstances = ({
  componentId,
  propName,
  propValue,
  newPropValue,
  components,
}: {
  componentId: string;
  propName: string;
  propValue: EnumPropValue;
  newPropValue: EnumPropValue;
  components: WritableDraft<JuxStore['components']>;
}) => {
  const componentData = components[componentId];
  const instances = Object.values(components).filter(
    (component) => component?.sourceComponentId === componentId
  );

  for (const instance of instances) {
    if (
      propName in instance.config.props &&
      instance.config.props[propName] === propValue
    ) {
      instance.config.props[propName] = newPropValue;

      // For variants inside a matrix fix display name too
      if (instance.type === NodeType.VARIANT_INSTANCE) {
        instance.displayName = getVariantDisplayName({
          interactiveState: instance.config.interactiveState ?? DEFAULT_STATE,
          variantsConfig: componentData.config.variants ?? [],
          variantValues: instance.config.props,
        });
      }
    }
  }
};
