import { FC } from 'react';
import { SubModule } from '../../../../base';
import * as S from './TextContent.style';
import { useTextContent } from './useTextContent';

export const TextContent: FC = () => {
  const { id, save, discard, registerField } = useTextContent();

  return (
    <S.TextContentLabel>
      <SubModule title="Text content" renderInline>
        <S.TextContentInput
          id={id}
          onEscape={discard}
          onEnter={save}
          {...registerField()}
          onBlurCapture={save}
        />
      </SubModule>
    </S.TextContentLabel>
  );
};
