import {
  JuxStore,
  JuxStoreActionFn,
  TokenSetsActionsParams,
} from '@jux/canjux/core';
import { namingPatternsSchema } from '@juxio/design-tokens';
import { CORE_TOKEN_SET_NAME } from '@jux/types';
import cloneDeep from 'lodash/cloneDeep';
import { z } from 'zod';

export const addFirstTokenSetAfterCore: JuxStoreActionFn<
  TokenSetsActionsParams['addFirstTokenSetAfterCore'],
  JuxStore
> = ({ state, ...params }) => {
  const { name } = z
    .object({
      name: namingPatternsSchema,
    })
    .parse(params);

  if (!(CORE_TOKEN_SET_NAME in state.tokenSets)) {
    throw new Error('Core token set not found');
  }

  state.tokenSets[name] = cloneDeep({
    ...state.tokenSets[CORE_TOKEN_SET_NAME],
    name,
  });

  delete state.tokenSets[CORE_TOKEN_SET_NAME];

  return state;
};
