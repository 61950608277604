/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { styled, css, type Merge } from '@juxio/react-styled';

export interface JuxButtonVariants {
  hierarchy: 'secondary' | 'primary' | 'Text';
  disabled: boolean;
  size: 'medium' | 'big';
}

export interface JuxButtonProps
  extends Merge<React.HTMLAttributes<HTMLButtonElement>, JuxButtonVariants> {
  text?: string;
}

const text_7f54036e = css({
  color: '#000000',
  width: 'auto',
  height: 'auto',
  display: 'inline-block',
  fontSize: '20px',
  fontFamily: 'Inter',
  lineHeight: '130%',
  whiteSpace: 'pre-wrap',
  overflowWrap: 'break-word',
});

const StyledJuxButton = styled<'button', JuxButtonProps>(
  'button',
  {
    root: {
      gap: '8px',
      color: '#FFFFFF',
      width: 'auto',
      cursor: 'pointer',
      height: '36px',
      display: 'flex',
      padding: '8px 16px',
      fontSize: '14px',
      fontStyle: 'normal',
      alignItems: 'center',
      fontFamily: 'Inter',
      fontWeight: '500',
      lineHeight: '20px',
      borderStyle: 'none',
      borderRadius: '4px',
      flexDirection: 'row',
      justifyContent: 'center',
      '&:focus': {
        outline: 'none',
      },
      '&:active': {
        outline: 'none',
      },
      '&:focus-visible': {
        borderRadius: '8px',
        outlineColor: '#3851DD',
        outlineStyle: 'solid',
        outlineWidth: '2px',
        outlineOffset: '2px',
      },
    },
    variants: [
      {
        props: {
          size: 'medium',
          disabled: false,
          hierarchy: 'primary',
        },
        style: {
          gap: '{dimension.core.size_xs}',
          width: '124px',
          height: '32px',
          padding: '0px 0px 0px 0px',
          borderRadius: '2px',
          backgroundColor: '{core.color.greys.0}',
          '&:hover': {
            backgroundColor: '{core.color.greys.200}',
          },
          '&:active': {
            backgroundColor: '{core.color.greys.400}',
          },
        },
      },
      {
        props: {
          size: 'medium',
          disabled: false,
          hierarchy: 'secondary',
        },
        style: {
          height: '28px',
          padding: '10px 10px 10px 10px',
          borderColor: '{color.fill_base.0}',
          borderStyle: 'Solid',
          borderWidth: '1px',
          borderRadius: '2px',
          backgroundColor: '{color.fill_base.-5}',
          [`.${text_7f54036e}`]: {
            fontSize: '{typography.core.12_regular.fontSize}',
            fontFamily: '{typography.core.12_regular.fontFamily}',
            fontWeight: '{typography.core.12_regular.fontWeight}',
            lineHeight: '{typography.core.12_regular.lineHeight}',
            letterSpacing: '{typography.core.12_regular.letterSpacing}',
          },
          '&:hover': {
            borderColor: '{color.fill_base.+1}',
            backgroundColor: '{color.fill_base.-5}',
          },
          '&:active': {
            borderColor: '{color.fill_base.+3}',
            backgroundColor: '{color.fill_base.-5}',
          },
        },
      },
      {
        props: {
          size: 'medium',
          disabled: true,
          hierarchy: 'primary',
        },
        style: {
          cursor: 'pointer',
          height: '28px',
          padding:
            '{dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs}',
          borderRadius: '2px',
          backgroundColor: '{core.color.greys.200}',
          '&:active': {
            height: '28px',
            padding:
              '{dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs}',
            borderRadius: '2px',
          },
        },
      },
      {
        props: {
          size: 'medium',
          disabled: true,
          hierarchy: 'secondary',
        },
        style: {
          cursor: 'pointer',
          backgroundColor: '#C8C8C8',
        },
      },
      {
        props: {
          size: 'medium',
          disabled: false,
          hierarchy: 'primary',
        },
        style: {
          gap: '{dimension.core.size_xs}',
          width: 'auto',
          height: '30px',
          display: 'flex',
          padding: '10px 10px 10px 10px',
          alignItems: 'center',
          borderRadius: '2px',
          justifyContent: 'center',
          backgroundColor: '{color.fill_base.+2}',
          [`.${text_7f54036e}`]: {
            color: 'rgba(255, 255, 255, 1)',
            fontSize: '{typography.core.12_regular.fontSize}',
            fontFamily: '{typography.core.12_regular.fontFamily}',
            fontWeight: '{typography.core.12_regular.fontWeight}',
            lineHeight: '{typography.core.12_regular.lineHeight}',
            letterSpacing: '{typography.core.12_regular.letterSpacing}',
            width: 'auto',
            height: 'auto',
          },
          '&:hover': {
            backgroundColor: '{color.fill_base.+3}',
          },
          '&:active': {
            backgroundColor: '{color.fill_base.+1}',
          },
        },
      },
      {
        props: {
          size: 'medium',
          disabled: true,
          hierarchy: 'primary',
        },
        style: {
          cursor: 'pointer',
          height: '28px',
          padding:
            '{dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs}',
          borderRadius: '2px',
          backgroundColor: '{core.color.greys.200}',
          '&:active': {
            height: '28px',
            padding:
              '{dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs}',
            borderRadius: '2px',
          },
        },
      },
      {
        props: {
          size: 'big',
          disabled: false,
          hierarchy: 'primary',
        },
        style: {
          gap: '{dimension.core.size_xs}',
          width: '124px',
          height: '32px',
          padding: '0px 0px 0px 0px',
          borderRadius: '2px',
          backgroundColor: '{core.color.greys.0}',
          '&:hover': {
            backgroundColor: '{core.color.greys.200}',
          },
          '&:active': {
            backgroundColor: '{core.color.greys.400}',
          },
        },
      },
      {
        props: {
          size: 'big',
          disabled: false,
          hierarchy: 'secondary',
        },
        style: {
          height: '36px',
          padding:
            '{dimension.core.size_sm} {dimension.core.size_ml} {dimension.core.size_sm} {dimension.core.size_ml}',
          borderColor: '{core.color.greys.400}',
          borderStyle: 'Solid',
          borderWidth: '1px',
          borderRadius: '2px',
          backgroundColor: '{core.color.greys.0}',
          [`.${text_7f54036e}`]: {
            fontSize: '{typography.core.12_regular.fontSize}',
            fontFamily: '{typography.core.12_regular.fontFamily}',
            fontWeight: '{typography.core.12_regular.fontWeight}',
            lineHeight: '{typography.core.12_regular.lineHeight}',
            letterSpacing: '{typography.core.12_regular.letterSpacing}',
          },
          '&:hover': {
            borderColor: '{core.color.greys.500}',
            backgroundColor: '{core.color.greys.0}',
          },
          '&:active': {
            borderColor: '{core.color.greys.800}',
            backgroundColor: '{core.color.greys.0}',
          },
        },
      },
      {
        props: {
          size: 'big',
          disabled: true,
          hierarchy: 'primary',
        },
        style: {
          cursor: 'pointer',
          height: '28px',
          padding:
            '{dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs}',
          borderRadius: '2px',
          backgroundColor: '{core.color.greys.200}',
          '&:active': {
            height: '28px',
            padding:
              '{dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs}',
            borderRadius: '2px',
          },
        },
      },
      {
        props: {
          size: 'big',
          disabled: true,
          hierarchy: 'secondary',
        },
        style: {
          cursor: 'pointer',
          backgroundColor: '#C8C8C8',
        },
      },
      {
        props: {
          size: 'big',
          disabled: false,
          hierarchy: 'primary',
        },
        style: {
          gap: '{dimension.core.size_xs}',
          width: 'auto',
          height: '36px',
          display: 'flex',
          padding:
            '{dimension.core.size_sm} {dimension.core.size_ml} {dimension.core.size_sm} {dimension.core.size_ml}',
          borderRadius: '2px',
          justifyContent: 'space-between',
          backgroundColor: '{core.color.greys.800}',
          [`.${text_7f54036e}`]: {
            color: 'rgba(255, 255, 255, 1)',
            fontSize: '{typography.core.12_regular.fontSize}',
            fontFamily: '{typography.core.12_regular.fontFamily}',
            fontWeight: '{typography.core.12_regular.fontWeight}',
            lineHeight: '{typography.core.12_regular.lineHeight}',
            letterSpacing: '{typography.core.12_regular.letterSpacing}',
          },
          '&:hover': {
            backgroundColor: '{core.color.greys.700}',
          },
          '&:active': {
            backgroundColor: '{core.color.greys.900}',
          },
        },
      },
      {
        props: {
          size: 'big',
          disabled: true,
          hierarchy: 'primary',
        },
        style: {
          cursor: 'pointer',
          height: '28px',
          padding:
            '{dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs}',
          borderRadius: '2px',
          backgroundColor: '{core.color.greys.200}',
          '&:active': {
            height: '28px',
            padding:
              '{dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs}',
            borderRadius: '2px',
          },
        },
      },
      {
        props: {
          size: 'medium',
          disabled: false,
          hierarchy: 'Text',
        },
        style: {
          height: 'auto',
          padding: '0px 0px 0px 0px',
          borderColor: '{core.color.greys.400}',
          borderStyle: 'None',
          borderWidth: '1px',
          borderRadius: '2px',
          backgroundColor: 'rgba(255,255,255,0)',
          opacity: '1',
          [`.${text_7f54036e}`]: {
            fontSize: '{typography.core.12_regular.fontSize}',
            fontFamily: '{typography.core.12_regular.fontFamily}',
            fontWeight: '{typography.core.12_regular.fontWeight}',
            lineHeight: '{typography.core.12_regular.lineHeight}',
            letterSpacing: '{typography.core.12_regular.letterSpacing}',
            color: '{core.color.greys.500}',
          },
          '&:hover': {
            borderColor: '{core.color.greys.500}',
            backgroundColor: '{core.color.greys.100}',
            padding: '0px 0px 0px 0px',
            height: 'auto',
            [`.${text_7f54036e}`]: {
              color: '{core.color.greys.800}',
            },
          },
          '&:active': {
            borderColor: '{core.color.greys.800}',
            backgroundColor: 'rgba(255,255,255,0)',
            [`.${text_7f54036e}`]: {
              backgroundColor: 'rgba(255, 255, 255, 0)',
            },
          },
        },
      },
      {
        props: {
          size: 'medium',
          disabled: true,
          hierarchy: 'Text',
        },
        style: {
          cursor: 'pointer',
          backgroundColor: '#C8C8C8',
        },
      },
      {
        props: {
          size: 'big',
          disabled: false,
          hierarchy: 'Text',
        },
        style: {
          height: '36px',
          padding:
            '{dimension.core.size_sm} {dimension.core.size_ml} {dimension.core.size_sm} {dimension.core.size_ml}',
          borderColor: '{core.color.greys.400}',
          borderStyle: 'Solid',
          borderWidth: '1px',
          borderRadius: '2px',
          backgroundColor: '{core.color.greys.0}',
          [`.${text_7f54036e}`]: {
            fontSize: '{typography.core.12_regular.fontSize}',
            fontFamily: '{typography.core.12_regular.fontFamily}',
            fontWeight: '{typography.core.12_regular.fontWeight}',
            lineHeight: '{typography.core.12_regular.lineHeight}',
            letterSpacing: '{typography.core.12_regular.letterSpacing}',
          },
          '&:hover': {
            borderColor: '{core.color.greys.500}',
            backgroundColor: '{core.color.greys.0}',
          },
          '&:active': {
            borderColor: '{core.color.greys.800}',
            backgroundColor: '{core.color.greys.0}',
          },
        },
      },
      {
        props: {
          size: 'big',
          disabled: true,
          hierarchy: 'Text',
        },
        style: {
          cursor: 'pointer',
          backgroundColor: '#C8C8C8',
        },
      },
    ],
  },
  {
    // Prevent non-valid HTML props from being passed to the DOM
    shouldForwardProp: (propName) => !['hierarchy'].includes(propName),
  }
);

export const JuxButton = React.forwardRef<HTMLButtonElement, JuxButtonProps>(
  function JuxButton(
    {
      hierarchy = 'secondary',
      disabled = false,
      size = 'medium',
      text = '',
      ...otherProps
    },
    ref
  ) {
    return (
      <StyledJuxButton
        hierarchy={hierarchy}
        disabled={disabled}
        size={size}
        ref={ref}
        {...otherProps}
      >
        {otherProps.children || <span className={text_7f54036e}>{text}</span>}
      </StyledJuxButton>
    );
  }
);
