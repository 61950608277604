import { useCallback } from 'react';
import {
  getNodeComputedStyles,
  selectedNodeComponentAssetData,
  selectedNodeId,
  storeApi,
} from '@jux/canjux/core';
import { AssetData } from '@jux/data-entities';
import {
  DDPFieldsState,
  getDDPFieldsStateFromStyles,
  useDDPFieldsOptionsState,
  useDDPFieldsState,
} from '@jux/ui/components/editor/state';
import { SupportedStyles, getDDPSupportedStyles } from '../utils';
import { useNodeStyles } from './useNodeStyles';
import { useSetupShouldRenderSubModule } from './useSetupShouldRenderSubModule';

const getSelectedNodeId = () => selectedNodeId(storeApi.getState());
const getSelectedNodeAssetData = () =>
  selectedNodeComponentAssetData(storeApi.getState());

export const useSetupDDPModuleState = () => {
  const { setupShouldRenderSubModulesState } = useSetupShouldRenderSubModule();
  const { setFieldsState } = useDDPFieldsState();
  const { setFieldsOptionsState } = useDDPFieldsOptionsState();
  const { getNodeStyles } = useNodeStyles();

  const setInitialDDPModuleState = useCallback(
    ({
      styles,
      initialStyles,
      assetData,
    }: {
      styles: SupportedStyles;
      initialStyles: SupportedStyles;
      assetData?: AssetData;
    }) => {
      // setting up sub modules state
      setupShouldRenderSubModulesState(styles, initialStyles);

      // setting up fields state
      setFieldsOptionsState({
        color: {
          hasMultipleValues: assetData?.hasMixedColors ?? false,
        },
      });

      const fieldState = getDDPFieldsStateFromStyles(styles, initialStyles);

      if (!fieldState) return;

      // TODO: field state at the moment is used for all the fields but we need to consider using it for primitive fields only
      setFieldsState(fieldState as DDPFieldsState);
    },
    [setFieldsOptionsState, setFieldsState, setupShouldRenderSubModulesState]
  );

  const setupDDPValues = useCallback(() => {
    const nodeId = getSelectedNodeId();
    if (!nodeId) return;

    const styles = getDDPSupportedStyles(
      getNodeStyles({
        id: nodeId,
      })
    );

    const initialStyles = getDDPSupportedStyles(getNodeComputedStyles(nodeId));

    // set the DDP state
    setInitialDDPModuleState({
      styles,
      initialStyles,
      assetData: getSelectedNodeAssetData(),
    });
  }, [getNodeStyles, setInitialDDPModuleState]);

  return {
    setupDDPValues,
  };
};
