import { useCallback, useMemo } from 'react';
import {
  CompositeTokenTypes,
  DesignTokenData,
  isAlias,
  isAliasPattern,
  isAliasToTokenType,
  SupportedTokenTypes,
} from '@juxio/design-tokens';
import { getTokenDisplayName } from '@jux/ui/components/editor/utils';
import { useEffectOnSelectedNodeChange } from '@jux/ui/components/editor/hooks';
import { useEffectOnSelectedNodeStateChange } from './useEffectOnSelectedNodeStateChange';
import { useTokensPopper } from './useTokensPopper';
import { useTokensByType } from './useTokensByType';
import { useSelectedToken } from './useSelectedToken';

export const useFieldTokens = ({
  fieldName,
  setStateByTokenValue,
  setFieldValue,
  value,
  tokenType,
  parentFieldTokenType,
}: {
  fieldName: string;
  setStateByTokenValue: (newToken: DesignTokenData) => void;
  setFieldValue: (value: string | undefined) => void;
  value: string;
  tokenType: SupportedTokenTypes | null;
  parentFieldTokenType?: CompositeTokenTypes;
}) => {
  const tokensPopper = useTokensPopper();

  const {
    parsedTokens,
    resolveFieldValueFromTokens,
    getTokenValueFromValuesMapByPath,
  } = useTokensByType(fieldName, tokenType, parentFieldTokenType);

  const {
    selectedToken,
    setSelectedToken,
    handleTokenSelection,
    handleTokenDetach,
    handleCompositeTokenDetach,
    handleCreateNewTokenSaved,
  } = useSelectedToken({
    setFieldValue,
    setStateByTokenValue,
    fieldName,
    closeTokensPopper: tokensPopper.closeTokens,
  });

  const resolvedToken = useMemo(
    () => resolveFieldValueFromTokens(value),
    [resolveFieldValueFromTokens, value]
  );

  const isAliasToParent = useMemo(() => {
    if (!parentFieldTokenType) return false;

    return isAlias(value) && isAliasToTokenType(value, parentFieldTokenType);
  }, [parentFieldTokenType, value]);

  // if doesn't have a parent field token type - we can determine if the field is tokenized by the value
  // if it has a parent field token type - we need to determine if the field is tokenized by the value
  // that was resolved from the parent field child.
  const isTokenized = useMemo(() => {
    if (!parentFieldTokenType) return Boolean(selectedToken);

    if (isAliasToParent) {
      // if this is an alias token but that points to the parent field - we need to determine
      // if the field is tokenized by the value that was resolved from the parent field child.
      if (!resolvedToken?.value) return false;

      return isAliasPattern(resolvedToken.value.toString());
    }

    return isAliasPattern(value);
  }, [
    parentFieldTokenType,
    selectedToken,
    value,
    isAliasToParent,
    resolvedToken,
  ]);

  const tokenDisplayName = useMemo(() => {
    if (!isTokenized) return;

    let tokenName = resolvedToken?.path;

    // in case the token we have points to a different token (in case of parentField), we need to re-resolve the token name and get the final token name
    // example:
    // this is me: '{typography.core.myTypoToken.fontFamily}'
    // get the value of the 'fontFamily' inside the parent token and check if it's a token too (possible value: {fontFamily.mainFont})
    // then we resolve the name of the inner token
    if (isAliasToParent && resolvedToken?.value) {
      tokenName = resolveFieldValueFromTokens(
        resolvedToken.value.toString()
      )?.path;
    }

    return getTokenDisplayName(tokenName, tokenType);
  }, [
    isTokenized,
    resolvedToken,
    isAliasToParent,
    tokenType,
    resolveFieldValueFromTokens,
  ]);

  const tokenParsedValue = useMemo(() => {
    if (!resolvedToken) return;

    return getTokenValueFromValuesMapByPath(resolvedToken.path.toString());
  }, [getTokenValueFromValuesMapByPath, resolvedToken]);

  const handleEffectChanges = useCallback(() => {
    if (!resolvedToken) {
      setSelectedToken(undefined);
      return;
    }

    setSelectedToken({
      name: resolvedToken.path ?? '',
      value: tokenParsedValue ?? '',
    });
  }, [resolvedToken, setSelectedToken, tokenParsedValue]);

  useEffectOnSelectedNodeChange(handleEffectChanges);
  useEffectOnSelectedNodeStateChange(handleEffectChanges);

  return {
    tokens: parsedTokens,
    selectedToken,
    isTokenized,
    tokensPopper,
    tokenDisplayName,
    tokenParsedValue,
    handleTokenDetach,
    handleCompositeTokenDetach,
    handleCreateNewTokenSaved,
    handleTokenSelection,
  };
};
