import { useCallback } from 'react';
import { useStoreActions } from '@jux/canjux/core';
import { useTrackEvents } from '@jux/ui/hooks';
import { ComponentTagName } from '@jux/data-entities';
import { generatePropValueName } from '../utils';

export const useHandleAddPropValue = ({
  componentId,
  tagName,
}: {
  componentId: string | undefined;
  tagName: ComponentTagName | undefined;
}) => {
  const {
    propsActions: { addComponentPropValue },
  } = useStoreActions();

  const { trackPropValueCreatedEvent } = useTrackEvents();

  const addPropValue = useCallback(
    (propName: string, allCurrentValues: Array<string>) => {
      if (!componentId || !tagName) return;

      const newPropValue = generatePropValueName(allCurrentValues);

      addComponentPropValue({
        componentId,
        propName,
        propValue: newPropValue,
      });

      trackPropValueCreatedEvent({
        componentUUID: componentId,
        tagName,
        propName,
        newPropValue,
      });
    },
    [addComponentPropValue, componentId, tagName, trackPropValueCreatedEvent]
  );

  return {
    handleAddPropValue: addPropValue,
  };
};
