import { useMemo } from 'react';
import {
  canjuxElementSelector,
  DATA_JUX_NODE_ID_ATTRIBUTE,
} from '../constants';
import { storeApi } from '../crdt';
import { NodeChange } from '../store';
import { Dimensions } from '../types';
import { getNodesAbsolutePositionUpdates } from '../utils';
import { useStoreActions } from './useStoreActions';
import { useViewportHelper } from './useViewportHelper';

/**
 * Custom hook that creates a global resize observer to track the dimensions of specified nodes.
 * @returns The resize observer instance.
 */
export const useGlobalResizeObserver = () => {
  const { fitView } = useViewportHelper();
  const {
    commonActions: { updateNodesDimensions, updateNodesIndicatorsPosition },
  } = useStoreActions();

  // Create resize observer to track each node's dimensions
  return useMemo(() => {
    return new ResizeObserver((entries) => {
      const positionsUpdates: Array<NodeChange<'position_absolute'>> = [];
      const dimensionsUpdates: Array<{
        nodeId: string;
        dimensions: Dimensions;
      }> = [];
      const { fitViewOnInit, fitViewOnInitDone, fitViewOnInitOptions } =
        storeApi.getState();

      entries.forEach((entry) => {
        const nodeId = entry.target.getAttribute(
          DATA_JUX_NODE_ID_ATTRIBUTE
        ) as string;

        const selector = canjuxElementSelector(nodeId);
        const nodeElement = document.querySelector(selector);
        if (!nodeElement) {
          return;
        }

        positionsUpdates.push(
          ...getNodesAbsolutePositionUpdates([nodeElement])
        );

        const nodeRect = nodeElement.getBoundingClientRect();

        const { transform } = storeApi.getState();

        dimensionsUpdates.push({
          nodeId,
          dimensions: {
            width: nodeRect.width / transform.zoom,
            height: nodeRect.height / transform.zoom,
          },
        });
      });

      updateNodesDimensions({
        payload: dimensionsUpdates,
      });

      updateNodesIndicatorsPosition({
        payload: positionsUpdates.map(({ id, data: { x, y } }) => ({
          nodeId: id,
          position: { x, y },
        })),
      });

      // we call fitView once initially after all dimensions are set
      let nextFitViewDone = fitViewOnInitDone;
      if (!fitViewOnInitDone && fitViewOnInit) {
        nextFitViewDone = fitView(fitViewOnInitOptions || {});
      }

      storeApi.setState((state) => {
        state.fitViewOnInitDone = nextFitViewDone;
      });
    });
  }, [fitView, updateNodesDimensions, updateNodesIndicatorsPosition]);
};
