import { DesignTokens, getAliasMatches, isAlias } from '@juxio/design-tokens';
import get from 'lodash/get';

// TODO: Move to @juxio/design-tokens
export const getAliasResolutionPath = ({
  alias,
  rawTokenSet,
  resolutionPath = [],
}: {
  alias: string;
  rawTokenSet: DesignTokens;
  resolutionPath?: string[];
}): string[] => {
  if (isAlias(alias)) {
    const { valuePath } = getAliasMatches(alias);

    resolutionPath.push(valuePath);

    const { $value: nextValue } = get(rawTokenSet, valuePath) || {};

    if (typeof nextValue === 'string' && isAlias(nextValue)) {
      return getAliasResolutionPath({
        alias: nextValue,
        rawTokenSet,
        resolutionPath,
      });
    }
  }

  return resolutionPath;
};
