export const scrollToCenter = (
  element: Element,
  wrapperElement: Element,
  behavior: ScrollBehavior = 'instant'
) => {
  const elementRect = element.getBoundingClientRect();
  const wrapperRect = wrapperElement.getBoundingClientRect();

  // Calculate the center position within the wrapper
  const center = wrapperRect.top + wrapperRect.height / 2;
  const elementCenter = elementRect.top + elementRect.height / 2;

  // Calculate the scroll amount
  const scrollAmount = elementCenter - center;

  // Adjust the wrapper's scroll position
  wrapperElement.scrollBy({
    top: scrollAmount,
    behavior,
  });
};
