import { FC } from 'react';
import { capitalize } from 'lodash';
import { Icons, SelectField } from '@jux/ui/components';
import {
  MuiListItemIcon,
  MuiListItemText,
  MuiMenuItem,
} from '@jux/ui/components/common/mui';
import { COMMON_SELECT_FIELD_ICON } from '@jux/ui/theme/palette';
import { usePositionField } from './usePositionField';
import {
  iconsToPositionValues,
  positionValueList,
} from './PositionField.interface';

export const PositionField: FC = () => {
  const { selectedPosition, handlePositionChange } = usePositionField();

  return (
    <SelectField
      name="position"
      type="text"
      value={selectedPosition || ''}
      onChange={(e) => handlePositionChange(e.target.value)}
      blurOnSelect
    >
      {positionValueList.map(
        (option) =>
          option && (
            <MuiMenuItem key={option} value={option}>
              <MuiListItemIcon>
                <Icons
                  variant={iconsToPositionValues[option]}
                  color={COMMON_SELECT_FIELD_ICON}
                />
              </MuiListItemIcon>
              <MuiListItemText primary={capitalize(option)} disableTypography />
            </MuiMenuItem>
          )
      )}
    </SelectField>
  );
};
