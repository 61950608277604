import { useCallback, useMemo } from 'react';
import { z } from 'zod';
import { parseIntoField } from './useNumericField.utils';
import { NumericFieldOptions } from './useNumericField.interface';

export const useNumericFieldValue = ({
  rawValue,
  fieldSchema,
  options,
  fieldName,
}: {
  rawValue: string;
  fieldSchema: z.ZodOptional<z.ZodString>;
  options: NumericFieldOptions;
  fieldName: string;
}) => {
  const parseValue = useCallback(
    (newValue: string) =>
      parseIntoField({ value: newValue, options, key: fieldName }),
    [fieldName, options]
  );

  const parsedValue = useMemo(
    () => parseValue(rawValue),
    [parseValue, rawValue]
  );

  const validateField = useCallback(
    (val: string) => fieldSchema.safeParse(val),
    [fieldSchema]
  );

  return {
    parsedValue,
    rawValue,
    parseValue,
    validateField,
  };
};
