import type { Draft as WritableDraft } from 'mutative';
import { CanjuxState } from '../../store';
import { deleteInstanceNodes, setRootComponentUpdateTime } from '../actions';

export const deleteNodeAndComponent = ({
  nodeId,
  shouldDeleteComponent,
  state,
}: {
  nodeId: string;
  shouldDeleteComponent: boolean;
  state: WritableDraft<CanjuxState>;
}) => {
  const nodeComponentToDelete = state.components[nodeId];
  const parent = nodeComponentToDelete?.parentId
    ? state.components[nodeComponentToDelete.parentId]
    : undefined;

  if (parent?.children && shouldDeleteComponent) {
    parent.children = parent.children.filter(
      (id) => id !== nodeComponentToDelete.id
    );
  }

  // Remove node from root-nodes list
  for (const canvas of Object.values(state.canvases)) {
    if (canvas.rootNodesOrder.includes(nodeComponentToDelete.id))
      canvas.rootNodesOrder = canvas.rootNodesOrder.filter(
        (id) => id !== nodeComponentToDelete.id
      );
    if (nodeId in canvas.nodes) {
      delete canvas.nodes[nodeId];
    }
  }

  nodeComponentToDelete.children.forEach((childId) => {
    deleteNodeAndComponent({
      nodeId: childId,
      shouldDeleteComponent,
      state,
    });
  });

  state.selectedNodesStack = state.selectedNodesStack.filter(
    (nId) => nId !== nodeId
  );
  state.hoveredNodesStack = state.hoveredNodesStack.filter(
    (n) => n.id !== nodeId
  );

  // For example in case we delete an object under a local component.
  // This will not be true when we delete an instance or a root of a component
  if (shouldDeleteComponent) {
    deleteInstanceNodes({
      sourceNodeId: nodeId,
      canvases: state.canvases,
      components: state.components,
    });

    // if deleted under a component
    setRootComponentUpdateTime({
      id: nodeId,
      components: state.components,
    });

    delete state.components[nodeId];
  }
};
