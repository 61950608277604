import { useCallback, useMemo } from 'react';
import {
  DDPPrimitiveFieldsKeys,
  DDPSubModulesKeys,
  useDDPSubModulesState,
} from '@jux/ui/components/editor/state';
import { isOpenPrevValueOrDefault } from '@jux/ui/components/editor/hooks/useSetupShouldRenderSubModule.utils';
import { useFieldValues, useSetMultiFieldsValues } from '../../../../hooks';
import { DEFAULT_POSITION_VALUE, parsePosition } from './PositionField.utils';
import { PositionValue } from './PositionField.interface';

const FIELD_KEY = DDPPrimitiveFieldsKeys.position;
export const usePositionField = () => {
  const { value } = useFieldValues(FIELD_KEY);
  const { setMultiFieldsValues } = useSetMultiFieldsValues();

  const { setSubModulesState } = useDDPSubModulesState();

  // we don't want to use the initial value in here
  // because we want to avoid using styles that the canvas is giving us
  const parsedPosition = useMemo(
    () => parsePosition(value ?? DEFAULT_POSITION_VALUE),
    [value]
  );

  const saveChanges = useCallback(
    (newPositionValue: string | undefined) => {
      // TODO: delete old css (left, right, top, bottom)
      // we want to avoid using styles that the component doesn't need anymore
      // we'll be using the deleteComponentStyles action
      if (!newPositionValue) {
        return;
      }

      const parsedPositionValue = parsePosition(newPositionValue);
      if (!parsedPositionValue) {
        return;
      }

      const isPositionAbsolute = parsedPositionValue === PositionValue.absolute;
      const isPositionRelative = parsedPositionValue === PositionValue.relative;

      setSubModulesState((prev) => ({
        ...prev,
        relativeTo: {
          shouldRender: isPositionAbsolute,
        },
        constraints: {
          shouldRender: isPositionAbsolute || isPositionRelative,
          isOpen: isOpenPrevValueOrDefault(prev, DDPSubModulesKeys.constraints),
        },
        zIndex: {
          shouldRender: isPositionAbsolute || isPositionRelative,
        },
      }));

      switch (parsedPositionValue) {
        case PositionValue.absolute: {
          setMultiFieldsValues({
            [DDPPrimitiveFieldsKeys.position]: PositionValue.absolute,
            [DDPPrimitiveFieldsKeys.top]: '0',
            [DDPPrimitiveFieldsKeys.right]: 'auto',
            [DDPPrimitiveFieldsKeys.bottom]: 'auto',
            [DDPPrimitiveFieldsKeys.left]: '0',
          });
          break;
        }
        case PositionValue.relative: {
          setMultiFieldsValues({
            [DDPPrimitiveFieldsKeys.position]: PositionValue.relative,
            [DDPPrimitiveFieldsKeys.top]: 'auto',
            [DDPPrimitiveFieldsKeys.right]: 'auto',
            [DDPPrimitiveFieldsKeys.bottom]: 'auto',
            [DDPPrimitiveFieldsKeys.left]: 'auto',
          });
          break;
        }
        case PositionValue.static: {
          // if one of the component's children has position absolute, we should notify the user
          // and then
          // if one of the component's ancestors has position relative or absolute, we should notify the user

          setMultiFieldsValues({
            [DDPPrimitiveFieldsKeys.position]: PositionValue.static,
            [DDPPrimitiveFieldsKeys.left]: 'auto',
            [DDPPrimitiveFieldsKeys.right]: 'auto',
            [DDPPrimitiveFieldsKeys.top]: 'auto',
            [DDPPrimitiveFieldsKeys.bottom]: 'auto',
          });
          break;
        }
      }
    },
    [setMultiFieldsValues, setSubModulesState]
  );

  return {
    selectedPosition: parsedPosition,
    handlePositionChange: saveChanges,
  };
};
